import { ReactComponent as BackgroundSignInLine } from "@patentedai/web/images/backgroundSignInLine.svg";
import { ReactComponent as PatentedLogo } from "@patentedai/web/images/patentedLogo.svg";

import Logo from "@patentedai/web/components/core/Logo";
interface AuthLayoutProps {
    children: React.ReactNode;
    showHeaders?: boolean;
    fullLogo?: boolean;
}

const AuthLayout = ({ children, showHeaders = true, fullLogo = false}: AuthLayoutProps) => {
    const handleLogoClick = () => {
        // Open the image in a new tab
        window.open("https://patented.ai/", "_blank");
    };

    return (
        <div className="auth-layout">
            <BackgroundSignInLine className="backgroundSignInLine" />
            <div className="login-container">
                {showHeaders && (
                    <div className={`auth-header ${fullLogo && "full-logo" }`}>
                        <Logo className="logo" variant={fullLogo? "full" : "icon"} />
                        {fullLogo && <h2 className="subtitle">IPR-tested prior art search. <span className="highlight">The ultimate patent invalidation tool.</span></h2>}
                    </div>
                )}
                {children}
                {showHeaders && (
                    <div className="auth-footer">
                        Powered by <PatentedLogo className="patented-logo"onClick={handleLogoClick} />
                    </div>
                )}
            </div>
        </div>
    );
};
export default AuthLayout;