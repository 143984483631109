import { DateTime } from "luxon";
import { Backdrop } from "@mui/material";
import PriorArtDetailMenu from "../menu/PriorArtDetailMenu";
import { useEffect, useRef, useState } from "react";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { Loading } from "@patentedai/web/components/core/Loading";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// @ts-ignore
import { BriefcaseIcon } from "@patentedai/web/images/BriefcaseIcon";
import { CalendarIcon } from "@patentedai/web/images/CalendarIcon";
import { IndustriesTag } from "@patentedai/web/images/IndustriesTag";
import { ReactComponent as PatentIcon } from "@patentedai/web/images/patentIcon.svg";
import { ReactComponent as DottedDoc } from "@patentedai/web/images/dottedDoc.svg";
import { OfflineSearch } from "@patentedai/web/entities/OfflineSearch";
import { OfflineSearchPatent } from "@patentedai/web/entities/OfflineSearchPatent";
import { OfflineSearchProduct } from "@patentedai/web/entities/OfflineSearchProduct";
import plantumlEncoder from "plantuml-encoder";
import * as Sentry from "@sentry/react";
import SearchResultHeader from "searchs/SearchResultHeader";
import { ProductCard } from "matters/ProductCard";
import ClaimOverlapTable from "searchs/components/ClaimOverlapTable";
import { sortProductsByDateAndName, extractUMLCode, filterText } from "utils/searchDetailsUtils";
import { OfflineSearchPatentDataComparer } from "@patentedai/common/entities/OfflineSearchPatent";
import UseCaseCard from "searchs/components/UseCaseCard";
import MorePatents from "searchs/components/MorePatents";

const StyledTab = styled(Tab)(() => ({
    textTransform: "none",
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 600,
    letterSpacing: "-0.01em",
    borderWidth: "1px 1px 0px 1px",
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    minHeight: "50px",
    transition: "none",
    "&.Mui-selected": {
        color: "black",
        background: "white",
        backdropFilter: "blur(50px)",
        borderRadius: "10px 10px 0px 0px",
    },
    "&:not(.Mui-selected)": {
        background: "rgba(255, 255, 255, 0.45)",
        borderRadius: "10px 10px 0px 0px",
        opacity: 0.9,
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent", // Remove the indicator line
    },
    "& .MuiTab-iconWrapper": {
        marginRight: "12px", // Remove the indicator line
    },
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            className="tab-panel"
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

export type SearchResultsViewMode = "PRODUCT" | "PATENT";

interface OfflineSearchResultDetailProps {
    initialSelectedProductId?: string;
    patent: ReducedPatentData,
    offlineSearch: OfflineSearch,
    products: OfflineSearchProduct[],
};

const OfflineSearchResultDetail = ({ offlineSearch, products, patent, initialSelectedProductId }: OfflineSearchResultDetailProps) => {
    const [selectedProduct, setSelectedProduct] = useState<OfflineSearchProduct | undefined>(undefined);
    const [isLoadingRelatedPatents, setIsLoadingRelatedPatents] = useState<boolean>(false);
    const [relatedPatents, setRelatedPatents] = useState<OfflineSearchPatent[]>([]);
    const [patentsRelatedToProduct, setPatentsRelatedToProduct] = useState<OfflineSearchPatent[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [isImageLoaded, setIsImageLoaded] = useState(true);
    const [diagramImage, setDiagramImage] = useState<Blob | null>(null);
    const [activeView, setActiveView] = useState<SearchResultsViewMode>("PRODUCT");
    const [selectedRelatedPatent, setSelectedRelatedPatent] = useState<OfflineSearchPatent>();
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    products.sort(sortProductsByDateAndName);

    const encoded = plantumlEncoder.encode(extractUMLCode(activeView === "PATENT" ? selectedRelatedPatent?.claimChartReasons[0]?.overlapDiagram || "" : selectedProduct?.claimChartReasons[0].scenarioDiagram || ""));

    useEffect(() => {
        const fetchDiagram = async () => {
            if (encoded.length < 10) setIsImageLoaded(false);
            else {
                setDiagramImage(null);
                try {
                    const response = await fetch(`https://plantuml-server-d4ifaf7eya-uc.a.run.app/img/${encoded}`);
                    if (!response.ok) {
                        setIsImageLoaded(false);
                        return;
                    }
                    const imageBlob = await response.blob();
                    setDiagramImage(imageBlob);
                    setIsImageLoaded(true);
                }
                catch {
                    setIsImageLoaded(false);
                }
            }
        };
        fetchDiagram();
    }, [selectedProduct, encoded]);

    useEffect(() => {
        if (!initialSelectedProductId)
            setSelectedProduct(products[0]);
        else {
            const product = products.find(product => product.externalId === initialSelectedProductId);
            if (!product)
                return;

            setSelectedProduct(product);
        }
        // HACK: we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    useEffect(() => {
        const callGetRelatedPatents = async () => {
            const calculateScore = (relatedPatents: OfflineSearchPatent[]): OfflineSearchPatent[] => {
                return relatedPatents.map((relatedPatent) => {
                    const { claimChartReasons } = relatedPatent;

                    const validClaimChartReasons = claimChartReasons.filter(
                        (reason) => reason.hasOverlap === true
                    );

                    const allSubElements = claimChartReasons.flatMap((reason) => reason.subElements);
                    const allSubElementsCount = allSubElements.length;
                    const validSubElementsCount = allSubElements.filter(
                        (subElement) => subElement.hasOverlap === true
                    ).length;

                    const recalculatedScore =
                        (validClaimChartReasons.length + validSubElementsCount) /
                        (validClaimChartReasons.length + allSubElementsCount);

                    return {
                        ...relatedPatent,
                        toData: relatedPatent.toData,
                        score: Math.round(recalculatedScore * 100),
                    };
                });
            };
            try {
                if (!selectedProduct || !patent)
                    return;

                setIsLoadingRelatedPatents(true);
                const relatedPatents = await OfflineSearchPatent.searchPatentWithAnalysis(selectedProduct.offlineSearchId);
                const relatePatentsWithRecalculatedScore = calculateScore(relatedPatents);
                setRelatedPatents(relatePatentsWithRecalculatedScore.sort(OfflineSearchPatentDataComparer));
                setSelectedRelatedPatent(relatePatentsWithRecalculatedScore[0]);
            } catch (error) {
                console.log(error);
                Sentry.captureException(error);
                setRelatedPatents([]);
            }
            finally {
                setIsLoadingRelatedPatents(false);
            }
        };
        const callPatentsRelatedToProduct = async () => {
            try {
                if (!selectedProduct || !patent)
                    return;

                setIsLoadingRelatedPatents(true);
                const PatentsRelatedToProduct = await OfflineSearchPatent.searchRelatedPatents(selectedProduct.offlineSearchId, selectedProduct.id);
                console.log(await OfflineSearchPatent.searchRelatedPatents(selectedProduct.offlineSearchId, selectedProduct.id));
                if (PatentsRelatedToProduct.length <= 0) setSelectedTab(0);
                setPatentsRelatedToProduct(PatentsRelatedToProduct.sort(OfflineSearchPatentDataComparer));
            } catch (error) {
                console.log(error);
                Sentry.captureException(error);
                setPatentsRelatedToProduct([]);
            }
            finally {
                setIsLoadingRelatedPatents(false);
            }
        };

        callGetRelatedPatents();
        callPatentsRelatedToProduct();
    }, [selectedProduct, patent]);

    const handleImageClick = () => {
        window.open(`https://www.plantuml.com/plantuml/img/${encoded}`, "_blank");
    };

    const handleSelectPriorArt = (product: any) => {
        if (!product)
            return;
        setActiveView("PRODUCT");
        setSelectedTab(0);
        setSelectedProduct(product as OfflineSearchProduct);  // hack: priorArt is of type OfflineSearchProduct. The PriorArtData type is used to reuse the PriorArtDetailMenu component
    };

    const onTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleSelectPatent = (patent?: OfflineSearchPatent) => {
        if (!patent)
            return;
        setSelectedRelatedPatent(patent);
        setActiveView("PATENT");
    };

    const toggleToView = (view: "PRODUCT" | "PATENT") => {
        setActiveView(view);
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    if (!patent) {
        return (
            <div>
                Patent not found!
            </div>
        );
    }
    if (!selectedProduct) {
        return (
            <Backdrop open={true}>
                <Loading />
            </Backdrop>
        );
    }

    return (
        <>
            <div className="prior-art-list-container">
                <div className="content prior-art-details-content">
                    <div className="menu">
                        <PriorArtDetailMenu
                            patent={patent}
                            searchNumber={offlineSearch.searchNumber}
                            priorArts={products}
                            relatedPatents={relatedPatents}
                            selectedRelatedPatentId={selectedRelatedPatent?.id}
                            selectedPriorArtId={selectedProduct?.externalId || ""}
                            onSelectPriorArt={handleSelectPriorArt}
                            onSelectRelatedPatent={handleSelectPatent}
                            toggleToView={toggleToView}
                            activeView={activeView}
                            blockNavigation
                        />
                    </div>
                    <div ref={scrollContainerRef} className="detail">
                        <div className="info-container">
                            <div className="info-header">
                                <SearchResultHeader
                                    priorArt={selectedProduct}
                                    activeView={activeView}
                                    returnToPriorArtView={toggleToView}
                                    patent={selectedRelatedPatent}
                                />
                            </div>
                            <div className="stretch-row">
                                {activeView === "PRODUCT" ?
                                    <div className="quick-info-row">
                                        <div className="info">
                                            <BriefcaseIcon color="#FB0F42" />{selectedProduct?.companyName}
                                        </div>
                                        {selectedProduct?.stringedReleaseDate &&
                                            <div className="info">
                                                <CalendarIcon color="#FB0F42" /> {selectedProduct.stringedReleaseDate}
                                            </div>
                                        }
                                        <div className="info">
                                            <IndustriesTag color="#FB0F42" /> {selectedProduct?.productIndustry}
                                        </div>
                                    </div>
                                    :
                                    <div className="stretch-row">
                                        <div className="patent-name">
                                            {selectedRelatedPatent?.priorArtPatentObject.title}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="add-to-matter-row">
                                <div className="quick-info-row">
                                    {activeView === "PATENT" &&
                                        <>
                                            {selectedRelatedPatent?.priorArtPatentObject.assignee &&
                                                <div className="info">
                                                    <BriefcaseIcon color="#FB0F42" />{selectedRelatedPatent?.priorArtPatentObject.assignee}
                                                </div>
                                            }
                                            {selectedRelatedPatent?.priorArtPatentObject.priorityDate &&
                                                <div className="info">
                                                    <CalendarIcon color="#FB0F42" />
                                                    <p>
                                                        <span className="highlight">PRIORITY:{" "}</span> {DateTime.fromFormat(selectedRelatedPatent.priorArtPatentObject.priorityDate, "yyyyMMdd").toFormat("yyyy, MMM dd")}
                                                    </p>
                                                </div>
                                            }
                                            {selectedRelatedPatent?.priorArtPatentObject.publicationDate &&
                                                <div className="info">
                                                    <CalendarIcon color="#FB0F42" />
                                                    <p>
                                                        <span className="highlight">PUBLICATION:{" "}</span>{DateTime.fromFormat(selectedRelatedPatent.priorArtPatentObject.publicationDate, "yyyyMMdd").toFormat("yyyy, MMM dd")}
                                                    </p>
                                                </div>
                                            }
                                            {selectedRelatedPatent?.priorArtPatentObject?.relatedIndustries?.length !== 0 &&
                                                <div className="info">
                                                    <IndustriesTag color="#FB0F42" /> {selectedRelatedPatent?.priorArtPatentObject.relatedIndustries}
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="stretch-row">
                                <div className="reason styled-scroll">
                                    {activeView === "PATENT" ? selectedRelatedPatent?.priorArtPatentObject.abstract : filterText(selectedProduct.reason)}
                                </div>
                            </div>
                        </div>
                        <UseCaseCard
                            claimChartReason={(activeView === "PATENT" ? selectedRelatedPatent : selectedProduct)?.claimChartReasons[0]}
                            diagramImage={diagramImage}
                            handleImageClick={handleImageClick}
                            isImageLoaded={isImageLoaded}
                            publicationNumber={activeView === "PATENT" ? selectedRelatedPatent?.priorArtPatentObject.publicationNumber : patent.publicationNumber}
                        />
                        <div className="tabs-container">
                            {activeView === "PATENT" ?
                                <>
                                    <div className="patent-v-patent-header">
                                        <div className="pvp-wrapper prior-art-patent-wrapper">
                                            <div className="pvp-patent-number">
                                                {patent.publicationNumber}
                                            </div>
                                        </div>
                                        <div className="vs">VS</div>
                                        <div className="pvp-wrapper related-patent-wrapper">
                                            <div className="pvp-patent-number">
                                                {selectedRelatedPatent?.priorArtPatentObject.publicationNumber}
                                            </div>
                                        </div>
                                    </div>
                                    <ClaimOverlapTable
                                        claimChartReasons={selectedRelatedPatent?.claimChartReasons}
                                        patentNumber={selectedRelatedPatent?.priorArtPatentObject.publicationNumber}
                                        patent={patent}
                                        showPvPHeader
                                    />
                                </>
                                :
                                <>
                                    <div className="patent-v-patent-header">
                                        <div className="pvp-wrapper prior-art-patent-wrapper">
                                            <div className="pvp-patent-number">
                                                {patent.publicationNumber}
                                            </div>
                                        </div>
                                        <div className="vs">VS</div>
                                        <div className="pvp-wrapper related-patent-wrapper">
                                            <div className="pvp-patent-number">
                                                {selectedProduct.productName}
                                            </div>
                                        </div>
                                    </div>
                                    <Tabs
                                        variant="scrollable"
                                        className="tabs-row"
                                        TabIndicatorProps={{ sx: { display: "none" } }}
                                        value={selectedTab}
                                        onChange={onTabChange}
                                    >
                                        <StyledTab icon={<DottedDoc />} iconPosition="start" label="Claim Overlap" />
                                        {(patentsRelatedToProduct.length > 0) &&
                                            <StyledTab icon={<PatentIcon />} iconPosition="start" label="Related Patents" />
                                        }
                                    </Tabs>
                                    <TabPanel value={selectedTab} index={0}>
                                        <ClaimOverlapTable
                                            claimChartReasons={selectedProduct.claimChartReasons}
                                            patent={patent}
                                        />
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={1}>
                                        <div className="section-container">
                                            {isLoadingRelatedPatents ?
                                                <Loading />
                                                :
                                                <>
                                                    {patentsRelatedToProduct.map((patentRelatedToProduct, rowIdx) => (
                                                        (patentRelatedToProduct.analysisStatus === "completed") && (
                                                            <ProductCard
                                                                patent={patentRelatedToProduct}
                                                                patentSearched={patent}
                                                                key={rowIdx}
                                                                onClick={handleSelectPatent}
                                                            />
                                                        )
                                                    ))}
                                                    <MorePatents
                                                        notProcessedPatents={patentsRelatedToProduct.filter(patent => {
                                                            return patent.analysisStatus !== "completed";
                                                        })}
                                                        offlineSearch={offlineSearch}
                                                        patent={patent}
                                                    />
                                                </>
                                            }
                                        </div>
                                    </TabPanel>
                                </>
                            }
                        </div>
                    </div >
                </div >
            </div >
        </>

    );
};

export default OfflineSearchResultDetail;
