import { ClaimChartReasonElementData } from "@patentedai/common/entities/ClaimChartReasonElement";
import { ClaimElementData } from "@patentedai/common/entities/Patent";
import { styled } from "@mui/material/styles";

const BingoStyle = styled("div")`
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    &.not-selected{
      .bingo-item.matched{
        background: var(--color-palette-grey-50, #8F8F91);
      }
    }
    .bingo-item{
      display: flex;
      width: 22px;
      height: 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 555px;
      box-sizing: border-box;
    }
    .not-matched{
      border: 1px solid var(--color-palette-grey-15, #DDDEDE);
      background: var(--color-palette-grey-15, #DDDEDE);
      color: var(--color-palette-grey-75, #505154);
      text-align: center;
      font-family: Manrope;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
    }
    .matched{
      background-color: #DF1440;
      color: var(--color-palette-white, #FFF);
      text-align: center;
      font-family: Manrope;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
    }
`;

interface BingoProps {
  matchedClaims: ClaimChartReasonElementData[]
  allClaims: ClaimElementData[]
  isSelected?: boolean
}

const Bingo = ({ matchedClaims, allClaims, isSelected = true }: BingoProps) => {
  return (
    <BingoStyle className={isSelected ? "" : "not-selected"}>
      {allClaims.map((claim) => {
        const matchedClaim = matchedClaims.find(matchedClaim => matchedClaim.id === claim.id);
        return (
          <>
            <div key={claim.id} className={`${matchedClaim?.hasOverlap ? "matched" : "not-matched"} bingo-item`} title={matchedClaim?.reason}>
              {claim.id}
            </div>
            {claim.subElements.map(subElement => {
              const matchedSubElement = matchedClaim?.subElements.find(matchedSubElement => matchedSubElement.id === subElement.id);
              return (
                <div key={subElement.id} className={`${matchedSubElement?.hasOverlap ? "matched" : "not-matched"} bingo-item`} title={matchedSubElement?.reason}>
                  {subElement.id}
                </div>
              );
            })}
          </>
        );
      })}
    </BingoStyle>
  );
};

export default Bingo;