import { getNonEmptyData, workspacesCollection } from "@patentedai/web/database";
import { doc, getDoc, DocumentSnapshot } from "firebase/firestore";
import { WorkspaceBase, WorkspaceData } from "@patentedai/common/entities/Workspace";

const mapEntity = function (snapshot: DocumentSnapshot<WorkspaceData>) {
    return new Workspace(snapshot.id, getNonEmptyData(snapshot));
};

export class Workspace extends WorkspaceBase {
    static async getById(id: string) {
        const reference = doc(workspacesCollection, id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            return null;
        }
        return mapEntity(snapshot);
    }
}