import { TextField } from "@mui/material";
import Button from "@patentedai/web/components/core/Button";
// @ts-ignore
import unionLogoBG from "@patentedai/web/images/unionLogoBG.svg";
import { useState } from "react";
import makeSytles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { inviteMember } from "@patentedai/web/helpers/functionHelper";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import { ResponseStatus } from "@patentedai/common/api/Common";
import * as Sentry from "@sentry/react";

const useStyles = makeSytles({
    inputOutline: {
        display: "flex",
        padding: "10px",
        background: "rgba(223, 20, 64, 0.04)",
        borderRadius: "20px",
        justifyContent: "center",
        alignItems: "center",
    },

    contentBackground: {
        backgroundImage: `url(${unionLogoBG})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundSize: "auto",
    },

    redAuthorIcon: {
        "& path": {
            stroke: "red",
        }
    }
});

const StyledTextField = styled(TextField)`
    width: 502px;
    height: 41px;
    border-radius: 10px;
    border: 1px solid #FFF;
    background: #FFF;
    padding: 10px 15px;
    box-sizing: border-box;

    & .MuiOutlinedInput-root {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }

    & fieldset {
        border:none
    }

    & .MuiInputLabel-root {
        display: none;
    }
`;

const WorkspaceInvitation = () => {
    const { workspace } = useAuth();
    const [isSendInvite, setIsSendInvite] = useState(true);
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const classes = useStyles();

    const handleSendInvite = async () => {
        try {
            if (!workspace)
                throw new Error("Workspace not found");

            setIsSendInvite(true);
            const response = await inviteMember({
                workspaceId: workspace.id,
                email
            });

            if (response.status !== ResponseStatus.Success)
                throw new Error("Error sending Invite");

            setIsSendInvite(false);
        } catch (error) {
            console.log(error);
            Sentry.captureException(error);
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setEmail(inputValue);
        setIsValidEmail(validateEmail(inputValue));
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const GotoSendInvitePage = () => {
        setIsSendInvite(true);
        setEmail("");
        setIsValidEmail(false);
    };

    if (isSendInvite) {
        return (
            <div className="patent-container">
                <div className="patent-content">
                    <div className={`content ${classes.contentBackground}`}>
                        <h1 className="title-header">Invite others to your workspace</h1>
                        <h3 className="sub-title-header">Add their email address to the input below.</h3>
                        <div className="inventor-input">
                            <StyledTextField
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                        <Button
                            className={`search-button ${isValidEmail ? "" : "disabled"}`}
                            variant="contained"
                            onClick={handleSendInvite}
                            disabled={!isValidEmail}>
                            Invite
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="patent-container">
            <div className="patent-content">
                <div className={`content ${classes.contentBackground}`}>
                    <h1 className="title-header">Invite Sent!</h1>
                    <h3 className="sub-title-header">Invitation on its way to the email you provided.</h3>
                    <Button
                        className={"search-button"}
                        variant="contained"
                        onClick={GotoSendInvitePage}>
                        Send another invitation
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default WorkspaceInvitation;