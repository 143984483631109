import bgGradient from "@patentedai/web/images/bgGradient.svg";
import makeSytles from "@mui/styles/makeStyles";

const useStyles = makeSytles({

    backgroundGradient: {
        backgroundImage: `url(${bgGradient})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "auto",
    },

    copyright: {
        position: "absolute",
        bottom: "50px",
        left: 0,
        width: "100%",
        textAlign: "center",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "12px",
        color: "#AAAAB3",
    }
});

interface AppLayoutProps {
    children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
    const classes = useStyles();

    return (
        <div className={`app-layout ${classes.backgroundGradient}`}>
            {children}
            <p className={`${classes.copyright}`}>© Copyright 2023. Licensing, Inc. Patent Pending.</p>
        </div>
    );
};
export default AppLayout;