import { createContext, useContext } from "react";
import { User } from "@patentedai/web/entities/User";
import { Workspace } from "@patentedai/web/entities/Workspace";

interface AuthContextType {
    user?: User;
    setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
    workspace?: Workspace
    setWorkspace: React.Dispatch<React.SetStateAction<Workspace | undefined>>;
    isUserAuthenticated: () => boolean;
    logOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>(
    {
        user: undefined,
        setUser: (_: React.SetStateAction<User | undefined>) => null,
        workspace: undefined,
        setWorkspace: (_: React.SetStateAction<Workspace | undefined>) => null,
        isUserAuthenticated: () => false,
        logOut: async () => Promise.resolve()
    } as AuthContextType
);

export const useAuth = (): AuthContextType => useContext(AuthContext);