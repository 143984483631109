import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";
import { initializeFirestore } from "firebase/firestore";
import { browserLocalPersistence, getAuth, initializeAuth } from "firebase/auth";

const firebaseConfiguration = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    //TODO: Implement.
    //measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfiguration);
initializeAnalytics(firebaseApp);
initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
initializeAuth(firebaseApp, { persistence: browserLocalPersistence });
export const authentication = getAuth(firebaseApp);

export default firebaseApp;