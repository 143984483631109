import { getNonEmptyData, getParentId, offlineSearchPatentsCollection } from "@patentedai/web/database";
import { DocumentSnapshot, getDocs, orderBy, query, where } from "firebase/firestore";
import { OfflineSearchPatentBase, OfflineSearchPatentData } from "@patentedai/common/entities/OfflineSearchPatent";

const mapEntity = function (snapshot: DocumentSnapshot<OfflineSearchPatentData>) {
    return new OfflineSearchPatent(snapshot.id, getParentId(snapshot), getNonEmptyData(snapshot));
};

export class OfflineSearchPatent extends OfflineSearchPatentBase {
    static async searchPatentWithAnalysis(offlineSearchId: string) {
        const snapshot = await getDocs(
            query(offlineSearchPatentsCollection(offlineSearchId),
                where("analysisStatus", "==", "completed"),
                orderBy("score", "desc")
            )
        );
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async searchRelatedPatents(offlineSearchId: string, productId: string) {
        const snapshot = await getDocs(
            query(offlineSearchPatentsCollection(offlineSearchId),
                where("relatedProducts", "array-contains", productId),
                orderBy("score", "desc")
            )
        );
        return snapshot.docs.map(x => mapEntity(x));
    }
}