import { styled } from "@mui/material/styles";
import { BriefcaseIcon } from "@patentedai/web/images/BriefcaseIcon";
import { AuthorIcon } from "@patentedai/web/images/AuthorIcon";
import { CalendarIcon } from "@patentedai/web/images/CalendarIcon";
import { IndustriesTag } from "@patentedai/web/images/IndustriesTag";
import { DateTime } from "luxon";
import Bingo from "@patentedai/web/components/core/Bingo";
import IconWithText from "@patentedai/web/components/core/IconWithText";
import Link from "@mui/material/Link";
import { MatterProductBase } from "@patentedai/common/entities/MatterProduct";
import { OfflineSearchPatent } from "@patentedai/web/entities/OfflineSearchPatent";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { SearchProductRelatedPatentBaseExtended } from "@patentedai/common/entities/SearchProductRelatedPatent";

interface CompactPatentCardProps {
  product?: MatterProductBase;
  patent?: OfflineSearchPatent | SearchProductRelatedPatentBaseExtended;
  patentSearched: ReducedPatentData;
  handleNewSearch?: (searchId: string) => void;
  onClick?: ProductOnClickHandler | OfflineProductOnClickHandler;
}

type ProductOnClickHandler = (patent?: SearchProductRelatedPatentBaseExtended) => void;
type OfflineProductOnClickHandler = (patent?: OfflineSearchPatent) => void;


//TODO: IconWithText should be its own component and used throughout the application
//TODO: maybe change if/else logic to better reuse jsx
export const ProductCard = ({ product, patent, patentSearched, handleNewSearch, onClick }: CompactPatentCardProps) => {
  const handleClick = () => {
    if (!patent) return;
    if ("workspaceId" in patent) {
      (onClick as ProductOnClickHandler)(patent);
    }
    else (onClick as OfflineProductOnClickHandler)(patent);
  };
  if (product) {
    return (
      <CardWrapper>
        <div className="product-card-container">
          <div className="product-info">
            <div className="product-row space-between">
              <div className="inner-row">
                <div className="match-meter">{`${product.score}%`}</div>
                <h3 className="product-name">{product?.productName}</h3>
              </div>
              {
                product?.releaseDate &&
                <IconWithText
                  icon={
                    <CalendarIcon color="#505154" />
                  }
                  text={DateTime.fromFormat(product.releaseDate.replace("T00:00:00", ""), "yyyy-MM-dd").toFormat("yyyy, MMM dd")}
                />
              }
            </div>
            <div className="card-info-row">
              <IconWithText
                icon={<BriefcaseIcon color="#505154" />}
                text={product?.companyName}
              />
              <IconWithText
                icon={<IndustriesTag color="#505154" />}
                text={product?.productIndustry}
              />
            </div>
            <div className="search-info">
              <Link
                component="button"
                onClick={() => handleNewSearch?.(product.relatedSearch.id)}
                className="search-id">
                Prior Art Search <span>#{product?.relatedSearch?.searchNumber} {">"}</span>
              </Link>
              <div className="patent-number">{patentSearched.publicationNumber}</div>
            </div>
          </div>
          <div className="bingo">
            <Bingo
              allClaims={patentSearched.claims.filter(claim => product.claimsQuery.includes(claim.id))}
              matchedClaims={product.claimChartReasons}
            />
          </div>
        </div>
      </CardWrapper>
    );
  }
  else if (patent?.priorArtPatent) return (
    <CardWrapper>
      <div className="product-card-container">
        <div className="product-info">
          <div className="product-row space-between">
            <div className="inner-row m-gap">
              <div className="patent-number">{patent.priorArtPatent.publicationNumber}</div>
              <IconWithText
                icon={<IndustriesTag color="#505154" />}
                text={patent.priorArtPatentObject.relatedIndustries}
              />
            </div>
            {
              patent?.priorArtPatentObject.priorityDate &&
              <IconWithText
                icon={<CalendarIcon color="#505154" />}
                text={DateTime.fromFormat(patent.priorArtPatentObject.priorityDate, "yyyyMMdd").toFormat("yyyy, MMM dd")}
              />
            }
          </div>
          <div className="product-row space-between">
            <div className="inner-row">
              <div className="match-meter">{`${patent.score}%`}</div>
              <h3 className="product-name pointer" onClick={handleClick}>{patent?.priorArtPatentObject.title}</h3>
            </div>
          </div>
          <div className="card-info-row">
            <IconWithText
              icon={<BriefcaseIcon color="#505154" />}
              text={patent?.priorArtPatentObject.assignee}
              truncateText={false}
            />
            <IconWithText
              icon={<AuthorIcon color="#505154" />}
              text={patent?.priorArtPatentObject.authors}
            />
          </div>
        </div>
        <div className="bingo">
          <Bingo
            allClaims={patentSearched.claims.filter(claim => patent.claimsQuery.includes(claim.id))}
            matchedClaims={patent.claimChartReasons}
          />
        </div>
      </div>
    </CardWrapper>
  );
  else return <></>;
};

const CardWrapper = styled("div")`
  display: flex;
  align-items: center;
  align-self: stretch;
  background: #FFF;
  border-bottom: 1px solid var(--color-palette-grey-15, #DDDEDE);
  .product-card-container{
    display: flex;
    padding: 20px 16px;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
    box-sizing: border-box;
    overflow: hidden;
    .product-info{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 11px;
      flex: 1 0 0;
      overflow: hidden;
      .product-row{
        display: flex;
        align-items: center;
        align-self: stretch;
        .inner-row{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          flex: 1 1 0;
          overflow: hidden;
          &.m-gap{
            gap: 15px;
          }
          .patent-number{
            width: fit-content;
            padding: 5px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background: var(--color-palette-red-15, #FEE9ED);
            color: var(--color-palette-red-dark, #DF1440);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Manrope;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.6px;
            white-space: nowrap;
          }
          .match-meter{
            display: flex;
            height: 26px;
            padding: 0px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background: var(--color-palette-red-100, #FB0F42);
            color: #FFF;
            text-align: center;
            font-family: Manrope;
            font-size: 13.935px;
            font-weight: 700;
          }
          .product-name{
            overflow: hidden;
            margin: 0;
            color: var(--color-palette-grey-100, #1F2023);
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: Manrope;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .pointer{
            cursor: pointer;
          }
        }
      }
      .space-between{
        gap: 30px;
        justify-content: space-between;
      }
      .card-info-row{
        display: flex;
        align-items: center;
        gap: 20px;
        align-self: stretch;
      }
      .search-info{
        display: flex;
        align-items: center;
        gap: 11px;
        .search-id{
          color: var(--color-palette-grey-100, #1F2023);
          text-align: right;
          font-family: Manrope;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px; /* 146.154% */
          letter-spacing: 0.13px;
          text-decoration: none;
          span{
            color: var(--color-palette-red-100, #FB0F42);
            font-family: Manrope;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0.65px;
          }
        }
        .patent-number{
          display: flex;
          width: fit-content;
          padding: 3px 8px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 5px;
          background: var(--color-palette-grey-5, #F1F3F3);
          color: var(--color-palette-grey-75, #505154);
          text-align: right;
          font-family: Manrope;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.6px;
        }
      }
    }
  }
  .bingo{
    max-width: 210px;
  }
`;