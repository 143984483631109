import firebaseApp from "@patentedai/web/firebaseApp";
import AuthLayout from "./AuthLayout";
import AppLayout from "./AppLayout";
import Signin from "@patentedai/web/components/Auth/Signin";
import Signup from "@patentedai/web/components/Auth/Signup";
import Auth from "@patentedai/web/components/Auth/Auth";
import PersonalInfo from "@patentedai/web/components/Auth/PersonalInfo";
import FinalStep from "@patentedai/web/components/Auth/FinalStep";
import Waitlist from "@patentedai/web/components/Auth/Waitlist";
import { AuthProvider } from "@patentedai/web/contexts/AuthContext/AuthProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import "styles/all.scss";
import Patent from "patents/Patent";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import Admin from "admin/admin";
import ClaimSelection from "priorArts/ClaimSelection";
import InventorPatents from "patents/InventorPatents";
import PatentSearchResult from "patents/PatentSearchResult";
import SearchContainer from "searchs/SearchContainer";
import { SearchProvider } from "contexts/SearchProvider";
import WorkspaceInvitation from "user/WorkspaceInvitation";
import MatterSingle from "matters/MatterSingle";
import OfflineSearchContainer from "offlineSearch/OfflineSearchContainer";
import Privacy from "privacy/Privacy";

console.log("Initialized firebase", firebaseApp);

//TODO: Refactor navigations out of App.
function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<AuthLayout fullLogo><Signin /></AuthLayout>} />
              <Route path="signin" element={<AuthLayout fullLogo><Signin /></AuthLayout>} />
              <Route path="auth" element={<AuthLayout showHeaders={false}><Auth /></AuthLayout>} />
              <Route path="signin/personalinfo" element={<AuthLayout><PersonalInfo /></AuthLayout>} />
              <Route path="signin/finalstep" element={<AuthLayout><FinalStep /></AuthLayout>} />
              <Route path="signup" element={<AuthLayout><Signup /></AuthLayout>} />
              <Route path="waitlist" element={<AuthLayout><Waitlist /></AuthLayout>} />
              <Route path="patent/" element={<AppLayout><Patent /></AppLayout>} />
              <Route path="patent/:patentNumber?" element={<AppLayout><PatentSearchResult /></AppLayout>} />
              <Route path="patent/inventor/:inventorName" element={<AppLayout><InventorPatents /></AppLayout>} />
              <Route path="prior-art/:patentPublicationNumber/selectClaim" element={<AppLayout><ClaimSelection /></AppLayout>} />
              <Route path="search/:searchId/:action/:itemId?" element={<SearchProvider><AppLayout><SearchContainer /></AppLayout></SearchProvider>} />
              <Route path="offlinesearch/:offlineSearchId" element={<AppLayout><OfflineSearchContainer /></AppLayout>} />
              <Route path="matter/:matterId?" element={<AppLayout><MatterSingle /></AppLayout>} />
              <Route path="admin" element={<AppLayout><Admin /></AppLayout>} />
              <Route path="invite" element={<AppLayout><WorkspaceInvitation /></AppLayout>} />
              <Route path="privacy" element={<Privacy/>} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider >
  );
}

export default App;
