import { styled } from "styled-components";

export const CustomStyles = styled.div`

 /* @import 'llm-security.scss'; */
 /* @import 'release-notes.scss'; */
 /* @import 'faqs.scss'; */
 /* @import 'pricing.scss'; */
 /* @import 'source-code.scss'; */
 /* @import 'signup.scss'; */
 /* @import 'terms.scss'; */
 /* @import 'footer.scss'; */
 /* @import 'animation.scss'; */
 * {
	 scroll-behavior: smooth;
}
 body {
	 font-family: 'Manrope', sans-serif;
	 box-sizing: border-box;
	 padding: 0;
	 margin: 0;
	 background-color: #020107;
}
 input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
	 -webkit-appearance: none;
	 margin: 0;
}
 input[type=number] {
	 -moz-appearance: textfield;
}
 button:focus {
	 outline: 0;
}
 a {
	 text-decoration: none;
	 transition: all 0.3s ease-in-out;
}
 a:hover {
	 text-decoration: none;
}
 .loading {
	 overflow: hidden;
	 height: 100vh;
}
 .preloader {
	 overflow: hidden;
	 position: fixed;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100vh;
	 z-index: 9999999;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 background-color: #020107;
}
 .preloader .box-preloader {
	 display: flex;
	 align-items: center;
	 flex-direction: column;
	 position: relative;
	 overflow: hidden;
	 min-width: 500px;
	 padding: 30px 0 25px;
}
 .preloader .box-preloader::before {
	 content: "";
	 width: 50%;
	 height: 20%;
	 position: absolute;
	 bottom: -40px;
	 left: 50%;
	 border-radius: 50%;
	 transform: translateX(-50%) translateZ(0);
	 background: linear-gradient(90deg, #ffc163 0%, #da8f1f 100%);
	 filter: blur(80px);
	 opacity: 100%;
	 z-index: -1;
}
 .preloader .box-preloader::after {
	 content: "";
	 position: absolute;
	 bottom: 0;
	 width: 100%;
	 height: 1px;
	 background: linear-gradient(90deg, rgba(36, 94, 127, 0) 0%, #fff 35%, #fff 65%, rgba(36, 94, 127, 0) 100%);
	 opacity: 12%;
}
 .preloader .box-preloader span {
	 display: block;
	 margin-bottom: 8px;
	 font-size: 13px;
	 line-height: 16px;
	 font-weight: 700;
	 letter-spacing: 0.19em;
	 text-transform: uppercase;
	 background: linear-gradient(90deg, #ffc163 0%, #da8f1f 100%);
	 -webkit-background-clip: text;
	 -webkit-text-fill-color: transparent;
	 background-clip: text;
	 text-fill-color: transparent;
}
 .preloader .box-preloader .logo-holder {
	 display: flex;
	 align-items: center;
	 justify-content: center;
}
 .preloader .box-preloader .logo-holder img {
	 width: 190px;
	 max-width: 190px;
}
 .preloader .box-preloader #particles-js-preloader {
	 position: absolute;
	 bottom: -100px;
	 left: 50%;
	 width: 350px;
	 height: 200px;
	 transform: translateX(-50%);
	 z-index: 1;
	 pointer-events: none;
}
 @media (max-width: 767px) {
	 .preloader .box-preloader {
		 min-width: 350px;
		 padding: 30px 0 20px;
	}
	 .preloader .box-preloader::before {
		 bottom: 0;
	}
	 .preloader .box-preloader .logo-holder img {
		 max-width: 200px;
	}
	 .preloader .box-preloader #particles-js-preloader {
		 width: 270px;
		 height: 150px;
		 bottom: -50px;
	}
}
 h1, .h1 {
	 font-size: 57px;
	 font-weight: 600;
	 line-height: 61px;
	 letter-spacing: -0.02em;
	 color: #fff;
}
 @media (max-width: 767px) {
	 h1, .h1 {
		 font-size: 50px;
		 line-height: 53px;
	}
}
 @media (max-width: 575px) {
	 h1, .h1 {
		 font-size: 40px;
		 line-height: 42px;
	}
}
 @media (max-width: 425px) {
	 h1, .h1 {
		 font-size: 35px;
		 line-height: 35px;
	}
}
 h2, .h2 {
	 font-size: 50px;
	 line-height: 53px;
	 font-weight: 600;
	 letter-spacing: -0.02em;
	 color: #fff;
}
 @media (max-width: 767px) {
	 h2, .h2 {
		 font-size: 44px;
		 line-height: 49px;
	}
}
 @media (max-width: 575px) {
	 h2, .h2 {
		 font-size: 36px;
		 line-height: 40px;
	}
}
 h3, .h3 {
	 font-weight: 600;
	 font-size: 39px;
	 line-height: 43px;
	 letter-spacing: -0.02em;
	 color: #fff;
}
 @media (max-width: 575px) {
	 h3, .h3 {
		 font-size: 30px;
		 line-height: 35px;
	}
}
 h4, .h4 {
	 font-weight: 600;
	 font-size: 28px;
	 line-height: 34px;
	 letter-spacing: -0.02em;
	 color: #fff;
}
 @media (max-width: 575px) {
	 h4, .h4 {
		 font-size: 24px;
		 line-height: 28px;
	}
}
 h5, .h5 {
	 font-size: 20px;
	 line-height: 26px;
	 letter-spacing: -0.01em;
	 color: #fff;
}
 @media (max-width: 575px) {
	 h5, .h5 {
		 font-size: 18px;
		 line-height: 23px;
	}
}
 p {
	 font-size: 17px;
	 line-height: 24px;
	 letter-spacing: -0.01em;
	 color: #78779c;
}
 p.featured-shadow {
	 font-size: 21px;
	 line-height: 29px;
	 text-align: center;
	 letter-spacing: -0.01em;
	 color: #fff;
	 text-shadow: 0px 0px 18px rgba(255, 255, 255, 0.75);
}
 @media (max-width: 575px) {
	 p.featured-shadow {
		 font-size: 18px;
		 line-height: 26px;
	}
}
 p.featured {
	 font-size: 21px;
	 line-height: 29px;
	 text-align: center;
	 letter-spacing: -0.01em;
}
 @media (max-width: 575px) {
	 p.featured {
		 font-size: 18px;
		 line-height: 26px;
	}
}
 @media (max-width: 575px) {
	 p {
		 font-size: 15px;
		 line-height: 21px;
	}
}
 .button {
	 display: inline-block;
	 padding: 16px 23px 15px 24px;
	 background: linear-gradient(90deg, #ffc163 0%, #da8f1f 100%);
	 background-size: 100% 100%;
	 text-align: center;
	 border-radius: 10px;
	 font-weight: 800;
	 font-size: 12px;
	 line-height: 16px;
	 letter-spacing: 0.3em;
	 text-transform: uppercase;
	 color: #040509;
	 text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.66);
	 transition: all 0.3s ease-in-out;
	 position: relative;
	 z-index: 1;
	 min-width: 190px;
	 animation: buttonGradient 5s ease-in-out infinite;
}
 .button img {
	 margin-left: 9px;
	 width: 8px;
	 margin-top: -2px;
}
 .button::before {
	 content: "";
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100%;
	 background: linear-gradient(90deg, #ffc163 0%, #da8f1f 100%);
	 opacity: 0.25;
	 filter: blur(20px);
	 -webkit-filter: blur(20px);
	 border-radius: 10px;
	 z-index: -1;
	 transition: all 0.3s ease-in-out;
	 transform: translateZ(0px);
}
 .button::after {
	 content: "";
	 pointer-events: none;
	 user-select: none;
	 position: absolute;
	 inset: 0px;
	 border-radius: 10px;
	 padding: 2px;
	 background: linear-gradient(#fff, #000);
	 -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
	 -webkit-mask-composite: xor;
	 opacity: 0.3;
}
 .button:hover {
	 color: #040509;
}
 .button:hover::before {
	 opacity: 0.5;
	 filter: blur(25px);
	 -webkit-filter: blur(25px);
	 transform: translateZ(0px);
}
 .button.without-arrow {
	 min-width: auto;
}
 .button.dark {
	 background: #12102b;
	 color: #fff;
	 text-shadow: none;
}
 .button.dark::before {
	 display: none;
}
 .button.dark::after {
	 opacity: 1;
	 background: linear-gradient(90deg, #fd9e47 0%, #f843d0 100%);
}
 .button.secondary {
	 background: rgba(0, 0, 0, 0);
	 color: #ffb629;
	 text-shadow: none;
	 border-radius: 8px;
	 border: 1px solid #ffb629;
}
 .button.secondary::after {
	 display: none;
}
 .button.secondary::before {
	 display: none;
}
 .button.secondary:hover {
	 background: #ffb629;
	 color: #040509;
	 text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.66);
	 box-shadow: 0 0 25px #ffb629 45;
}
 @media (max-width: 575px) {
	 .button {
		 min-width: auto;
		 padding: 14px 14px 14px 18px;
	}
}
 .outline-link {
	 color: #fff;
	 border: 1px solid #fff;
	 border-radius: 4px;
	 padding: 5px 7px 5px 8px;
	 font-weight: 600;
	 font-size: 10px;
	 line-height: 14px;
	 letter-spacing: 0.25em;
	 text-transform: uppercase;
	 transition: all 0.3s ease-in-out;
}
 .outline-link:hover {
	 color: #100d23;
	 background-color: #fff;
	 box-shadow: 0 0 25px rgba(255, 255, 255, .35);
}
 .outline-link.yellow {
	 color: #ffb629;
	 border: 1px solid #ffb629;
}
 .outline-link.yellow:hover {
	 color: #100d23;
	 background-color: #ffb629;
	 box-shadow: 0 0 25px rgba(255, 182, 41, .35);
}
 .outline-link.dark {
	 color: #1f2023;
	 border-color: #1f2023;
}
 .outline-link.dark:hover {
	 background-color: #1f2023;
	 color: #fff;
}
 .box-button-dark {
	 display: inline-block;
	 margin-top: 25px;
	 position: relative;
}
 .box-button-dark .blur {
	 position: absolute;
	 top: 0;
	 left: 50%;
	 width: 100%;
	 height: 100%;
	 background: linear-gradient(93.5deg, #ffa13e 0%, #fb34ce 100%);
	 opacity: 0;
	 filter: blur(20px);
	 -webkit-filter: blur(20px);
	 border-radius: 50%;
	 z-index: 0;
	 transition: all 0.3s ease-in-out;
	 pointer-events: none;
	 transform: translateX(-50%) translateZ(0px);
}
 .box-button-dark:hover .blur {
	 opacity: 0.35;
}
 .submit-country, .submit-press {
	 margin-top: 40px;
	 text-align: center;
}
 @media (min-width: 991px) {
	 .submit-country, .submit-press {
		 max-width: 400px;
		 margin-left: 40px;
	}
}
 .submit-country .outline-link, .submit-press .outline-link {
	 background-color: transparent;
	 color: #ffb629;
	 font-size: 12px;
	 line-height: 16px;
	 padding: 12px 30px 11px 30px;
	 border-radius: 8px;
}
 .submit-country .outline-link .triangle, .submit-press .outline-link .triangle {
	 display: inline-block;
	 margin-left: 5px;
	 width: 0;
	 height: 0;
	 border-top: 4px solid transparent;
	 border-bottom: 4px solid transparent;
	 border-left: 6px solid #ffb629;
}
 .submit-country .outline-link:hover, .submit-press .outline-link:hover {
	 background-color: #ffb629;
	 color: #100d23;
}
 .submit-country .outline-link:hover .triangle, .submit-press .outline-link:hover .triangle {
	 border-left-color: #100d23;
}
 .arrow {
	 display: inline-block;
	 position: absolute;
	 right: 30px;
	 top: 50%;
	 transform: translateY(-50%);
	 width: 8px;
	 z-index: 1;
	 pointer-events: none;
}
 .icon-holder {
	 position: relative;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 min-height: 60px;
	 margin-bottom: 20px;
}
 .icon-holder .icon-blur {
	 position: absolute;
	 width: 40px;
	 height: 70px;
	 background: #ffb629;
	 opacity: 0.25;
	 filter: blur(20px);
	 -webkit-filter: blur(20px);
	 border-radius: 50%;
	 transform: translateZ(0px);
}
 .icon-holder img {
	 position: relative;
	 z-index: 1;
}
 input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
	 -webkit-box-shadow: 0 0 0 30px #000 inset !important;
}
 input:-webkit-autofill {
	 -webkit-text-fill-color: #fff !important;
}
 .banner {
	 width: 100%;
	 padding: 8px 0;
	 background: #ffb629;
	 position: absolute;
	 top: 0;
	 z-index: 1;
}
 .banner .content-banner {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 15px;
}
 .banner .content-banner img {
	 width: 15px;
}
 .banner .content-banner p {
	 line-height: 12px;
	 font-size: 11px;
	 letter-spacing: 2.2px;
	 text-transform: uppercase;
	 color: #1f2023;
	 font-weight: 600;
	 margin: 0;
}
 .banner .content-banner a {
	 margin-left: 20px;
}
 @media (max-width: 991px) {
	 .banner .content-banner p {
		 line-height: 10px;
		 font-size: 8px;
	}
}
 @media (max-width: 767px) {
	 .banner .content-banner {
		 flex-wrap: wrap;
		 gap: 5px;
		 text-align: center;
	}
	 .banner .content-banner img {
		 display: none;
	}
	 .banner .content-banner a {
		 margin: 0;
		 padding: 4px 7px 4px 7px;
		 font-size: 8px;
	}
}
 @media (max-width: 575px) {
	 .banner .content-banner {
		 display: block;
		 line-height: 10px;
	}
	 .banner .content-banner p {
		 display: inline;
	}
	 .banner .content-banner a {
		 display: inline;
		 border: 0;
		 padding: 0;
		 border-bottom: 1px solid;
		 border-radius: 0;
		 margin-left: 6px;
		 position: relative;
	}
	 .banner .content-banner a::before {
		 content: "・";
		 position: absolute;
		 left: -10px;
	}
}
 .navbar {
	 position: fixed;
	 top: 0;
	 left: 0;
	 right: 0;
	 width: 100%;
	 padding: 20px 0;
	 z-index: 99;
	 transition: all 0.3s ease-in-out;
}
 .navbar.home-nav {
	 top: 50px;
}
 @media (max-width: 575px) {
	 .navbar.home-nav {
		 top: 35px;
	}
}
 .navbar.sticky {
	 background-color: #0d0c1e;
	 padding: 8px 0;
	 top: 0;
}
 .navbar.sticky .navbar-brand .logo {
	 max-width: 140px;
}
 .navbar .navbar-brand .logo {
	 transition: all 0.3s ease-in-out;
	 max-width: 168px;
}
 .navbar .navbar-collapse ul {
	 align-items: center;
	 margin-left: auto;
}
 .navbar .navbar-collapse ul .nav-item {
	 margin-left: 20px;
}
 .navbar .navbar-collapse ul .nav-item .nav-link {
	 font-weight: 800;
	 font-size: 11px;
	 line-height: 15px;
	 letter-spacing: 0.16em;
	 text-transform: uppercase;
	 color: #78779c;
	 transition: all 0.3s ease-in-out;
}
 .navbar .navbar-collapse ul .nav-item .nav-link:hover {
	 color: #fff;
}
 .navbar .navbar-collapse ul .nav-item .button {
	 min-width: auto;
	 padding: 12px 15px 11px 18px;
	 font-size: 11px;
	 line-height: 15px;
}
 .navbar .navbar-collapse ul .nav-item.with-button {
	 position: relative;
}
 .navbar .navbar-collapse ul .nav-item.with-button .blur {
	 position: absolute;
	 top: 0;
	 left: 50%;
	 width: 100%;
	 height: 100%;
	 background: linear-gradient(90deg, #fd9e47 0%, #f843d0 100%);
	 opacity: 0.55;
	 filter: blur(20px);
	 -webkit-filter: blur(20px);
	 border-radius: 50%;
	 z-index: -1;
	 transition: all 0.3s ease-in-out;
	 transform: translateX(-50%) translateZ(0px);
}
 .navbar .navbar-collapse ul .nav-item.with-button:hover .blur {
	 opacity: 0.8;
	 filter: blur(25px);
	 -webkit-filter: blur(25px);
}
 @media (max-width: 991px) {
	 .navbar .navbar-collapse {
		 background-color: #0d0c1e;
		 padding: 50px;
		 border-radius: 12px;
		 margin-top: 20px;
		 margin-left: 10px;
		 margin-right: 10px;
		 z-index: 9;
	}
	 .navbar .navbar-collapse ul .nav-item {
		 margin-bottom: 20px;
		 margin-left: 0;
	}
	 .navbar .navbar-collapse ul .nav-item:last-child {
		 margin-bottom: 0;
	}
}
 .navbar .navbar-toggler {
	 border: 0;
}
 .navbar .navbar-toggler span {
	 filter: invert(1);
}
 @media (max-width: 767px) {
	 .navbar .navbar-brand {
		 padding-left: 20px;
	}
}
 #introducing {
	 padding: 200px 0 70px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 position: relative;
	 overflow: hidden;
	 background-color: #020107;
}
 #introducing::after {
	 content: "";
	 position: absolute;
	 bottom: 0;
	 height: 150px;
	 width: 100%;
	 z-index: 4;
	 pointer-events: none;
}
 #introducing .horizon {
	 width: 70%;
	 height: 6px;
	 position: absolute;
	 bottom: 280px;
	 right: 0;
	 filter: blur(15px);
	 z-index: 1;
	 background: linear-gradient(90deg, rgba(14, 87, 123, 0) 0%, rgba(14, 87, 123, 1) 15%, rgba(14, 87, 123, 1) 85%, rgba(14, 87, 123, 0) 100%);
	 transform: translateZ(0);
}
 #introducing .grid {
	 width: 60%;
	 height: 300px;
	 overflow: hidden;
	 perspective: 195px;
	 position: absolute;
	 bottom: 0;
	 right: 0;
}
 #introducing .grid-fade {
	 width: 100%;
	 height: 100%;
	 position: absolute;
	 z-index: 1;
	 background: radial-gradient(ellipse at 50% 50%, rgba(2, 1, 7, 0) 0%, rgba(2, 1, 7, 100) 80%);
}
 #introducing .grid-lines {
	 width: 100%;
	 height: 200%;
	 background-size: 10px 5px;
	 background-repeat: repeat;
	 transform-origin: 100% 0 0;
	 animation: play 15s linear infinite;
}
 @media (min-width: 1920px) {

}
 @keyframes play {
	 0% {
		 transform: rotateX(45deg) translateY(-50%);
	}
	 100% {
		 transform: rotateX(45deg) translateY(0);
	}
}
 @-webkit-keyframes play {
	 0% {
		 transform: rotateX(45deg) translateY(-50%);
	}
	 100% {
		 transform: rotateX(45deg) translateY(0);
	}
}
 #introducing .introducing-content {
	 position: relative;
	 min-height: 500px;
	 display: flex;
	 flex-direction: column;
	 align-items: flex-start;
	 justify-content: flex-start;
	 z-index: 1;
}
 #introducing .introducing-content .pretitle {
	 width: 100%;
	 max-width: 400px;
	 display: flex;
	 align-items: center;
	 padding-bottom: 25px;
	 margin-bottom: 25px;
	 border-bottom: 1px solid #0e1f40;
}
 #introducing .introducing-content .pretitle p {
	 margin: 0;
	 font-weight: 800;
	 font-size: 11px;
	 line-height: 13px;
	 text-align: center;
	 letter-spacing: 0.2em;
	 text-transform: uppercase;
	 color: #ffb629;
	 text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
 #introducing .introducing-content .pretitle img {
	 width: 25px;
	 margin: 0 10px;
}
 #introducing .introducing-content h1 {
	 margin-bottom: 20px;
	 position: relative;
	 z-index: 1;
}
 #introducing .introducing-content h1 strong {
	 font-weight: 600;
	 color: #ffb629;
	 text-shadow: 0px 0px 35px rgba(255, 182, 41, 0.25);
}
 #introducing .introducing-content h4 {
	 max-width: 450px;
	 margin-bottom: 40px;
}
 #introducing .introducing-content h4 strong {
	 font-weight: 600;
	 color: #ffb629;
}
 #introducing .animation-chat {
	 position: relative;
	 z-index: 3;
}
 #introducing .animation-chat .backlight {
	 background: #f0f;
	 filter: blur(100px);
	 width: 70%;
	 height: 50%;
	 position: absolute;
	 left: 70%;
	 bottom: 0%;
	 opacity: 0.5;
	 transform: translateX(-50%) translateZ(0);
	 z-index: 0;
}
 #introducing .animation-chat .blur-chat {
	 width: 70%;
	 height: 80%;
	 position: absolute;
	 left: 25%;
	 top: 60%;
	 transform: translate(-50%, -50%) translateZ(0);
	 border-radius: 50%;
	 background: #ffb629;
	 opacity: 0.45;
	 -webkit-filter: blur(10px);
	 filter: blur(100px);
	 z-index: 0;
}
 #introducing .animation-chat .chat-area {
	 max-width: 505px;
	 margin: 0 auto;
	 border-radius: 18px;
	 overflow: hidden;
	 box-shadow: 0px 216px 86px rgba(0, 0, 0, 0.02), 0px 121px 73px rgba(0, 0, 0, 0.08), 0px 54px 54px rgba(0, 0, 0, 0.13), 0px 13px 30px rgba(0, 0, 0, 0.15), 0px 0px 0px rgba(0, 0, 0, 0.15);
	 position: relative;
	 z-index: 3;
	 line-height: 0;
}
 #introducing .animation-chat .chat-area video {
	 width: 100%;
}
 #introducing .animation-chat .pretitle {
	 margin-top: 18px;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 gap: 10px;
	 position: relative;
	 z-index: 9;
}
 #introducing .animation-chat .pretitle p {
	 margin: 0;
	 font-weight: 800;
	 font-size: 11px;
	 line-height: 13px;
	 text-align: center;
	 letter-spacing: 0.2em;
	 text-transform: uppercase;
	 color: #ffb629;
	 text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
 @media (max-width: 991px) {
	 #introducing .horizon {
		 width: 100%;
	}
	 #introducing .grid {
		 width: 100%;
	}
	 #introducing .grid .grid-lines {
		 background-size: 15px 10px;
	}
	 #introducing .animation-chat {
		 max-width: 500px;
		 margin: 0 auto;
	}
	 #introducing .introducing-content {
		 text-align: center;
		 align-items: center;
		 min-height: auto;
		 margin-bottom: 50px;
	}
	 #introducing .introducing-content .pretitle {
		 justify-content: center;
		 max-width: 100%;
	}
	 #introducing .introducing-content h4 {
		 margin: 0 auto 40px;
	}
}
 @media (max-width: 575px) {
	 #introducing {
		 padding: 150px 0 70px;
	}
	 #introducing .introducing-content .pretitle {
		 padding-bottom: 15px;
		 margin-bottom: 15px;
	}
	 #introducing .animation-chat .chat-area {
		 border-radius: 12px;
	}
	 #introducing .animation-chat .chat-area .bottom-chat .input-area .input {
		 width: 87%;
	}
	 #introducing .animation-chat .chat-area .bottom-chat .input-area .input .text img {
		 max-width: 190px;
	}
}
 @media (max-width: 425px) {
	 #introducing .introducing-content .pretitle p {
		 font-size: 9px;
	}
}
 @media (max-width: 375px) {
	 #introducing .introducing-content .pretitle {
		 flex-direction: column;
		 gap: 5px;
	}
}
 #pasting-data {
	 padding: 50px 0 40px;
	 background-position: center bottom;
	 background-size: 980px auto;
	 background-repeat: no-repeat;
	 position: relative;
	 overflow: hidden;
	 border-bottom: solid 1px;
	 border-image: linear-gradient(to right, rgba(255, 255, 255, 0), #4e73c3, rgba(255, 255, 255, 0)) 1;
}
 #pasting-data #particles-js-data {
	 position: absolute;
	 width: 70%;
	 max-width: 600px;
	 height: 300px;
	 left: 50%;
	 bottom: 0;
	 transform: translateX(-50%) translateZ(0);
	 opacity: 0.6;
	 pointer-events: none;
	 z-index: 1;
	 border-radius: 50%;
	 overflow: hidden;
}
 #pasting-data .glow-bottom {
	 width: 70%;
	 max-width: 600px;
	 height: 20%;
	 border-radius: 50%;
	 position: absolute;
	 bottom: 0;
	 left: 50%;
	 transform: translateX(-50%);
	 background: #ffb629;
	 opacity: 0.15;
	 filter: blur(100px);
}
 #pasting-data .top-content {
	 text-align: center;
	 margin-bottom: 60px;
}
 #pasting-data .top-content .pretitle {
	 margin-bottom: 20px;
}
 #pasting-data .top-content .pretitle p {
	 margin: 0;
	 font-weight: 800;
	 font-size: 11px;
	 line-height: 13px;
	 text-align: center;
	 letter-spacing: 0.2em;
	 text-transform: uppercase;
	 color: #ffb629;
	 text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
 #pasting-data .top-content h2 {
	 color: #ffb629;
}
 #pasting-data .top-content h2.white {
	 color: #fff;
}
 #pasting-data .animation-area {
	 position: relative;
	 z-index: 1;
}
 #pasting-data .animation-area .attorney-step .bubble {
	 text-align: center;
	 margin-bottom: 50px;
}
 #pasting-data .animation-area .attorney-step .bubble img {
	 width: 100%;
	 max-width: 317px;
	 -webkit-filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
	 filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
}
 #pasting-data .animation-area .attorney-step .graphic-area {
	 position: relative;
	 text-align: center;
	 max-width: 351px;
	 margin: 0 auto;
}
 #pasting-data .animation-area .attorney-step .graphic-area img {
	 width: 100%;
}
 #pasting-data .animation-area .attorney-step .graphic-area img.clip {
	 max-width: 22px;
	 position: absolute;
	 left: 14%;
	 top: 6%;
	 z-index: 1;
}
 #pasting-data .animation-area .attorney-step .graphic-area img.papers {
	 max-width: 351px;
}
 #pasting-data .animation-area .password-step {
	 position: absolute;
	 left: 0;
	 right: 0;
	 top: 0;
}
 #pasting-data .animation-area .password-step .bubble {
	 text-align: center;
	 margin-bottom: 50px;
}
 #pasting-data .animation-area .password-step .bubble img {
	 width: 100%;
	 max-width: 174px;
	 -webkit-filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
	 filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
}
 #pasting-data .animation-area .password-step .graphic-area {
	 text-align: center;
}
 #pasting-data .animation-area .password-step .graphic-area .passwords-box .password {
	 display: block;
	 width: 100%;
	 margin: 0 auto;
	 -webkit-filter: drop-shadow(0px 6px 40px rgba(0, 0, 0, 0.15));
	 filter: drop-shadow(0px 6px 40px rgba(0, 0, 0, 0.15));
}
 #pasting-data .animation-area .password-step .graphic-area .passwords-box .password.password-1 {
	 max-width: 511px;
	 margin-bottom: 10px;
}
 #pasting-data .animation-area .password-step .graphic-area .passwords-box .password.password-2 {
	 max-width: 456px;
	 margin-bottom: 0px;
}
 #pasting-data .animation-area .password-step .graphic-area .passwords-box .password.password-3 {
	 max-width: 411px;
	 margin-bottom: -10px;
}
 #pasting-data .animation-area .password-step .graphic-area .passwords-box .password.password-4 {
	 max-width: 362px;
}
 #pasting-data .animation-area .customers-step {
	 position: absolute;
	 left: 0;
	 right: 0;
	 top: 0;
}
 #pasting-data .animation-area .customers-step .bubble {
	 text-align: center;
	 margin-bottom: 50px;
}
 #pasting-data .animation-area .customers-step .bubble img {
	 width: 100%;
	 max-width: 217px;
	 -webkit-filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
	 filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
}
 #pasting-data .animation-area .customers-step .graphic-area {
	 position: relative;
	 max-width: 316px;
	 margin: 0 auto;
}
 #pasting-data .animation-area .customers-step .graphic-area img {
	 width: 100%;
}
 #pasting-data .animation-area .customers-step .graphic-area img.top-secret {
	 max-width: 131px;
	 position: absolute;
	 left: 14%;
	 top: 8%;
	 z-index: 1;
}
 #pasting-data .animation-area .customers-step .graphic-area img.customer-list {
	 max-width: 316px;
}
 @media (max-width: 575px) {
	 #pasting-data {
		 padding: 50px 0 0;
		 background-size: 100% auto;
	}
	 #pasting-data .glow-bottom {
		 width: 100%;
		 opacity: 0.3;
	}
	 #pasting-data .animation-area .attorney-step .bubble, #pasting-data .animation-area .password-step .bubble, #pasting-data .animation-area .customers-step .bubble {
		 margin-bottom: 20px;
	}
	 #pasting-data .animation-area .password-step .graphic-area .passwords-box .password.password-1 {
		 margin-bottom: 20px;
	}
	 #pasting-data .animation-area .password-step .graphic-area .passwords-box .password.password-2 {
		 margin-bottom: 20px;
	}
	 #pasting-data .animation-area .password-step .graphic-area .passwords-box .password.password-3 {
		 margin-bottom: 15px;
	}
	 #pasting-data .animation-area .password-step .graphic-area .passwords-box .password.password-4 {
		 margin-bottom: 0;
	}
}
 #visibility {
	 padding: 100px 0 120px;
	 background: #0d0c1e;
	 background: linear-gradient(180deg, rgba(0, 0, 0, 0) 57.83%, #08080f 100%), #0d0c1e;
	 overflow-x: clip;
}
 #visibility .bottom-content {
	 text-align: center;
}
 #visibility .bottom-content h2 {
	 max-width: 600px;
	 margin: 0 auto 30px;
}
 #visibility .bottom-content h2 strong {
	 font-weight: 600;
	 color: #ffb629;
}
 #visibility .graphics {
	 position: relative;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 margin: 0 auto 70px;
	 perspective: 2000px;
}
 #visibility .graphics .text-indicator {
	 position: absolute;
	 top: 45%;
	 right: -25px;
	 transform: translateY(-50%);
	 filter: drop-shadow(0 0 10px #fff 4c);
}
 #visibility .graphics .text-indicator.animated {
	 animation: pulseShadow 3s ease infinite;
}
 #visibility .graphics .backlight {
	 width: 75%;
	 height: 75%;
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%) translateZ(0);
	 background: linear-gradient(269.32deg, #fe9d44 1.25%, #fc41ce 96.95%);
	 opacity: 0.2;
	 filter: blur(100px);
	 -webkit-filter: blur(100px);
	 pointer-events: none;
	 z-index: 0;
	 border-radius: 50%;
	 display: none;
}
 #visibility .graphics .blur-dashboard {
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -55%) translateZ(0px);
	 max-width: 1100px;
	 opacity: 0;
}
 #visibility .graphics .blur-dashboard.animated {
	 animation: showBlur 5000ms ease-out forwards;
	 animation-delay: 1s;
}
 #visibility .graphics .dashboard {
	 position: relative;
	 z-index: 3;
	 transform: rotateX(35deg) translateZ(0);
	 overflow: hidden;
	 border-radius: 8px;
}
 #visibility .graphics .dashboard.animated {
	 animation: rotateCarousel 1.4s ease forwards;
	 animation-delay: 0.7s;
}
 #visibility .graphics .dashboard::before {
	 content: "";
	 position: absolute;
	 width: 200%;
	 height: 200%;
	 top: -50%;
	 left: -50%;
	 right: 0;
	 bottom: 0;
	 background: conic-gradient(transparent, transparent, transparent, rgba(255, 255, 255, 0.4));
	 animation: borderLight 7s linear infinite;
	 z-index: 1;
}
 #visibility .graphics .dashboard .border-animation {
	 position: absolute;
	 inset: 1px;
	 border-radius: 8px;
	 background: #000;
	 z-index: 1;
}
 #visibility .graphics .dashboard .blur {
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -45%) translateZ(0);
	 width: 100%;
	 height: 100%;
	 border-radius: 50%;
	 opacity: 0;
	 background: linear-gradient(224deg, #fe9d44 0%, #fc41ce 100%);
	 filter: blur(100px);
	 pointer-events: none;
}
 #visibility .graphics .dashboard .blur.animated {
	 animation: showColorBlur 5000ms ease-out forwards;
	 animation-delay: 1s;
}
 #visibility .graphics .dashboard .overlay {
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100%;
	 z-index: 1;
	 background-color: #271b2e;
	 border-radius: 8px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 z-index: 2;
}
 #visibility .graphics .dashboard .overlay img {
	 max-width: 200px;
}
 #visibility .graphics .dashboard .overlay.animated {
	 animation: removeOverlay 1.8s ease forwards;
	 animation-delay: 0.6s;
}
 #visibility .graphics .dashboard img {
	 width: 100%;
	 max-width: 835px;
	 position: relative;
	 z-index: 1;
}
 #visibility .graphics .dashboard .right-dashboard {
	 position: absolute;
	 top: 50%;
	 transform: translateY(-46%);
	 right: 18px;
	 width: 100%;
	 height: 100%;
	 max-width: 623px;
	 max-height: 426px;
	 background: rgba(0, 0, 0, 0.15);
	 border-radius: 0px 8px 8px 0px;
	 z-index: 1;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list ul#nav_0 {
	 padding: 20px 25px 0;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .top-dashboard img {
	 max-width: 571px;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns {
	 width: 100%;
	 display: flex;
	 align-items: center;
	 padding: 12px 22px;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns .name, #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns .devices {
	 text-transform: uppercase;
	 font-size: 8px;
	 font-weight: 700;
	 line-height: 11px;
	 color: #b7b9da;
	 letter-spacing: 0.25em;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns .activity {
	 letter-spacing: 0.25em;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .user-item {
	 width: 100%;
	 max-width: 571px;
	 background: rgba(0, 0, 0, 0.15);
	 border-radius: 8px;
	 display: flex;
	 align-items: center;
	 padding: 11px 22px;
	 margin: 1px 0;
	 transition: all 0.3s ease-in-out;
	 border: 1px solid rgba(255, 182, 41, 0);
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .user-item > div {
	 pointer-events: none;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .user-item:nth-child(even) {
	 background: transparent;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .user-item.hover, #visibility .graphics .dashboard .right-dashboard .navigation__list .user-item:hover {
	 border: 1px solid rgba(255, 182, 41, 0.30);
	 background: rgba(255, 182, 41, 0.10);
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .name {
	 width: 40%;
	 font-size: 13px;
	 line-height: 15px;
	 color: #fff;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .devices {
	 display: flex;
	 align-items: center;
	 gap: 10px;
	 width: 35%;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .devices .number {
	 width: 25px;
	 height: 25px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 font-size: 10px;
	 line-height: 15px;
	 letter-spacing: 0.02em;
	 color: #ffb629;
	 font-weight: 700;
	 background: rgba(255, 182, 41, 0.1);
	 border-radius: 50%;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .devices .device {
	 max-width: 15px;
	 max-height: 14px;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .activity {
	 width: 25%;
	 font-weight: 700;
	 font-size: 8px;
	 line-height: 12px;
	 letter-spacing: 0.25em;
	 text-transform: uppercase;
	 color: #ffb629;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .detail-user .user-info .dashboard-bg {
	 position: absolute;
	 top: 0;
	 right: 0;
	 pointer-events: none;
}
 #visibility .graphics .dashboard .right-dashboard .navigation__list .detail-user .user-info .user-name {
	 max-width: 251px;
	 position: absolute;
	 left: 35px;
	 top: 26px;
	 z-index: 9;
	 pointer-events: none;
}
 #visibility .graphics .dashboard .navigation {
	 position: relative;
	 width: 100%;
	 height: 100%;
	 margin: 0 auto;
	 overflow: hidden;
}
 #visibility .graphics .dashboard .navigation__header ul li {
	 color: #ffb629;
	 font-size: 10px;
	 line-height: 14px;
	 font-weight: 700;
	 letter-spacing: 0.25em;
	 text-transform: uppercase;
	 position: absolute;
	 top: 25px;
	 right: 30px;
	 transition: all 0.3s ease-in-out;
}
 #visibility .graphics .dashboard .navigation__header ul li:hover {
	 text-shadow: 0 0 20px #ffb829 97;
}
 #visibility .graphics .dashboard .button-list {
	 list-style: none;
	 margin: 0;
	 padding: 0;
}
 #visibility .graphics .dashboard .button-list li {
	 cursor: pointer;
}
 #visibility .graphics .dashboard .navigation__list {
	 opacity: 1;
	 transition: all 0.3s ease-in-out;
}
 #visibility .graphics .dashboard .navigation__list ul {
	 display: none;
	 list-style: none;
	 margin: 0;
	 padding: 0;
}
 #visibility .graphics .dashboard .navigation__list li {
	 cursor: pointer;
}
 #visibility .graphics .dashboard .navigation__list .active {
	 display: block;
}
 #visibility .graphics .dashboard .navigation__list .right-active, #visibility .graphics .dashboard .navigation__list .left-active {
	 position: absolute;
	 width: 100%;
	 top: 0;
	 transition: all 0.3s ease-in-out;
}
 #visibility .graphics .dashboard .navigation__list .right-active {
	 left: 0%;
}
 #visibility .graphics .dashboard .navigation__list .left-active {
	 left: 0%;
}
 #visibility .graphics .dashboard .navigation__list.transit-to-left, #visibility .graphics .dashboard .navigation__list.transit-to-right {
	 transition: all 0.3s ease-in-out;
}
 #visibility .graphics .dashboard .navigation__list.transit-to-left {
	 opacity: 0;
}
 #visibility .graphics .dashboard .navigation__list.transit-to-right {
	 opacity: 0;
}
 @media (max-width: 1200px) {
	 #visibility .graphics .text-indicator {
		 right: -90px;
	}
}
 @media (max-width: 991px) {
	 #visibility .graphics .text-indicator {
		 right: -100px;
		 z-index: 4;
	}
	 #visibility .graphics .dashboard .right-dashboard {
		 max-width: 520px;
		 max-height: 350px;
		 right: 10px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list ul#nav_0 {
		 padding: 10px 10px 0;
		 overflow: scroll;
		 max-height: 350px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns {
		 padding: 8px 15px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .user-item {
		 padding: 8px 15px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .name {
		 width: 35%;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .devices {
		 width: 30%;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .activity {
		 width: 35%;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .detail-user .user-info .user-name {
		 max-width: 190px;
	}
	 #visibility .graphics .blur-dashboard {
		 max-width: 800px;
	}
}
 @media (max-width: 767px) {
	 #visibility .graphics .dashboard .navigation__header ul li {
		 font-size: 8px;
		 line-height: 12px;
		 top: 17px;
		 right: 20px;
	}
	 #visibility .graphics .dashboard .right-dashboard {
		 max-width: 387px;
		 max-height: 260px;
		 right: 5px;
		 transform: none;
		 top: 32px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list ul#nav_0 {
		 max-height: 260px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns {
		 padding: 5px 10px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns .name, #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns .devices, #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns .activity {
		 font-size: 7px;
		 font-weight: 500;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .user-item {
		 padding: 10px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .name {
		 width: 30%;
		 font-size: 10px;
		 line-height: 12px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .devices {
		 width: 25%;
		 gap: 5px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .devices .number {
		 display: none;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .devices .device {
		 max-width: 12px;
		 max-height: 11px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .activity {
		 width: 45%;
		 font-size: 7px;
		 line-height: 11px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .detail-user .user-info .user-name {
		 max-width: 140px;
		 left: 25px;
		 top: 20px;
	}
}
 @media (max-width: 575px) {
	 #visibility .graphics .text-indicator {
		 right: 10px;
		 z-index: 4;
		 top: initial;
		 transform: rotate(15deg);
		 bottom: -50px;
		 max-width: 100px;
	}
	 #visibility .graphics .dashboard .overlay img {
		 max-width: 120px;
	}
	 #visibility .graphics .dashboard .navigation__header ul li {
		 font-size: 5px;
		 line-height: 11px;
		 top: 9px;
		 right: 15px;
	}
	 #visibility .graphics .dashboard .right-dashboard {
		 max-width: 76%;
		 max-height: 85%;
		 top: 11%;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list ul#nav_0 {
		 padding: 5px 5px 0;
		 max-height: 100%;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns {
		 padding: 5px 10px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns .name, #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns .devices, #visibility .graphics .dashboard .right-dashboard .navigation__list .top-columns .activity {
		 font-size: 5px;
		 letter-spacing: 0.03em;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .user-item {
		 padding: 8px 7px;
		 border-radius: 3px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .name {
		 width: 35%;
		 font-size: 7px;
		 line-height: 12px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .devices {
		 width: 30%;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .devices .device {
		 max-width: 9px;
		 max-height: 8px;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .activity {
		 width: 35%;
		 font-size: 5px;
		 line-height: 9px;
		 letter-spacing: 0.02em;
	}
	 #visibility .graphics .dashboard .right-dashboard .navigation__list .detail-user .user-info .user-name {
		 max-width: 32%;
		 left: 6%;
		 top: 16px;
	}
	 #visibility .graphics .blur-dashboard {
		 max-width: 500px;
	}
}
 @media (max-width: 425px) {
	 #visibility .graphics .text-indicator {
		 bottom: -40px;
		 max-width: 70px;
	}
}
 #how-it-works-homepage {
	 padding: 100px 0;
	 position: relative;
	 overflow: hidden;
}
 #how-it-works-homepage .backlight {
	 width: 100%;
	 max-width: 1200px;
	 height: 60%;
	 border-radius: 50%;
	 opacity: 0;
	 background: #0c15ff;
	 filter: blur(100px);
	 position: absolute;
	 top: -8%;
	 left: 50%;
	 transform: translateX(-50%) translateZ(0);
}
 #how-it-works-homepage .top-area {
	 position: relative;
	 z-index: 1;
	 text-align: center;
	 margin-bottom: 50px;
}
 #how-it-works-homepage .top-area h4 {
	 color: #ffb629;
}
 #how-it-works-homepage .top-area p {
	 max-width: 860px;
	 margin: 0 auto;
}
 #how-it-works-homepage .container-areas {
	 display: flex;
	 gap: 30px;
	 justify-content: center;
	 align-items: center;
	 max-width: 935px;
	 margin: 0 auto;
	 position: relative;
	 z-index: 1;
}
 #how-it-works-homepage .container-areas .arrows {
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 100%;
	 height: 100%;
	 max-width: 71%;
}
 #how-it-works-homepage .container-areas .arrows img {
	 max-width: 375px;
	 position: absolute;
	 left: 50%;
	 transform: translateX(-50%);
	 z-index: 1;
}
 #how-it-works-homepage .container-areas .arrows .configuration-arrow {
	 top: 15%;
}
 #how-it-works-homepage .container-areas .arrows .sensitive-arrow {
	 bottom: 15%;
}
 #how-it-works-homepage .container-areas .area {
	 width: 23%;
	 text-align: center;
	 position: relative;
}
 #how-it-works-homepage .container-areas .area img {
	 max-width: 201px;
}
 #how-it-works-homepage .container-areas .area.large {
	 width: 54%;
}
 #how-it-works-homepage .container-areas .area.large img {
	 max-width: 457px;
}
 #how-it-works-homepage .container-areas .area.large .arrow-data-red {
	 position: absolute;
	 max-width: 143px;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -30%);
}
 #how-it-works-homepage .container-areas .area.large .vertical-arrow {
	 max-width: 37px;
	 position: absolute;
	 top: 50%;
	 right: 22%;
	 transform: translateY(-30%);
}
 #how-it-works-homepage .bottom-cta {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 30px;
	 margin-top: 50px;
}
 #how-it-works-homepage .bottom-cta p {
	 margin: 0;
	 color: #b7b9da;
}
 @media (max-width: 991px) {
	 #how-it-works-homepage .container-areas {
		 gap: 10px;
	}
	 #how-it-works-homepage .container-areas .arrows img {
		 max-width: 270px;
	}
	 #how-it-works-homepage .container-areas .area img {
		 width: 100%;
	}
	 #how-it-works-homepage .container-areas .area.large .arrow-data-red {
		 max-width: 100px;
	}
	 #how-it-works-homepage .container-areas .area.large .vertical-arrow {
		 max-width: 25px;
	}
}
 @media (max-width: 767px) {
	 #how-it-works-homepage .container-areas .arrows img {
		 max-width: 180px;
	}
	 #how-it-works-homepage .container-areas .area.large .arrow-data-red {
		 max-width: 80px;
	}
	 #how-it-works-homepage .container-areas .area.large .vertical-arrow {
		 max-width: 20px;
	}
	 #how-it-works-homepage .bottom-cta {
		 flex-direction: column;
		 gap: 15px;
	}
}
 @media (max-width: 575px) {
	 #how-it-works-homepage .container-areas .arrows img {
		 max-width: 55%;
	}
	 #how-it-works-homepage .container-areas .area.large .arrow-data-red {
		 max-width: 30%;
	}
	 #how-it-works-homepage .container-areas .area.large .vertical-arrow {
		 max-width: 8%;
	}
}
 .quote-llm-shield {
	 padding: 50px 0 100px;
	 position: relative;
}
 .quote-llm-shield.transparent {
	 padding-top: 150px;
	 background: #0d0c1e;
}
 .quote-llm-shield.dark {
	 padding: 150px 0 100px;
	 background: linear-gradient(180deg, #000 57.83%, #020107 100%), #0d0c1e;
}
 .quote-llm-shield.biospring {
	 background: linear-gradient(180deg, rgba(0, 0, 0, 0) 57.83%, #08080f 100%), #090911;
	 padding: 100px 0 100px;
}
 .quote-llm-shield.dark-grey {
	 background-color: #090911;
}
 .quote-llm-shield .backlight {
	 width: 80%;
	 height: 55%;
	 position: absolute;
	 top: 0%;
	 left: 50%;
	 transform: translateX(-50%) translateZ(0px);
	 background: #0c15ff;
	 opacity: 0.25;
	 filter: blur(100px);
	 -webkit-filter: blur(100px);
	 pointer-events: none;
	 z-index: 0;
}
 .quote-llm-shield .quote-box {
	 max-width: 1000px;
	 margin: 0 auto;
}
 .quote-llm-shield .quote-box .quote-description {
	 background: #12102b;
	 border: 1px solid rgba(21, 132, 255, 0.18);
	 border-radius: 22px;
	 padding: 35px;
	 position: relative;
}
 .quote-llm-shield .quote-box .quote-description p {
	 font-size: 16.5px;
	 line-height: 29px;
	 margin: 0;
}
 .quote-llm-shield .quote-box .quote-description p strong {
	 color: #ffb629;
	 font-weight: 400;
}
 .quote-llm-shield .quote-box .quote-description::after, .quote-llm-shield .quote-box .quote-description::before {
	 content: "";
	 width: 30px;
	 height: 25px;
	 display: inline-block;
	 background-size: contain;
	 background-repeat: no-repeat;
	 background-position: center;
	 position: absolute;
	 top: -14px;
	 left: 35px;
	 transform: rotate(180deg);
}
 .quote-llm-shield .quote-box .quote-description::after {
	 top: initial;
	 bottom: -14px;
	 left: initial;
	 right: 35px;
	 transform: none;
}
 .quote-llm-shield .quote-box .quote-description .quote-tip {
	 position: absolute;
	 left: 50%;
	 bottom: -14px;
	 transform: translateX(-50%);
}
 .quote-llm-shield .quote-box .data-quote {
	 margin-top: 30px;
}
 .quote-llm-shield .quote-box .data-quote h4 {
	 font-size: 24px;
	 text-align: center;
}
 .quote-llm-shield .quote-box .data-quote .companies {
	 display: flex;
	 align-items: flex-start;
	 justify-content: center;
	 gap: 60px;
	 max-width: 500px;
	 margin: 0 auto;
}
 .quote-llm-shield .quote-box .data-quote .companies img.connect-america {
	 max-width: 99px;
}
 .quote-llm-shield .quote-box .data-quote .companies img.biospring {
	 max-width: 117px;
}
 .quote-llm-shield .quote-box .data-quote .companies .company {
	 width: 50%;
	 text-align: right;
	 position: relative;
	 padding: 20px 0;
}
 .quote-llm-shield .quote-box .data-quote .companies .company .role {
	 display: block;
	 font-size: 11px;
	 line-height: 15px;
	 font-weight: 700;
	 text-align: right;
	 letter-spacing: 0.16em;
	 text-transform: uppercase;
	 color: #78779c;
	 margin-top: 15px;
}
 .quote-llm-shield .quote-box .data-quote .companies .company.right {
	 text-align: left;
}
 .quote-llm-shield .quote-box .data-quote .companies .company.right span {
	 text-align: left;
}
 .quote-llm-shield .quote-box .data-quote .companies .company.right::after {
	 content: "";
	 height: 100%;
	 width: 1px;
	 border-left: solid 1px;
	 border-image: linear-gradient(to top, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0)) 1;
	 position: absolute;
	 top: 0;
	 left: -30px;
	 opacity: 0.1;
}
 .quote-llm-shield .quote-box .data-quote .companies .company.center {
	 text-align: center;
}
 .quote-llm-shield .quote-box .data-quote .companies .company.center span {
	 text-align: center;
}
 @media (max-width: 575px) {
	 .quote-llm-shield {
		 padding: 25px 0 30px;
	}
	 .quote-llm-shield.dark {
		 padding: 100px 0 50px;
	}
	 .quote-llm-shield.biospring {
		 padding: 50px 0;
	}
	 .quote-llm-shield .quote-box .quote-description {
		 padding: 25px;
	}
}
 #best-organizations {
	 padding: 140px 0 140px;
	 overflow: hidden;
	 background: #000;
}
 #best-organizations .content-papers {
	 text-align: center;
	 margin: 0 auto 100px;
}
 #best-organizations .content-papers .pretitle {
	 margin-bottom: 20px;
}
 #best-organizations .content-papers .pretitle p {
	 margin: 0;
	 font-weight: 800;
	 font-size: 11px;
	 line-height: 13px;
	 text-align: center;
	 letter-spacing: 0.2em;
	 text-transform: uppercase;
	 color: #ffb629;
	 text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
 #best-organizations .content-papers h2 {
	 color: #ffb629;
}
 #best-organizations .content-papers h2.white {
	 color: #fff;
}
 #best-organizations .box-papers {
	 position: relative;
	 text-align: center;
	 max-width: 690px;
	 margin: 0 auto;
}
 #best-organizations .box-papers::after {
	 content: "";
	 width: 100%;
	 height: 60%;
	 position: absolute;
	 top: 50%;
	 left: 0;
	 transform: translateY(-50%) translateZ(0px);
	 background: #0c15ff;
	 opacity: 0.3;
	 filter: blur(80px);
	 -webkit-filter: blur(80px);
}
 #best-organizations .box-papers .paper {
	 margin-top: -33px;
	 position: relative;
	 z-index: 1;
}
 #best-organizations .box-papers .paper .paper-1 {
	 max-width: 541px;
	 filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.35));
	 -webkit-filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.35));
}
 #best-organizations .box-papers .paper .paper-2 {
	 max-width: 594px;
}
 #best-organizations .box-papers .paper .paper-3 {
	 max-width: 637px;
	 filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.35));
	 -webkit-filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.35));
}
 #best-organizations .box-papers .paper .paper-4 {
	 max-width: 514px;
}
 #best-organizations .box-papers .paper .paper-5 {
	 max-width: 541px;
	 filter: drop-shadow(0px 7px 18px rgba(0, 0, 0, 0.55));
	 -webkit-filter: drop-shadow(0px 7px 18px rgba(0, 0, 0, 0.55));
}
 #best-organizations .content-area {
	 margin-top: 60px;
	 text-align: center;
}
 #best-organizations .content-area p:nth-child(2) {
	 max-width: 450px;
	 margin: 25px auto 10px;
}
 @media (max-width: 767px) {
	 #best-organizations {
		 padding: 120px 0 100px;
	}
	 #best-organizations .box-papers .paper {
		 margin-top: -25px;
	}
	 #best-organizations .box-papers .paper img {
		 width: 100%;
	}
}
 @media (max-width: 475px) {
	 #best-organizations {
		 padding: 120px 0 80px;
	}
	 #best-organizations .box-papers .paper {
		 margin-top: -20px;
	}
	 #best-organizations .box-papers::after {
		 display: none;
	}
}
 #toggle-section {
	 padding: 100px 0;
}
 #toggle-section .privacy-status {
	 margin: 0;
}
 #policies {
	 padding: 100px 0;
	 overflow: hidden;
}
 #policies .top-content {
	 text-align: center;
	 margin-bottom: 60px;
}
 #policies .top-content h2 strong {
	 color: #ffb629;
	 font-weight: 600;
}
 #policies .top-content p {
	 margin-top: 25px;
}
 #policies .top-content p strong {
	 color: #ffb629;
	 font-weight: 400;
}
 #policies .dashboard-policies {
	 position: relative;
	 z-index: 1;
	 max-width: 627px;
	 border-radius: 8px;
}
 #policies .dashboard-policies .image-area {
	 position: relative;
	 overflow: hidden;
	 border-radius: 8px;
}
 #policies .dashboard-policies .image-area::before {
	 content: "";
	 position: absolute;
	 width: 200%;
	 height: 200%;
	 top: -50%;
	 left: -50%;
	 right: 0;
	 bottom: 0;
	 background: conic-gradient(transparent, transparent, transparent, rgba(255, 255, 255, 0.4));
	 animation: borderLight 7s linear infinite;
	 z-index: 1;
}
 #policies .dashboard-policies .image-area .border-animation {
	 position: absolute;
	 inset: 1px;
	 border-radius: 8px;
	 background: #000 0;
	 z-index: 1;
}
 #policies .dashboard-policies img {
	 width: 100%;
	 max-width: 627px;
	 position: relative;
	 z-index: 1;
}
 #policies .dashboard-policies img.blur-dashboard {
	 max-width: initial;
	 width: 1000px;
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-45%, -55%);
	 opacity: 0.2;
	 z-index: 0;
}
 #policies .dashboard-policies .backlight {
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -45%) translateZ(0);
	 width: 120%;
	 height: 110%;
	 border-radius: 50%;
	 opacity: 0.3;
	 background: linear-gradient(224deg, #fe9d44 0%, #fc41ce 100%);
	 filter: blur(120px);
}
 #policies .steps-policies {
	 padding-left: 10px;
}
 #policies .steps-policies .step {
	 display: flex;
	 margin-bottom: 40px;
}
 #policies .steps-policies .step .number {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 width: 45px;
	 height: 45px;
	 border: 2px solid #ffb629;
	 filter: drop-shadow(0px 0px 15px #f25410);
	 border-radius: 50%;
	 font-size: 21px;
	 line-height: 24px;
	 color: #fff;
	 margin-right: 15px;
}
 #policies .steps-policies .step .data-step {
	 padding-top: 10px;
	 width: 90%;
}
 #policies .steps-policies .step .data-step h5 {
	 font-size: 24px;
	 line-height: 28px;
	 margin-bottom: 10px;
}
 #policies .steps-policies .step .data-step p {
	 max-width: 380px;
}
 #policies .steps-policies .step .data-step p strong {
	 color: #ffb629;
	 font-weight: 400;
}
 #policies .steps-policies .step .data-step .small-title {
	 font-weight: 800;
	 font-size: 11px;
	 line-height: 13px;
	 letter-spacing: 0.2em;
	 text-transform: uppercase;
	 color: #ffb629;
	 display: flex;
	 align-content: center;
	 width: 100%;
	 margin-top: 12px;
	 margin-bottom: 5px;
}
 #policies .steps-policies .step .data-step .small-title img {
	 margin-right: 10px;
	 filter: drop-shadow(0px 0px 5px #ffb629);
}
 @media (max-width: 991px) {
	 #policies .dashboard-policies {
		 max-width: 644px;
		 margin: 0 auto 50px;
	}
	 #policies .steps-policies {
		 max-width: 480px;
		 margin: 0 auto;
	}
}
 @media (max-width: 767px) {
	 #policies {
		 padding: 100px 0 50px;
	}
	 #policies .dashboard-policies .logo-core {
		 top: 40px;
		 left: 20px;
	}
	 #policies .dashboard-policies img.blur-dashboard {
		 width: 900px;
		 transform: translate(-40%, -55%);
	}
}
 @media (max-width: 575px) {
	 #policies .steps-policies .step {
		 flex-direction: column;
		 justify-content: center;
		 align-items: center;
		 text-align: center;
	}
	 #policies .steps-policies .step .data-step .small-title {
		 justify-content: center;
	}
	 #policies .steps-policies .step .data-step .small-title img {
		 display: none;
	}
	 #policies .steps-policies .step .data-step p {
		 margin: 0 auto 15px;
	}
	 #policies .dashboard-policies .logo-core {
		 top: 11%;
		 left: 4%;
	}
	 #policies .dashboard-policies .logo-core .core-logo img {
		 width: 25px;
	}
	 #policies .dashboard-policies img.blur-dashboard {
		 width: 550px;
	}
}
 #activated-shield {
	 padding: 100px 0;
	 background-color: #090911;
}
 #activated-shield .top-content {
	 text-align: center;
	 margin-bottom: 60px;
}
 #activated-shield .top-content .h1 {
	 margin-bottom: 20px;
}
 #activated-shield .top-content .h1 strong {
	 color: #ffb629;
	 font-weight: 600;
	 text-shadow: 0 0 15px #ffb629 76;
}
 #activated-shield .context-setup {
	 font-family: -apple-system, sans-serif;
	 max-width: 815px;
	 min-height: 400px;
	 background-position: center;
	 background-size: contain;
	 background-repeat: no-repeat;
	 padding-top: 55px;
	 margin: 0 auto;
	 position: relative;
	 z-index: 1;
}
 #activated-shield .context-setup .circles-danger {
	 max-width: 500px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 position: relative;
	 top: -37px;
	 left: -43px;
	 z-index: 1;
}
 #activated-shield .context-setup .circles-danger .circle {
	 width: 50px;
	 height: 50px;
	 position: absolute;
	 z-index: 0;
	 opacity: 0;
	 border-radius: 50%;
	 border: 0.547px solid rgba(251, 15, 66, 0.20);
	 background: rgba(251, 15, 66, 0.08);
	 animation: pulseCircleDanger 3s ease-in-out infinite;
}
 #activated-shield .context-setup .circles-danger .circle.medium {
	 width: 80px;
	 height: 80px;
}
 #activated-shield .context-setup .circles-danger .circle.big {
	 width: 110px;
	 height: 110px;
}
 #activated-shield .context-setup .shield-bubble {
	 max-width: 500px;
	 margin: 0 auto;
	 background: rgba(40, 40, 40, 0.5);
	 backdrop-filter: blur(15px);
	 border: 1px solid rgba(235, 235, 245, 0.2);
	 border-radius: 16px;
	 padding: 25px;
	 position: relative;
	 margin-bottom: 70px;
}
 #activated-shield .context-setup .shield-bubble p {
	 font-size: 19px;
	 line-height: 24px;
	 font-weight: 200;
	 letter-spacing: 0.01em;
	 color: rgba(255, 255, 255, 0.5);
	 margin-bottom: 0;
}
 #activated-shield .context-setup .shield-bubble .top-bubble {
	 display: flex;
	 align-items: center;
}
 #activated-shield .context-setup .shield-bubble .top-bubble img {
	 margin-right: 20px;
}
 #activated-shield .context-setup .shield-bubble .top-bubble .data-bubble h4 {
	 font-size: 22px;
	 line-height: 24px;
	 letter-spacing: 0.02em;
	 font-weight: 400;
	 color: rgba(255, 255, 255, 0.85);
}
 #activated-shield .context-setup .shield-bubble .top-bubble .data-bubble p strong {
	 font-weight: 200;
	 color: #fff;
}
 #activated-shield .context-setup .shield-bubble .top-bubble .data-bubble p span {
	 margin: 0 15px;
}
 #activated-shield .context-setup .shield-bubble hr {
	 border-color: rgba(235, 235, 245, 0.2);
}
 #activated-shield .context-setup .shield-bubble .bottom-bubble .description-data {
	 display: flex;
	 align-items: center;
}
 #activated-shield .context-setup .shield-bubble .bottom-bubble .description-data img {
	 margin-right: 12px;
}
 #activated-shield .context-setup .shield-bubble .bottom-bubble .description-data img.green {
	 filter: drop-shadow(0px 0px 11.0709px rgba(255, 182, 41, 0.75));
}
 #activated-shield .context-setup .shield-bubble .bottom-bubble .description-data img.red {
	 filter: drop-shadow(0px 0px 11.0709px rgba(245, 24, 63, 0.75));
}
 #activated-shield .context-setup .shield-bubble .bottom-bubble .actions {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 15px;
	 margin-top: 20px;
}
 #activated-shield .context-setup .shield-bubble .bottom-bubble .actions .button-apple {
	 width: 100%;
	 text-align: center;
	 padding: 6px 50px;
	 background: rgba(255, 255, 255, 0.15);
	 border-radius: 7px;
	 font-weight: 200;
	 font-size: 17px;
	 line-height: 23px;
	 letter-spacing: 0.01em;
	 color: rgba(255, 255, 255, 0.5);
	 transition: all 0.3s ease-in-out;
}
 #activated-shield .context-setup .shield-bubble .bottom-bubble .actions .button-apple:hover {
	 background: rgba(255, 255, 255, 0.30);
	 color: rgba(255, 255, 255, 0.6);
}
 #activated-shield .context-setup .shield-bubble::after {
	 content: "";
	 position: absolute;
	 left: 38px;
	 top: -12px;
	 width: 20px;
	 height: 12px;
	 background-position: center;
	 background-size: contain;
	 background-repeat: no-repeat;
	 border-bottom: 1px solid #2e1d51;
}
 #activated-shield .all-major {
	 background: rgba(33, 32, 96, 0.47);
	 border: 1px solid rgba(255, 255, 255, 0.05);
	 box-shadow: 0px 8px 24px #080715;
	 border-radius: 22px;
	 padding: 25px;
	 display: flex;
	 align-items: center;
	 gap: 15px;
}
 #activated-shield .all-major .companies {
	 display: flex;
	 flex-wrap: wrap;
	 gap: 15px;
	 width: 25%;
}
 #activated-shield .all-major .content-major {
	 width: 75%;
}
 #activated-shield .all-major .content-major h4 {
	 margin-bottom: 15px;
}
 #activated-shield .all-major .content-major h4 strong {
	 font-weight: 600;
	 color: #ffb629;
}
 #activated-shield .all-major .content-major p {
	 margin-bottom: 28px;
}
 #activated-shield .all-major .content-major .box-button {
	 display: flex;
	 align-items: center;
}
 #activated-shield .all-major .content-major .box-button span {
	 margin-right: 15px;
	 font-size: 12px;
	 line-height: 16px;
	 font-weight: 600;
	 letter-spacing: 0.28em;
	 text-transform: uppercase;
	 color: #b7b9da;
	 text-shadow: 0px 1px 0px #212058;
}
 #activated-shield .backlight.green {
	 width: 40%;
	 height: 30%;
	 position: absolute;
	 top: 0;
	 left: 50%;
	 transform: translateX(-50%) translateZ(0px);
	 background: #ffb629 65;
	 filter: blur(100px);
	 -webkit-filter: blur(100px);
	 pointer-events: none;
	 z-index: 0;
}
 @media (max-width: 991px) {
	 #activated-shield .context-setup {
		 min-height: 350px;
		 padding-top: 70px;
		 margin-bottom: 50px;
	}
	 #activated-shield .context-setup .circles-danger {
		 top: -50px;
		 left: -75px;
	}
	 #activated-shield .context-setup .shield-bubble {
		 margin-bottom: 0;
	}
	 #activated-shield .context-setup .shield-bubble::after {
		 display: none;
	}
	 #activated-shield .all-major {
		 flex-wrap: wrap;
		 gap: 25px;
	}
	 #activated-shield .all-major .companies {
		 width: 100%;
		 justify-content: center;
	}
	 #activated-shield .all-major .content-major {
		 width: 100%;
	}
}
 @media (max-width: 767px) {
	 #activated-shield .top-content {
		 margin-bottom: 20px;
	}
	 #activated-shield .context-setup {
		 min-height: 250px;
		 padding-top: 50px;
		 margin-bottom: 60px;
	}
	 #activated-shield .context-setup .circles-danger {
		 top: -40px;
		 left: -120px;
	}
}
 @media (max-width: 575px) {
	 #activated-shield .context-setup .circles-danger {
		 display: none;
	}
	 #activated-shield .context-setup .shield-bubble {
		 border-radius: 12px;
		 padding: 15px;
	}
	 #activated-shield .context-setup .shield-bubble p {
		 font-size: 14px;
		 line-height: 16px;
	}
	 #activated-shield .context-setup .shield-bubble .top-bubble img {
		 margin-right: 15px;
		 max-width: 40px;
	}
	 #activated-shield .context-setup .shield-bubble .top-bubble .data-bubble h4 {
		 font-size: 19px;
		 line-height: 19px;
	}
	 #activated-shield .context-setup .shield-bubble .top-bubble .data-bubble p span {
		 margin: 0 7px;
	}
	 #activated-shield .context-setup .shield-bubble .bottom-bubble .actions .button-apple {
		 padding: 6px 10px;
	}
	 #activated-shield .all-major .companies img {
		 max-width: 60px;
	}
	 #activated-shield .all-major .content-major {
		 text-align: center;
	}
	 #activated-shield .all-major .content-major .box-button {
		 flex-direction: column;
		 gap: 20px;
	}
}
 @media (max-width: 425px) {
	 #activated-shield .context-setup {
		 padding-top: 0;
		 margin-bottom: 0;
	}
	 #activated-shield .all-major {
		 padding: 20px;
	}
	 #activated-shield .all-major .content-major .box-button span {
		 margin-right: 0;
	}
	 #activated-shield .all-major .companies {
		 gap: 10px;
	}
	 #activated-shield .all-major .companies img {
		 max-width: 50px;
	}
}
 #it-infrastructure {
	 padding: 50px 0 100px;
	 background-color: #090911;
}
 #it-infrastructure .top-content {
	 text-align: center;
	 margin-bottom: 60px;
}
 #it-infrastructure .top-content h3 strong {
	 color: #ffb629;
	 font-weight: 600;
}
 #it-infrastructure .col-12:nth-child(2) {
	 position: relative;
}
 #it-infrastructure .col-12:nth-child(2)::after, #it-infrastructure .col-12:nth-child(2)::before {
	 content: "";
	 height: 100%;
	 width: 1px;
	 position: absolute;
	 top: 50%;
	 left: 0;
	 transform: translateY(-50%) translateZ(0);
	 opacity: 0.5;
}
 #it-infrastructure .col-12:nth-child(2)::after {
	 left: initial;
	 right: 0;
}
 #it-infrastructure .box-area.middle {
	 padding: 0 23px;
}
 #it-infrastructure .box-area.left {
	 padding-right: 23px;
}
 #it-infrastructure .box-area.right {
	 padding-left: 40px;
}
 #it-infrastructure .box-area .top-box img {
	 -webkit-filter: drop-shadow(0px 0px 15px #ffb629);
	 filter: drop-shadow(0px 0px 15px #ffb629);
	 margin-bottom: 20px;
}
 #it-infrastructure .box-area .top-box h4 {
	 margin-bottom: 20px;
}
 #it-infrastructure .box-area p span {
	 color: #fff;
}
 #it-infrastructure .box-area .companies {
	 display: flex;
	 align-items: flex-start;
	 gap: 12px;
	 margin: 25px 0 15px;
}
 #it-infrastructure .box-area .companies .company {
	 filter: drop-shadow(0px 4.375px 13.125px #080715);
}
 #it-infrastructure .box-area .companies .company span {
	 display: block;
	 text-align: center;
	 font-weight: 600;
	 font-size: 8.5px;
	 line-height: 10px;
	 letter-spacing: 0.08em;
	 text-transform: uppercase;
	 color: #514a9a;
	 opacity: 0.5;
	 margin-top: 5px;
}
 #it-infrastructure .box-area .small-title {
	 font-size: 11px;
	 line-height: 13px;
	 font-weight: 800;
	 letter-spacing: 0.2em;
	 text-transform: uppercase;
	 color: #ffb629;
	 display: flex;
	 align-content: center;
	 width: 100%;
	 margin-bottom: 5px;
}
 #it-infrastructure .box-area .disclaimer {
	 margin-top: 40px;
	 padding: 18px;
	 background: rgba(33, 32, 96, 0.47);
	 border-radius: 10px;
	 position: relative;
	 text-align: center;
}
 #it-infrastructure .box-area .disclaimer img {
	 position: absolute;
	 left: 50%;
	 top: -12px;
	 transform: translateX(-50%) translateZ(0);
	 filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.24));
}
 #it-infrastructure .box-area .disclaimer p {
	 margin: 0;
	 font-size: 11px;
	 line-height: 17px;
	 font-weight: 800;
	 letter-spacing: 0.16em;
	 text-transform: uppercase;
	 color: #ffb629;
}
 #it-infrastructure .box-area ul {
	 list-style-type: none;
	 padding: 0;
	 margin: 0;
}
 #it-infrastructure .box-area ul li {
	 font-size: 16px;
	 line-height: 23px;
	 letter-spacing: -0.01em;
	 color: #78779c;
	 position: relative;
	 margin-bottom: 10px;
}
 #it-infrastructure .box-area ul li:first-child {
	 max-width: 200px;
}
 #it-infrastructure .box-area ul li span {
	 color: #fff;
}
 #it-infrastructure .box-area ul li .api {
	 padding: 10px;
	 background: rgba(33, 32, 96, 0.47);
	 border-radius: 10px;
	 font-size: 25px;
	 line-height: 31px;
	 font-weight: 800;
	 color: #ffb629;
	 min-width: 87px;
	 position: absolute;
	 top: -3px;
	 right: -70px;
}
 #it-infrastructure .box-area ul li .api span {
	 font-weight: 200;
	 color: #ffb629;
}
 #it-infrastructure .box-area ul li::before {
	 content: "";
	 position: absolute;
	 left: -22px;
	 top: 7px;
	 width: 10px;
	 height: 10px;
	 margin-right: 8px;
	 background-position: center;
	 background-size: contain;
	 background-repeat: no-repeat;
}
 @media (max-width: 991px) {
	 #it-infrastructure .col-12:nth-child(2)::after, #it-infrastructure .col-12:nth-child(2)::before {
		 display: none;
	}
	 #it-infrastructure .box-area {
		 padding: 0;
		 margin-bottom: 40px;
		 text-align: center;
	}
	 #it-infrastructure .box-area.middle, #it-infrastructure .box-area.left, #it-infrastructure .box-area.right {
		 padding: 0;
	}
	 #it-infrastructure .box-area.middle {
		 padding: 50px 0;
		 border-top: 1px solid #fff 20;
		 border-bottom: 1px solid #fff 20;
	}
	 #it-infrastructure .box-area .disclaimer {
		 max-width: 400px;
		 margin: 30px auto 0;
	}
	 #it-infrastructure .box-area .companies {
		 justify-content: center;
	}
	 #it-infrastructure .box-area .small-title {
		 justify-content: center;
	}
	 #it-infrastructure .box-area ul {
		 max-width: 350px;
		 margin: 20px auto 0;
		 text-align: left;
	}
}
 @media (max-width: 425px) {
	 #it-infrastructure {
		 padding: 50px 0;
	}
	 #it-infrastructure .box-area ul {
		 padding-left: 20px;
	}
}
 #source-code-protection {
	 padding: 130px 0 100px;
	 background: linear-gradient(180deg, #090911 0%, #100d23 100%);
	 overflow: hidden;
	 position: relative;
}
 #source-code-protection .container {
	 position: relative;
}
 #source-code-protection::after {
	 content: "";
	 width: 100%;
	 height: 200px;
	 position: absolute;
	 bottom: 0;
	 left: 0;
	 background: linear-gradient(0deg, #100d23 0%, #100d23 0 100%);
	 pointer-events: none;
	 z-index: 1;
}
 #source-code-protection::before {
	 content: "";
	 width: 100%;
	 height: 200px;
	 position: absolute;
	 top: 0;
	 left: 0;
	 background: linear-gradient(180deg, #090911 0%, #100d23 0 100%);
	 pointer-events: none;
	 z-index: 1;
}
 #source-code-protection .blur-dashboard {
	 max-width: initial;
	 width: 1250px;
	 position: absolute;
	 top: 50%;
	 right: -12%;
	 transform: translateY(-50%) translateZ(0);
	 opacity: 0.2;
	 z-index: 0;
	 pointer-events: none;
}
 #source-code-protection .left-content {
	 position: relative;
	 z-index: 2;
}
 #source-code-protection .left-content .pretitle {
	 display: flex;
	 align-items: center;
	 font-size: 11px;
	 line-height: 13px;
	 font-weight: 800;
	 letter-spacing: 0.2em;
	 text-transform: uppercase;
	 color: #ffb629;
	 margin-bottom: 15px;
}
 #source-code-protection .left-content .pretitle img {
	 margin-right: 8px;
}
 #source-code-protection .left-content h3 {
	 margin-bottom: 0;
}
 #source-code-protection .left-content h5 {
	 color: #ffb629;
	 max-width: 400px;
	 margin-bottom: 25px;
	 margin-top: 20px;
}
 #source-code-protection .left-content ul {
	 list-style: none;
	 padding: 0;
	 margin: 0 0 25px;
}
 #source-code-protection .left-content ul li {
	 font-size: 16px;
	 line-height: 23px;
	 letter-spacing: -0.01em;
	 color: #78779c;
	 position: relative;
	 margin-bottom: 12px;
}
 #source-code-protection .left-content ul li span {
	 color: #fff;
}
 #source-code-protection .left-content ul li::before {
	 content: "";
	 display: inline-block;
	 margin-right: 13px;
	 width: 10px;
	 height: 10px;
	 margin-right: 8px;
	 background-position: center;
	 background-size: contain;
	 background-repeat: no-repeat;
}
 #source-code-protection .left-content .button {
	 margin-top: 20px;
}
 #source-code-protection .dashboard-source {
	 position: relative;
	 z-index: 2;
	 max-width: 627px;
	 border-radius: 8px;
}
 #source-code-protection .dashboard-source .image-area {
	 position: relative;
	 overflow: hidden;
	 border-radius: 8px;
}
 #source-code-protection .dashboard-source .image-area::before {
	 content: "";
	 position: absolute;
	 width: 200%;
	 height: 200%;
	 top: -50%;
	 left: -50%;
	 right: 0;
	 bottom: 0;
	 background: conic-gradient(transparent, transparent, transparent, rgba(255, 255, 255, 0.4));
	 animation: borderLight 7s linear infinite;
	 z-index: 1;
}
 #source-code-protection .dashboard-source .image-area .border-animation {
	 position: absolute;
	 inset: 1px;
	 border-radius: 8px;
	 background: #000 0;
	 z-index: 1;
}
 #source-code-protection .dashboard-source span {
	 position: relative;
	 bottom: -30px;
	 display: block;
	 font-size: 11px;
	 line-height: 13px;
	 font-weight: 800;
	 text-align: center;
	 letter-spacing: 0.2em;
	 text-transform: uppercase;
	 color: #78779c;
	 opacity: 0.5;
	 z-index: 2;
}
 #source-code-protection .dashboard-source img {
	 width: 100%;
	 max-width: 627px;
	 position: relative;
	 z-index: 1;
}
 #source-code-protection #particles-js-source-dashboard {
	 position: absolute;
	 top: -5%;
	 left: -5%;
	 height: 110%;
	 width: 110%;
	 opacity: 0.7;
	 pointer-events: none;
}
 #source-code-protection .backlight {
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -45%) translateZ(0);
	 width: 100%;
	 height: 100%;
	 border-radius: 50%;
	 opacity: 0.2;
	 background: linear-gradient(224deg, #fe9d44 0%, #fc41ce 100%);
	 filter: blur(120px);
	 pointer-events: none;
}
 @media (max-width: 991px) {
	 #source-code-protection .left-content {
		 text-align: center;
		 margin-bottom: 50px;
	}
	 #source-code-protection .left-content .pretitle {
		 justify-content: center;
	}
	 #source-code-protection .left-content h5 {
		 margin: 10px auto 25px;
	}
	 #source-code-protection .dashboard-source {
		 max-width: 632px;
		 margin: 0 auto;
	}
	 #source-code-protection .blur-dashboard {
		 width: 950px;
		 right: 0%;
		 transform: translateY(-10%);
	}
}
 @media (max-width: 767px) {
	 #source-code-protection #particles-js-source-dashboard {
		 top: 0;
		 left: 0;
		 height: 100%;
		 width: 100%;
	}
	 #source-code-protection .dashboard-source .logo-core {
		 top: 45px;
		 left: 20px;
	}
	 #source-code-protection .blur-dashboard {
		 right: initial;
		 transform: translateX(-50%);
		 left: 50%;
	}
}
 @media (max-width: 575px) {
	 #source-code-protection {
		 padding: 80px 0;
		 border-bottom: 1px solid #fff 10;
	}
	 #source-code-protection .dashboard-source .logo-core {
		 top: 11%;
		 left: 4%;
	}
	 #source-code-protection .dashboard-source .logo-core .core-logo img {
		 width: 25px;
	}
	 #source-code-protection .blur-dashboard {
		 top: 55%;
		 width: 650px;
		 left: 35%;
	}
}
 #services {
	 padding: 100px 0 150px;
	 background-color: #0d0c1e;
	 position: relative;
	 z-index: 1;
}
 #services .container {
	 max-width: 950px;
}
 #services .content-area {
	 text-align: center;
	 max-width: 700px;
	 margin: 0 auto 100px;
}
 #services .content-area h3 {
	 max-width: 500px;
	 margin: 0 auto 8px;
}
 #services .content-area strong {
	 font-weight: 600;
	 color: #ffb629;
	 text-shadow: 0px 0px 20px rgba(255, 182, 41, 0.45);
}
 #services .content-area p:not(.featured-shadow) {
	 margin: 20px auto 0;
}
 #services .spacer {
	 height: 1px;
	 background-color: #1f1c43;
	 margin: 100px 0;
}
 #services .backlight {
	 width: 100%;
	 height: 70%;
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%) translateZ(0px);
	 background: #0c15ff;
	 opacity: 0.3;
	 filter: blur(100px);
	 -webkit-filter: blur(100px);
	 pointer-events: none;
	 z-index: 0;
}
 #services .content-features {
	 max-width: 350px;
}
 #services .content-features h3 {
	 margin-bottom: 20px;
}
 #services .content-features h3 strong {
	 color: #ffb629;
	 font-weight: 600;
}
 #services .content-features p {
	 max-width: 260px;
	 text-align: left;
	 margin-bottom: 0;
}
 #services .content-features p span {
	 color: #fff;
}
 #services .content-features .companies-row {
	 display: flex;
	 align-items: flex-start;
	 align-items: center;
	 gap: 10px;
	 margin-top: 25px;
}
 #services .content-features .companies-row > span {
	 font-size: 11px;
	 line-height: 13px;
	 letter-spacing: 0.2em;
	 font-weight: 800;
	 text-transform: uppercase;
	 background: linear-gradient(90deg, #ffc163 0%, #da8f1f 100%);
	 -webkit-background-clip: text;
	 -webkit-text-fill-color: transparent;
	 background-clip: text;
	 text-fill-color: transparent;
}
 #services .content-features .companies-row .company-bubble {
	 max-width: 50px;
}
 #services .content-features .companies-row .company-bubble img {
	 width: 100%;
	 filter: drop-shadow(0px 5px 15px #080715);
}
 #services .chat-area {
	 position: relative;
	 z-index: 1;
	 max-width: 479px;
	 margin: 0 auto;
}
 #services .chat-area .chat-bg {
	 width: 100%;
	 filter: drop-shadow(0px 26.425px 46.2438px rgba(0, 0, 0, 0.35));
}
 #services .chat-area .chat-messages {
	 display: flex;
	 align-items: center;
	 flex-direction: column;
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100%;
}
 #services .chat-area .chat-messages .message {
	 position: absolute;
	 max-width: 452px;
	 bottom: 75px;
	 filter: drop-shadow(0px 0px 30px rgba(255, 195, 41, 0.10));
	 opacity: 0;
}
 #services .chat-area .logo-core {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 position: absolute;
	 right: 10px;
	 z-index: 1;
}
 #services .chat-area .logo-core .circle {
	 width: 50px;
	 height: 50px;
	 position: absolute;
	 z-index: 0;
	 background: #43ffdb;
	 opacity: 0;
	 border-radius: 50%;
	 animation: pulseCircleSmall 4s linear infinite;
}
 #services .chat-area .logo-core .circle.big {
	 animation: pulseCircleBig 4s linear infinite;
	 width: 70px;
	 height: 70px;
}
 #services .chat-area .logo-core .core-logo {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 position: relative;
	 z-index: 3;
}
 #services .chat-area .logo-core .core-logo img {
	 width: 28px;
}
 #services .sources-area {
	 position: relative;
	 max-width: 500px;
	 margin: 0 auto;
	 display: flex;
	 align-items: center;
	 justify-content: center;
}
 #services .sources-area .shadows {
	 position: absolute;
	 z-index: 0;
}
 #services .sources-area .shadows.near {
	 max-width: 434px;
	 left: -10px;
	 top: 30px;
	 z-index: 1;
}
 #services .sources-area .shadows.far {
	 max-width: 436px;
}
 #services .sources-area .globe {
	 position: relative;
	 z-index: 2;
	 filter: drop-shadow(0px 5px 15px rgba(8, 7, 21, 0.25));
}
 #services .sources-area .globe.aws {
	 left: -25px;
	 top: 15px;
}
 #services .sources-area .globe.github {
	 z-index: 3;
}
 #services .sources-area .globe.stripe {
	 top: 15px;
}
 #services .sources-area .globe.x {
	 bottom: 15px;
}
 #services .sources-area .globe.shopify {
	 top: 40px;
}
 #services .sources-area .globe.hubspot {
	 top: 20px;
	 right: -20px;
	 z-index: 3;
}
 #services .sources-area .globe.slack {
	 bottom: 15px;
	 right: 30px;
}
 @media (max-width: 767px) {
	 #services {
		 padding: 100px 0 100px;
	}
	 #services .content-features {
		 max-width: 400px;
		 margin: 0 auto;
		 text-align: center;
	}
}
 @media (max-width: 475px) {
	 #services .spacer {
		 margin: 40px 0;
	}
	 #services .backlight {
		 display: none;
	}
}
 #source-code {
	 padding: 100px 0 120px;
	 background: linear-gradient(180deg, #12102b 0%, #0c0a1f 100%);
}
 #source-code .top-area {
	 text-align: center;
}
 #source-code .top-area h5 {
	 color: #78779c;
}
 #source-code .list-source {
	 margin-top: 60px;
}
 #source-code .list-source .top-list {
	 text-align: center;
	 margin-bottom: 15px;
	 min-height: 80px;
}
 #source-code .list-source .top-list img {
	 margin-bottom: 15px;
}
 #source-code .list-source .top-list span {
	 display: block;
	 font-size: 25px;
	 line-height: 31px;
	 font-weight: 600;
	 letter-spacing: -0.02em;
	 color: #fff;
}
 #source-code .list-source ul {
	 list-style-type: none;
	 padding: 0;
	 margin: 0;
}
 #source-code .list-source ul li {
	 font-size: 15px;
	 line-height: 19px;
	 font-weight: 500;
	 letter-spacing: -0.01em;
	 color: #78779c;
	 border-top: 1px solid #1f1c43;
	 padding: 17px 0;
	 position: relative;
}
 #source-code .list-source ul li:last-child {
	 border-bottom: 1px solid #1f1c43;
}
 #source-code .list-source ul li::before {
	 content: "";
	 display: inline-block;
	 width: 10px;
	 height: 10px;
	 margin-right: 8px;
	 background-position: center;
	 background-size: contain;
	 background-repeat: no-repeat;
}
 #source-code .view-more {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 margin-top: 80px;
}
 #source-code .view-more .box-button-dark {
	 margin: 0;
}
 #source-code .view-more p {
	 margin-bottom: 0;
	 font-size: 24px;
	 line-height: 29px;
	 font-weight: 600;
	 text-align: center;
	 letter-spacing: -0.02em;
	 color: #fff;
	 margin-right: 30px;
}
 #source-code .view-more p strong {
	 font-weight: 600;
	 color: #ffb629;
}
 #how-it-works {
	 padding: 100px 0 120px;
	 background: linear-gradient(180deg, #12102b 0%, #09081a 100%);
}
 #how-it-works .top-area {
	 text-align: center;
	 margin-bottom: 80px;
}
 #how-it-works .top-area .shields {
	 position: relative;
}
 #how-it-works .top-area .shields .backlight {
	 width: 40%;
	 height: 50%;
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%) translateZ(0px);
	 background: #0c15ff;
	 opacity: 0.4;
	 border-radius: 50%;
	 filter: blur(100px);
	 -webkit-filter: blur(100px);
	 pointer-events: none;
	 z-index: 0;
}
 #how-it-works .top-area .shields img {
	 position: relative;
	 z-index: 1;
	 opacity: 0;
	 transition: all 0.5s ease-in-out;
}
 #how-it-works .top-area .shields img.shield-left {
	 max-width: 199px;
	 margin-right: -90px;
	 transform: translateX(-150px) translateZ(0);
}
 #how-it-works .top-area .shields img.shield-middle {
	 max-width: 336px;
	 z-index: 2;
	 transform: translateY(-100px) translateZ(0);
}
 #how-it-works .top-area .shields img.shield-right {
	 max-width: 191px;
	 margin-left: -90px;
	 transform: translateX(150px) translateZ(0);
}
 #how-it-works .top-area .shields.reveal img {
	 opacity: 1;
}
 #how-it-works .top-area .shields.reveal img.shield-middle {
	 transition-delay: 0.4s;
	 transform: translateY(0) translateZ(0);
}
 #how-it-works .top-area .shields.reveal img.shield-left, #how-it-works .top-area .shields.reveal img.shield-right {
	 transition-delay: 0.5s;
	 transform: translateX(0) translateZ(0);
}
 #how-it-works .top-area h2 {
	 margin-bottom: 20px;
}
 #how-it-works .top-area h2::after {
	 content: "®";
	 display: inline-block;
	 font-weight: 100;
	 font-size: 23px;
	 vertical-align: 23px;
	 margin-left: 5px;
}
 #how-it-works .top-area p {
	 max-width: 650px;
	 margin: 0 auto 5px;
}
 #how-it-works .top-area p span {
	 color: #fff;
}
 #how-it-works .card-area {
	 position: relative;
	 margin-bottom: 70px;
}
 #how-it-works .card-area .behind-card {
	 background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.14) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #383187;
	 box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.15);
	 border-radius: 30px;
	 padding: 24px 25px;
	 min-height: 190px;
	 max-width: 285px;
	 margin: 0 auto -75px;
	 position: relative;
	 overflow: hidden;
	 --squircle-radius: 30px;
	 --squircle-smooth: 0.6;
	 -webkit-mask-image: paint(squircle);
	 mask-image: paint(squircle);
}
 #how-it-works .card-area .behind-card::before {
	 content: "";
	 position: absolute;
	 top: 0;
	 right: 0;
	 left: 0;
	 bottom: 0;
	 --squircle-radius: 34px;
	 --squircle-smooth: 0.7;
	 background: paint(squircle);
	 --squircle-color: rgba(255, 255, 255, 0.05);
	 --squircle-outline: 1px;
	 pointer-events: none;
}
 #how-it-works .card-area .behind-card .shadow {
	 position: absolute;
	 width: 85%;
	 height: 70px;
	 background-color: #020107;
	 filter: blur(50px);
	 -webkit-filter: blur(50px);
	 transform: translateZ(0px);
	 top: 55%;
	 opacity: 0.8;
	 pointer-events: none;
}
 #how-it-works .card-area .behind-card img {
	 position: absolute;
	 left: 50%;
	 top: 10px;
	 transform: translateX(-50%);
}
 #how-it-works .card-area .behind-card img.warn-info {
	 max-width: 256px;
	 top: 14px;
}
 #how-it-works .card-area .behind-card img.stop-info {
	 max-width: 245px;
}
 #how-it-works .card-area .behind-card img.obfuscate-info {
	 top: 20px;
	 max-width: 247px;
}
 #how-it-works .card-area .static-card {
	 padding: 30px 35px 20px;
	 background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%), #12102b;
	 box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
	 border-radius: 30px;
	 min-height: 215px;
	 --squircle-radius: 34px;
	 --squircle-smooth: 0.7;
	 -webkit-mask-image: paint(squircle);
	 mask-image: paint(squircle);
	 position: relative;
	 z-index: 1;
}
 #how-it-works .card-area .static-card.safari {
	 border: 1px solid rgba(255, 255, 255, 0.04);
}
 #how-it-works .card-area .static-card::before {
	 content: "";
	 position: absolute;
	 top: 0;
	 right: 0;
	 left: 0;
	 bottom: 0;
	 --squircle-radius: 34px;
	 --squircle-smooth: 0.7;
	 background: paint(squircle);
	 --squircle-color: rgba(255, 255, 255, 0.04);
	 --squircle-outline: 1px;
	 pointer-events: none;
}
 #how-it-works .card-area .static-card .number-box {
	 display: flex;
	 align-items: center;
}
 #how-it-works .card-area .static-card .number-box .number {
	 position: relative;
	 width: 55px;
	 height: 55px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 border-radius: 50%;
	 border: 2px solid #ffb629;
	 filter: drop-shadow(0px 0px 10px rgba(255, 182, 41, 0.75));
}
 #how-it-works .card-area .static-card .number-box .number.red {
	 border-color: #ea0e42;
	 filter: drop-shadow(0px 0px 10px rgba(255, 101, 14, 0.75));
}
 #how-it-works .card-area .static-card .number-box .number.orange {
	 border-color: #ff7529;
	 filter: drop-shadow(0px 0px 10px rgba(234, 14, 66, 0.96));
}
 #how-it-works .card-area .static-card .number-box .number span {
	 color: #fff;
	 font-weight: 300;
	 font-size: 25px;
	 line-height: 24px;
	 letter-spacing: -0.01em;
	 text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.55);
}
 #how-it-works .card-area .static-card .number-box h5 {
	 margin-left: 15px;
	 margin-bottom: 0;
}
 #how-it-works .card-area .static-card .card-static-body {
	 padding-top: 20px;
}
 #how-it-works .card-area .static-card .card-static-body p {
	 font-weight: 400;
	 font-size: 16px;
	 line-height: 23px;
	 min-height: 95px;
	 margin-bottom: 0;
}
 #how-it-works .card-area .static-card .card-static-body p strong {
	 font-weight: 400;
	 color: #fff;
}
 #how-it-works .box-disclaimer {
	 position: relative;
}
 #how-it-works .box-disclaimer .disclaimer {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 margin-top: 40px;
	 padding: 20px 10px;
	 border-radius: 15px;
	 --squircle-radius: 40px;
	 --squircle-smooth: 1;
	 --intro-outline-width: 2;
	 -webkit-mask-image: paint(squircle);
	 mask-image: paint(squircle);
	 background-color: #0b0a18;
	 text-align: center;
	 position: relative;
	 z-index: 1;
}
 #how-it-works .box-disclaimer .disclaimer img {
	 margin-right: 10px;
	 filter: drop-shadow(0 0 5px #ffb629);
	 -webkit-filter: drop-shadow(0 0 5px #ffb629);
}
 #how-it-works .box-disclaimer .disclaimer p {
	 margin: 0;
	 font-size: 15px;
	 line-height: 22px;
}
 #how-it-works .box-disclaimer .disclaimer p strong {
	 font-size: 19px;
	 line-height: 24px;
	 font-weight: 600;
	 letter-spacing: -0.01em;
	 background: linear-gradient(180deg, #ffc163 0%, #da8f1f 100%);
	 -webkit-background-clip: text;
	 -webkit-text-fill-color: transparent;
	 background-clip: text;
	 text-fill-color: transparent;
	 margin-right: 20px;
}
 #how-it-works .box-disclaimer .disclaimer p span {
	 color: #fff;
}
 #how-it-works .box-disclaimer .border-disclaimer {
	 border-radius: 15px;
	 background: paint(squircle);
	 --squircle-radius: 40px;
	 --squircle-smooth: 1;
	 --squircle-outline: 1px;
	 background: linear-gradient(-122.37deg, #fe9d44 0%, #fc41ce 100%);
	 -webkit-mask-image: paint(squircle);
	 mask-image: paint(squircle);
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%);
	 z-index: 1;
	 width: 100%;
	 height: 100%;
}
 #how-it-works .box-disclaimer .border-disclaimer.safari {
	 width: 100.2%;
	 height: 104%;
	 z-index: 0;
}
 @media (max-width: 991px) {
	 #how-it-works .box-disclaimer .border-disclaimer.safari {
		 height: 102%;
	}
}
 #how-it-works .box-disclaimer .blur-disclaimer {
	 position: absolute;
	 top: 0;
	 width: 100%;
	 height: 100%;
	 z-index: -1;
	 background: linear-gradient(-122.37deg, #fe9d44 0%, #fc41ce 100%);
	 filter: blur(20px);
	 -webkit-filter: blur(20px);
	 opacity: 20%;
	 transform: translateZ(0px);
}
 @media (max-width: 991px) {
	 #how-it-works .card-area {
		 margin-bottom: 80px;
	}
	 #how-it-works .card-area .static-card {
		 min-height: 180px;
		 max-width: 400px;
		 margin: 0 auto;
	}
	 #how-it-works .card-area .glow-card {
		 width: 40%;
	}
	 #how-it-works .card-area .overlay-card {
		 max-width: 350px;
		 margin: 0 auto;
		 border-radius: 50px;
	}
	 #how-it-works .box-disclaimer .disclaimer {
		 padding: 25px 20px;
		 flex-direction: column;
		 margin-top: 0;
	}
	 #how-it-works .box-disclaimer .disclaimer p strong {
		 display: block;
		 margin-right: 0;
		 margin-top: 5px;
		 margin-bottom: 10px;
	}
}
 #mc_embed_signup div#mce-responses {
	 overflow: initial;
	 float: initial;
	 top: 60px;
}
 #register-company .input-group, #register-press .input-group, #register-country .input-group {
	 display: flex;
}
 #register-company .input-group .input-box label, #register-press .input-group .input-box label, #register-country .input-group .input-box label {
	 display: block;
}
 #register-company .input-group .input-box select, #register-press .input-group .input-box select, #register-country .input-group .input-box select, #register-company .input-group .input-box input, #register-press .input-group .input-box input, #register-country .input-group .input-box input {
	 width: 100%;
}
 #register-company .box-button, #register-press .box-button, #register-country .box-button {
	 margin-top: 25px;
	 text-align: center;
}
 #register-company .box-button button, #register-press .box-button button, #register-country .box-button button {
	 border: 0;
}
 #register-country .success-message, #register-company .success-message, #register-press .success-message {
	 position: absolute;
	 top: 0;
	 left: 0;
	 right: 0;
	 bottom: 0;
	 background: #110d23;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 transition: all 0.3s ease-in-out;
	 text-align: center;
	 opacity: 0;
	 pointer-events: none;
	 z-index: 1;
}
 #register-country .success-message h4, #register-company .success-message h4, #register-press .success-message h4 {
	 max-width: 350px;
	 margin: 0 auto 20px;
}
 #register-country .success-message.success, #register-company .success-message.success, #register-press .success-message.success {
	 opacity: 1;
}
 #register-country.success form, #register-company.success form, #register-press.success form {
	 opacity: 0;
	 pointer-events: none;
}
 #register-company, #register-press, #register-country, #mc_embed_signup {
	 font-family: 'Manrope', sans-serif;
	 background-color: transparent;
	 width: 100%;
}
 #register-company form, #register-press form, #register-country form, #mc_embed_signup form {
	 margin: 0;
}
 #register-company form .input-group, #register-press form .input-group, #register-country form .input-group, #mc_embed_signup form .input-group {
	 display: flex;
	 align-items: center;
	 flex-wrap: nowrap;
	 margin-bottom: 8px;
	 gap: 0 20px;
}
 #register-company form .input-group .type, #register-press form .input-group .type, #register-country form .input-group .type, #mc_embed_signup form .input-group .type {
	 width: 0%;
	 display: none;
}
 #register-company form .input-group .input-box div.mce_inline_error, #register-press form .input-group .input-box div.mce_inline_error, #register-country form .input-group .input-box div.mce_inline_error, #mc_embed_signup form .input-group .input-box div.mce_inline_error, #register-company form .input-group .mc-field-group div.mce_inline_error, #register-press form .input-group .mc-field-group div.mce_inline_error, #register-country form .input-group .mc-field-group div.mce_inline_error, #mc_embed_signup form .input-group .mc-field-group div.mce_inline_error {
	 top: 63%;
}
 #register-company form .input-group .input-box.hide, #register-press form .input-group .input-box.hide, #register-country form .input-group .input-box.hide, #mc_embed_signup form .input-group .input-box.hide, #register-company form .input-group .mc-field-group.hide, #register-press form .input-group .mc-field-group.hide, #register-country form .input-group .mc-field-group.hide, #mc_embed_signup form .input-group .mc-field-group.hide {
	 display: none;
}
 #register-company form .divider, #register-press form .divider, #register-country form .divider, #mc_embed_signup form .divider {
	 padding: 25px 0 12px;
	 margin-bottom: 22px;
	 border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
 #register-company form .divider.top, #register-press form .divider.top, #register-country form .divider.top, #mc_embed_signup form .divider.top {
	 padding-top: 0;
}
 #register-company form .input-box, #register-press form .input-box, #register-country form .input-box, #mc_embed_signup form .input-box, #register-company form .mc-field-group, #register-press form .mc-field-group, #register-country form .mc-field-group, #mc_embed_signup form .mc-field-group {
	 width: 100%;
	 min-height: auto;
	 padding-bottom: 8px;
	 margin: 0 auto;
}
 #register-company form .input-box label, #register-press form .input-box label, #register-country form .input-box label, #mc_embed_signup form .input-box label, #register-company form .mc-field-group label, #register-press form .mc-field-group label, #register-country form .mc-field-group label, #mc_embed_signup form .mc-field-group label {
	 font-size: 13px;
	 text-transform: uppercase;
	 letter-spacing: 0.05em;
	 color: #fff;
	 font-weight: 600;
	 margin-bottom: 7px;
}
 #register-company form .input-box select, #register-press form .input-box select, #register-country form .input-box select, #mc_embed_signup form .input-box select, #register-company form .mc-field-group select, #register-press form .mc-field-group select, #register-country form .mc-field-group select, #mc_embed_signup form .mc-field-group select, #register-company form .input-box input, #register-press form .input-box input, #register-country form .input-box input, #mc_embed_signup form .input-box input, #register-company form .mc-field-group input, #register-press form .mc-field-group input, #register-country form .mc-field-group input, #mc_embed_signup form .mc-field-group input {
	 background-color: #040509;
	 background: #000;
	 backdrop-filter: blur(7.5px);
	 border-radius: 6px;
	 text-indent: 0;
	 padding: 8px 10px 10px;
	 border: 0;
	 font-weight: 500;
	 font-size: 14px;
	 line-height: 19px;
	 letter-spacing: 0.1em;
	 color: #fff;
	 text-shadow: 0px 0px 35.8518px #000;
	 box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.25);
	 min-height: 45px;
	 transition: all 0.3 ease-in-out;
}
 #register-company form .input-box select:focus, #register-press form .input-box select:focus, #register-country form .input-box select:focus, #mc_embed_signup form .input-box select:focus, #register-company form .mc-field-group select:focus, #register-press form .mc-field-group select:focus, #register-country form .mc-field-group select:focus, #mc_embed_signup form .mc-field-group select:focus, #register-company form .input-box input:focus, #register-press form .input-box input:focus, #register-country form .input-box input:focus, #mc_embed_signup form .input-box input:focus, #register-company form .mc-field-group input:focus, #register-press form .mc-field-group input:focus, #register-country form .mc-field-group input:focus, #mc_embed_signup form .mc-field-group input:focus {
	 box-shadow: 0;
	 border: 1px solid #ffb629;
	 outline: 0;
	 box-shadow: 0 0 30px #ffb829 2c;
}
 #register-company form .input-box select::placeholder, #register-press form .input-box select::placeholder, #register-country form .input-box select::placeholder, #mc_embed_signup form .input-box select::placeholder, #register-company form .mc-field-group select::placeholder, #register-press form .mc-field-group select::placeholder, #register-country form .mc-field-group select::placeholder, #mc_embed_signup form .mc-field-group select::placeholder, #register-company form .input-box input::placeholder, #register-press form .input-box input::placeholder, #register-country form .input-box input::placeholder, #mc_embed_signup form .input-box input::placeholder, #register-company form .mc-field-group input::placeholder, #register-press form .mc-field-group input::placeholder, #register-country form .mc-field-group input::placeholder, #mc_embed_signup form .mc-field-group input::placeholder {
	 font-weight: 500;
	 font-size: 10px;
	 line-height: 14px;
	 letter-spacing: 0.25em;
	 color: #fff a8;
	 text-shadow: 0px 0px 35.8518px #000;
}
 #register-company form .input-box select, #register-press form .input-box select, #register-country form .input-box select, #mc_embed_signup form .input-box select, #register-company form .mc-field-group select, #register-press form .mc-field-group select, #register-country form .mc-field-group select, #mc_embed_signup form .mc-field-group select {
	 -webkit-appearance: none;
	 appearance: none;
	 background-position: 91% center;
	 background-size: 10px;
	 background-repeat: no-repeat;
}
 #register-company form .input-box div.mce_inline_error, #register-press form .input-box div.mce_inline_error, #register-country form .input-box div.mce_inline_error, #mc_embed_signup form .input-box div.mce_inline_error, #register-company form .mc-field-group div.mce_inline_error, #register-press form .mc-field-group div.mce_inline_error, #register-country form .mc-field-group div.mce_inline_error, #mc_embed_signup form .mc-field-group div.mce_inline_error {
	 font-size: 11px;
	 background-color: transparent !important;
	 margin: 0px;
	 position: absolute;
	 right: 5px;
	 top: 50%;
	 transform: translateY(-60%);
	 pointer-events: none;
	 opacity: 0.7;
}
 #register-company form .input-box.col-one, #register-press form .input-box.col-one, #register-country form .input-box.col-one, #mc_embed_signup form .input-box.col-one, #register-company form .mc-field-group.col-one, #register-press form .mc-field-group.col-one, #register-country form .mc-field-group.col-one, #mc_embed_signup form .mc-field-group.col-one {
	 width: 58%;
	 margin: 0 2%;
}
 #register-company form .input-box.col-one input, #register-press form .input-box.col-one input, #register-country form .input-box.col-one input, #mc_embed_signup form .input-box.col-one input, #register-company form .mc-field-group.col-one input, #register-press form .mc-field-group.col-one input, #register-country form .mc-field-group.col-one input, #mc_embed_signup form .mc-field-group.col-one input {
	 background: rgba(255, 255, 255, 0.1);
}
 #register-company form .input-box.col-two, #register-press form .input-box.col-two, #register-country form .input-box.col-two, #mc_embed_signup form .input-box.col-two, #register-company form .mc-field-group.col-two, #register-press form .mc-field-group.col-two, #register-country form .mc-field-group.col-two, #mc_embed_signup form .mc-field-group.col-two {
	 width: 36%;
	 margin-right: 0;
}
 #register-company form .clear.submit-box, #register-press form .clear.submit-box, #register-country form .clear.submit-box, #mc_embed_signup form .clear.submit-box {
	 position: relative;
	 left: 50%;
	 transform: translateX(-50%);
	 display: inline-block;
	 margin-top: 20px;
	 transition: all 0.3s ease;
}
 #register-company form .clear.submit-box::before, #register-press form .clear.submit-box::before, #register-country form .clear.submit-box::before, #mc_embed_signup form .clear.submit-box::before {
	 content: "";
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100%;
	 background: linear-gradient(90deg, #fd9e47 0%, #f843d0 100%);
	 opacity: 0.44;
	 filter: blur(20px);
	 -webkit-filter: blur(20px);
	 border-radius: 10px;
	 z-index: -1;
	 transition: all 0.3s ease-in-out;
}
 #register-company form .clear.submit-box::after, #register-press form .clear.submit-box::after, #register-country form .clear.submit-box::after, #mc_embed_signup form .clear.submit-box::after {
	 content: "";
	 pointer-events: none;
	 user-select: none;
	 position: absolute;
	 inset: 0px;
	 border-radius: inherit;
	 padding: 2px;
	 border-radius: 10px;
	 background: linear-gradient(#fff, #000);
	 -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
	 -webkit-mask-composite: xor;
	 opacity: 0.3;
	 z-index: 1;
}
 #register-company form .clear.submit-box .button, #register-press form .clear.submit-box .button, #register-country form .clear.submit-box .button, #mc_embed_signup form .clear.submit-box .button {
	 padding: 16px 23px 15px;
	 display: block;
	 margin: 0;
	 height: auto;
	 border-radius: 10px;
	 font-weight: 800;
	 font-size: 12px;
	 line-height: 16px;
	 letter-spacing: 0.3em;
	 text-transform: uppercase;
	 color: #040509;
	 text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.66);
	 transition: all 0.3s ease-in-out;
	 position: relative;
}
 #register-company form .clear.submit-box:hover, #register-press form .clear.submit-box:hover, #register-country form .clear.submit-box:hover, #mc_embed_signup form .clear.submit-box:hover {
	 color: #040509;
}
 #register-company form .clear.submit-box:hover::before, #register-press form .clear.submit-box:hover::before, #register-country form .clear.submit-box:hover::before, #mc_embed_signup form .clear.submit-box:hover::before {
	 opacity: 0.6;
	 filter: blur(25px);
	 -webkit-filter: blur(25px);
	 transform: translateZ(0px);
}
 #register-company form .clear.submit-box.disabled, #register-press form .clear.submit-box.disabled, #register-country form .clear.submit-box.disabled, #mc_embed_signup form .clear.submit-box.disabled {
	 opacity: 0.3 !important;
	 pointer-events: none;
}
 #register-company #mce-responses, #register-press #mce-responses, #register-country #mce-responses, #mc_embed_signup #mce-responses {
	 margin: 0;
	 padding: 0;
	 position: relative;
	 top: 70px;
	 width: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 overflow: initial;
}
 #register-company #mce-responses .response, #register-press #mce-responses .response, #register-country #mce-responses .response, #mc_embed_signup #mce-responses .response {
	 padding: 17px 40px;
	 margin: 0;
	 width: auto;
	 text-align: center;
	 float: inherit;
	 color: #fff;
	 font-size: 12px;
	 border-radius: 6px;
	 background: rgba(255, 255, 255, 0.1);
	 position: absolute;
	 bottom: -75px;
	 top: initial;
}
 #register-company #mce-responses .response#mce-success-response, #register-press #mce-responses .response#mce-success-response, #register-country #mce-responses .response#mce-success-response, #mc_embed_signup #mce-responses .response#mce-success-response {
	 color: #ffb629;
}
 #register-company #mce-responses .response#mce-error-response, #register-press #mce-responses .response#mce-error-response, #register-country #mce-responses .response#mce-error-response, #mc_embed_signup #mce-responses .response#mce-error-response {
	 color: #e85c41;
}
 body.press-article-form {
	 background-color: transparent;
}
 .modal {
	 padding: 20px;
}
 .modal .modal-dialog {
	 max-width: 600px;
	 height: 100%;
	 padding: 0;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 width: 100%;
	 margin: 0 auto;
}
 .modal .modal-content {
	 border-radius: 12px;
	 background-color: #100d23;
	 overflow: hidden;
}
 .modal .modal-content .modal-header {
	 border: 0;
	 padding: 0;
}
 .modal .modal-content .modal-header button {
	 margin: 0;
	 padding: 0;
	 opacity: 1;
	 position: absolute;
	 right: 20px;
	 top: 15px;
	 z-index: 9;
}
 .modal .modal-content .modal-header button span {
	 font-size: 40px;
	 font-weight: 200;
	 color: #fff;
}
 .modal .modal-body {
	 padding: 40px 50px;
}
 .modal .modal-body #mc_embed_signup div#mce-responses {
	 height: 20px;
	 position: absolute;
	 bottom: -33px;
	 display: flex;
	 align-items: center;
	 top: initial;
}
 .modal .modal-body #mc_embed_signup div#mce-responses .response {
	 display: block;
	 top: 0;
	 bottom: 0;
	 position: absolute;
	 background-color: transparent;
	 padding: 0;
}
 .modal .modal-body .container-iframe {
	 position: relative;
	 overflow: hidden;
	 width: 100%;
	 padding-top: 85%;
}
 .modal .modal-body .container-iframe iframe {
	 position: absolute;
	 top: 0;
	 left: 0;
	 bottom: 0;
	 right: 0;
	 width: 100%;
	 height: 100%;
}
 .modal .modal-body.with-iframe {
	 padding-bottom: 0;
}
 @media (max-width: 575px) {
	 .modal .modal-body {
		 padding: 20px;
	}
	 .modal #register-company form .divider, .modal #register-press form .divider, .modal #register-country form .divider, .modal #mc_embed_signup form .divider {
		 padding: 12px 0 4px;
		 margin-bottom: 12px;
	}
	 .modal #register-company form .input-group, .modal #register-press form .input-group, .modal #register-country form .input-group, .modal #mc_embed_signup form .input-group {
		 flex-wrap: wrap;
		 gap: 0;
		 margin: 0;
	}
	 .modal #register-company form .input-group .input-box label, .modal #register-press form .input-group .input-box label, .modal #register-country form .input-group .input-box label, .modal #mc_embed_signup form .input-group .input-box label, .modal #register-company form .input-group .mc-field-group label, .modal #register-press form .input-group .mc-field-group label, .modal #register-country form .input-group .mc-field-group label, .modal #mc_embed_signup form .input-group .mc-field-group label {
		 font-size: 11px;
		 margin-bottom: 5px;
	}
	 .modal #register-company form .input-group .input-box input, .modal #register-press form .input-group .input-box input, .modal #register-country form .input-group .input-box input, .modal #mc_embed_signup form .input-group .input-box input, .modal #register-company form .input-group .mc-field-group input, .modal #register-press form .input-group .mc-field-group input, .modal #register-country form .input-group .mc-field-group input, .modal #mc_embed_signup form .input-group .mc-field-group input {
		 min-height: 38px;
	}
	 .modal #register-company form .box-button, .modal #register-press form .box-button, .modal #register-country form .box-button, .modal #mc_embed_signup form .box-button {
		 margin-top: 10px;
	}
}
 .modal-backdrop.show {
	 opacity: 0.7;
}
 
`;

export const CustomCss = styled.div`
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;600;800&display=swap");
* {
  scroll-behavior: smooth;
}
body {
  font-family: "Manrope", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: #020107;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
button:focus {
  outline: 0;
}
a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a:hover {
  text-decoration: none;
}
.loading {
  overflow: hidden;
  height: 100vh;
}
.preloader {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #020107;
}
.preloader .box-preloader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  min-width: 500px;
  padding: 30px 0 25px;
}
.preloader .box-preloader:before {
  content: "";
  width: 50%;
  height: 20%;
  position: absolute;
  bottom: -40px;
  left: 50%;
  border-radius: 50%;
  -webkit-transform: translateX(-50%) translateZ(0);
  transform: translateX(-50%) translateZ(0);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffc163),
    to(#da8f1f)
  );
  background: linear-gradient(90deg, #ffc163 0%, #da8f1f 100%);
  -webkit-filter: blur(80px);
  filter: blur(80px);
  opacity: 100%;
  z-index: -1;
}
.preloader .box-preloader:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(36, 94, 127, 0)),
    color-stop(35%, #fff),
    color-stop(65%, #fff),
    to(rgba(36, 94, 127, 0))
  );
  background: linear-gradient(
    90deg,
    rgba(36, 94, 127, 0) 0%,
    #fff 35%,
    #fff 65%,
    rgba(36, 94, 127, 0) 100%
  );
  opacity: 12%;
}
.preloader .box-preloader span {
  display: block;
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.19em;
  text-transform: uppercase;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffc163),
    to(#da8f1f)
  );
  background: linear-gradient(90deg, #ffc163 0%, #da8f1f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.preloader .box-preloader .logo-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.preloader .box-preloader .logo-holder img {
  width: 190px;
  max-width: 190px;
}
.preloader .box-preloader #particles-js-preloader {
  position: absolute;
  bottom: -100px;
  left: 50%;
  width: 350px;
  height: 200px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 767px) {
  .preloader .box-preloader {
    min-width: 350px;
    padding: 30px 0 20px;
  }
  .preloader .box-preloader:before {
    bottom: 0;
  }
  .preloader .box-preloader .logo-holder img {
    max-width: 200px;
  }
  .preloader .box-preloader #particles-js-preloader {
    width: 270px;
    height: 150px;
    bottom: -50px;
  }
}
.h1,
h1 {
  font-size: 57px;
  font-weight: 600;
  line-height: 61px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 50px;
    line-height: 53px;
  }
}
@media (max-width: 575px) {
  .h1,
  h1 {
    font-size: 40px;
    line-height: 42px;
  }
}
@media (max-width: 425px) {
  .h1,
  h1 {
    font-size: 35px;
    line-height: 35px;
  }
}
.h2,
h2 {
  font-size: 50px;
  line-height: 53px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #ffffff;
}
@media (max-width: 767px) {
  .h2,
  h2 {
    font-size: 44px;
    line-height: 49px;
  }
}
@media (max-width: 575px) {
  .h2,
  h2 {
    font-size: 36px;
    line-height: 40px;
  }
}
.h3,
h3 {
  font-weight: 600;
  font-size: 39px;
  line-height: 43px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
@media (max-width: 575px) {
  .h3,
  h3 {
    font-size: 30px;
    line-height: 35px;
  }
}
.h4,
h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
@media (max-width: 575px) {
  .h4,
  h4 {
    font-size: 24px;
    line-height: 28px;
  }
}
.h5,
h5 {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #ffffff;
}
@media (max-width: 575px) {
  .h5,
  h5 {
    font-size: 18px;
    line-height: 23px;
  }
}
p {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #78779c;
}
p.featured-shadow {
  font-size: 21px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  text-shadow: 0px 0px 18px rgba(255, 255, 255, 0.75);
}
@media (max-width: 575px) {
  p.featured-shadow {
    font-size: 18px;
    line-height: 26px;
  }
}
p.featured {
  font-size: 21px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.01em;
}
@media (max-width: 575px) {
  p.featured {
    font-size: 18px;
    line-height: 26px;
  }
}
@media (max-width: 575px) {
  p {
    font-size: 15px;
    line-height: 21px;
  }
}
.button {
  display: inline-block;
  padding: 16px 23px 15px 24px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffc163),
    to(#da8f1f)
  );
  background: linear-gradient(90deg, #ffc163 0%, #da8f1f 100%);
  background-size: 100% 100%;
  text-align: center;
  border-radius: 10px;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #040509;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.66);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
  min-width: 190px;
  -webkit-animation: buttonGradient 5s ease-in-out infinite;
  animation: buttonGradient 5s ease-in-out infinite;
}
.button img {
  margin-left: 9px;
  width: 8px;
  margin-top: -2px;
}
.button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffc163),
    to(#da8f1f)
  );
  background: linear-gradient(90deg, #ffc163 0%, #da8f1f 100%);
  opacity: 0.25;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  border-radius: 10px;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
}
.button:after {
  content: "";
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: 10px;
  padding: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(255, 255, 255)),
    to(rgb(0, 0, 0))
  );
  background: linear-gradient(rgb(255, 255, 255), rgb(0, 0, 0));
  -webkit-mask: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(black),
        to(black)
      )
      content-box content-box,
    -webkit-gradient(linear, left top, left bottom, from(black), to(black));
  -webkit-mask: linear-gradient(black, black) content-box content-box,
    linear-gradient(black, black);
  -webkit-mask-composite: xor;
  opacity: 0.3;
}
.button:hover {
  color: #040509;
}
.button:hover:before {
  opacity: 0.5;
  filter: blur(25px);
  -webkit-filter: blur(25px);
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
}
.button.without-arrow {
  min-width: auto;
}
.button.dark {
  background: #12102b;
  color: #ffffff;
  text-shadow: none;
}
.button.dark:before {
  display: none;
}
.button.dark:after {
  opacity: 1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fd9e47),
    to(#f843d0)
  );
  background: linear-gradient(90deg, #fd9e47 0%, #f843d0 100%);
}
.button.secondary {
  background: rgba(0, 0, 0, 0);
  color: #ffb629;
  text-shadow: none;
  border-radius: 8px;
  border: 1px solid #ffb629;
}
.button.secondary:after {
  display: none;
}
.button.secondary:before {
  display: none;
}
.button.secondary:hover {
  background: #ffb629;
  color: #040509;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.66);
  -webkit-box-shadow: 0 0 25px rgba(255, 182, 41, 0.2705882353);
  box-shadow: 0 0 25px rgba(255, 182, 41, 0.2705882353);
}
@media (max-width: 575px) {
  .button {
    min-width: auto;
    padding: 14px 14px 14px 18px;
  }
}
.outline-link {
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 5px 7px 5px 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.outline-link:hover {
  color: #100d23;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.35);
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.35);
}
.outline-link.yellow {
  color: #ffb629;
  border: 1px solid #ffb629;
}
.outline-link.yellow:hover {
  color: #100d23;
  background-color: #ffb629;
  -webkit-box-shadow: 0 0 25px rgba(255, 182, 41, 0.35);
  box-shadow: 0 0 25px rgba(255, 182, 41, 0.35);
}
.outline-link.dark {
  color: #1f2023;
  border-color: #1f2023;
}
.outline-link.dark:hover {
  background-color: #1f2023;
  color: #ffffff;
}
.box-button-dark {
  display: inline-block;
  margin-top: 25px;
  position: relative;
}
.box-button-dark .blur {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(93.5deg, #ffa13e 0%, #fb34ce 100%);
  opacity: 0;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  border-radius: 50%;
  z-index: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  -webkit-transform: translateX(-50%) translateZ(0px);
  transform: translateX(-50%) translateZ(0px);
}
.box-button-dark:hover .blur {
  opacity: 0.35;
}
.submit-country,
.submit-press {
  margin-top: 40px;
  text-align: center;
}
@media (min-width: 991px) {
  .submit-country,
  .submit-press {
    max-width: 400px;
    margin-left: 40px;
  }
}
.submit-country .outline-link,
.submit-press .outline-link {
  background-color: transparent;
  color: #ffb629;
  font-size: 12px;
  line-height: 16px;
  padding: 12px 30px 11px 30px;
  border-radius: 8px;
}
.submit-country .outline-link .triangle,
.submit-press .outline-link .triangle {
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #ffb629;
}
.submit-country .outline-link:hover,
.submit-press .outline-link:hover {
  background-color: #ffb629;
  color: #100d23;
}
.submit-country .outline-link:hover .triangle,
.submit-press .outline-link:hover .triangle {
  border-left-color: #100d23;
}
.arrow {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
  z-index: 1;
  pointer-events: none;
}
.icon-holder {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 60px;
  margin-bottom: 20px;
}
.icon-holder .icon-blur {
  position: absolute;
  width: 40px;
  height: 70px;
  background: #ffb629;
  opacity: 0.25;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  border-radius: 50%;
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
}
.icon-holder img {
  position: relative;
  z-index: 1;
}
input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px rgb(47, 44, 62) inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff !important;
}
.banner {
  width: 100%;
  padding: 8px 0;
  background: #ffb629;
  position: absolute;
  top: 0;
  z-index: 1;
}
.banner .content-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 15px;
}
.banner .content-banner img {
  width: 15px;
}
.banner .content-banner p {
  line-height: 12px;
  font-size: 11px;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  color: #1f2023;
  font-weight: 600;
  margin: 0;
}
.banner .content-banner a {
  margin-left: 20px;
}
@media (max-width: 991px) {
  .banner .content-banner p {
    line-height: 10px;
    font-size: 8px;
  }
}
@media (max-width: 767px) {
  .banner .content-banner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5px;
    text-align: center;
  }
  .banner .content-banner img {
    display: none;
  }
  .banner .content-banner a {
    margin: 0;
    padding: 4px 7px 4px 7px;
    font-size: 8px;
  }
}
@media (max-width: 575px) {
  .banner .content-banner {
    display: block;
    line-height: 10px;
  }
  .banner .content-banner p {
    display: inline;
  }
  .banner .content-banner a {
    display: inline;
    border: 0;
    padding: 0;
    border-bottom: 1px solid;
    border-radius: 0;
    margin-left: 6px;
    position: relative;
  }
  .banner .content-banner a:before {
    content: "・";
    position: absolute;
    left: -10px;
  }
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px 0;
  z-index: 99;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar.home-nav {
  top: 50px;
}
@media (max-width: 575px) {
  .navbar.home-nav {
    top: 35px;
  }
}
.navbar.sticky {
  background-color: #0d0c1e;
  padding: 8px 0;
  top: 0;
}
.navbar.sticky .navbar-brand .logo {
  max-width: 140px;
}
.navbar .navbar-brand .logo {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  max-width: 168px;
}
.navbar .navbar-collapse ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}
.navbar .navbar-collapse ul .nav-item {
  margin-left: 20px;
}
.navbar .navbar-collapse ul .nav-item .nav-link {
  font-weight: 800;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #78779c;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar .navbar-collapse ul .nav-item .nav-link:hover {
  color: #ffffff;
}
.navbar .navbar-collapse ul .nav-item .button {
  min-width: auto;
  padding: 12px 15px 11px 18px;
  font-size: 11px;
  line-height: 15px;
}
.navbar .navbar-collapse ul .nav-item.with-button {
  position: relative;
}
.navbar .navbar-collapse ul .nav-item.with-button .blur {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fd9e47),
    to(#f843d0)
  );
  background: linear-gradient(90deg, #fd9e47 0%, #f843d0 100%);
  opacity: 0.55;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateX(-50%) translateZ(0px);
  transform: translateX(-50%) translateZ(0px);
}
.navbar .navbar-collapse ul .nav-item.with-button:hover .blur {
  opacity: 0.8;
  filter: blur(25px);
  -webkit-filter: blur(25px);
}
@media (max-width: 991px) {
  .navbar .navbar-collapse {
    background-color: #0d0c1e;
    padding: 50px;
    border-radius: 12px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    z-index: 9;
  }
  .navbar .navbar-collapse ul .nav-item {
    margin-bottom: 20px;
    margin-left: 0;
  }
  .navbar .navbar-collapse ul .nav-item:last-child {
    margin-bottom: 0;
  }
}
.navbar .navbar-toggler {
  border: 0;
}
.navbar .navbar-toggler span {
  -webkit-filter: invert(1);
  filter: invert(1);
}
@media (max-width: 767px) {
  .navbar .navbar-brand {
    padding-left: 20px;
  }
}
#introducing {
  padding: 200px 0 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: #020107;
}
#introducing:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 150px;
  width: 100%;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(black),
    to(rgba(2, 1, 7, 0))
  );
  background-image: linear-gradient(0deg, black 0%, rgba(2, 1, 7, 0) 100%);
  z-index: 4;
  pointer-events: none;
}
#introducing .horizon {
  width: 70%;
  height: 6px;
  position: absolute;
  bottom: 280px;
  right: 0;
  -webkit-filter: blur(15px);
  filter: blur(15px);
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(14, 87, 123, 0)),
    color-stop(15%, rgb(14, 87, 123)),
    color-stop(85%, rgb(14, 87, 123)),
    to(rgba(14, 87, 123, 0))
  );
  background: linear-gradient(
    90deg,
    rgba(14, 87, 123, 0) 0%,
    rgb(14, 87, 123) 15%,
    rgb(14, 87, 123) 85%,
    rgba(14, 87, 123, 0) 100%
  );
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
#introducing .grid {
  width: 60%;
  height: 300px;
  overflow: hidden;
  -webkit-perspective: 195px;
  perspective: 195px;
  position: absolute;
  bottom: 0;
  right: 0;
}
#introducing .grid-fade {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: radial-gradient(
    ellipse at 50% 50%,
    rgba(2, 1, 7, 0) 0%,
    #020107 80%
  );
}
#introducing .grid-lines {
  width: 100%;
  height: 200%;
  background-image: linear-gradient(to right, #0d416e 0.5px, transparent 0),
    linear-gradient(
      to bottom,
      rgba(13, 65, 110, 0.4470588235) 0.3px,
      transparent 0
    );
  background-size: 10px 5px;
  background-repeat: repeat;
  -webkit-transform-origin: 100% 0 0;
  transform-origin: 100% 0 0;
  -webkit-animation: play 15s linear infinite;
  animation: play 15s linear infinite;
}
@media (min-width: 1920px) {
  #introducing .grid-lines {
    background-image: linear-gradient(to right, #0d416e 1px, transparent 0),
      linear-gradient(
        to bottom,
        rgba(13, 65, 110, 0.4470588235) 1px,
        transparent 0
      );
  }
}
@keyframes play {
  0% {
    -webkit-transform: rotateX(45deg) translateY(-50%);
    transform: rotateX(45deg) translateY(-50%);
  }
  to {
    -webkit-transform: rotateX(45deg) translateY(0);
    transform: rotateX(45deg) translateY(0);
  }
}
@-webkit-keyframes play {
  0% {
    -webkit-transform: rotateX(45deg) translateY(-50%);
    transform: rotateX(45deg) translateY(-50%);
  }
  to {
    -webkit-transform: rotateX(45deg) translateY(0);
    transform: rotateX(45deg) translateY(0);
  }
}
#introducing .introducing-content {
  position: relative;
  min-height: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  z-index: 1;
}
#introducing .introducing-content .pretitle {
  width: 100%;
  max-width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #0e1f40;
}
#introducing .introducing-content .pretitle p {
  margin: 0;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffb629;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#introducing .introducing-content .pretitle img {
  width: 25px;
  margin: 0 10px;
}
#introducing .introducing-content h1 {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
#introducing .introducing-content h1 strong {
  font-weight: 600;
  color: #ffb629;
  text-shadow: 0px 0px 35px rgba(255, 182, 41, 0.25);
}
#introducing .introducing-content h4 {
  max-width: 450px;
  margin-bottom: 40px;
}
#introducing .introducing-content h4 strong {
  font-weight: 600;
  color: #ffb629;
}
#introducing .animation-chat {
  position: relative;
  z-index: 3;
}
#introducing .animation-chat .backlight {
  background: #ff00ff;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  width: 70%;
  height: 50%;
  position: absolute;
  left: 70%;
  bottom: 0%;
  opacity: 0.5;
  -webkit-transform: translateX(-50%) translateZ(0);
  transform: translateX(-50%) translateZ(0);
  z-index: 0;
}
#introducing .animation-chat .blur-chat {
  width: 70%;
  height: 80%;
  position: absolute;
  left: 25%;
  top: 60%;
  -webkit-transform: translate(-50%, -50%) translateZ(0);
  transform: translate(-50%, -50%) translateZ(0);
  border-radius: 50%;
  background: #ffb629;
  opacity: 0.45;
  -webkit-filter: blur(10px);
  filter: blur(100px);
  z-index: 0;
}
#introducing .animation-chat .chat-area {
  max-width: 505px;
  margin: 0 auto;
  border-radius: 18px;
  overflow: hidden;
  -webkit-box-shadow: 0px 216px 86px rgba(0, 0, 0, 0.02),
    0px 121px 73px rgba(0, 0, 0, 0.08), 0px 54px 54px rgba(0, 0, 0, 0.13),
    0px 13px 30px rgba(0, 0, 0, 0.15), 0px 0px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 216px 86px rgba(0, 0, 0, 0.02),
    0px 121px 73px rgba(0, 0, 0, 0.08), 0px 54px 54px rgba(0, 0, 0, 0.13),
    0px 13px 30px rgba(0, 0, 0, 0.15), 0px 0px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 3;
  line-height: 0;
}
#introducing .animation-chat .chat-area video {
  width: 100%;
}
#introducing .animation-chat .pretitle {
  margin-top: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 9;
}
#introducing .animation-chat .pretitle p {
  margin: 0;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffb629;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
@media (max-width: 991px) {
  #introducing .horizon {
    width: 100%;
  }
  #introducing .grid {
    width: 100%;
  }
  #introducing .grid .grid-lines {
    background-size: 15px 10px;
    background-image: linear-gradient(to right, #0d416e 1px, transparent 0),
      linear-gradient(
        to bottom,
        rgba(13, 65, 110, 0.4470588235) 1px,
        transparent 0
      );
  }
  #introducing .animation-chat {
    max-width: 500px;
    margin: 0 auto;
  }
  #introducing .introducing-content {
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: auto;
    margin-bottom: 50px;
  }
  #introducing .introducing-content .pretitle {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 100%;
  }
  #introducing .introducing-content h4 {
    margin: 0 auto 40px;
  }
}
@media (max-width: 575px) {
  #introducing {
    padding: 150px 0 70px;
  }
  #introducing .introducing-content .pretitle {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  #introducing .animation-chat .chat-area {
    border-radius: 12px;
  }
  #introducing .animation-chat .chat-area .bottom-chat .input-area .input {
    width: 87%;
  }
  #introducing
    .animation-chat
    .chat-area
    .bottom-chat
    .input-area
    .input
    .text
    img {
    max-width: 190px;
  }
}
@media (max-width: 425px) {
  #introducing .introducing-content .pretitle p {
    font-size: 9px;
  }
}
@media (max-width: 375px) {
  #introducing .introducing-content .pretitle {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 5px;
  }
}
#pasting-data {
  padding: 50px 0 40px;
  background-position: center bottom;
  background-size: 980px auto;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  border-bottom: solid 1px;
  -o-border-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      #4e73c3,
      rgba(255, 255, 255, 0)
    )
    1;
  border-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(255, 255, 255, 0)),
      color-stop(#4e73c3),
      to(rgba(255, 255, 255, 0))
    )
    1;
  border-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      #4e73c3,
      rgba(255, 255, 255, 0)
    )
    1;
}
#pasting-data #particles-js-data {
  position: absolute;
  width: 70%;
  max-width: 600px;
  height: 300px;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%) translateZ(0);
  transform: translateX(-50%) translateZ(0);
  opacity: 0.6;
  pointer-events: none;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
}
#pasting-data .glow-bottom {
  width: 70%;
  max-width: 600px;
  height: 20%;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #ffb629;
  opacity: 0.15;
  -webkit-filter: blur(100px);
  filter: blur(100px);
}
#pasting-data .top-content {
  text-align: center;
  margin-bottom: 60px;
}
#pasting-data .top-content .pretitle {
  margin-bottom: 20px;
}
#pasting-data .top-content .pretitle p {
  margin: 0;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffb629;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#pasting-data .top-content h2 {
  color: #ffb629;
}
#pasting-data .top-content h2.white {
  color: #ffffff;
}
#pasting-data .animation-area {
  position: relative;
  z-index: 1;
}
#pasting-data .animation-area .attorney-step .bubble {
  text-align: center;
  margin-bottom: 50px;
}
#pasting-data .animation-area .attorney-step .bubble img {
  width: 100%;
  max-width: 317px;
  -webkit-filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
  filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
}
#pasting-data .animation-area .attorney-step .graphic-area {
  position: relative;
  text-align: center;
  max-width: 351px;
  margin: 0 auto;
}
#pasting-data .animation-area .attorney-step .graphic-area img {
  width: 100%;
}
#pasting-data .animation-area .attorney-step .graphic-area img.clip {
  max-width: 22px;
  position: absolute;
  left: 14%;
  top: 6%;
  z-index: 1;
}
#pasting-data .animation-area .attorney-step .graphic-area img.papers {
  max-width: 351px;
}
#pasting-data .animation-area .password-step {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
#pasting-data .animation-area .password-step .bubble {
  text-align: center;
  margin-bottom: 50px;
}
#pasting-data .animation-area .password-step .bubble img {
  width: 100%;
  max-width: 174px;
  -webkit-filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
  filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
}
#pasting-data .animation-area .password-step .graphic-area {
  text-align: center;
}
#pasting-data
  .animation-area
  .password-step
  .graphic-area
  .passwords-box
  .password {
  display: block;
  width: 100%;
  margin: 0 auto;
  -webkit-filter: drop-shadow(0px 6px 40px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(0px 6px 40px rgba(0, 0, 0, 0.15));
}
#pasting-data
  .animation-area
  .password-step
  .graphic-area
  .passwords-box
  .password.password-1 {
  max-width: 511px;
  margin-bottom: 10px;
}
#pasting-data
  .animation-area
  .password-step
  .graphic-area
  .passwords-box
  .password.password-2 {
  max-width: 456px;
  margin-bottom: 0px;
}
#pasting-data
  .animation-area
  .password-step
  .graphic-area
  .passwords-box
  .password.password-3 {
  max-width: 411px;
  margin-bottom: -10px;
}
#pasting-data
  .animation-area
  .password-step
  .graphic-area
  .passwords-box
  .password.password-4 {
  max-width: 362px;
}
#pasting-data .animation-area .customers-step {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
#pasting-data .animation-area .customers-step .bubble {
  text-align: center;
  margin-bottom: 50px;
}
#pasting-data .animation-area .customers-step .bubble img {
  width: 100%;
  max-width: 217px;
  -webkit-filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
  filter: drop-shadow(0px 10px 20px rgba(255, 182, 71, 0.15));
}
#pasting-data .animation-area .customers-step .graphic-area {
  position: relative;
  max-width: 316px;
  margin: 0 auto;
}
#pasting-data .animation-area .customers-step .graphic-area img {
  width: 100%;
}
#pasting-data .animation-area .customers-step .graphic-area img.top-secret {
  max-width: 131px;
  position: absolute;
  left: 14%;
  top: 8%;
  z-index: 1;
}
#pasting-data .animation-area .customers-step .graphic-area img.customer-list {
  max-width: 316px;
}
@media (max-width: 575px) {
  #pasting-data {
    padding: 50px 0 0;
    background-size: 100% auto;
  }
  #pasting-data .glow-bottom {
    width: 100%;
    opacity: 0.3;
  }
  #pasting-data .animation-area .attorney-step .bubble,
  #pasting-data .animation-area .customers-step .bubble,
  #pasting-data .animation-area .password-step .bubble {
    margin-bottom: 20px;
  }
  #pasting-data
    .animation-area
    .password-step
    .graphic-area
    .passwords-box
    .password.password-1 {
    margin-bottom: 20px;
  }
  #pasting-data
    .animation-area
    .password-step
    .graphic-area
    .passwords-box
    .password.password-2 {
    margin-bottom: 20px;
  }
  #pasting-data
    .animation-area
    .password-step
    .graphic-area
    .passwords-box
    .password.password-3 {
    margin-bottom: 15px;
  }
  #pasting-data
    .animation-area
    .password-step
    .graphic-area
    .passwords-box
    .password.password-4 {
    margin-bottom: 0;
  }
}
#visibility {
  padding: 100px 0 120px;
  background: #0d0c1e;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(57.83%, rgba(0, 0, 0, 0)),
      to(#08080f)
    ),
    #0d0c1e;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 57.83%, #08080f 100%),
    #0d0c1e;
  overflow-x: clip;
}
#visibility .bottom-content {
  text-align: center;
}
#visibility .bottom-content h2 {
  max-width: 600px;
  margin: 0 auto 30px;
}
#visibility .bottom-content h2 strong {
  font-weight: 600;
  color: #ffb629;
}
#visibility .graphics {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto 70px;
  -webkit-perspective: 2000px;
  perspective: 2000px;
}
#visibility .graphics .text-indicator {
  position: absolute;
  top: 45%;
  right: -25px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.2980392157));
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.2980392157));
}
#visibility .graphics .text-indicator.animated {
  -webkit-animation: pulseShadow 3s ease infinite;
  animation: pulseShadow 3s ease infinite;
}
#visibility .graphics .backlight {
  width: 75%;
  height: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) translateZ(0);
  transform: translate(-50%, -50%) translateZ(0);
  background: linear-gradient(269.32deg, #fe9d44 1.25%, #fc41ce 96.95%);
  opacity: 0.2;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  pointer-events: none;
  z-index: 0;
  border-radius: 50%;
  display: none;
}
#visibility .graphics .blur-dashboard {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -55%) translateZ(0px);
  transform: translate(-50%, -55%) translateZ(0px);
  max-width: 1100px;
  opacity: 0;
}
#visibility .graphics .blur-dashboard.animated {
  -webkit-animation: showBlur 5000ms ease-out forwards;
  animation: showBlur 5000ms ease-out forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
#visibility .graphics .dashboard {
  position: relative;
  z-index: 3;
  -webkit-transform: rotateX(35deg) translateZ(0);
  transform: rotateX(35deg) translateZ(0);
  overflow: hidden;
  border-radius: 8px;
}
#visibility .graphics .dashboard.animated {
  -webkit-animation: rotateCarousel 1.4s ease forwards;
  animation: rotateCarousel 1.4s ease forwards;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
#visibility .graphics .dashboard:before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  right: 0;
  bottom: 0;
  background: conic-gradient(
    transparent,
    transparent,
    transparent,
    rgba(255, 255, 255, 0.4)
  );
  -webkit-animation: borderLight 7s linear infinite;
  animation: borderLight 7s linear infinite;
  z-index: 1;
}
#visibility .graphics .dashboard .border-animation {
  position: absolute;
  inset: 1px;
  border-radius: 8px;
  background: #000;
  z-index: 1;
}
#visibility .graphics .dashboard .blur {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -45%) translateZ(0);
  transform: translate(-50%, -45%) translateZ(0);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  background: linear-gradient(224deg, #fe9d44 0%, #fc41ce 100%);
  -webkit-filter: blur(100px);
  filter: blur(100px);
  pointer-events: none;
}
#visibility .graphics .dashboard .blur.animated {
  -webkit-animation: showColorBlur 5000ms ease-out forwards;
  animation: showColorBlur 5000ms ease-out forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
#visibility .graphics .dashboard .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #271b2e;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2;
}
#visibility .graphics .dashboard .overlay img {
  max-width: 200px;
}
#visibility .graphics .dashboard .overlay.animated {
  -webkit-animation: removeOverlay 1.8s ease forwards;
  animation: removeOverlay 1.8s ease forwards;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
#visibility .graphics .dashboard img {
  width: 100%;
  max-width: 835px;
  position: relative;
  z-index: 1;
}
#visibility .graphics .dashboard .right-dashboard {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-46%);
  transform: translateY(-46%);
  right: 18px;
  width: 100%;
  height: 100%;
  max-width: 623px;
  max-height: 426px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 0px 8px 8px 0px;
  z-index: 1;
}
#visibility .graphics .dashboard .right-dashboard .navigation__list ul#nav_0 {
  padding: 20px 25px 0;
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .top-dashboard
  img {
  max-width: 571px;
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .top-columns {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 22px;
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .top-columns
  .devices,
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .top-columns
  .name {
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 700;
  line-height: 11px;
  color: #b7b9da;
  letter-spacing: 0.25em;
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .top-columns
  .activity {
  letter-spacing: 0.25em;
}
#visibility .graphics .dashboard .right-dashboard .navigation__list .user-item {
  width: 100%;
  max-width: 571px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 11px 22px;
  margin: 1px 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(255, 182, 41, 0);
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .user-item
  > div {
  pointer-events: none;
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .user-item:nth-child(2n) {
  background: transparent;
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .user-item.hover,
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .user-item:hover {
  border: 1px solid rgba(255, 182, 41, 0.3);
  background: rgba(255, 182, 41, 0.1);
}
#visibility .graphics .dashboard .right-dashboard .navigation__list .name {
  width: 40%;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
}
#visibility .graphics .dashboard .right-dashboard .navigation__list .devices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  width: 35%;
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .devices
  .number {
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #ffb629;
  font-weight: 700;
  background: rgba(255, 182, 41, 0.1);
  border-radius: 50%;
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .devices
  .device {
  max-width: 15px;
  max-height: 14px;
}
#visibility .graphics .dashboard .right-dashboard .navigation__list .activity {
  width: 25%;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #ffb629;
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .detail-user
  .user-info
  .dashboard-bg {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}
#visibility
  .graphics
  .dashboard
  .right-dashboard
  .navigation__list
  .detail-user
  .user-info
  .user-name {
  max-width: 251px;
  position: absolute;
  left: 35px;
  top: 26px;
  z-index: 9;
  pointer-events: none;
}
#visibility .graphics .dashboard .navigation {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
#visibility .graphics .dashboard .navigation__header ul li {
  color: #ffb629;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  position: absolute;
  top: 25px;
  right: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#visibility .graphics .dashboard .navigation__header ul li:hover {
  text-shadow: 0 0 20px rgba(255, 184, 41, 0.5921568627);
}
#visibility .graphics .dashboard .button-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
#visibility .graphics .dashboard .button-list li {
  cursor: pointer;
}
#visibility .graphics .dashboard .navigation__list {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#visibility .graphics .dashboard .navigation__list ul {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
#visibility .graphics .dashboard .navigation__list li {
  cursor: pointer;
}
#visibility .graphics .dashboard .navigation__list .active {
  display: block;
}
#visibility .graphics .dashboard .navigation__list .left-active,
#visibility .graphics .dashboard .navigation__list .right-active {
  position: absolute;
  width: 100%;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#visibility .graphics .dashboard .navigation__list .right-active {
  left: 0%;
}
#visibility .graphics .dashboard .navigation__list .left-active {
  left: 0%;
}
#visibility .graphics .dashboard .navigation__list.transit-to-left,
#visibility .graphics .dashboard .navigation__list.transit-to-right {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#visibility .graphics .dashboard .navigation__list.transit-to-left {
  opacity: 0;
}
#visibility .graphics .dashboard .navigation__list.transit-to-right {
  opacity: 0;
}
@media (max-width: 1200px) {
  #visibility .graphics .text-indicator {
    right: -90px;
  }
}
@media (max-width: 991px) {
  #visibility .graphics .text-indicator {
    right: -100px;
    z-index: 4;
  }
  #visibility .graphics .dashboard .right-dashboard {
    max-width: 520px;
    max-height: 350px;
    right: 10px;
  }
  #visibility .graphics .dashboard .right-dashboard .navigation__list ul#nav_0 {
    padding: 10px 10px 0;
    overflow: scroll;
    max-height: 350px;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .top-columns {
    padding: 8px 15px;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .user-item {
    padding: 8px 15px;
  }
  #visibility .graphics .dashboard .right-dashboard .navigation__list .name {
    width: 35%;
  }
  #visibility .graphics .dashboard .right-dashboard .navigation__list .devices {
    width: 30%;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .activity {
    width: 35%;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .detail-user
    .user-info
    .user-name {
    max-width: 190px;
  }
  #visibility .graphics .blur-dashboard {
    max-width: 800px;
  }
}
@media (max-width: 767px) {
  #visibility .graphics .dashboard .navigation__header ul li {
    font-size: 8px;
    line-height: 12px;
    top: 17px;
    right: 20px;
  }
  #visibility .graphics .dashboard .right-dashboard {
    max-width: 387px;
    max-height: 260px;
    right: 5px;
    -webkit-transform: none;
    transform: none;
    top: 32px;
  }
  #visibility .graphics .dashboard .right-dashboard .navigation__list ul#nav_0 {
    max-height: 260px;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .top-columns {
    padding: 5px 10px;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .top-columns
    .activity,
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .top-columns
    .devices,
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .top-columns
    .name {
    font-size: 7px;
    font-weight: 500;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .user-item {
    padding: 10px;
  }
  #visibility .graphics .dashboard .right-dashboard .navigation__list .name {
    width: 30%;
    font-size: 10px;
    line-height: 12px;
  }
  #visibility .graphics .dashboard .right-dashboard .navigation__list .devices {
    width: 25%;
    gap: 5px;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .devices
    .number {
    display: none;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .devices
    .device {
    max-width: 12px;
    max-height: 11px;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .activity {
    width: 45%;
    font-size: 7px;
    line-height: 11px;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .detail-user
    .user-info
    .user-name {
    max-width: 140px;
    left: 25px;
    top: 20px;
  }
}
@media (max-width: 575px) {
  #visibility .graphics .text-indicator {
    right: 10px;
    z-index: 4;
    top: initial;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
    bottom: -50px;
    max-width: 100px;
  }
  #visibility .graphics .dashboard .overlay img {
    max-width: 120px;
  }
  #visibility .graphics .dashboard .navigation__header ul li {
    font-size: 5px;
    line-height: 11px;
    top: 9px;
    right: 15px;
  }
  #visibility .graphics .dashboard .right-dashboard {
    max-width: 76%;
    max-height: 85%;
    top: 11%;
  }
  #visibility .graphics .dashboard .right-dashboard .navigation__list ul#nav_0 {
    padding: 5px 5px 0;
    max-height: 100%;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .top-columns {
    padding: 5px 10px;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .top-columns
    .activity,
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .top-columns
    .devices,
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .top-columns
    .name {
    font-size: 5px;
    letter-spacing: 0.03em;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .user-item {
    padding: 8px 7px;
    border-radius: 3px;
  }
  #visibility .graphics .dashboard .right-dashboard .navigation__list .name {
    width: 35%;
    font-size: 7px;
    line-height: 12px;
  }
  #visibility .graphics .dashboard .right-dashboard .navigation__list .devices {
    width: 30%;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .devices
    .device {
    max-width: 9px;
    max-height: 8px;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .activity {
    width: 35%;
    font-size: 5px;
    line-height: 9px;
    letter-spacing: 0.02em;
  }
  #visibility
    .graphics
    .dashboard
    .right-dashboard
    .navigation__list
    .detail-user
    .user-info
    .user-name {
    max-width: 32%;
    left: 6%;
    top: 16px;
  }
  #visibility .graphics .blur-dashboard {
    max-width: 500px;
  }
}
@media (max-width: 425px) {
  #visibility .graphics .text-indicator {
    bottom: -40px;
    max-width: 70px;
  }
}
#how-it-works-homepage {
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}
#how-it-works-homepage .backlight {
  width: 100%;
  max-width: 1200px;
  height: 60%;
  border-radius: 50%;
  opacity: 0;
  background: #0c15ff;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  position: absolute;
  top: -8%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateZ(0);
  transform: translateX(-50%) translateZ(0);
}
#how-it-works-homepage .top-area {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 50px;
}
#how-it-works-homepage .top-area h4 {
  color: #ffb629;
}
#how-it-works-homepage .top-area p {
  max-width: 860px;
  margin: 0 auto;
}
#how-it-works-homepage .container-areas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 935px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
#how-it-works-homepage .container-areas .arrows {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 71%;
}
#how-it-works-homepage .container-areas .arrows img {
  max-width: 375px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
}
#how-it-works-homepage .container-areas .arrows .configuration-arrow {
  top: 15%;
}
#how-it-works-homepage .container-areas .arrows .sensitive-arrow {
  bottom: 15%;
}
#how-it-works-homepage .container-areas .area {
  width: 23%;
  text-align: center;
  position: relative;
}
#how-it-works-homepage .container-areas .area img {
  max-width: 201px;
}
#how-it-works-homepage .container-areas .area.large {
  width: 54%;
}
#how-it-works-homepage .container-areas .area.large img {
  max-width: 457px;
}
#how-it-works-homepage .container-areas .area.large .arrow-data-red {
  position: absolute;
  max-width: 143px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
}
#how-it-works-homepage .container-areas .area.large .vertical-arrow {
  max-width: 37px;
  position: absolute;
  top: 50%;
  right: 22%;
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
}
#how-it-works-homepage .bottom-cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
}
#how-it-works-homepage .bottom-cta p {
  margin: 0;
  color: #b7b9da;
}
@media (max-width: 991px) {
  #how-it-works-homepage .container-areas {
    gap: 10px;
  }
  #how-it-works-homepage .container-areas .arrows img {
    max-width: 270px;
  }
  #how-it-works-homepage .container-areas .area img {
    width: 100%;
  }
  #how-it-works-homepage .container-areas .area.large .arrow-data-red {
    max-width: 100px;
  }
  #how-it-works-homepage .container-areas .area.large .vertical-arrow {
    max-width: 25px;
  }
}
@media (max-width: 767px) {
  #how-it-works-homepage .container-areas .arrows img {
    max-width: 180px;
  }
  #how-it-works-homepage .container-areas .area.large .arrow-data-red {
    max-width: 80px;
  }
  #how-it-works-homepage .container-areas .area.large .vertical-arrow {
    max-width: 20px;
  }
  #how-it-works-homepage .bottom-cta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 15px;
  }
}
@media (max-width: 575px) {
  #how-it-works-homepage .container-areas .arrows img {
    max-width: 55%;
  }
  #how-it-works-homepage .container-areas .area.large .arrow-data-red {
    max-width: 30%;
  }
  #how-it-works-homepage .container-areas .area.large .vertical-arrow {
    max-width: 8%;
  }
}
.quote-llm-shield {
  padding: 50px 0 100px;
  position: relative;
}
.quote-llm-shield.transparent {
  padding-top: 150px;
  background: #0d0c1e;
}
.quote-llm-shield.dark {
  padding: 150px 0 100px;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(57.83%, rgb(2, 1, 7)),
      to(#020107)
    ),
    #0d0c1e;
  background: linear-gradient(180deg, rgb(2, 1, 7) 57.83%, #020107 100%),
    #0d0c1e;
}
.quote-llm-shield.biospring {
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(57.83%, rgba(0, 0, 0, 0)),
      to(#08080f)
    ),
    #090911;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 57.83%, #08080f 100%),
    #090911;
  padding: 100px 0 100px;
}
.quote-llm-shield.dark-grey {
  background-color: rgb(9, 9, 17);
}
.quote-llm-shield .backlight {
  width: 80%;
  height: 55%;
  position: absolute;
  top: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateZ(0px);
  transform: translateX(-50%) translateZ(0px);
  background: #0c15ff;
  opacity: 0.25;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  pointer-events: none;
  z-index: 0;
}
.quote-llm-shield .quote-box {
  max-width: 1000px;
  margin: 0 auto;
}
.quote-llm-shield .quote-box .quote-description {
  background: #12102b;
  border: 1px solid rgba(21, 132, 255, 0.18);
  border-radius: 22px;
  padding: 35px;
  position: relative;
}
.quote-llm-shield .quote-box .quote-description p {
  font-size: 16.5px;
  line-height: 29px;
  margin: 0;
}
.quote-llm-shield .quote-box .quote-description p strong {
  color: #ffb629;
  font-weight: 400;
}
.quote-llm-shield .quote-box .quote-description:after,
.quote-llm-shield .quote-box .quote-description:before {
  content: "";
  width: 30px;
  height: 25px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: -14px;
  left: 35px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.quote-llm-shield .quote-box .quote-description:after {
  top: initial;
  bottom: -14px;
  left: initial;
  right: 35px;
  -webkit-transform: none;
  transform: none;
}
.quote-llm-shield .quote-box .quote-description .quote-tip {
  position: absolute;
  left: 50%;
  bottom: -14px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.quote-llm-shield .quote-box .data-quote {
  margin-top: 30px;
}
.quote-llm-shield .quote-box .data-quote h4 {
  font-size: 24px;
  text-align: center;
}
.quote-llm-shield .quote-box .data-quote .companies {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 60px;
  max-width: 500px;
  margin: 0 auto;
}
.quote-llm-shield .quote-box .data-quote .companies img.connect-america {
  max-width: 99px;
}
.quote-llm-shield .quote-box .data-quote .companies img.biospring {
  max-width: 117px;
}
.quote-llm-shield .quote-box .data-quote .companies .company {
  width: 50%;
  text-align: right;
  position: relative;
  padding: 20px 0;
}
.quote-llm-shield .quote-box .data-quote .companies .company .role {
  display: block;
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
  text-align: right;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #78779c;
  margin-top: 15px;
}
.quote-llm-shield .quote-box .data-quote .companies .company.right {
  text-align: left;
}
.quote-llm-shield .quote-box .data-quote .companies .company.right span {
  text-align: left;
}
.quote-llm-shield .quote-box .data-quote .companies .company.right:after {
  content: "";
  height: 100%;
  width: 1px;
  border-left: solid 1px;
  -o-border-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      #ffffff,
      rgba(255, 255, 255, 0)
    )
    1;
  border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(255, 255, 255, 0)),
      color-stop(#ffffff),
      to(rgba(255, 255, 255, 0))
    )
    1;
  border-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      #ffffff,
      rgba(255, 255, 255, 0)
    )
    1;
  position: absolute;
  top: 0;
  left: -30px;
  opacity: 0.1;
}
.quote-llm-shield .quote-box .data-quote .companies .company.center {
  text-align: center;
}
.quote-llm-shield .quote-box .data-quote .companies .company.center span {
  text-align: center;
}
@media (max-width: 575px) {
  .quote-llm-shield {
    padding: 25px 0 30px;
  }
  .quote-llm-shield.dark {
    padding: 100px 0 50px;
  }
  .quote-llm-shield.biospring {
    padding: 50px 0;
  }
  .quote-llm-shield .quote-box .quote-description {
    padding: 25px;
  }
}
#best-organizations {
  padding: 140px 0 140px;
  overflow: hidden;
  background: #000000;
}
#best-organizations .content-papers {
  text-align: center;
  margin: 0 auto 100px;
}
#best-organizations .content-papers .pretitle {
  margin-bottom: 20px;
}
#best-organizations .content-papers .pretitle p {
  margin: 0;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffb629;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#best-organizations .content-papers h2 {
  color: #ffb629;
}
#best-organizations .content-papers h2.white {
  color: #ffffff;
}
#best-organizations .box-papers {
  position: relative;
  text-align: center;
  max-width: 690px;
  margin: 0 auto;
}
#best-organizations .box-papers:after {
  content: "";
  width: 100%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%) translateZ(0px);
  transform: translateY(-50%) translateZ(0px);
  background: #0c15ff;
  opacity: 0.3;
  filter: blur(80px);
  -webkit-filter: blur(80px);
}
#best-organizations .box-papers .paper {
  margin-top: -33px;
  position: relative;
  z-index: 1;
}
#best-organizations .box-papers .paper .paper-1 {
  max-width: 541px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.35));
  -webkit-filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.35));
}
#best-organizations .box-papers .paper .paper-2 {
  max-width: 594px;
}
#best-organizations .box-papers .paper .paper-3 {
  max-width: 637px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.35));
  -webkit-filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.35));
}
#best-organizations .box-papers .paper .paper-4 {
  max-width: 514px;
}
#best-organizations .box-papers .paper .paper-5 {
  max-width: 541px;
  filter: drop-shadow(0px 7px 18px rgba(0, 0, 0, 0.55));
  -webkit-filter: drop-shadow(0px 7px 18px rgba(0, 0, 0, 0.55));
}
#best-organizations .content-area {
  margin-top: 60px;
  text-align: center;
}
#best-organizations .content-area p:nth-child(2) {
  max-width: 450px;
  margin: 25px auto 10px;
}
@media (max-width: 767px) {
  #best-organizations {
    padding: 120px 0 100px;
  }
  #best-organizations .box-papers .paper {
    margin-top: -25px;
  }
  #best-organizations .box-papers .paper img {
    width: 100%;
  }
}
@media (max-width: 475px) {
  #best-organizations {
    padding: 120px 0 80px;
  }
  #best-organizations .box-papers .paper {
    margin-top: -20px;
  }
  #best-organizations .box-papers:after {
    display: none;
  }
}
#toggle-section {
  padding: 100px 0;
}
#toggle-section .privacy-status {
  margin: 0;
}
#policies {
  padding: 100px 0;
  overflow: hidden;
}
#policies .top-content {
  text-align: center;
  margin-bottom: 60px;
}
#policies .top-content h2 strong {
  color: #ffb629;
  font-weight: 600;
}
#policies .top-content p {
  margin-top: 25px;
}
#policies .top-content p strong {
  color: #ffb629;
  font-weight: 400;
}
#policies .dashboard-policies {
  position: relative;
  z-index: 1;
  max-width: 627px;
  border-radius: 8px;
}
#policies .dashboard-policies .image-area {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
#policies .dashboard-policies .image-area:before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  right: 0;
  bottom: 0;
  background: conic-gradient(
    transparent,
    transparent,
    transparent,
    rgba(255, 255, 255, 0.4)
  );
  -webkit-animation: borderLight 7s linear infinite;
  animation: borderLight 7s linear infinite;
  z-index: 1;
}
#policies .dashboard-policies .image-area .border-animation {
  position: absolute;
  inset: 1px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
}
#policies .dashboard-policies img {
  width: 100%;
  max-width: 627px;
  position: relative;
  z-index: 1;
}
#policies .dashboard-policies img.blur-dashboard {
  max-width: initial;
  width: 1000px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-45%, -55%);
  transform: translate(-45%, -55%);
  opacity: 0.2;
  z-index: 0;
}
#policies .dashboard-policies .backlight {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -45%) translateZ(0);
  transform: translate(-50%, -45%) translateZ(0);
  width: 120%;
  height: 110%;
  border-radius: 50%;
  opacity: 0.3;
  background: linear-gradient(224deg, #fe9d44 0%, #fc41ce 100%);
  -webkit-filter: blur(120px);
  filter: blur(120px);
}
#policies .steps-policies {
  padding-left: 10px;
}
#policies .steps-policies .step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
}
#policies .steps-policies .step .number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border: 2px solid #ffb629;
  -webkit-filter: drop-shadow(0px 0px 15px #f25410);
  filter: drop-shadow(0px 0px 15px #f25410);
  border-radius: 50%;
  font-size: 21px;
  line-height: 24px;
  color: #ffffff;
  margin-right: 15px;
}
#policies .steps-policies .step .data-step {
  padding-top: 10px;
  width: 90%;
}
#policies .steps-policies .step .data-step h5 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
}
#policies .steps-policies .step .data-step p {
  max-width: 380px;
}
#policies .steps-policies .step .data-step p strong {
  color: #ffb629;
  font-weight: 400;
}
#policies .steps-policies .step .data-step .small-title {
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffb629;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 5px;
}
#policies .steps-policies .step .data-step .small-title img {
  margin-right: 10px;
  -webkit-filter: drop-shadow(0px 0px 5px #ffb629);
  filter: drop-shadow(0px 0px 5px #ffb629);
}
@media (max-width: 991px) {
  #policies .dashboard-policies {
    max-width: 644px;
    margin: 0 auto 50px;
  }
  #policies .steps-policies {
    max-width: 480px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  #policies {
    padding: 100px 0 50px;
  }
  #policies .dashboard-policies .logo-core {
    top: 40px;
    left: 20px;
  }
  #policies .dashboard-policies img.blur-dashboard {
    width: 900px;
    -webkit-transform: translate(-40%, -55%);
    transform: translate(-40%, -55%);
  }
}
@media (max-width: 575px) {
  #policies .steps-policies .step {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  #policies .steps-policies .step .data-step .small-title {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #policies .steps-policies .step .data-step .small-title img {
    display: none;
  }
  #policies .steps-policies .step .data-step p {
    margin: 0 auto 15px;
  }
  #policies .dashboard-policies .logo-core {
    top: 11%;
    left: 4%;
  }
  #policies .dashboard-policies .logo-core .core-logo img {
    width: 25px;
  }
  #policies .dashboard-policies img.blur-dashboard {
    width: 550px;
  }
}
#activated-shield {
  padding: 100px 0;
  background-color: #090911;
}
#activated-shield .top-content {
  text-align: center;
  margin-bottom: 60px;
}
#activated-shield .top-content .h1 {
  margin-bottom: 20px;
}
#activated-shield .top-content .h1 strong {
  color: #ffb629;
  font-weight: 600;
  text-shadow: 0 0 15px rgba(255, 182, 41, 0.462745098);
}
#activated-shield .context-setup {
  font-family: -apple-system, sans-serif;
  max-width: 815px;
  min-height: 400px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 55px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
#activated-shield .context-setup .circles-danger {
  max-width: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  top: -37px;
  left: -43px;
  z-index: 1;
}
#activated-shield .context-setup .circles-danger .circle {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 0;
  opacity: 0;
  border-radius: 50%;
  border: 0.547px solid rgba(251, 15, 66, 0.2);
  background: rgba(251, 15, 66, 0.08);
  -webkit-animation: pulseCircleDanger 3s ease-in-out infinite;
  animation: pulseCircleDanger 3s ease-in-out infinite;
}
#activated-shield .context-setup .circles-danger .circle.medium {
  width: 80px;
  height: 80px;
}
#activated-shield .context-setup .circles-danger .circle.big {
  width: 110px;
  height: 110px;
}
#activated-shield .context-setup .shield-bubble {
  max-width: 500px;
  margin: 0 auto;
  background: rgba(40, 40, 40, 0.5);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(235, 235, 245, 0.2);
  border-radius: 16px;
  padding: 25px;
  position: relative;
  margin-bottom: 70px;
}
#activated-shield .context-setup .shield-bubble p {
  font-size: 19px;
  line-height: 24px;
  font-weight: 200;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
}
#activated-shield .context-setup .shield-bubble .top-bubble {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#activated-shield .context-setup .shield-bubble .top-bubble img {
  margin-right: 20px;
}
#activated-shield .context-setup .shield-bubble .top-bubble .data-bubble h4 {
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.02em;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
}
#activated-shield
  .context-setup
  .shield-bubble
  .top-bubble
  .data-bubble
  p
  strong {
  font-weight: 200;
  color: #ffffff;
}
#activated-shield
  .context-setup
  .shield-bubble
  .top-bubble
  .data-bubble
  p
  span {
  margin: 0 15px;
}
#activated-shield .context-setup .shield-bubble hr {
  border-color: rgba(235, 235, 245, 0.2);
}
#activated-shield
  .context-setup
  .shield-bubble
  .bottom-bubble
  .description-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#activated-shield
  .context-setup
  .shield-bubble
  .bottom-bubble
  .description-data
  img {
  margin-right: 12px;
}
#activated-shield
  .context-setup
  .shield-bubble
  .bottom-bubble
  .description-data
  img.green {
  -webkit-filter: drop-shadow(0px 0px 11.0709px rgba(255, 182, 41, 0.75));
  filter: drop-shadow(0px 0px 11.0709px rgba(255, 182, 41, 0.75));
}
#activated-shield
  .context-setup
  .shield-bubble
  .bottom-bubble
  .description-data
  img.red {
  -webkit-filter: drop-shadow(0px 0px 11.0709px rgba(245, 24, 63, 0.75));
  filter: drop-shadow(0px 0px 11.0709px rgba(245, 24, 63, 0.75));
}
#activated-shield .context-setup .shield-bubble .bottom-bubble .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}
#activated-shield
  .context-setup
  .shield-bubble
  .bottom-bubble
  .actions
  .button-apple {
  width: 100%;
  text-align: center;
  padding: 6px 50px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 7px;
  font-weight: 200;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#activated-shield
  .context-setup
  .shield-bubble
  .bottom-bubble
  .actions
  .button-apple:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6);
}
#activated-shield .context-setup .shield-bubble:after {
  content: "";
  position: absolute;
  left: 38px;
  top: -12px;
  width: 20px;
  height: 12px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-bottom: 1px solid #2e1d51;
}
#activated-shield .all-major {
  background: rgba(33, 32, 96, 0.47);
  border: 1px solid rgba(255, 255, 255, 0.05);
  -webkit-box-shadow: 0px 8px 24px #080715;
  box-shadow: 0px 8px 24px #080715;
  border-radius: 22px;
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}
#activated-shield .all-major .companies {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 15px;
  width: 25%;
}
#activated-shield .all-major .content-major {
  width: 75%;
}
#activated-shield .all-major .content-major h4 {
  margin-bottom: 15px;
}
#activated-shield .all-major .content-major h4 strong {
  font-weight: 600;
  color: #ffb629;
}
#activated-shield .all-major .content-major p {
  margin-bottom: 28px;
}
#activated-shield .all-major .content-major .box-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#activated-shield .all-major .content-major .box-button span {
  margin-right: 15px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.28em;
  text-transform: uppercase;
  color: #b7b9da;
  text-shadow: 0px 1px 0px #212058;
}
#activated-shield .backlight.green {
  width: 40%;
  height: 30%;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateZ(0px);
  transform: translateX(-50%) translateZ(0px);
  background: rgba(255, 182, 41, 0.3960784314);
  filter: blur(100px);
  -webkit-filter: blur(100px);
  pointer-events: none;
  z-index: 0;
}
@media (max-width: 991px) {
  #activated-shield .context-setup {
    min-height: 350px;
    padding-top: 70px;
    margin-bottom: 50px;
  }
  #activated-shield .context-setup .circles-danger {
    top: -50px;
    left: -75px;
  }
  #activated-shield .context-setup .shield-bubble {
    margin-bottom: 0;
  }
  #activated-shield .context-setup .shield-bubble:after {
    display: none;
  }
  #activated-shield .all-major {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 25px;
  }
  #activated-shield .all-major .companies {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #activated-shield .all-major .content-major {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #activated-shield .top-content {
    margin-bottom: 20px;
  }
  #activated-shield .context-setup {
    min-height: 250px;
    padding-top: 50px;
    margin-bottom: 60px;
  }
  #activated-shield .context-setup .circles-danger {
    top: -40px;
    left: -120px;
  }
}
@media (max-width: 575px) {
  #activated-shield .context-setup .circles-danger {
    display: none;
  }
  #activated-shield .context-setup .shield-bubble {
    border-radius: 12px;
    padding: 15px;
  }
  #activated-shield .context-setup .shield-bubble p {
    font-size: 14px;
    line-height: 16px;
  }
  #activated-shield .context-setup .shield-bubble .top-bubble img {
    margin-right: 15px;
    max-width: 40px;
  }
  #activated-shield .context-setup .shield-bubble .top-bubble .data-bubble h4 {
    font-size: 19px;
    line-height: 19px;
  }
  #activated-shield
    .context-setup
    .shield-bubble
    .top-bubble
    .data-bubble
    p
    span {
    margin: 0 7px;
  }
  #activated-shield
    .context-setup
    .shield-bubble
    .bottom-bubble
    .actions
    .button-apple {
    padding: 6px 10px;
  }
  #activated-shield .all-major .companies img {
    max-width: 60px;
  }
  #activated-shield .all-major .content-major {
    text-align: center;
  }
  #activated-shield .all-major .content-major .box-button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 425px) {
  #activated-shield .context-setup {
    background-image: none;
    padding-top: 0;
    margin-bottom: 0;
  }
  #activated-shield .all-major {
    padding: 20px;
  }
  #activated-shield .all-major .content-major .box-button span {
    margin-right: 0;
  }
  #activated-shield .all-major .companies {
    gap: 10px;
  }
  #activated-shield .all-major .companies img {
    max-width: 50px;
  }
}
#it-infrastructure {
  padding: 50px 0 100px;
  background-color: #090911;
}
#it-infrastructure .top-content {
  text-align: center;
  margin-bottom: 60px;
}
#it-infrastructure .top-content h3 strong {
  color: #ffb629;
  font-weight: 600;
}
#it-infrastructure .col-12:nth-child(2) {
  position: relative;
}
#it-infrastructure .col-12:nth-child(2):after,
#it-infrastructure .col-12:nth-child(2):before {
  content: "";
  height: 100%;
  width: 1px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(14, 31, 64, 0)),
    color-stop(50%, #102753),
    to(rgba(14, 31, 64, 0))
  );
  background-image: linear-gradient(
    0deg,
    rgba(14, 31, 64, 0) 0%,
    #102753 50%,
    rgba(14, 31, 64, 0) 100%
  );
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%) translateZ(0);
  transform: translateY(-50%) translateZ(0);
  opacity: 0.5;
}
#it-infrastructure .col-12:nth-child(2):after {
  left: initial;
  right: 0;
}
#it-infrastructure .box-area.middle {
  padding: 0 23px;
}
#it-infrastructure .box-area.left {
  padding-right: 23px;
}
#it-infrastructure .box-area.right {
  padding-left: 40px;
}
#it-infrastructure .box-area .top-box img {
  -webkit-filter: drop-shadow(0px 0px 15px #ffb629);
  filter: drop-shadow(0px 0px 15px #ffb629);
  margin-bottom: 20px;
}
#it-infrastructure .box-area .top-box h4 {
  margin-bottom: 20px;
}
#it-infrastructure .box-area p span {
  color: #ffffff;
}
#it-infrastructure .box-area .companies {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 12px;
  margin: 25px 0 15px;
}
#it-infrastructure .box-area .companies .company {
  -webkit-filter: drop-shadow(0px 4.375px 13.125px #080715);
  filter: drop-shadow(0px 4.375px 13.125px #080715);
}
#it-infrastructure .box-area .companies .company span {
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 8.5px;
  line-height: 10px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #514a9a;
  opacity: 0.5;
  margin-top: 5px;
}
#it-infrastructure .box-area .small-title {
  font-size: 11px;
  line-height: 13px;
  font-weight: 800;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffb629;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  width: 100%;
  margin-bottom: 5px;
}
#it-infrastructure .box-area .disclaimer {
  margin-top: 40px;
  padding: 18px;
  background: rgba(33, 32, 96, 0.47);
  border-radius: 10px;
  position: relative;
  text-align: center;
}
#it-infrastructure .box-area .disclaimer img {
  position: absolute;
  left: 50%;
  top: -12px;
  -webkit-transform: translateX(-50%) translateZ(0);
  transform: translateX(-50%) translateZ(0);
  -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.24));
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.24));
}
#it-infrastructure .box-area .disclaimer p {
  margin: 0;
  font-size: 11px;
  line-height: 17px;
  font-weight: 800;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #ffb629;
}
#it-infrastructure .box-area ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#it-infrastructure .box-area ul li {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #78779c;
  position: relative;
  margin-bottom: 10px;
}
#it-infrastructure .box-area ul li:first-child {
  max-width: 200px;
}
#it-infrastructure .box-area ul li span {
  color: #ffffff;
}
#it-infrastructure .box-area ul li .api {
  padding: 10px;
  background: rgba(33, 32, 96, 0.47);
  border-radius: 10px;
  font-size: 25px;
  line-height: 31px;
  font-weight: 800;
  color: #ffb629;
  min-width: 87px;
  position: absolute;
  top: -3px;
  right: -70px;
}
#it-infrastructure .box-area ul li .api span {
  font-weight: 200;
  color: #ffb629;
}
#it-infrastructure .box-area ul li:before {
  content: "";
  position: absolute;
  left: -22px;
  top: 7px;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  #it-infrastructure .col-12:nth-child(2):after,
  #it-infrastructure .col-12:nth-child(2):before {
    display: none;
  }
  #it-infrastructure .box-area {
    padding: 0;
    margin-bottom: 40px;
    text-align: center;
  }
  #it-infrastructure .box-area.left,
  #it-infrastructure .box-area.middle,
  #it-infrastructure .box-area.right {
    padding: 0;
  }
  #it-infrastructure .box-area.middle {
    padding: 50px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1254901961);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1254901961);
  }
  #it-infrastructure .box-area .disclaimer {
    max-width: 400px;
    margin: 30px auto 0;
  }
  #it-infrastructure .box-area .companies {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #it-infrastructure .box-area .small-title {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #it-infrastructure .box-area ul {
    max-width: 350px;
    margin: 20px auto 0;
    text-align: left;
  }
}
@media (max-width: 425px) {
  #it-infrastructure {
    padding: 50px 0;
  }
  #it-infrastructure .box-area ul {
    padding-left: 20px;
  }
}
#source-code-protection {
  padding: 130px 0 100px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#090911),
    to(#100d23)
  );
  background: linear-gradient(180deg, #090911 0%, #100d23 100%);
  overflow: hidden;
  position: relative;
}
#source-code-protection .container {
  position: relative;
}
#source-code-protection:after {
  content: "";
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#100d23),
    to(rgba(16, 13, 35, 0))
  );
  background: linear-gradient(0deg, #100d23 0%, rgba(16, 13, 35, 0) 100%);
  pointer-events: none;
  z-index: 1;
}
#source-code-protection:before {
  content: "";
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(9, 9, 17)),
    to(rgba(16, 13, 35, 0))
  );
  background: linear-gradient(
    180deg,
    rgb(9, 9, 17) 0%,
    rgba(16, 13, 35, 0) 100%
  );
  pointer-events: none;
  z-index: 1;
}
#source-code-protection .blur-dashboard {
  max-width: initial;
  width: 1250px;
  position: absolute;
  top: 50%;
  right: -12%;
  -webkit-transform: translateY(-50%) translateZ(0);
  transform: translateY(-50%) translateZ(0);
  opacity: 0.2;
  z-index: 0;
  pointer-events: none;
}
#source-code-protection .left-content {
  position: relative;
  z-index: 2;
}
#source-code-protection .left-content .pretitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 11px;
  line-height: 13px;
  font-weight: 800;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffb629;
  margin-bottom: 15px;
}
#source-code-protection .left-content .pretitle img {
  margin-right: 8px;
}
#source-code-protection .left-content h3 {
  margin-bottom: 0;
}
#source-code-protection .left-content h5 {
  color: #ffb629;
  max-width: 400px;
  margin-bottom: 25px;
  margin-top: 20px;
}
#source-code-protection .left-content ul {
  list-style: none;
  padding: 0;
  margin: 0 0 25px;
}
#source-code-protection .left-content ul li {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #78779c;
  position: relative;
  margin-bottom: 12px;
}
#source-code-protection .left-content ul li span {
  color: #ffffff;
}
#source-code-protection .left-content ul li:before {
  content: "";
  display: inline-block;
  margin-right: 13px;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
#source-code-protection .left-content .button {
  margin-top: 20px;
}
#source-code-protection .dashboard-source {
  position: relative;
  z-index: 2;
  max-width: 627px;
  border-radius: 8px;
}
#source-code-protection .dashboard-source .image-area {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
#source-code-protection .dashboard-source .image-area:before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  right: 0;
  bottom: 0;
  background: conic-gradient(
    transparent,
    transparent,
    transparent,
    rgba(255, 255, 255, 0.4)
  );
  -webkit-animation: borderLight 7s linear infinite;
  animation: borderLight 7s linear infinite;
  z-index: 1;
}
#source-code-protection .dashboard-source .image-area .border-animation {
  position: absolute;
  inset: 1px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
}
#source-code-protection .dashboard-source span {
  position: relative;
  bottom: -30px;
  display: block;
  font-size: 11px;
  line-height: 13px;
  font-weight: 800;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #78779c;
  opacity: 0.5;
  z-index: 2;
}
#source-code-protection .dashboard-source img {
  width: 100%;
  max-width: 627px;
  position: relative;
  z-index: 1;
}
#source-code-protection #particles-js-source-dashboard {
  position: absolute;
  top: -5%;
  left: -5%;
  height: 110%;
  width: 110%;
  opacity: 0.7;
  pointer-events: none;
}
#source-code-protection .backlight {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -45%) translateZ(0);
  transform: translate(-50%, -45%) translateZ(0);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.2;
  background: linear-gradient(224deg, #fe9d44 0%, #fc41ce 100%);
  -webkit-filter: blur(120px);
  filter: blur(120px);
  pointer-events: none;
}
@media (max-width: 991px) {
  #source-code-protection .left-content {
    text-align: center;
    margin-bottom: 50px;
  }
  #source-code-protection .left-content .pretitle {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #source-code-protection .left-content h5 {
    margin: 10px auto 25px;
  }
  #source-code-protection .dashboard-source {
    max-width: 632px;
    margin: 0 auto;
  }
  #source-code-protection .blur-dashboard {
    width: 950px;
    right: 0%;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }
}
@media (max-width: 767px) {
  #source-code-protection #particles-js-source-dashboard {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  #source-code-protection .dashboard-source .logo-core {
    top: 45px;
    left: 20px;
  }
  #source-code-protection .blur-dashboard {
    right: initial;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
  }
}
@media (max-width: 575px) {
  #source-code-protection {
    padding: 80px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.062745098);
  }
  #source-code-protection .dashboard-source .logo-core {
    top: 11%;
    left: 4%;
  }
  #source-code-protection .dashboard-source .logo-core .core-logo img {
    width: 25px;
  }
  #source-code-protection .blur-dashboard {
    top: 55%;
    width: 650px;
    left: 35%;
  }
}
#services {
  padding: 100px 0 150px;
  background-color: #0d0c1e;
  position: relative;
  z-index: 1;
}
#services .container {
  max-width: 950px;
}
#services .content-area {
  text-align: center;
  max-width: 700px;
  margin: 0 auto 100px;
}
#services .content-area h3 {
  max-width: 500px;
  margin: 0 auto 8px;
}
#services .content-area strong {
  font-weight: 600;
  color: #ffb629;
  text-shadow: 0px 0px 20px rgba(255, 182, 41, 0.45);
}
#services .content-area p:not(.featured-shadow) {
  margin: 20px auto 0;
}
#services .spacer {
  height: 1px;
  background-color: #1f1c43;
  margin: 100px 0;
}
#services .backlight {
  width: 100%;
  height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) translateZ(0px);
  transform: translate(-50%, -50%) translateZ(0px);
  background: #0c15ff;
  opacity: 0.3;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  pointer-events: none;
  z-index: 0;
}
#services .content-features {
  max-width: 350px;
}
#services .content-features h3 {
  margin-bottom: 20px;
}
#services .content-features h3 strong {
  color: #ffb629;
  font-weight: 600;
}
#services .content-features p {
  max-width: 260px;
  text-align: left;
  margin-bottom: 0;
}
#services .content-features p span {
  color: #ffffff;
}
#services .content-features .companies-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
}
#services .content-features .companies-row > span {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.2em;
  font-weight: 800;
  text-transform: uppercase;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffc163),
    to(#da8f1f)
  );
  background: linear-gradient(90deg, #ffc163 0%, #da8f1f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
#services .content-features .companies-row .company-bubble {
  max-width: 50px;
}
#services .content-features .companies-row .company-bubble img {
  width: 100%;
  -webkit-filter: drop-shadow(0px 5px 15px #080715);
  filter: drop-shadow(0px 5px 15px #080715);
}
#services .chat-area {
  position: relative;
  z-index: 1;
  max-width: 479px;
  margin: 0 auto;
}
#services .chat-area .chat-bg {
  width: 100%;
  -webkit-filter: drop-shadow(0px 26.425px 46.2438px rgba(0, 0, 0, 0.35));
  filter: drop-shadow(0px 26.425px 46.2438px rgba(0, 0, 0, 0.35));
}
#services .chat-area .chat-messages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#services .chat-area .chat-messages .message {
  position: absolute;
  max-width: 452px;
  bottom: 75px;
  -webkit-filter: drop-shadow(0px 0px 30px rgba(255, 195, 41, 0.1));
  filter: drop-shadow(0px 0px 30px rgba(255, 195, 41, 0.1));
  opacity: 0;
}
#services .chat-area .logo-core {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  z-index: 1;
}
#services .chat-area .logo-core .circle {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 0;
  background: #43ffdb;
  opacity: 0;
  border-radius: 50%;
  -webkit-animation: pulseCircleSmall 4s linear infinite;
  animation: pulseCircleSmall 4s linear infinite;
}
#services .chat-area .logo-core .circle.big {
  -webkit-animation: pulseCircleBig 4s linear infinite;
  animation: pulseCircleBig 4s linear infinite;
  width: 70px;
  height: 70px;
}
#services .chat-area .logo-core .core-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}
#services .chat-area .logo-core .core-logo img {
  width: 28px;
}
#services .sources-area {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
#services .sources-area .shadows {
  position: absolute;
  z-index: 0;
}
#services .sources-area .shadows.near {
  max-width: 434px;
  left: -10px;
  top: 30px;
  z-index: 1;
}
#services .sources-area .shadows.far {
  max-width: 436px;
}
#services .sources-area .globe {
  position: relative;
  z-index: 2;
  -webkit-filter: drop-shadow(0px 5px 15px rgba(8, 7, 21, 0.25));
  filter: drop-shadow(0px 5px 15px rgba(8, 7, 21, 0.25));
}
#services .sources-area .globe.aws {
  left: -25px;
  top: 15px;
}
#services .sources-area .globe.github {
  z-index: 3;
}
#services .sources-area .globe.stripe {
  top: 15px;
}
#services .sources-area .globe.x {
  bottom: 15px;
}
#services .sources-area .globe.shopify {
  top: 40px;
}
#services .sources-area .globe.hubspot {
  top: 20px;
  right: -20px;
  z-index: 3;
}
#services .sources-area .globe.slack {
  bottom: 15px;
  right: 30px;
}
@media (max-width: 767px) {
  #services {
    padding: 100px 0 100px;
  }
  #services .content-features {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }
}
@media (max-width: 475px) {
  #services .spacer {
    margin: 40px 0;
  }
  #services .backlight {
    display: none;
  }
}
#source-code {
  padding: 100px 0 120px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#12102b),
    to(#0c0a1f)
  );
  background: linear-gradient(180deg, #12102b 0%, #0c0a1f 100%);
}
#source-code .top-area {
  text-align: center;
}
#source-code .top-area h5 {
  color: #78779c;
}
#source-code .list-source {
  margin-top: 60px;
}
#source-code .list-source .top-list {
  text-align: center;
  margin-bottom: 15px;
  min-height: 80px;
}
#source-code .list-source .top-list img {
  margin-bottom: 15px;
}
#source-code .list-source .top-list span {
  display: block;
  font-size: 25px;
  line-height: 31px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #ffffff;
}
#source-code .list-source ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#source-code .list-source ul li {
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: #78779c;
  border-top: 1px solid #1f1c43;
  padding: 17px 0;
  position: relative;
}
#source-code .list-source ul li:last-child {
  border-bottom: 1px solid #1f1c43;
}
#source-code .list-source ul li:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
#source-code .view-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 80px;
}
#source-code .view-more .box-button-dark {
  margin: 0;
}
#source-code .view-more p {
  margin-bottom: 0;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-right: 30px;
}
#source-code .view-more p strong {
  font-weight: 600;
  color: #ffb629;
}
#how-it-works {
  padding: 100px 0 120px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#12102b),
    to(#09081a)
  );
  background: linear-gradient(180deg, #12102b 0%, #09081a 100%);
}
#how-it-works .top-area {
  text-align: center;
  margin-bottom: 80px;
}
#how-it-works .top-area .shields {
  position: relative;
}
#how-it-works .top-area .shields .backlight {
  width: 40%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) translateZ(0px);
  transform: translate(-50%, -50%) translateZ(0px);
  background: #0c15ff;
  opacity: 0.4;
  border-radius: 50%;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  pointer-events: none;
  z-index: 0;
}
#how-it-works .top-area .shields img {
  position: relative;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#how-it-works .top-area .shields img.shield-left {
  max-width: 199px;
  margin-right: -90px;
  -webkit-transform: translateX(-150px) translateZ(0);
  transform: translateX(-150px) translateZ(0);
}
#how-it-works .top-area .shields img.shield-middle {
  max-width: 336px;
  z-index: 2;
  -webkit-transform: translateY(-100px) translateZ(0);
  transform: translateY(-100px) translateZ(0);
}
#how-it-works .top-area .shields img.shield-right {
  max-width: 191px;
  margin-left: -90px;
  -webkit-transform: translateX(150px) translateZ(0);
  transform: translateX(150px) translateZ(0);
}
#how-it-works .top-area .shields.reveal img {
  opacity: 1;
}
#how-it-works .top-area .shields.reveal img.shield-middle {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
  -webkit-transform: translateY(0) translateZ(0);
  transform: translateY(0) translateZ(0);
}
#how-it-works .top-area .shields.reveal img.shield-left,
#how-it-works .top-area .shields.reveal img.shield-right {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
  -webkit-transform: translateX(0) translateZ(0);
  transform: translateX(0) translateZ(0);
}
#how-it-works .top-area h2 {
  margin-bottom: 20px;
}
#how-it-works .top-area h2:after {
  content: "®";
  display: inline-block;
  font-weight: 100;
  font-size: 23px;
  vertical-align: 23px;
  margin-left: 5px;
}
#how-it-works .top-area p {
  max-width: 650px;
  margin: 0 auto 5px;
}
#how-it-works .top-area p span {
  color: #ffffff;
}
#how-it-works .card-area {
  position: relative;
  margin-bottom: 70px;
}
#how-it-works .card-area .behind-card {
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0)),
      to(rgba(0, 0, 0, 0.14))
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))),
    #383187;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.14) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #383187;
  -webkit-box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  padding: 24px 25px;
  min-height: 190px;
  max-width: 285px;
  margin: 0 auto -75px;
  position: relative;
  overflow: hidden;
  --squircle-radius: 30px;
  --squircle-smooth: 0.6;
  -webkit-mask-image: paint(squircle);
  mask-image: paint(squircle);
}
#how-it-works .card-area .behind-card:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  --squircle-radius: 34px;
  --squircle-smooth: 0.7;
  background: paint(squircle);
  --squircle-color: rgba(255, 255, 255, 0.05);
  --squircle-outline: 1px;
  pointer-events: none;
}
#how-it-works .card-area .behind-card .shadow {
  position: absolute;
  width: 85%;
  height: 70px;
  background-color: #020107;
  filter: blur(50px);
  -webkit-filter: blur(50px);
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
  top: 55%;
  opacity: 0.8;
  pointer-events: none;
}
#how-it-works .card-area .behind-card img {
  position: absolute;
  left: 50%;
  top: 10px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
#how-it-works .card-area .behind-card img.warn-info {
  max-width: 256px;
  top: 14px;
}
#how-it-works .card-area .behind-card img.stop-info {
  max-width: 245px;
}
#how-it-works .card-area .behind-card img.obfuscate-info {
  top: 20px;
  max-width: 247px;
}
#how-it-works .card-area .static-card {
  padding: 30px 35px 20px;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0)),
      to(rgba(0, 0, 0, 0.2))
    ),
    #12102b;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    #12102b;
  -webkit-box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  min-height: 215px;
  --squircle-radius: 34px;
  --squircle-smooth: 0.7;
  -webkit-mask-image: paint(squircle);
  mask-image: paint(squircle);
  position: relative;
  z-index: 1;
}
#how-it-works .card-area .static-card.safari {
  border: 1px solid rgba(255, 255, 255, 0.04);
}
#how-it-works .card-area .static-card:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  --squircle-radius: 34px;
  --squircle-smooth: 0.7;
  background: paint(squircle);
  --squircle-color: rgba(255, 255, 255, 0.04);
  --squircle-outline: 1px;
  pointer-events: none;
}
#how-it-works .card-area .static-card .number-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#how-it-works .card-area .static-card .number-box .number {
  position: relative;
  width: 55px;
  height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #ffb629;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(255, 182, 41, 0.75));
  filter: drop-shadow(0px 0px 10px rgba(255, 182, 41, 0.75));
}
#how-it-works .card-area .static-card .number-box .number.red {
  border-color: #ea0e42;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(255, 101, 14, 0.75));
  filter: drop-shadow(0px 0px 10px rgba(255, 101, 14, 0.75));
}
#how-it-works .card-area .static-card .number-box .number.orange {
  border-color: #ff7529;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(234, 14, 66, 0.96));
  filter: drop-shadow(0px 0px 10px rgba(234, 14, 66, 0.96));
}
#how-it-works .card-area .static-card .number-box .number span {
  color: #ffffff;
  font-weight: 300;
  font-size: 25px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.55);
}
#how-it-works .card-area .static-card .number-box h5 {
  margin-left: 15px;
  margin-bottom: 0;
}
#how-it-works .card-area .static-card .card-static-body {
  padding-top: 20px;
}
#how-it-works .card-area .static-card .card-static-body p {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  min-height: 95px;
  margin-bottom: 0;
}
#how-it-works .card-area .static-card .card-static-body p strong {
  font-weight: 400;
  color: #ffffff;
}
#how-it-works .box-disclaimer {
  position: relative;
}
#how-it-works .box-disclaimer .disclaimer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 40px;
  padding: 20px 10px;
  border-radius: 15px;
  --squircle-radius: 40px;
  --squircle-smooth: 1;
  --intro-outline-width: 2;
  -webkit-mask-image: paint(squircle);
  mask-image: paint(squircle);
  background-color: #0b0a18;
  text-align: center;
  position: relative;
  z-index: 1;
}
#how-it-works .box-disclaimer .disclaimer img {
  margin-right: 10px;
  filter: drop-shadow(0 0 5px #ffb629);
  -webkit-filter: drop-shadow(0 0 5px #ffb629);
}
#how-it-works .box-disclaimer .disclaimer p {
  margin: 0;
  font-size: 15px;
  line-height: 22px;
}
#how-it-works .box-disclaimer .disclaimer p strong {
  font-size: 19px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: -0.01em;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffc163),
    to(#da8f1f)
  );
  background: linear-gradient(180deg, #ffc163 0%, #da8f1f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-right: 20px;
}
#how-it-works .box-disclaimer .disclaimer p span {
  color: #ffffff;
}
#how-it-works .box-disclaimer .border-disclaimer {
  border-radius: 15px;
  background: paint(squircle);
  --squircle-radius: 40px;
  --squircle-smooth: 1;
  --squircle-outline: 1px;
  background: linear-gradient(-122.37deg, #fe9d44 0%, #fc41ce 100%);
  -webkit-mask-image: paint(squircle);
  mask-image: paint(squircle);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  height: 100%;
}
#how-it-works .box-disclaimer .border-disclaimer.safari {
  width: 100.2%;
  height: 104%;
  z-index: 0;
}
@media (max-width: 991px) {
  #how-it-works .box-disclaimer .border-disclaimer.safari {
    height: 102%;
  }
}
#how-it-works .box-disclaimer .blur-disclaimer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(-122.37deg, #fe9d44 0%, #fc41ce 100%);
  filter: blur(20px);
  -webkit-filter: blur(20px);
  opacity: 20%;
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
}
@media (max-width: 991px) {
  #how-it-works .card-area {
    margin-bottom: 80px;
  }
  #how-it-works .card-area .static-card {
    min-height: 180px;
    max-width: 400px;
    margin: 0 auto;
  }
  #how-it-works .card-area .glow-card {
    width: 40%;
  }
  #how-it-works .card-area .overlay-card {
    max-width: 350px;
    margin: 0 auto;
    border-radius: 50px;
  }
  #how-it-works .box-disclaimer .disclaimer {
    padding: 25px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 0;
  }
  #how-it-works .box-disclaimer .disclaimer p strong {
    display: block;
    margin-right: 0;
    margin-top: 5px;
    margin-bottom: 10px;
  }
}
#mc_embed_signup div#mce-responses {
  overflow: initial;
  float: initial;
  top: 60px;
}
#register-company .input-group,
#register-country .input-group,
#register-press .input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#register-company .input-group .input-box label,
#register-country .input-group .input-box label,
#register-press .input-group .input-box label {
  display: block;
}
#register-company .input-group .input-box input,
#register-company .input-group .input-box select,
#register-country .input-group .input-box input,
#register-country .input-group .input-box select,
#register-press .input-group .input-box input,
#register-press .input-group .input-box select {
  width: 100%;
}
#register-company .box-button,
#register-country .box-button,
#register-press .box-button {
  margin-top: 25px;
  text-align: center;
}
#register-company .box-button button,
#register-country .box-button button,
#register-press .box-button button {
  border: 0;
}
#register-company .success-message,
#register-country .success-message,
#register-press .success-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #110d23;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}
#register-company .success-message h4,
#register-country .success-message h4,
#register-press .success-message h4 {
  max-width: 350px;
  margin: 0 auto 20px;
}
#register-company .success-message.success,
#register-country .success-message.success,
#register-press .success-message.success {
  opacity: 1;
}
#register-company.success form,
#register-country.success form,
#register-press.success form {
  opacity: 0;
  pointer-events: none;
}
#mc_embed_signup,
#register-company,
#register-country,
#register-press {
  font-family: "Manrope", sans-serif;
  background-color: transparent;
  width: 100%;
}
#mc_embed_signup form,
#register-company form,
#register-country form,
#register-press form {
  margin: 0;
}
#mc_embed_signup form .input-group,
#register-company form .input-group,
#register-country form .input-group,
#register-press form .input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  gap: 0 20px;
}
#mc_embed_signup form .input-group .type,
#register-company form .input-group .type,
#register-country form .input-group .type,
#register-press form .input-group .type {
  width: 0%;
  display: none;
}
#mc_embed_signup form .input-group .input-box div.mce_inline_error,
#mc_embed_signup form .input-group .mc-field-group div.mce_inline_error,
#register-company form .input-group .input-box div.mce_inline_error,
#register-company form .input-group .mc-field-group div.mce_inline_error,
#register-country form .input-group .input-box div.mce_inline_error,
#register-country form .input-group .mc-field-group div.mce_inline_error,
#register-press form .input-group .input-box div.mce_inline_error,
#register-press form .input-group .mc-field-group div.mce_inline_error {
  top: 63%;
}
#mc_embed_signup form .input-group .input-box.hide,
#mc_embed_signup form .input-group .mc-field-group.hide,
#register-company form .input-group .input-box.hide,
#register-company form .input-group .mc-field-group.hide,
#register-country form .input-group .input-box.hide,
#register-country form .input-group .mc-field-group.hide,
#register-press form .input-group .input-box.hide,
#register-press form .input-group .mc-field-group.hide {
  display: none;
}
#mc_embed_signup form .divider,
#register-company form .divider,
#register-country form .divider,
#register-press form .divider {
  padding: 25px 0 12px;
  margin-bottom: 22px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
#mc_embed_signup form .divider.top,
#register-company form .divider.top,
#register-country form .divider.top,
#register-press form .divider.top {
  padding-top: 0;
}
#mc_embed_signup form .input-box,
#mc_embed_signup form .mc-field-group,
#register-company form .input-box,
#register-company form .mc-field-group,
#register-country form .input-box,
#register-country form .mc-field-group,
#register-press form .input-box,
#register-press form .mc-field-group {
  width: 100%;
  min-height: auto;
  padding-bottom: 8px;
  margin: 0 auto;
}
#mc_embed_signup form .input-box label,
#mc_embed_signup form .mc-field-group label,
#register-company form .input-box label,
#register-company form .mc-field-group label,
#register-country form .input-box label,
#register-country form .mc-field-group label,
#register-press form .input-box label,
#register-press form .mc-field-group label {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 7px;
}
#mc_embed_signup form .input-box input,
#mc_embed_signup form .input-box select,
#mc_embed_signup form .mc-field-group input,
#mc_embed_signup form .mc-field-group select,
#register-company form .input-box input,
#register-company form .input-box select,
#register-company form .mc-field-group input,
#register-company form .mc-field-group select,
#register-country form .input-box input,
#register-country form .input-box select,
#register-country form .mc-field-group input,
#register-country form .mc-field-group select,
#register-press form .input-box input,
#register-press form .input-box select,
#register-press form .mc-field-group input,
#register-press form .mc-field-group select {
  background-color: #040509;
  background: rgb(47, 44, 62);
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  border-radius: 6px;
  text-indent: 0;
  padding: 8px 10px 10px;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #ffffff;
  text-shadow: 0px 0px 35.8518px #000000;
  -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.25);
  min-height: 45px;
  -webkit-transition: all 0.3 ease-in-out;
  transition: all 0.3 ease-in-out;
}
#mc_embed_signup form .input-box input:focus,
#mc_embed_signup form .input-box select:focus,
#mc_embed_signup form .mc-field-group input:focus,
#mc_embed_signup form .mc-field-group select:focus,
#register-company form .input-box input:focus,
#register-company form .input-box select:focus,
#register-company form .mc-field-group input:focus,
#register-company form .mc-field-group select:focus,
#register-country form .input-box input:focus,
#register-country form .input-box select:focus,
#register-country form .mc-field-group input:focus,
#register-country form .mc-field-group select:focus,
#register-press form .input-box input:focus,
#register-press form .input-box select:focus,
#register-press form .mc-field-group input:focus,
#register-press form .mc-field-group select:focus {
  -webkit-box-shadow: 0;
  box-shadow: 0;
  border: 1px solid #ffb629;
  outline: 0;
  -webkit-box-shadow: 0 0 30px rgba(255, 184, 41, 0.1725490196);
  box-shadow: 0 0 30px rgba(255, 184, 41, 0.1725490196);
}
#mc_embed_signup form .input-box input::-webkit-input-placeholder,
#mc_embed_signup form .input-box select::-webkit-input-placeholder,
#mc_embed_signup form .mc-field-group input::-webkit-input-placeholder,
#mc_embed_signup form .mc-field-group select::-webkit-input-placeholder,
#register-company form .input-box input::-webkit-input-placeholder,
#register-company form .input-box select::-webkit-input-placeholder,
#register-company form .mc-field-group input::-webkit-input-placeholder,
#register-company form .mc-field-group select::-webkit-input-placeholder,
#register-country form .input-box input::-webkit-input-placeholder,
#register-country form .input-box select::-webkit-input-placeholder,
#register-country form .mc-field-group input::-webkit-input-placeholder,
#register-country form .mc-field-group select::-webkit-input-placeholder,
#register-press form .input-box input::-webkit-input-placeholder,
#register-press form .input-box select::-webkit-input-placeholder,
#register-press form .mc-field-group input::-webkit-input-placeholder,
#register-press form .mc-field-group select::-webkit-input-placeholder {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25em;
  color: rgba(255, 255, 255, 0.6588235294);
  text-shadow: 0px 0px 35.8518px #000000;
}
#mc_embed_signup form .input-box input::-moz-placeholder,
#mc_embed_signup form .input-box select::-moz-placeholder,
#mc_embed_signup form .mc-field-group input::-moz-placeholder,
#mc_embed_signup form .mc-field-group select::-moz-placeholder,
#register-company form .input-box input::-moz-placeholder,
#register-company form .input-box select::-moz-placeholder,
#register-company form .mc-field-group input::-moz-placeholder,
#register-company form .mc-field-group select::-moz-placeholder,
#register-country form .input-box input::-moz-placeholder,
#register-country form .input-box select::-moz-placeholder,
#register-country form .mc-field-group input::-moz-placeholder,
#register-country form .mc-field-group select::-moz-placeholder,
#register-press form .input-box input::-moz-placeholder,
#register-press form .input-box select::-moz-placeholder,
#register-press form .mc-field-group input::-moz-placeholder,
#register-press form .mc-field-group select::-moz-placeholder {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25em;
  color: rgba(255, 255, 255, 0.6588235294);
  text-shadow: 0px 0px 35.8518px #000000;
}
#mc_embed_signup form .input-box input:-ms-input-placeholder,
#mc_embed_signup form .input-box select:-ms-input-placeholder,
#mc_embed_signup form .mc-field-group input:-ms-input-placeholder,
#mc_embed_signup form .mc-field-group select:-ms-input-placeholder,
#register-company form .input-box input:-ms-input-placeholder,
#register-company form .input-box select:-ms-input-placeholder,
#register-company form .mc-field-group input:-ms-input-placeholder,
#register-company form .mc-field-group select:-ms-input-placeholder,
#register-country form .input-box input:-ms-input-placeholder,
#register-country form .input-box select:-ms-input-placeholder,
#register-country form .mc-field-group input:-ms-input-placeholder,
#register-country form .mc-field-group select:-ms-input-placeholder,
#register-press form .input-box input:-ms-input-placeholder,
#register-press form .input-box select:-ms-input-placeholder,
#register-press form .mc-field-group input:-ms-input-placeholder,
#register-press form .mc-field-group select:-ms-input-placeholder {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25em;
  color: rgba(255, 255, 255, 0.6588235294);
  text-shadow: 0px 0px 35.8518px #000000;
}
#mc_embed_signup form .input-box input::-ms-input-placeholder,
#mc_embed_signup form .input-box select::-ms-input-placeholder,
#mc_embed_signup form .mc-field-group input::-ms-input-placeholder,
#mc_embed_signup form .mc-field-group select::-ms-input-placeholder,
#register-company form .input-box input::-ms-input-placeholder,
#register-company form .input-box select::-ms-input-placeholder,
#register-company form .mc-field-group input::-ms-input-placeholder,
#register-company form .mc-field-group select::-ms-input-placeholder,
#register-country form .input-box input::-ms-input-placeholder,
#register-country form .input-box select::-ms-input-placeholder,
#register-country form .mc-field-group input::-ms-input-placeholder,
#register-country form .mc-field-group select::-ms-input-placeholder,
#register-press form .input-box input::-ms-input-placeholder,
#register-press form .input-box select::-ms-input-placeholder,
#register-press form .mc-field-group input::-ms-input-placeholder,
#register-press form .mc-field-group select::-ms-input-placeholder {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25em;
  color: rgba(255, 255, 255, 0.6588235294);
  text-shadow: 0px 0px 35.8518px #000000;
}
#mc_embed_signup form .input-box input::placeholder,
#mc_embed_signup form .input-box select::placeholder,
#mc_embed_signup form .mc-field-group input::placeholder,
#mc_embed_signup form .mc-field-group select::placeholder,
#register-company form .input-box input::placeholder,
#register-company form .input-box select::placeholder,
#register-company form .mc-field-group input::placeholder,
#register-company form .mc-field-group select::placeholder,
#register-country form .input-box input::placeholder,
#register-country form .input-box select::placeholder,
#register-country form .mc-field-group input::placeholder,
#register-country form .mc-field-group select::placeholder,
#register-press form .input-box input::placeholder,
#register-press form .input-box select::placeholder,
#register-press form .mc-field-group input::placeholder,
#register-press form .mc-field-group select::placeholder {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25em;
  color: rgba(255, 255, 255, 0.6588235294);
  text-shadow: 0px 0px 35.8518px #000000;
}
#mc_embed_signup form .input-box select,
#mc_embed_signup form .mc-field-group select,
#register-company form .input-box select,
#register-company form .mc-field-group select,
#register-country form .input-box select,
#register-country form .mc-field-group select,
#register-press form .input-box select,
#register-press form .mc-field-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: 91% center;
  background-size: 10px;
  background-repeat: no-repeat;
}
#mc_embed_signup form .input-box div.mce_inline_error,
#mc_embed_signup form .mc-field-group div.mce_inline_error,
#register-company form .input-box div.mce_inline_error,
#register-company form .mc-field-group div.mce_inline_error,
#register-country form .input-box div.mce_inline_error,
#register-country form .mc-field-group div.mce_inline_error,
#register-press form .input-box div.mce_inline_error,
#register-press form .mc-field-group div.mce_inline_error {
  font-size: 11px;
  background-color: transparent !important;
  margin: 0px;
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-60%);
  transform: translateY(-60%);
  pointer-events: none;
  opacity: 0.7;
}
#mc_embed_signup form .input-box.col-one,
#mc_embed_signup form .mc-field-group.col-one,
#register-company form .input-box.col-one,
#register-company form .mc-field-group.col-one,
#register-country form .input-box.col-one,
#register-country form .mc-field-group.col-one,
#register-press form .input-box.col-one,
#register-press form .mc-field-group.col-one {
  width: 58%;
  margin: 0 2%;
}
#mc_embed_signup form .input-box.col-one input,
#mc_embed_signup form .mc-field-group.col-one input,
#register-company form .input-box.col-one input,
#register-company form .mc-field-group.col-one input,
#register-country form .input-box.col-one input,
#register-country form .mc-field-group.col-one input,
#register-press form .input-box.col-one input,
#register-press form .mc-field-group.col-one input {
  background: rgba(255, 255, 255, 0.1);
}
#mc_embed_signup form .input-box.col-two,
#mc_embed_signup form .mc-field-group.col-two,
#register-company form .input-box.col-two,
#register-company form .mc-field-group.col-two,
#register-country form .input-box.col-two,
#register-country form .mc-field-group.col-two,
#register-press form .input-box.col-two,
#register-press form .mc-field-group.col-two {
  width: 36%;
  margin-right: 0;
}
#mc_embed_signup form .clear.submit-box,
#register-company form .clear.submit-box,
#register-country form .clear.submit-box,
#register-press form .clear.submit-box {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
  margin-top: 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#mc_embed_signup form .clear.submit-box:before,
#register-company form .clear.submit-box:before,
#register-country form .clear.submit-box:before,
#register-press form .clear.submit-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fd9e47),
    to(#f843d0)
  );
  background: linear-gradient(90deg, #fd9e47 0%, #f843d0 100%);
  opacity: 0.44;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  border-radius: 10px;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#mc_embed_signup form .clear.submit-box:after,
#register-company form .clear.submit-box:after,
#register-country form .clear.submit-box:after,
#register-press form .clear.submit-box:after {
  content: "";
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 2px;
  border-radius: 10px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(255, 255, 255)),
    to(rgb(0, 0, 0))
  );
  background: linear-gradient(rgb(255, 255, 255), rgb(0, 0, 0));
  -webkit-mask: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(black),
        to(black)
      )
      content-box content-box,
    -webkit-gradient(linear, left top, left bottom, from(black), to(black));
  -webkit-mask: linear-gradient(black, black) content-box content-box,
    linear-gradient(black, black);
  -webkit-mask-composite: xor;
  opacity: 0.3;
  z-index: 1;
}
#mc_embed_signup form .clear.submit-box .button,
#register-company form .clear.submit-box .button,
#register-country form .clear.submit-box .button,
#register-press form .clear.submit-box .button {
  padding: 16px 23px 15px;
  display: block;
  margin: 0;
  height: auto;
  border-radius: 10px;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #040509;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.66);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
}
#mc_embed_signup form .clear.submit-box:hover,
#register-company form .clear.submit-box:hover,
#register-country form .clear.submit-box:hover,
#register-press form .clear.submit-box:hover {
  color: #040509;
}
#mc_embed_signup form .clear.submit-box:hover:before,
#register-company form .clear.submit-box:hover:before,
#register-country form .clear.submit-box:hover:before,
#register-press form .clear.submit-box:hover:before {
  opacity: 0.6;
  filter: blur(25px);
  -webkit-filter: blur(25px);
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
}
#mc_embed_signup form .clear.submit-box.disabled,
#register-company form .clear.submit-box.disabled,
#register-country form .clear.submit-box.disabled,
#register-press form .clear.submit-box.disabled {
  opacity: 0.3 !important;
  pointer-events: none;
}
#mc_embed_signup #mce-responses,
#register-company #mce-responses,
#register-country #mce-responses,
#register-press #mce-responses {
  margin: 0;
  padding: 0;
  position: relative;
  top: 70px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: initial;
}
#mc_embed_signup #mce-responses .response,
#register-company #mce-responses .response,
#register-country #mce-responses .response,
#register-press #mce-responses .response {
  padding: 17px 40px;
  margin: 0;
  width: auto;
  text-align: center;
  float: inherit;
  color: #ffffff;
  font-size: 12px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: -75px;
  top: initial;
}
#mc_embed_signup #mce-responses .response#mce-success-response,
#register-company #mce-responses .response#mce-success-response,
#register-country #mce-responses .response#mce-success-response,
#register-press #mce-responses .response#mce-success-response {
  color: #ffb629;
}
#mc_embed_signup #mce-responses .response#mce-error-response,
#register-company #mce-responses .response#mce-error-response,
#register-country #mce-responses .response#mce-error-response,
#register-press #mce-responses .response#mce-error-response {
  color: #e85c41;
}
body.press-article-form {
  background-color: transparent;
}
.modal {
  padding: 20px;
}
.modal .modal-dialog {
  max-width: 600px;
  height: 100%;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}
.modal .modal-content {
  border-radius: 12px;
  background-color: #100d23;
  overflow: hidden;
}
.modal .modal-content .modal-header {
  border: 0;
  padding: 0;
}
.modal .modal-content .modal-header button {
  margin: 0;
  padding: 0;
  opacity: 1;
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 9;
}
.modal .modal-content .modal-header button span {
  font-size: 40px;
  font-weight: 200;
  color: #ffffff;
}
.modal .modal-body {
  padding: 40px 50px;
}
.modal .modal-body #mc_embed_signup div#mce-responses {
  height: 20px;
  position: absolute;
  bottom: -33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: initial;
}
.modal .modal-body #mc_embed_signup div#mce-responses .response {
  display: block;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: transparent;
  padding: 0;
}
.modal .modal-body .container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 85%;
}
.modal .modal-body .container-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.modal .modal-body.with-iframe {
  padding-bottom: 0;
}
@media (max-width: 575px) {
  .modal .modal-body {
    padding: 20px;
  }
  .modal #mc_embed_signup form .divider,
  .modal #register-company form .divider,
  .modal #register-country form .divider,
  .modal #register-press form .divider {
    padding: 12px 0 4px;
    margin-bottom: 12px;
  }
  .modal #mc_embed_signup form .input-group,
  .modal #register-company form .input-group,
  .modal #register-country form .input-group,
  .modal #register-press form .input-group {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 0;
    margin: 0;
  }
  .modal #mc_embed_signup form .input-group .input-box label,
  .modal #mc_embed_signup form .input-group .mc-field-group label,
  .modal #register-company form .input-group .input-box label,
  .modal #register-company form .input-group .mc-field-group label,
  .modal #register-country form .input-group .input-box label,
  .modal #register-country form .input-group .mc-field-group label,
  .modal #register-press form .input-group .input-box label,
  .modal #register-press form .input-group .mc-field-group label {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .modal #mc_embed_signup form .input-group .input-box input,
  .modal #mc_embed_signup form .input-group .mc-field-group input,
  .modal #register-company form .input-group .input-box input,
  .modal #register-company form .input-group .mc-field-group input,
  .modal #register-country form .input-group .input-box input,
  .modal #register-country form .input-group .mc-field-group input,
  .modal #register-press form .input-group .input-box input,
  .modal #register-press form .input-group .mc-field-group input {
    min-height: 38px;
  }
  .modal #mc_embed_signup form .box-button,
  .modal #register-company form .box-button,
  .modal #register-country form .box-button,
  .modal #register-press form .box-button {
    margin-top: 10px;
  }
}
.modal-backdrop.show {
  opacity: 0.7;
}
body.threat {
  background-color: #0f0e20;
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
#hero-threat {
  padding: 170px 0 150px;
  overflow: hidden;
  position: relative;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#090910),
    to(transparent)
  );
  background-image: linear-gradient(0deg, #090910 0%, transparent 100%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.062745098);
}
#hero-threat #particles-js-threat-hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 800px;
  pointer-events: none;
  opacity: 0.3;
  z-index: 0;
}
#hero-threat .backlight-bottom {
  background: #3226a3;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  width: 70%;
  height: 30%;
  position: absolute;
  left: 50%;
  bottom: -25%;
  opacity: 0.2;
  -webkit-transform: translateX(-50%) translateZ(0);
  transform: translateX(-50%) translateZ(0);
  z-index: 0;
  border-radius: 50%;
}
#hero-threat .top-area {
  text-align: center;
  margin-bottom: 80px;
}
#hero-threat .top-area h1 {
  margin: 0 auto 25px;
}
#hero-threat .top-area h1 strong {
  font-weight: 600;
  color: #ffb629;
}
#hero-threat .top-area p {
  max-width: 600px;
  margin: 0 auto;
}
#hero-threat .globe-container {
  position: relative;
  max-width: 600px;
  margin: 0 auto 0 -10px;
}
#hero-threat .globe-container .orbits {
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}
#hero-threat .globe-container .orbits .circle {
  position: absolute;
  border-radius: 50%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(21, 132, 255, 0.02)),
    color-stop(90%, rgba(21, 132, 255, 0))
  );
  background: linear-gradient(
    180deg,
    rgba(21, 132, 255, 0.02) 0%,
    rgba(21, 132, 255, 0) 90%
  );
}
#hero-threat .globe-container .orbits .circle:before {
  content: "";
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(21, 132, 255, 0.08)),
    color-stop(90%, rgba(21, 132, 255, 0))
  );
  background: linear-gradient(
    180deg,
    rgba(21, 132, 255, 0.08) 0%,
    rgba(21, 132, 255, 0) 90%
  );
  -webkit-mask: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(black),
        to(black)
      )
      content-box content-box,
    -webkit-gradient(linear, left top, left bottom, from(black), to(black));
  -webkit-mask: linear-gradient(black, black) content-box content-box,
    linear-gradient(black, black);
  -webkit-mask-composite: xor;
}
#hero-threat .globe-container .orbits .circle.small {
  width: 125%;
  height: 125%;
}
#hero-threat .globe-container .orbits .circle.medium {
  width: 160%;
  height: 160%;
}
#hero-threat .globe-container .orbits .circle.big {
  width: 195%;
  height: 195%;
}
#hero-threat .globe-container .shadow-globe {
  width: 80%;
  height: 80%;
  position: absolute;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffb629),
    color-stop(99.97%, #f94bc4)
  );
  background: linear-gradient(180deg, #ffb629 0%, #f94bc4 99.97%);
  opacity: 0.4;
  -webkit-filter: blur(80px);
  filter: blur(80px);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) translateZ(0) rotate(-45deg);
  transform: translate(-50%, -50%) translateZ(0) rotate(-45deg);
}
#hero-threat .fs-3dglobe-container {
  width: 600px;
  height: 600px;
  margin: 0 auto;
  background-color: transparent;
  position: relative;
  z-index: 2;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
#hero-threat .fs-3dglobe-container canvas {
  cursor: pointer;
}
#hero-threat .container-tooltips .tooltip-item {
  display: none;
}
#hero-threat .container-tooltips .tooltip-item .hidden-content {
  display: none;
}
#hero-threat .map-container .fs-3dglobe-arrow-box {
  margin: 0;
}
#hero-threat .map-container .fs-3dglobe-info-box {
  left: initial;
  top: 50%;
  left: 85%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  min-width: 135px;
  z-index: 99;
}
#hero-threat .map-container .fs-globe-tooltip {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.66);
  -webkit-backdrop-filter: blur(1.5px);
  backdrop-filter: blur(1.5px);
  padding: 6px 10px;
  z-index: 99;
}
#hero-threat .map-container .fs-globe-tooltip .content-tooltip {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 5px 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#hero-threat .map-container .fs-globe-tooltip .content-tooltip .country-name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #ffffff;
}
#hero-threat .map-container .fs-globe-tooltip .content-tooltip .status {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}
#hero-threat
  .map-container
  .fs-globe-tooltip
  .content-tooltip
  .status
  .triangle {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #fb0f42;
  -webkit-filter: drop-shadow(0px 0px 5px #fb0f42);
  filter: drop-shadow(0px 0px 5px #fb0f42);
}
#hero-threat
  .map-container
  .fs-globe-tooltip
  .content-tooltip
  .status
  .triangle.investigation {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #ffb629;
}
#hero-threat .map-container .fs-globe-tooltip .content-tooltip .status span {
  font-size: 10px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
#hero-threat
  .map-container
  .fs-globe-tooltip
  .content-tooltip
  .status
  span.banned {
  color: #fb0f42;
}
#hero-threat
  .map-container
  .fs-globe-tooltip
  .content-tooltip
  .status
  span.investigation {
  color: #ffb629;
}
#hero-threat
  .map-container
  .fs-globe-tooltip
  .content-tooltip
  .status
  span.considering {
  color: #ffb629;
}
#hero-threat .map-container .fs-globe-pin .pin-box {
  width: 80px;
  height: 80px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
#hero-threat .map-container .fs-globe-pin .pin-box .circle {
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  background: #fb0f42;
  border: 1px solid #fb0f1c;
}
#hero-threat .map-container .fs-globe-pin .pin-box .circle.core {
  position: relative;
  z-index: 1;
}
#hero-threat .map-container .fs-globe-pin .pin-box .circle.small {
  width: 30px;
  height: 30px;
  background: rgba(251, 15, 66, 0.2);
  border-color: rgba(251, 15, 28, 0.2);
}
#hero-threat .map-container .fs-globe-pin .pin-box .circle.big {
  width: 45px;
  height: 45px;
  background: rgba(251, 15, 66, 0.1);
  border-color: rgba(251, 15, 28, 0.15);
}
#hero-threat .map-container .fs-globe-pin .pin-box.green .circle {
  border-color: #ffb629;
  background: #ffb629;
}
#hero-threat .map-container .fs-globe-pin .pin-box.green .circle.small {
  background: rgba(255, 182, 41, 0.2);
  border-color: rgba(255, 182, 41, 0.2);
}
#hero-threat .map-container .fs-globe-pin .pin-box.green .circle.big {
  background: rgba(255, 182, 41, 0.1);
  border-color: rgba(255, 182, 41, 0.15);
}
#hero-threat .table-countries {
  position: relative;
  z-index: 9;
}
#hero-threat .table-countries ::-webkit-scrollbar {
  width: 6px;
  height: 0;
}
#hero-threat .table-countries ::-webkit-scrollbar-track {
  background-color: #141420;
  border-radius: 50px;
}
#hero-threat .table-countries ::-webkit-scrollbar-thumb {
  background-color: #292934;
  border-radius: 50px;
}
#hero-threat .table-countries table {
  display: block;
  height: 565px;
  overflow: scroll;
  padding-right: 10px;
}
#hero-threat .table-countries table thead {
  position: sticky;
  top: 0;
  background-color: #0a0a0e;
  z-index: 1;
}
#hero-threat .table-countries table thead:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #ffb629;
  position: absolute;
  bottom: 0;
}
#hero-threat .table-countries table thead th {
  padding: 12px 20px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
}
#hero-threat .table-countries table thead th:first-child {
  width: 40%;
}
#hero-threat .table-countries table thead th:nth-child(2) {
  width: 30%;
}
#hero-threat .table-countries table thead th:nth-child(3) {
  width: 25%;
}
#hero-threat .table-countries table tbody tr td {
  padding: 12px 20px;
}
#hero-threat .table-countries table tbody tr td:first-child,
#hero-threat .table-countries table tbody tr td:nth-child(2) {
  padding-right: 0;
}
#hero-threat .table-countries table tbody tr td .country {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}
#hero-threat .table-countries table tbody tr td .country .country-name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #b7b9da;
}
#hero-threat .table-countries table tbody tr td .status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}
#hero-threat .table-countries table tbody tr td .status .triangle {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #fb0f42;
  -webkit-filter: drop-shadow(0px 0px 5px #fb0f42);
  filter: drop-shadow(0px 0px 5px #fb0f42);
}
#hero-threat
  .table-countries
  table
  tbody
  tr
  td
  .status
  .triangle.investigation {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #ffb629;
}
#hero-threat .table-countries table tbody tr td .status span {
  font-size: 10px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
#hero-threat .table-countries table tbody tr td .status span.banned {
  color: #fb0f42;
}
#hero-threat .table-countries table tbody tr td .status span.investigation {
  color: #ffb629;
}
#hero-threat .table-countries table tbody tr td .status span.considering {
  color: #ffb629;
}
#hero-threat .table-countries table tbody tr td .source {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#hero-threat .table-countries table tbody tr td .source a {
  padding: 6px 12px 6px 10px;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
}
#hero-threat .table-countries table tbody tr td .source a:hover {
  background-color: #ffffff;
  color: rgb(56, 49, 135);
}
#hero-threat .table-countries table tbody tr:nth-child(odd) {
  background: rgba(56, 49, 135, 0.2);
}
#hero-threat .table-countries table tbody tr:nth-child(2n) {
  background: rgba(56, 49, 135, 0.45);
}
#hero-threat .stats-block {
  padding: 200px 0 0;
  position: relative;
}
#hero-threat .stats-block .backlight {
  background: #0c15ff;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  width: 80%;
  height: 60%;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0.5;
  -webkit-transform: translate(-50%, -50%) translateZ(0);
  transform: translate(-50%, -50%) translateZ(0);
  z-index: 0;
}
#hero-threat .stats-block .stats-counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 35px;
  position: relative;
}
#hero-threat .stats-block .stats-counter .counter-box {
  max-width: 190px;
  width: 31%;
  position: relative;
  z-index: 1;
}
#hero-threat .stats-block .stats-counter .counter-box .status {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 8px;
}
#hero-threat .stats-block .stats-counter .counter-box .status .triangle {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #fb0f42;
  -webkit-filter: drop-shadow(0px 0px 5px #fb0f42);
  filter: drop-shadow(0px 0px 5px #fb0f42);
}
#hero-threat
  .stats-block
  .stats-counter
  .counter-box
  .status
  .triangle.restricted {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #ffb629;
  -webkit-filter: drop-shadow(0px 0px 5px #ffb629);
  filter: drop-shadow(0px 0px 5px #ffb629);
}
#hero-threat .stats-block .stats-counter .counter-box .status .triangle.open {
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 6px solid #1584ff;
  -webkit-filter: drop-shadow(0px 0px 5px #1584ff);
  filter: drop-shadow(0px 0px 5px #1584ff);
  border-top: initial;
}
#hero-threat .stats-block .stats-counter .counter-box .status span {
  font-size: 10px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
#hero-threat .stats-block .stats-counter .counter-box .status span.banned {
  color: #fb0f42;
}
#hero-threat .stats-block .stats-counter .counter-box .status span.restricted {
  color: #ffb629;
}
#hero-threat .stats-block .stats-counter .counter-box .status span.open {
  color: #1584ff;
}
#hero-threat .stats-block .stats-counter .counter-box .counter-numbers {
  margin-top: 20px;
  background: rgba(33, 32, 96, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 20px 0;
  text-align: center;
}
#hero-threat .stats-block .stats-counter .counter-box .counter-numbers span {
  font-size: 12px;
  line-height: 10px;
  font-weight: 600;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #78779c;
}
#hero-threat
  .stats-block
  .stats-counter
  .counter-box
  .counter-numbers
  .top-numbers {
  padding: 0 20px 15px;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.33);
}
#hero-threat
  .stats-block
  .stats-counter
  .counter-box
  .counter-numbers
  .top-numbers
  .number {
  margin: 0;
  font-size: 50px;
  line-height: 54px;
  text-transform: uppercase;
  font-weight: 600;
}
#hero-threat
  .stats-block
  .stats-counter
  .counter-box
  .counter-numbers
  .bottom-numbers {
  padding: 15px 20px 0;
}
#hero-threat
  .stats-block
  .stats-counter
  .counter-box
  .counter-numbers
  .bottom-numbers
  .number {
  margin: 0;
  font-size: 40px;
  line-height: 44px;
  text-transform: uppercase;
  font-weight: 600;
}
#hero-threat
  .stats-block
  .stats-counter
  .counter-box
  .counter-numbers
  .bottom-numbers
  .number
  span {
  margin-left: 6px;
  font-size: 33px;
  line-height: 45px;
}
#hero-threat
  .stats-block
  .stats-counter
  .counter-box.open
  .counter-numbers
  .top-numbers
  .number {
  color: #1584ff;
  text-shadow: 0px 0px 10px rgba(21, 132, 255, 0.5);
}
#hero-threat
  .stats-block
  .stats-counter
  .counter-box.banned
  .counter-numbers
  .top-numbers
  .number {
  color: #fb0f42;
  text-shadow: 0px 0px 10px rgba(255, 46, 91, 0.66);
}
#hero-threat
  .stats-block
  .stats-counter
  .counter-box.restricted
  .counter-numbers
  .top-numbers
  .number {
  color: #ffb629;
  text-shadow: 0px 0px 10px rgba(255, 182, 41, 0.66);
}
#hero-threat .stats-block .stats-circle {
  text-align: center;
  position: relative;
}
#hero-threat .stats-block .stats-circle .percentage-number {
  position: absolute;
  text-align: left;
}
#hero-threat .stats-block .stats-circle .percentage-number p {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 4px;
}
#hero-threat .stats-block .stats-circle .percentage-number p span {
  font-size: 16px;
  line-height: 19px;
}
#hero-threat .stats-block .stats-circle .percentage-number .status {
  display: block;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  color: #78779c;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}
#hero-threat .stats-block .stats-circle .percentage-number:after {
  content: "";
  width: 1px;
  height: 60px;
  position: absolute;
  background-color: red;
  right: 0;
}
#hero-threat .stats-block .stats-circle .percentage-number.open {
  top: -40%;
  left: 43%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
#hero-threat .stats-block .stats-circle .percentage-number.open p {
  color: #1584ff;
}
#hero-threat .stats-block .stats-circle .percentage-number.open:after {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1584ff),
    to(rgba(21, 132, 255, 0))
  );
  background: linear-gradient(180deg, #1584ff 0%, rgba(21, 132, 255, 0) 100%);
}
#hero-threat .stats-block .stats-circle .percentage-number.banned {
  right: 13%;
  top: -33%;
}
#hero-threat .stats-block .stats-circle .percentage-number.banned p {
  color: #fb0f42;
}
#hero-threat .stats-block .stats-circle .percentage-number.banned:after {
  left: -35%;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fb0f42),
    to(rgba(251, 15, 66, 0))
  );
  background: linear-gradient(180deg, #fb0f42 0%, rgba(251, 15, 66, 0) 100%);
}
#hero-threat .stats-block .stats-circle .percentage-number.restricted {
  right: -14%;
  top: -2%;
}
#hero-threat .stats-block .stats-circle .percentage-number.restricted p {
  color: #ffb629;
}
#hero-threat .stats-block .stats-circle .percentage-number.restricted:after {
  height: 45px;
  left: -35%;
  top: 68%;
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffb629),
    to(rgba(255, 182, 41, 0))
  );
  background: linear-gradient(180deg, #ffb629 0%, rgba(255, 182, 41, 0) 100%);
}
#hero-threat .stats-block .stats-circle .core-stat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
#hero-threat .stats-block .stats-circle .core-stat .stats-graphic {
  max-width: 280px;
}
#hero-threat .stats-block .stats-circle .core-stat .globe-gif {
  border-radius: 50%;
  position: absolute;
  max-width: 92px;
}
@media (max-width: 1200px) {
  #hero-threat .fs-3dglobe-container {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  #hero-threat .globe-container .orbits {
    width: 580px;
  }
  #hero-threat .globe-container .orbits .circle.small {
    width: 105%;
    height: 105%;
  }
  #hero-threat .globe-container .orbits .circle.medium {
    width: 140%;
    height: 140%;
  }
  #hero-threat .globe-container .orbits .circle.big {
    width: 175%;
    height: 175%;
  }
  #hero-threat .globe-container .shadow-globe {
    width: 60%;
    height: 60%;
  }
  #hero-threat .stats-block .stats-circle .percentage-number.banned:after {
    height: 45px;
    left: -18%;
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  #hero-threat .stats-block .stats-circle .percentage-number.restricted {
    right: -27%;
  }
}
@media (max-width: 991px) {
  #hero-threat .overlay-scroll {
    position: absolute;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 8;
  }
  #hero-threat .overlay-scroll.left {
    left: 0;
  }
  #hero-threat .overlay-scroll.right {
    right: 0;
  }
  #hero-threat .globe-container {
    margin: 0 auto;
  }
  #hero-threat .globe-container .overlay-scroll.bottom {
    top: initial;
    bottom: 0;
    height: 20%;
    width: 100%;
  }
  #hero-threat:after {
    display: none;
  }
  #hero-threat .table-countries {
    max-width: 530px;
    margin: 0 auto;
  }
  #hero-threat .stats-block {
    padding: 120px 0 0;
  }
  #hero-threat .stats-block .stats-counter {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #hero-threat .stats-block .stats-circle {
    max-width: 370px;
    margin: 150px auto 0;
  }
}
@media (max-width: 767px) {
  #hero-threat .fs-3dglobe-container {
    width: 100%;
    height: 100%;
    min-height: 550px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) translateZ(0);
    transform: translate(-50%, -50%) translateZ(0);
  }
  #hero-threat .globe-container {
    min-height: 480px;
    margin: 0 auto 0 auto;
    margin-bottom: 60px;
  }
  #hero-threat .globe-container .orbits {
    width: 510px;
    height: 510px;
  }
  #hero-threat .map-container .fs-globe-pin .pin-box .circle {
    width: 10px;
    height: 10px;
  }
  #hero-threat .map-container .fs-globe-pin .pin-box .circle.small {
    width: 20px;
    height: 20px;
  }
  #hero-threat .map-container .fs-globe-pin .pin-box .circle.big {
    width: 35px;
    height: 35px;
  }
  #hero-threat .stats-block .stats-counter {
    gap: 10px;
  }
  #hero-threat .stats-block .stats-counter .counter-box {
    max-width: 100%;
    width: 33%;
  }
  #hero-threat
    .stats-block
    .stats-counter
    .counter-box
    .counter-numbers
    .top-numbers
    .number {
    font-size: 40px;
    line-height: 44px;
  }
  #hero-threat
    .stats-block
    .stats-counter
    .counter-box
    .counter-numbers
    .bottom-numbers
    .number {
    font-size: 30px;
    line-height: 34px;
  }
  #hero-threat
    .stats-block
    .stats-counter
    .counter-box
    .counter-numbers
    .bottom-numbers
    .number
    span {
    font-size: 23px;
    line-height: 25px;
  }
  #hero-threat .stats-block .stats-circle {
    max-width: 370px;
    margin: 150px auto 0;
  }
}
@media (max-width: 575px) {
  #hero-threat {
    padding: 170px 0 100px;
  }
  #hero-threat .top-area {
    margin-bottom: 0;
  }
  #hero-threat .fs-3dglobe-container {
    min-height: auto;
  }
  #hero-threat .map-container .fs-3dglobe-info-box {
    left: 80%;
    min-width: 60px;
  }
  #hero-threat .map-container .fs-globe-tooltip {
    padding: 5px 5px;
  }
  #hero-threat .map-container .fs-globe-tooltip .content-tooltip {
    gap: 8px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  #hero-threat .map-container .fs-globe-tooltip .content-tooltip .country-name {
    display: none;
  }
  #hero-threat
    .map-container
    .fs-globe-tooltip
    .content-tooltip
    .status
    .triangle {
    display: none;
  }
  #hero-threat .map-container .fs-globe-tooltip .content-tooltip .status span {
    font-size: 9px;
  }
  #hero-threat .globe-container .orbits {
    width: 400px;
    height: 400px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) translateZ(0);
    transform: translate(-50%, -50%) translateZ(0);
  }
  #hero-threat .globe-container .orbits .circle.small {
    width: 125%;
    height: 125%;
  }
  #hero-threat .globe-container .orbits .circle.medium {
    width: 160%;
    height: 160%;
  }
  #hero-threat .globe-container .orbits .circle.big {
    width: 195%;
    height: 195%;
  }
  #hero-threat .stats-block .stats-circle {
    margin: 70px auto 0;
  }
  #hero-threat .stats-block .stats-circle .percentage-number {
    position: static;
    display: inline-block;
    margin: 0 13px 40px;
  }
  #hero-threat .stats-block .stats-circle .percentage-number:after {
    display: none;
  }
  #hero-threat .stats-block .stats-circle .percentage-number.open {
    -webkit-transform: none;
    transform: none;
  }
}
@media (max-width: 425px) {
  #hero-threat .globe-container {
    min-height: 350px;
    margin-bottom: 20px;
  }
  #hero-threat .globe-container .orbits {
    width: 300px;
    height: 300px;
  }
  #hero-threat .table-countries table {
    padding-right: 2px;
  }
  #hero-threat .table-countries table thead th {
    padding: 12px 6px;
    font-size: 9px;
    line-height: 11px;
  }
  #hero-threat .table-countries table thead th:first-child {
    width: 30%;
  }
  #hero-threat .table-countries table thead th:nth-child(2) {
    width: 25%;
    text-align: center;
    padding-right: 0;
  }
  #hero-threat .table-countries table thead th:nth-child(3) {
    width: 45%;
    text-align: center;
  }
  #hero-threat .table-countries table tbody tr td {
    padding: 8px 6px;
  }
  #hero-threat .table-countries table tbody tr td .country img {
    display: none;
  }
  #hero-threat .table-countries table tbody tr td .status {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #hero-threat .table-countries table tbody tr td .status .triangle {
    display: none;
  }
  #hero-threat .table-countries table tbody tr td .status span {
    font-size: 9px;
    letter-spacing: 0.1em;
  }
  #hero-threat .table-countries table tbody tr td .source {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #hero-threat .table-countries table tbody tr td .source a {
    padding: 4px 6px 4px 6px;
    font-size: 8px;
    line-height: 8px;
    letter-spacing: 0.15em;
  }
  #hero-threat .table-countries table tbody tr td:nth-child(3) {
    padding-right: 0;
    padding-left: 0;
  }
  #hero-threat .stats-block .stats-counter {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 40px 10px;
  }
  #hero-threat .stats-block .stats-counter .counter-box {
    width: 45%;
  }
}
#service-regulation {
  padding: 100px 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(8, 8, 15, 0)),
    to(#100d23)
  );
  background: linear-gradient(0deg, rgba(8, 8, 15, 0) 0%, #100d23 100%);
}
#service-regulation .top-area {
  margin-bottom: 80px;
}
#service-regulation .top-companies-grid {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #0e1f40;
  padding-bottom: 15px;
  margin-bottom: 40px;
}
#service-regulation .top-companies-grid .category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}
#service-regulation .top-companies-grid .category img {
  width: 20px;
}
#service-regulation .top-companies-grid .category span {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #ffffff;
}
#service-regulation .top-companies-grid .stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 40px;
}
#service-regulation .top-companies-grid .stats a {
  cursor: default;
  font-size: 12px;
  line-height: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
#service-regulation .top-companies-grid .stats a span {
  font-size: 15px;
}
#service-regulation .top-companies-grid .stats a.banned {
  color: #fb0f42;
}
#service-regulation .top-companies-grid .stats a.restricted {
  color: #ffb629;
}
#service-regulation .top-companies-grid .stats a.testing {
  color: #ffffff;
}
#service-regulation .top-companies-grid .stats a.open {
  color: #0ffbb4;
}
#service-regulation .grid-companies {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 23px 1.8%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 70px;
}
#service-regulation .grid-companies .company-box {
  min-width: 32%;
  max-width: 32%;
  max-height: 100px;
  padding: 25px;
  background-image: linear-gradient(
    57.08deg,
    rgba(251, 15, 66, 0.33) -147.67%,
    rgba(251, 15, 66, 0) 59.75%
  );
  background-size: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#service-regulation .grid-companies .company-box:hover {
  background-size: 200%;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
#service-regulation .grid-companies .company-box.banned {
  background-image: linear-gradient(
    57.08deg,
    rgba(251, 15, 66, 0.33) -147.67%,
    rgba(251, 15, 66, 0) 59.75%
  );
}
#service-regulation .grid-companies .company-box.restricted {
  background-image: linear-gradient(
    57.08deg,
    rgba(255, 182, 41, 0.33) -147.67%,
    rgba(255, 182, 41, 0) 59.75%
  );
}
#service-regulation .grid-companies .company-box.testing {
  background-image: linear-gradient(
    57.08deg,
    rgba(255, 255, 255, 0.33) -147.67%,
    rgba(255, 255, 255, 0) 59.75%
  );
}
#service-regulation .grid-companies .company-box.open {
  background-image: linear-gradient(
    57.08deg,
    rgba(21, 132, 255, 0.33) -147.67%,
    rgba(21, 132, 255, 0) 59.75%
  );
}
#service-regulation .grid-companies .company-box.link-block {
  background: rgba(120, 119, 156, 0.05);
  border: 1px dashed rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.33);
  box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.33);
  text-align: left;
}
#service-regulation .grid-companies .company-box.link-block:focus {
  outline: 0;
}
#service-regulation .grid-companies .company-box.link-block span {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 13px;
  color: #78779c;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#service-regulation .grid-companies .company-box.link-block:hover {
  background: rgba(120, 119, 156, 0.1);
  border: 1px dashed rgba(255, 255, 255, 0.55);
}
#service-regulation .grid-companies .company-box.link-block:hover span {
  color: #ffffff;
}
#service-regulation .grid-companies .company-box .name {
  font-size: 18px;
  line-height: 13px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 17px;
}
#service-regulation .grid-companies .company-box .name.small {
  font-size: 16px;
}
#service-regulation .grid-companies .company-box .status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}
#service-regulation .grid-companies .company-box .status .triangle {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #fb0f42;
  -webkit-filter: drop-shadow(0px 0px 5px #fb0f42);
  filter: drop-shadow(0px 0px 5px #fb0f42);
}
#service-regulation .grid-companies .company-box .status .triangle.restricted {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #ffb629;
  -webkit-filter: drop-shadow(0px 0px 5px #ffb629);
  filter: drop-shadow(0px 0px 5px #ffb629);
}
#service-regulation .grid-companies .company-box .status .triangle.testing {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #ffffff;
  -webkit-filter: drop-shadow(0px 0px 5px #ffffff);
  filter: drop-shadow(0px 0px 5px #ffffff);
}
#service-regulation .grid-companies .company-box .status .triangle.open {
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 6px solid #1584ff;
  -webkit-filter: drop-shadow(0px 0px 5px #1584ff);
  filter: drop-shadow(0px 0px 5px #1584ff);
  border-top: initial;
}
#service-regulation .grid-companies .company-box .status span {
  font-size: 10px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
#service-regulation .grid-companies .company-box .status span.banned {
  color: #fb0f42;
}
#service-regulation .grid-companies .company-box .status span.restricted {
  color: #ffb629;
}
#service-regulation .grid-companies .company-box .status span.considering {
  color: #ffb629;
}
#service-regulation .grid-companies .company-box .status span.testing {
  color: #ffffff;
}
#service-regulation .grid-companies .company-box .status span.open {
  color: #1584ff;
}
#service-regulation .press-sc {
  border-radius: 20px;
  overflow: hidden;
}
#service-regulation .press-sc ::-webkit-scrollbar {
  width: 6px;
  height: 0;
  margin-right: -10px;
}
#service-regulation .press-sc ::-webkit-scrollbar-track {
  background-color: none;
  border-radius: 50px;
}
#service-regulation .press-sc ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.062745098);
  border-radius: 50px;
}
#service-regulation .press-container {
  background: rgba(33, 32, 96, 0.33);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 40px;
  max-width: 400px;
  max-height: 2366px;
  overflow: scroll;
  margin-left: auto;
}
#service-regulation .press-container h3 {
  color: #ffb629;
  text-align: left;
}
#service-regulation .press-container .press-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 720px;
  padding: 30px 0 0;
  margin: 30px auto 0;
  border-top: 1px solid #10244a;
}
#service-regulation .press-container .press-box:first-child {
  margin-top: 0;
  border-top: 0;
}
#service-regulation .press-container .press-box:last-child {
  border-bottom: 1px solid #10244a;
  padding-bottom: 30px;
}
#service-regulation .press-container .press-box .content-press h5 {
  font-size: 19px;
  letter-spacing: 0;
  margin: 15px 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#service-regulation .press-container .press-box .content-press .date,
#service-regulation .press-container .press-box .content-press .source {
  display: block;
  font-size: 11px;
  line-height: 13px;
  font-weight: 800;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #b7b9da;
}
#service-regulation .press-container .press-box .content-press .source {
  color: #78779c;
}
#service-regulation .press-container .press-box:hover .content-press h5 {
  color: #ffb629;
}
@media (max-width: 1200px) {
  #service-regulation .grid-companies .company-box {
    padding: 17px;
    min-height: 100px;
  }
  #service-regulation .grid-companies .company-box .name {
    line-height: 18px;
    min-height: 35px;
  }
}
@media (max-width: 991px) {
  #service-regulation .top-area {
    text-align: center;
  }
  #service-regulation .grid-companies .company-box {
    min-width: 23.5%;
    max-width: 23.5%;
    max-height: 110px;
    min-height: 110px;
  }
  #service-regulation .grid-companies .company-box .name {
    line-height: 21px;
    min-height: 42px;
  }
  #service-regulation .press-container {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #service-regulation .top-companies-grid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
  }
  #service-regulation .top-companies-grid .stats a {
    text-align: center;
  }
  #service-regulation .grid-companies .company-box {
    min-width: 32%;
    max-width: 32%;
  }
  #service-regulation .grid-companies .company-box .name {
    line-height: 21px;
    min-height: 42px;
  }
  #service-regulation .grid-companies .company-box.link-block span {
    font-size: 15px;
    line-height: 16px;
  }
}
@media (max-width: 425px) {
  #service-regulation {
    padding: 100px 0 0;
  }
  #service-regulation .top-companies-grid .stats {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 10px;
  }
  #service-regulation .grid-companies {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  #service-regulation .grid-companies .company-box {
    min-width: 47%;
    max-width: 47%;
    min-height: auto;
  }
  #service-regulation .grid-companies .company-box .name {
    font-size: 16px;
    line-height: 16px;
    min-height: 28px;
  }
  #service-regulation .press-container {
    padding: 30px;
  }
}
.privacy-status {
  margin: 150px 0 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 8;
}
.privacy-status .nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0;
  width: 13%;
}
.privacy-status .nav-tabs .nav-link {
  width: 100%;
  height: 94px;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0)),
      to(rgba(0, 0, 0, 0.14))
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))),
    rgba(32, 28, 84, 0.81);
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.14) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    rgba(32, 28, 84, 0.81);
  opacity: 0.4;
  border-radius: 22px 0px 0px 22px;
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.privacy-status .nav-tabs .nav-link.active {
  opacity: 1;
  background: rgba(33, 32, 96, 0.5);
}
.privacy-status .nav-tabs .nav-link:hover {
  opacity: 1;
  background: rgba(33, 32, 96, 0.5);
}
.privacy-status .nav-tabs .nav-link img {
  pointer-events: none;
}
.privacy-status .tab-content {
  width: 87%;
}
.privacy-status .toggle-llm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  position: absolute;
  right: 3%;
  top: 8%;
  z-index: 9;
}
.privacy-status .toggle-llm img {
  width: 147px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0.3;
}
.privacy-status .toggle-llm img.active {
  opacity: 1;
}
.privacy-status .toggle-llm .holder {
  position: relative;
  height: 30px;
  width: 55px;
  border-radius: 50px;
  background: #4e4bae;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.privacy-status .toggle-llm .holder .toggle {
  position: absolute;
  top: 5px;
  left: 6px;
  width: 20px;
  height: 20px;
  border-radius: 60px;
  background: #151437;
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
}
@-webkit-keyframes toggleOn {
  0% {
    left: 6px;
    border-radius: 60px;
  }
  to {
    left: 30px;
    border-radius: 60px;
  }
}
@keyframes toggleOn {
  0% {
    left: 6px;
    border-radius: 60px;
  }
  to {
    left: 30px;
    border-radius: 60px;
  }
}
@-webkit-keyframes toggleOff {
  0% {
    left: 30px;
    border-radius: 60px;
  }
  to {
    left: 6px;
    border-radius: 60px;
  }
}
@keyframes toggleOff {
  0% {
    left: 30px;
    border-radius: 60px;
  }
  to {
    left: 6px;
    border-radius: 60px;
  }
}
@keyframes toggleOn {
  0% {
    left: 6px;
    border-radius: 60px;
  }
  to {
    left: 30px;
    border-radius: 60px;
  }
}
@keyframes toggleOff {
  0% {
    left: 30px;
    border-radius: 60px;
  }
  to {
    left: 6px;
    border-radius: 60px;
  }
}
.privacy-status .toggle-llm .holder.off {
  background: #4e4bae;
}
.privacy-status .toggle-llm .holder.on {
  background: #ffb629;
  -webkit-box-shadow: 0 0 20px rgba(255, 182, 41, 0.65);
  box-shadow: 0 0 20px rgba(255, 182, 41, 0.65);
}
.privacy-status .toggle-llm .holder.on .toggle {
  -webkit-animation: toggleOn 0.3s linear;
  animation: toggleOn 0.3s linear;
  left: 30px;
  background: #151437;
}
.privacy-status .toggle-llm .holder.off .toggle {
  -webkit-animation: toggleOff 0.3s linear;
  animation: toggleOff 0.3s linear;
  left: 6px;
}
@keyframes toggleOn {
  0% {
    left: 6px;
  }
  to {
    left: 30px;
  }
}
@keyframes toggleOff {
  0% {
    left: 30px;
  }
  to {
    left: 6px;
  }
}
.privacy-status .privacy-block .core-content {
  padding: 40px 30px 40px 60px;
  background: rgba(33, 32, 96, 0.5);
  border-radius: 22px;
  min-height: 576px;
  position: relative;
}
.privacy-status .privacy-block .core-content .top-tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.privacy-status .privacy-block .core-content h3 strong {
  font-weight: 600;
  color: #ffb629;
}
.privacy-status .privacy-block .core-content hr {
  margin: 30px 0;
  border-color: #ffffff;
  opacity: 0.1;
}
.privacy-status .privacy-block .core-content ul {
  padding: 0 0 0 13px;
  margin: 0 0 30px;
}
.privacy-status .privacy-block .core-content ul li {
  padding: 0;
  margin-bottom: 8px;
  color: #b7b9da;
}
.privacy-status .privacy-block .core-content ul li strong {
  color: #ffffff;
  font-weight: 600;
}
.privacy-status .privacy-block .core-content ul li span {
  max-width: 300px;
  font-size: 13px;
  display: block;
}
.privacy-status .privacy-block .core-content ul li span strong {
  color: #ffffff;
}
.privacy-status .privacy-block .core-content ul li::marker {
  font-size: 11px;
  color: #ffb629;
}
.privacy-status .privacy-block .core-content ul li.grey strong {
  color: #b7b9da;
}
.privacy-status .privacy-block .core-content ul li.grey strong span {
  color: #ffffff;
  font-size: initial;
}
.privacy-status .privacy-block .core-content ul.red li::marker {
  color: #fb0f42;
}
.privacy-status .privacy-block .core-content ul.blue li::marker {
  color: #1584ff;
}
.privacy-status .privacy-block .core-content .disclaimer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(251, 15, 66, 0.12);
  border: 1px solid rgba(251, 15, 66, 0.2);
  border-radius: 9px;
  padding: 7px 8px;
}
.privacy-status .privacy-block .core-content .disclaimer span {
  color: #b7b9da;
  font-size: 11px;
  line-height: 17px;
  font-weight: 600;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.privacy-status .privacy-block .core-content .disclaimer span strong {
  color: #fb0f42;
}
.privacy-status .privacy-block .core-content .disclaimer img {
  margin-right: 10px;
}
.privacy-status .privacy-block .core-content .main-list {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.privacy-status .privacy-block .core-content .main-list.disabled {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  opacity: 0.1;
}
.privacy-status .privacy-block .core-content .data-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
}
.privacy-status .privacy-block .core-content .data-block.active {
  opacity: 1;
}
@media (max-width: 991px) {
  .privacy-status .toggle-llm {
    right: 0;
    top: -10%;
  }
  .privacy-status .nav-tabs {
    width: 20%;
  }
  .privacy-status .tab-content {
    width: 80%;
  }
  .privacy-status .privacy-block .left-content .left-boxs {
    width: 100px;
  }
  .privacy-status .privacy-block .core-content {
    padding: 30px;
  }
  .privacy-status .privacy-block .core-content .data-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px;
  }
  .privacy-status .privacy-block .core-content .data-block h4 {
    max-width: 400px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .privacy-status {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .privacy-status .toggle-llm {
    right: initial;
    top: -8%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .privacy-status .nav-tabs {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 8px;
  }
  .privacy-status .nav-tabs li {
    width: 20%;
  }
  .privacy-status .nav-tabs li .nav-link {
    margin: 0;
    border-radius: 22px 22px 0px 0px;
  }
  .privacy-status .nav-tabs li .nav-link img {
    max-width: 80px;
    max-height: 40px;
  }
  .privacy-status .tab-content {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .privacy-status .nav-tabs li {
    width: 22%;
  }
  .privacy-status .nav-tabs li .nav-link {
    border-radius: 10px 10px 0px 0px;
  }
  .privacy-status .nav-tabs li .nav-link img {
    max-width: 60px;
    max-height: 30px;
  }
  .privacy-status .privacy-block .core-content {
    border-radius: 10px;
    min-height: auto;
  }
}
#hero-release-notes {
  padding: 150px 0 70px;
  border-bottom: 1px solid rgba(12, 138, 255, 0.14);
  background-color: #0e0c1f;
  overflow: hidden;
}
#hero-release-notes .hero-content-release-notes {
  position: relative;
  text-align: center;
}
#hero-release-notes .hero-content-release-notes h2 {
  color: #ffb629;
  position: relative;
  z-index: 1;
}
#hero-release-notes .hero-content-release-notes p {
  margin-top: 20px;
  position: relative;
  z-index: 1;
}
#hero-release-notes .hero-content-release-notes .blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -35%) translateZ(0px);
  transform: translate(-50%, -35%) translateZ(0px);
  background: #0c15ff;
  opacity: 0.25;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  pointer-events: none;
  z-index: 0;
}
#hero-release-notes
  .hero-content-release-notes
  #particles-js-release-notes-hero {
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 400px;
  height: 200px;
  z-index: 1;
  pointer-events: none;
}
#notes {
  padding: 70px 0 90px;
  background: #0c091d;
}
#notes .note-box {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 40px;
  position: relative;
  background: #12102b;
  -webkit-box-shadow: 0px 10.9429px 18.2381px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 10.9429px 18.2381px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
  border-radius: 20px;
  --squircle-radius: 20px;
  --squircle-smooth: 1;
  --intro-outline-width: 2;
  -webkit-mask-image: paint(squircle);
  mask-image: paint(squircle);
}
#notes .note-box.safari {
  border: 1px solid rgba(255, 255, 255, 0.15);
}
#notes .note-box:last-child {
  margin-bottom: 0;
}
#notes .note-box:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  --squircle-radius: 20px;
  --squircle-smooth: 1;
  background: paint(squircle);
  --squircle-color: rgba(255, 255, 255, 0.15);
  --squircle-outline: 1px;
}
#notes .note-box .top-note {
  padding-bottom: 15px;
  border-bottom: 1px solid #0e1f40;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
#notes .note-box .top-note .title-note {
  max-width: 70%;
}
#notes .note-box .top-note .data-note .tag-note {
  background: rgba(222, 42, 255, 0.25);
  border-radius: 55px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #e557ff;
  padding: 4px 7px 4px 9px;
  margin-right: 10px;
}
#notes .note-box .top-note .data-note .tag-note.major {
  color: #ff640f;
  background: rgba(255, 100, 15, 0.25);
}
#notes .note-box .top-note .data-note .tag-note.improvements {
  color: #ffb629;
  background: rgba(255, 182, 41, 0.15);
}
#notes .note-box .top-note .data-note .tag-note.continuous {
  color: #ffd700;
  background: rgba(255, 215, 0, 0.25);
}
#notes .note-box .top-note .data-note .date-note {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.2em;
  font-weight: 700;
  text-transform: uppercase;
  color: #78779c;
}
#notes .note-box .body-note h5 {
  font-weight: 600;
  margin-top: 18px;
  color: #ffb629;
}
#notes .note-box .body-note ol li,
#notes .note-box .body-note p,
#notes .note-box .body-note ul li {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #b7b9da;
  font-weight: 500;
}
#notes .note-box .body-note ul {
  padding: 0 0 0 13px;
  margin: 0;
}
#notes .note-box .body-note ul li {
  padding: 0;
  margin-bottom: 6px;
}
#notes .note-box .body-note ul li strong {
  color: #ffffff;
  font-weight: 400;
}
#notes .note-box .body-note ul li::marker {
  font-size: 11px;
  color: #ffb629;
}
#notes table {
  margin: 30px 0 30px;
}
#notes table thead {
  border-bottom: 1px solid #ffb629;
}
#notes table thead th {
  padding: 12px 15px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
}
#notes table thead th:first-child {
  width: 10%;
}
#notes table thead th:nth-child(2) {
  width: 20%;
}
#notes table thead th:nth-child(3) {
  width: 70%;
}
#notes table tbody tr td {
  padding: 30px 15px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: #b7b9da;
}
#notes table tbody tr td:first-child {
  color: #ffffff;
}
#notes table tbody tr:nth-child(odd) {
  background: rgba(56, 49, 135, 0.2);
}
#notes table tbody tr:nth-child(2n) {
  background: rgba(56, 49, 135, 0.45);
}
@media (max-width: 991px) {
  #notes .note-box .top-note .data-note {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  #notes .note-box .top-note {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #notes .note-box .top-note .title-note {
    max-width: 100%;
  }
  #notes .note-box .top-note .title-note h4 {
    margin-bottom: 12px;
  }
}
@media (max-width: 575px) {
  #notes table {
    display: block;
    overflow-x: auto;
  }
  #notes table thead th {
    min-width: 100px;
  }
  #notes table thead th:nth-child(3) {
    min-width: 300px;
  }
  #notes table tbody tr td {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 425px) {
  #notes .note-box {
    padding: 20px 25px;
  }
  #notes .note-box .top-note .data-note .date-note,
  #notes .note-box .top-note .data-note .tag-note {
    font-size: 9px;
  }
  #notes .note-box .top-note .data-note .tag-note {
    margin-right: 5px;
  }
}
#hero-faqs {
  padding: 150px 0 70px;
  border-bottom: 1px solid rgba(12, 138, 255, 0.14);
  background-color: #0e0c1f;
  overflow: hidden;
}
#hero-faqs .hero-content-faqs {
  position: relative;
  text-align: center;
}
#hero-faqs .hero-content-faqs h2 {
  position: relative;
  z-index: 1;
}
#hero-faqs .hero-content-faqs p {
  margin-top: 20px;
  position: relative;
  z-index: 1;
}
#hero-faqs .hero-content-faqs .blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -30%) translateZ(0px);
  transform: translate(-50%, -30%) translateZ(0px);
  background: #0c15ff;
  opacity: 0.2;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  pointer-events: none;
  z-index: 0;
}
#hero-faqs .hero-content-faqs #particles-js-faqs-hero {
  position: absolute;
  top: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 400px;
  height: 200px;
  z-index: 1;
  pointer-events: none;
}
#faqs {
  background-color: #0e0c1f;
  padding: 100px 0 50px;
}
#faqs .category-area {
  min-height: 350px;
}
#faqs .category-area .icon-holder {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
#faqs .category-area p {
  margin-top: 15px;
  text-align: left;
  max-width: 250px;
}
#faqs .faq-list {
  list-style: none;
  padding: 0;
}
#faqs .faq-list li {
  margin: 30px 0;
  padding: 30px 0;
  margin: 0;
  text-align: left;
  border-top: 1px solid #0e1f40;
  position: relative;
}
#faqs .faq-list li a {
  color: #ffb629;
}
#faqs .faq-list li a:hover {
  text-decoration: underline;
}
#faqs .faq-list li:last-child {
  margin-bottom: 90px;
  border-bottom: 1px solid #0e1f40;
}
#faqs .faq-list li .faq-heading {
  max-width: 100%;
  cursor: pointer;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 0;
}
#faqs .faq-list li .faq-text {
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: #78779c;
  margin-top: 15px;
}
@media (max-width: 767px) {
  #faqs .category-area {
    text-align: center;
    min-height: auto;
    margin-bottom: 20px;
  }
  #faqs .category-area .icon-holder {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #faqs .category-area p {
    max-width: 400px;
    margin: 0 auto 30px;
    text-align: center;
  }
}
@media (max-width: 575px) {
  #faqs {
    padding: 50px 0 0;
  }
}
#pricing-top {
  padding: 160px 0 140px;
  background-color: #0d0c1e;

  background-position: center top, center;
  background-size: 1440px auto, cover;
  background-repeat: no-repeat, no-repeat;
}
#pricing-top .top-area {
  text-align: center;
  margin-bottom: 125px;
}
#pricing-top .top-area h1 {
  text-align: center;
  font-size: 68px;
  margin-bottom: 25px;
}
#pricing-top .top-area h1 strong {
  color: #ffb629;
  font-weight: 600;
}
#pricing-top .top-area p strong {
  color: #b7b9da;
  font-weight: normal;
}
#pricing-top #pricing-plans:hover .card-price:after {
  opacity: 1;
}
#pricing-top #pricing-plans .card-price {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
#pricing-top #pricing-plans .card-price:after {
  content: "";
  border-radius: inherit;
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 0px;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
  width: 100%;
  pointer-events: none;
}
#pricing-top #pricing-plans .card-price:after {
  background: radial-gradient(
    200px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 182, 41, 0.05),
    transparent 100%
  );
  z-index: 1;
}
#pricing-top #pricing-plans .card-price.business:after {
  background: radial-gradient(
    200px circle at var(--mouse-x) var(--mouse-y),
    rgba(252, 65, 205, 0.0862745098),
    transparent 100%
  );
  z-index: 1;
}
#pricing-top #pricing-plans .card-price.enterprise:after {
  background: radial-gradient(
    200px circle at var(--mouse-x) var(--mouse-y),
    rgba(21, 132, 255, 0.07),
    transparent 100%
  );
  z-index: 1;
}
#pricing-top #pricing-plans .toggle-pricing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 50px;
  gap: 20px;
}
#pricing-top #pricing-plans .toggle-pricing span {
  font-size: 11px;
  line-height: 16px;
  font-weight: 800;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#pricing-top #pricing-plans .toggle-pricing span.active {
  color: #ffb629;
}
#pricing-top #pricing-plans .toggle-llm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}
#pricing-top #pricing-plans .toggle-llm .holder-pricing {
  position: relative;
  height: 24px;
  width: 45px;
  border-radius: 50px;
  background: #080810;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
#pricing-top #pricing-plans .toggle-llm .holder-pricing .toggle {
  position: absolute;
  top: 3px;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 60px;
  background: #ffb629;
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
}
@keyframes toggleOn {
  0% {
    left: 4px;
    border-radius: 60px;
  }
  to {
    left: 23px;
    border-radius: 60px;
  }
}
@keyframes toggleOff {
  0% {
    left: 23px;
    border-radius: 60px;
  }
  to {
    left: 4px;
    border-radius: 60px;
  }
}
#pricing-top #pricing-plans .toggle-llm .holder-pricing.off {
  background: #080810;
}
#pricing-top #pricing-plans .toggle-llm .holder-pricing.on .toggle {
  -webkit-animation: toggleOn 0.3s ease-in-out;
  animation: toggleOn 0.3s ease-in-out;
  left: 23px;
}
#pricing-top #pricing-plans .toggle-llm .holder-pricing.off .toggle {
  -webkit-animation: toggleOff 0.3s ease-in-out;
  animation: toggleOff 0.3s ease-in-out;
  left: 4px;
}
#pricing-top #pricing-plans .pricing-plan {
  min-height: 510px;
  background: rgba(0, 0, 0, 0.33);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 10%;
  position: relative;
}
#pricing-top #pricing-plans .pricing-plan:before {
  content: "";
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  -webkit-mask: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(black),
        to(black)
      )
      content-box content-box,
    -webkit-gradient(linear, left top, left bottom, from(black), to(black));
  -webkit-mask: linear-gradient(black, black) content-box content-box,
    linear-gradient(black, black);
  -webkit-mask-composite: xor;
}
#pricing-top #pricing-plans .pricing-plan.personal:before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 182, 41, 0.25)),
    to(rgba(255, 182, 41, 0.05))
  );
  background: linear-gradient(
    rgba(255, 182, 41, 0.25),
    rgba(255, 182, 41, 0.05)
  );
}
#pricing-top #pricing-plans .pricing-plan.personal .pricing-title {
  color: #ffb629;
}
#pricing-top #pricing-plans .pricing-plan.personal .pricing-title:before {
  width: 23px;
}
#pricing-top #pricing-plans .pricing-plan.personal .plan-features li:before {
  border-color: transparent transparent transparent #ffb629;
  -webkit-filter: drop-shadow(0 0 6px #ffb629);
  filter: drop-shadow(0 0 6px #ffb629);
}
#pricing-top #pricing-plans .pricing-plan.personal .outline-link {
  color: #ffb629;
  border: 1px solid #ffb629;
}
#pricing-top #pricing-plans .pricing-plan.personal .outline-link:hover {
  background-color: #ffb629;
  -webkit-box-shadow: 0 0 25px rgba(255, 182, 41, 0.33);
  box-shadow: 0 0 25px rgba(255, 182, 41, 0.33);
}
#pricing-top #pricing-plans .pricing-plan.personal .outline-link:after {
  border-color: transparent transparent transparent #ffb629;
}
#pricing-top #pricing-plans .pricing-plan.business:before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(252, 65, 206, 0.2)),
    to(rgba(252, 65, 205, 0.0862745098))
  );
  background: linear-gradient(
    rgba(252, 65, 206, 0.2),
    rgba(252, 65, 205, 0.0862745098)
  );
}
#pricing-top #pricing-plans .pricing-plan.business .pricing-title {
  color: #fc41ce;
}
#pricing-top #pricing-plans .pricing-plan.business .pricing-title:before {
  width: 26px;
}
#pricing-top #pricing-plans .pricing-plan.business .plan-features li:before {
  border-color: transparent transparent transparent #fc41ce;
  -webkit-filter: drop-shadow(0 0 6px #fc41ce);
  filter: drop-shadow(0 0 6px #fc41ce);
}
#pricing-top #pricing-plans .pricing-plan.business .outline-link {
  color: #fc41ce;
  border: 1px solid #fc41ce;
}
#pricing-top #pricing-plans .pricing-plan.business .outline-link:hover {
  background-color: #fc41ce;
  -webkit-box-shadow: 0 0 25px rgba(252, 65, 206, 0.33);
  box-shadow: 0 0 25px rgba(252, 65, 206, 0.33);
}
#pricing-top #pricing-plans .pricing-plan.business .outline-link:after {
  border-color: transparent transparent transparent #fc41ce;
}
#pricing-top #pricing-plans .pricing-plan.enterprise:before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(21, 132, 255, 0.33)),
    to(rgba(21, 132, 255, 0.1))
  );
  background: linear-gradient(
    rgba(21, 132, 255, 0.33),
    rgba(21, 132, 255, 0.1)
  );
}
#pricing-top #pricing-plans .pricing-plan.enterprise .pricing-title {
  color: #1584ff;
}
#pricing-top #pricing-plans .pricing-plan.enterprise .pricing-title:before {
  width: 21px;
}
#pricing-top #pricing-plans .pricing-plan.enterprise .plan-features li:before {
  border-color: transparent transparent transparent #1584ff;
  -webkit-filter: drop-shadow(0 0 6px #1584ff);
  filter: drop-shadow(0 0 6px #1584ff);
}
#pricing-top #pricing-plans .pricing-plan.enterprise .outline-link {
  color: #1584ff;
  border: 1px solid #1584ff;
}
#pricing-top #pricing-plans .pricing-plan.enterprise .outline-link:hover {
  background-color: #1584ff;
  -webkit-box-shadow: 0 0 25px rgba(21, 132, 255, 0.33);
  box-shadow: 0 0 25px rgba(21, 132, 255, 0.33);
}
#pricing-top #pricing-plans .pricing-plan.enterprise .outline-link:after {
  border-color: transparent transparent transparent #1584ff;
}
#pricing-top #pricing-plans .pricing-plan .pricing-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 19px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  letter-spacing: 0.01em;
}
#pricing-top #pricing-plans .pricing-plan .pricing-title:before {
  content: "";
  height: 23px;
  display: inline-block;
  margin-bottom: -4px;
  margin-right: 20px;
}
#pricing-top #pricing-plans .pricing-plan .bussines-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 8px;
}
#pricing-top #pricing-plans .pricing-plan .bussines-price .divider {
  width: 1px;
  height: 50px;
  background-color: #ffffff;
  opacity: 0.1;
}
#pricing-top #pricing-plans .pricing-plan .price {
  font-size: 40px;
  color: white;
  font-weight: 200;
  line-height: 68px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  margin: 0;
}
#pricing-top #pricing-plans .pricing-plan .price span {
  display: inline-block;
  vertical-align: middle;
  font-size: 68px;
  line-height: 68px;
}
#pricing-top #pricing-plans .pricing-plan .price span.dollar {
  font-size: 40px;
}
#pricing-top #pricing-plans .pricing-plan .price span.month {
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #78779c;
  max-width: 100px;
  display: none;
}
#pricing-top #pricing-plans .pricing-plan .price span.month.active {
  display: inline-block;
}
#pricing-top #pricing-plans .pricing-plan .price span.value {
  display: none;
}
#pricing-top #pricing-plans .pricing-plan .price span.value.active {
  display: inline-block;
}
#pricing-top #pricing-plans .pricing-plan .plan-features {
  list-style: none;
  padding-left: 0;
  padding-bottom: 10px;
  margin-top: 12px;
}
#pricing-top #pricing-plans .pricing-plan .plan-features li {
  position: relative;
  min-height: 1.5em;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #b7b9da;
  padding: 17px 0 17px 1.33em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#pricing-top #pricing-plans .pricing-plan .plan-features li:first-child {
  color: white;
  font-weight: 600;
}
#pricing-top #pricing-plans .pricing-plan .plan-features li:last-child {
  border-bottom: none;
}
#pricing-top #pricing-plans .pricing-plan .plan-features li:before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 7px;
}
#pricing-top #pricing-plans .pricing-plan .outline-link {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  padding: 12px;
  width: 100%;
}
#pricing-top #pricing-plans .pricing-plan .outline-link:hover {
  color: #100d23;
}
#pricing-top #pricing-plans .pricing-plan .outline-link:hover:after {
  border-color: transparent transparent transparent #100d23;
}
#pricing-top #pricing-plans .pricing-plan .outline-link:after {
  content: "";
  position: absolute;
  margin-top: 3px;
  margin-left: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 7px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
@media (max-width: 991px) {
  #pricing-top {
    padding: 160px 0 80px;
  }
  #pricing-top #pricing-plans .pricing-plan {
    margin-bottom: 30px;
  }
  #pricing-top #pricing-plans .pricing-plan .bussines-price {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    gap: 40px;
  }
  #pricing-top #pricing-plans .pricing-plan .price {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    gap: 15px;
  }
}
@media (max-width: 767px) {
  #pricing-top {
    background-size: 1000px auto, cover;
    background-position: center 170px, center;
  }
  #pricing-top .top-area h1 {
    font-size: 50px;
  }
  #pricing-top #pricing-plans .pricing-plan {
    padding: 8% 7%;
  }
}
@media (max-width: 575px) {
  #pricing-top {
    padding: 130px 0 80px;
    background-position: center 60px, center;
  }
  #pricing-top .top-area {
    margin-bottom: 130px;
  }
  #pricing-top .top-area h1 {
    font-size: 40px;
  }
  #pricing-top #pricing-plans .pricing-plan .bussines-price {
    gap: 20px;
  }
  #pricing-top #pricing-plans .pricing-plan .price {
    gap: 5px;
  }
  #pricing-top #pricing-plans .pricing-plan .price span {
    font-size: 52px;
    line-height: 61px;
  }
  #pricing-top #pricing-plans .pricing-plan .price span.dollar {
    font-size: 30px;
  }
  #pricing-top #pricing-plans .pricing-plan .price span.month {
    font-size: 8px;
    line-height: 12px;
  }
}
@media (max-width: 425px) {
  #pricing-top {
    background-position: center 75px, center;
  }
  #pricing-top .top-area h1 {
    font-size: 35px;
  }
}
@media (max-width: 375px) {
  #pricing-top .top-area {
    margin-bottom: 100px;
  }
}
#plans-overview {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(8, 8, 15, 0)),
    to(#100d23)
  );
  background: linear-gradient(0deg, rgba(8, 8, 15, 0) 0%, #100d23 100%);
  padding: 100px 0;
}
#plans-overview .top-area {
  text-align: center;
  margin-bottom: 70px;
}
#plans-overview .top-area p strong {
  color: #b7b9da;
  font-weight: normal;
}
#plans-overview table {
  margin: 30px 0 30px;
}
#plans-overview table thead {
  border-bottom: 1px solid #ffb629;
}
#plans-overview table thead th {
  padding: 12px 20px;
}
#plans-overview table thead th p {
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 0;
}
#plans-overview table thead th img {
  display: none;
}
#plans-overview table thead th:first-child {
  width: 64%;
}
#plans-overview table thead th:nth-child(2) {
  width: 12%;
}
#plans-overview table thead th:nth-child(3) {
  width: 12%;
}
#plans-overview table thead th:nth-child(4) {
  width: 12%;
}
#plans-overview table tbody tr td {
  padding: 25px 20px;
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: #b7b9da;
}
#plans-overview table tbody tr td p {
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: #b7b9da;
  margin: 0;
}
#plans-overview table tbody tr td span {
  font-size: 16px;
  color: #ffffff;
}
#plans-overview table tbody tr td span.check {
  display: block;
  width: 20px;
  height: 14px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
}
#plans-overview table tbody tr td span.empty {
  display: block;
  width: 10px;
  height: 3px;
  background-color: #78779c;
  margin: 0 auto;
}
#plans-overview table tbody tr td:nth-child(2),
#plans-overview table tbody tr td:nth-child(3),
#plans-overview table tbody tr td:nth-child(4) {
  text-align: center;
  color: #ffb629;
}
#plans-overview table tbody tr:nth-child(odd) {
  background: rgba(56, 49, 135, 0.2);
}
#plans-overview table tbody tr:nth-child(2n) {
  background: rgba(56, 49, 135, 0.45);
}
#plans-overview table tbody tr.pretitles {
  background-color: transparent;
}
#plans-overview table tbody tr.pretitles td {
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #ffb629;
}
@media (max-width: 767px) {
  #plans-overview table thead th {
    padding: 12px 10px;
  }
  #plans-overview table thead th p {
    font-size: 9px;
    line-height: 11px;
  }
  #plans-overview table thead th:first-child {
    width: 76%;
  }
  #plans-overview table thead th:nth-child(2) {
    width: 8%;
  }
  #plans-overview table thead th:nth-child(3) {
    width: 8%;
  }
  #plans-overview table thead th:nth-child(4) {
    width: 8%;
  }
  #plans-overview table tbody tr td {
    padding: 15px 10px;
  }
  #plans-overview table tbody tr.pretitles td {
    padding: 15px 10px;
  }
}
@media (max-width: 575px) {
  #plans-overview {
    padding: 50px 0;
  }
  #plans-overview .top-area {
    margin-bottom: 0;
  }
  #plans-overview table {
    display: block;
    overflow-x: auto;
  }
  #plans-overview table thead th p {
    display: none;
  }
  #plans-overview table thead th img {
    display: block;
    margin: 0 auto;
    max-width: 20px;
    max-height: 20px;
  }
  #plans-overview table thead th:first-child {
    min-width: 60%;
  }
  #plans-overview table thead th:nth-child(2),
  #plans-overview table thead th:nth-child(3),
  #plans-overview table thead th:nth-child(4) {
    min-width: 55px;
  }
  #plans-overview table tbody tr td {
    line-height: 15px;
    font-size: 12px;
  }
  #plans-overview table tbody tr td p {
    display: none;
  }
  #plans-overview table tbody tr td span {
    font-size: 13px;
  }
  #plans-overview table tbody tr td span.check {
    width: 12px;
  }
  #plans-overview table tbody tr.pretitles td {
    font-size: 14px;
    padding: 20px 10px;
  }
}
#hero-source {
  padding: 150px 0 70px;
  border-bottom: 1px solid rgba(12, 138, 255, 0.14);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.831372549)),
    to(rgba(0, 0, 0, 0))
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.831372549) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  overflow: hidden;
}
#hero-source .hero-content-source {
  position: relative;
  text-align: center;
}
#hero-source .hero-content-source h2 {
  position: relative;
  z-index: 1;
}
#hero-source .hero-content-source p {
  margin-top: 20px;
  position: relative;
  z-index: 1;
}
#hero-source .hero-content-source .blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) translateZ(0px);
  transform: translate(-50%, -50%) translateZ(0px);
  background: #0c15ff;
  opacity: 0.3;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  pointer-events: none;
  z-index: 0;
}
#hero-source .hero-content-source #particles-js-source-hero {
  position: absolute;
  top: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 400px;
  height: 200px;
  z-index: 1;
  pointer-events: none;
}
#source-list {
  padding: 100px 0 50px;
  background-color: #0e0c1f;
}
#source-list .category-area .icon-holder {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 20px;
}
#source-list .category-area h3 {
  max-width: 250px;
}
#source-list .category-area p {
  margin-top: 15px;
  text-align: left;
  max-width: 250px;
}
#source-list .faq-list {
  list-style: none;
  padding: 0;
}
#source-list .faq-list > li {
  padding: 20px 0 15px;
  margin: 10px 0;
  text-align: left;
  border-top: 1px solid #0e1f40;
  position: relative;
}
#source-list .faq-list > li:first-child {
  margin-top: 0;
}
#source-list .faq-list > li:last-child {
  margin-bottom: 90px;
  padding-bottom: 20px;
  border-bottom: 1px solid #0e1f40;
}
#source-list .faq-list > li .faq-heading {
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin: 0;
  text-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
}
#source-list .faq-list > li .faq-text {
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: #78779c;
  margin-top: 10px;
  margin-bottom: 0;
}
#source-list .faq-list .list-security {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#source-list .faq-list .list-security ul {
  padding: 0;
  list-style: none;
  width: 50%;
}
#source-list .faq-list .list-security ul li {
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: #78779c;
  position: relative;
  margin: 6px 0;
}
#source-list .faq-list .list-security ul li:before {
  content: "·";
  color: #ffb629;
  display: inline-block;
  margin-right: 10px;
}
@media (max-width: 767px) {
  #source-list .category-area {
    text-align: center;
  }
  #source-list .category-area .icon-holder {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #source-list .category-area p {
    max-width: 400px;
    margin: 0 auto 30px;
    text-align: center;
  }
}
@media (max-width: 575px) {
  #source-list {
    padding: 50px 0 0;
  }
  #source-list .faq-list .list-security {
    gap: 20px;
  }
  #source-list .faq-list .list-security ul li {
    margin: 10px 0;
    line-height: 20px;
  }
}
#hero-signup {
  padding: 100px 0;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #100d23;
  position: relative;
  overflow: hidden;
}
#hero-signup:before {
  content: "";
  width: 70%;
  height: 40%;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateZ(0px);
  transform: translateX(-50%) translateZ(0px);
  background: #0c15ff;
  opacity: 15%;
  border-radius: 50%;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  pointer-events: none;
  z-index: 1;
}
#hero-signup #particles-js-signup {
  position: absolute;
  bottom: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 700px;
  height: 300px;
  z-index: 0;
  pointer-events: none;
  opacity: 0.5;
}
#hero-signup .top-area {
  text-align: center;
}
#hero-signup .sign-up-form {
  width: 100%;
  max-width: 450px;
  margin: 50px auto 0;
}
@media (max-width: 575px) {
  #hero-signup .sign-up-form #mc_embed_signup form .mc-field-group {
    width: 100%;
  }
  #hero-signup .sign-up-form #mc_embed_signup form .mc-field-group.col-one,
  #hero-signup .sign-up-form #mc_embed_signup form .mc-field-group.col-two {
    width: 100%;
    margin: 0;
  }
  #hero-signup .sign-up-form #mc_embed_signup #mce-responses .response {
    padding: 14px 30px;
  }
}
#hero-privacy {
  padding: 140px 0 80px;
  border-bottom: 1px solid rgba(12, 138, 255, 0.14);
  background-color: #0e0c1f;
  overflow: hidden;
}
#hero-privacy .top-area {
  text-align: center;
}
#hero-privacy .top-area h1 {
  margin-bottom: 15px;
}
#hero-privacy .blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -30%) translateZ(0px);
  transform: translate(-50%, -30%) translateZ(0px);
  background: #0c15ff;
  opacity: 0.2;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  pointer-events: none;
  z-index: 0;
}
#hero-privacy #particles-js-privacy-hero {
  position: absolute;
  top: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 400px;
  height: 200px;
  z-index: 1;
  pointer-events: none;
}
#privacy-body {
  padding: 80px 0;
  background-color: #0e0c1f;
}
#privacy-body .table-content {
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0)),
      to(rgba(0, 0, 0, 0.14))
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))),
    #383187;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.14) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #383187;
  border-radius: 16px;
  padding: 50px;
  margin-top: 10px;
}
#privacy-body .table-content h3 {
  margin-top: 0;
  margin-bottom: 20px;
}
#privacy-body .table-content .content-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
}
#privacy-body .table-content .content-links a {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #b7b9da;
  margin-bottom: 8px;
}
#privacy-body .table-content .content-links a:hover {
  color: #ffffff;
}
#privacy-body h3,
#privacy-body h4 {
  margin: 50px 0 15px;
}
#privacy-body h5 {
  color: #ffffff;
  margin: 30px 0 15px;
}
#privacy-body h6 {
  margin-bottom: 4px;
}
#privacy-body h6 a {
  color: #ffb629;
  font-weight: 600;
}
#privacy-body h6 a:hover {
  color: #ffffff;
}
#privacy-body ol li,
#privacy-body p,
#privacy-body ul li {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #78779c;
  font-weight: 400;
}
#privacy-body ol li strong,
#privacy-body p strong,
#privacy-body ul li strong {
  color: #ffffff;
  font-weight: 600;
}
#privacy-body ol li a,
#privacy-body p a,
#privacy-body ul li a {
  color: #ffb629;
}
#privacy-body ol li a:hover,
#privacy-body p a:hover,
#privacy-body ul li a:hover {
  color: #ffffff;
}
#privacy-body ol li::marker,
#privacy-body p::marker,
#privacy-body ul li::marker {
  color: #ffb629;
  font-size: 11px;
}
#privacy-body ol,
#privacy-body ul {
  margin: 20px 0 25px;
}
#privacy-body ol li,
#privacy-body ul li {
  margin: 5px 0;
}
#privacy-body table {
  margin: 10px 0 30px;
}
#privacy-body table thead {
  border-bottom: 1px solid #ffb629;
}
#privacy-body table thead th {
  padding: 12px 15px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
}
#privacy-body table thead th:first-child {
  width: 25%;
}
#privacy-body table thead th:nth-child(2) {
  width: 50%;
}
#privacy-body table thead th:nth-child(3) {
  width: 25%;
}
#privacy-body table tbody tr td {
  padding: 30px 15px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: #b7b9da;
}
#privacy-body table tbody tr td:first-child {
  color: #ffffff;
  font-weight: 600;
}
#privacy-body table tbody tr:nth-child(odd) {
  background: rgba(56, 49, 135, 0.2);
}
#privacy-body table tbody tr:nth-child(2n) {
  background: rgba(56, 49, 135, 0.45);
}
@media (max-width: 575px) {
  #privacy-body .table-content {
    padding: 20px;
  }
  #privacy-body .table-content .content-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0;
  }
  #privacy-body h3 {
    font-size: 25px;
    line-height: 30px;
  }
  #privacy-body ol,
  #privacy-body ul {
    padding-left: 10px;
  }
  #privacy-body table {
    display: block;
    overflow-x: auto;
  }
  #privacy-body table thead th {
    min-width: 150px;
  }
  #privacy-body table thead th:nth-child(2) {
    min-width: 200px;
  }
  #privacy-body table tbody tr td {
    font-size: 14px;
    line-height: 20px;
  }
}
footer {
  padding: 30px 0;
  background: #100d23;
}
footer .social-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 30px;
}
footer .copyright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}
footer .copyright .copy {
  margin: 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #413f74;
}
footer .terms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 15px;
}
footer .terms a:not(.outline-link) {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #413f74;
}
footer .terms a:not(.outline-link):hover {
  color: #ffffff;
}
@media (max-width: 991px) {
  footer .copyright,
  footer .terms {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  footer .social-box {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  footer .terms {
    gap: 10px;
  }
}
@media (max-width: 575px) {
  footer .terms {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 300px;
    margin: 0 auto;
  }
}
@media (max-width: 425px) {
  footer .copyright {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    max-width: 250px;
    margin: 0 auto 15px;
  }
  footer .terms {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 10px auto 0;
  }
}
@keyframes pulseText {
  0%,
  to {
    text-shadow: 0 0 20px rgba(255, 184, 41, 0.3137254902);
  }
  50% {
    text-shadow: 0 0 25px rgba(255, 184, 41, 0.5137254902);
  }
}
@-webkit-keyframes pulseText {
  0%,
  to {
    text-shadow: 0 0 20px rgba(255, 184, 41, 0.3137254902);
  }
  50% {
    text-shadow: 0 0 25px rgba(255, 184, 41, 0.5137254902);
  }
}
@keyframes rotateLogo {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateLogo {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes pulseBenefits {
  0%,
  to {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.6549019608);
  }
  50% {
    text-shadow: 0 0 20px #ffffff;
  }
}
@-webkit-keyframes pulseBenefits {
  0%,
  to {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.6549019608);
  }
  50% {
    text-shadow: 0 0 20px #ffffff;
  }
}
@-webkit-keyframes pulseCircleSmall {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  1% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}
@keyframes pulseCircleSmall {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  1% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}
@-webkit-keyframes pulseCircleBig {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  1% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes pulseCircleBig {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  1% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}
@-webkit-keyframes rotateCarousel {
  0% {
    -webkit-transform: rotateX(35deg) translateZ(0);
    transform: rotateX(35deg) translateZ(0);
  }
  25% {
    -webkit-transform: rotateX(35deg) scale(0.9) translateZ(0);
    transform: rotateX(35deg) scale(0.9) translateZ(0);
  }
  60% {
    -webkit-transform: none;
    transform: none;
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes rotateCarousel {
  0% {
    -webkit-transform: rotateX(35deg) translateZ(0);
    transform: rotateX(35deg) translateZ(0);
  }
  25% {
    -webkit-transform: rotateX(35deg) scale(0.9) translateZ(0);
    transform: rotateX(35deg) scale(0.9) translateZ(0);
  }
  60% {
    -webkit-transform: none;
    transform: none;
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes showBlur {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.74, 0.25, 0.76, 1);
    animation-timing-function: cubic-bezier(0.74, 0.25, 0.76, 1);
  }
  10% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.12, 0.01, 0.08, 0.99);
    animation-timing-function: cubic-bezier(0.12, 0.01, 0.08, 0.99);
  }
  to {
    opacity: 0.7;
  }
}
@keyframes showBlur {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.74, 0.25, 0.76, 1);
    animation-timing-function: cubic-bezier(0.74, 0.25, 0.76, 1);
  }
  10% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.12, 0.01, 0.08, 0.99);
    animation-timing-function: cubic-bezier(0.12, 0.01, 0.08, 0.99);
  }
  to {
    opacity: 0.7;
  }
}
@-webkit-keyframes showColorBlur {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.74, 0.25, 0.76, 1);
    animation-timing-function: cubic-bezier(0.74, 0.25, 0.76, 1);
  }
  10% {
    opacity: 0.2;
    -webkit-animation-timing-function: cubic-bezier(0.12, 0.01, 0.08, 0.99);
    animation-timing-function: cubic-bezier(0.12, 0.01, 0.08, 0.99);
  }
  to {
    opacity: 0;
  }
}
@keyframes showColorBlur {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.74, 0.25, 0.76, 1);
    animation-timing-function: cubic-bezier(0.74, 0.25, 0.76, 1);
  }
  10% {
    opacity: 0.2;
    -webkit-animation-timing-function: cubic-bezier(0.12, 0.01, 0.08, 0.99);
    animation-timing-function: cubic-bezier(0.12, 0.01, 0.08, 0.99);
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes removeOverlay {
  0% {
    top: 0;
    opacity: 1;
  }
  50%,
  to {
    top: 100%;
    opacity: 0;
  }
}
@keyframes removeOverlay {
  0% {
    top: 0;
    opacity: 1;
  }
  50%,
  to {
    top: 100%;
    opacity: 0;
  }
}
@-webkit-keyframes moveLinesRight {
  0% {
    left: 0;
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50%,
  to {
    width: 150px;
    left: 80%;
    opacity: 0;
  }
}
@keyframes moveLinesRight {
  0% {
    left: 0;
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50%,
  to {
    width: 150px;
    left: 80%;
    opacity: 0;
  }
}
@-webkit-keyframes moveLinesBottom {
  0% {
    bottom: 35%;
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60%,
  to {
    height: 70px;
    bottom: 80%;
    opacity: 0;
  }
}
@keyframes moveLinesBottom {
  0% {
    bottom: 35%;
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60%,
  to {
    height: 70px;
    bottom: 80%;
    opacity: 0;
  }
}
@-webkit-keyframes borderLight {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    opacity: 0;
  }
}
@keyframes borderLight {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    opacity: 0;
  }
}
@-webkit-keyframes pulseCircleDanger {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}
@keyframes pulseCircleDanger {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}
@-webkit-keyframes pulseShadow {
  0%,
  to {
    opacity: 0.8;
    -webkit-filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.2980392157));
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.2980392157));
  }
  50% {
    opacity: 1;
    -webkit-filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5333333333));
    filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5333333333));
  }
}
@keyframes pulseShadow {
  0%,
  to {
    opacity: 0.8;
    -webkit-filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.2980392157));
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.2980392157));
  }
  50% {
    opacity: 1;
    -webkit-filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5333333333));
    filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5333333333));
  }
}

`;

export const BootstrapMin = styled.div`
  /*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.5rem;
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
  font-size: 0.875em;
  font-weight: 400;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  -ms-flex-order: -1;
  order: -1;
}
.order-last {
  -ms-flex-order: 13;
  order: 13;
}
.order-0 {
  -ms-flex-order: 0;
  order: 0;
}
.order-1 {
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -ms-flex-order: 4;
  order: 4;
}
.order-5 {
  -ms-flex-order: 5;
  order: 5;
}
.order-6 {
  -ms-flex-order: 6;
  order: 6;
}
.order-7 {
  -ms-flex-order: 7;
  order: 7;
}
.order-8 {
  -ms-flex-order: 8;
  order: 8;
}
.order-9 {
  -ms-flex-order: 9;
  order: 9;
}
.order-10 {
  -ms-flex-order: 10;
  order: 10;
}
.order-11 {
  -ms-flex-order: 11;
  order: 11;
}
.order-12 {
  -ms-flex-order: 12;
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #7abaff;
}
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb;
}
.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb;
}
.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}
.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffdf7e;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb;
}
.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e;
}
.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}
.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}
.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated select.form-control:valid,
select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.75rem center/8px 10px no-repeat,
    #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
      no-repeat;
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.75rem center/8px 10px no-repeat,
    #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
      center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
      no-repeat;
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning.focus,
.btn-warning:focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.focus,
.btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.focus,
.btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}
.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label,
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label::after,
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation
  > .custom-file:nth-last-child(n + 3)
  .custom-file-label,
.input-group.has-validation
  > .custom-file:nth-last-child(n + 3)
  .custom-file-label::after,
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .form-control:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation
  > .input-group-append:nth-last-child(n + 3)
  > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}
.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label,
.custom-file-input[disabled] ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}
.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom,
.card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:focus,
a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media-body {
  -ms-flex: 1;
  flex: 1;
}
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="top"] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] > .arrow,
.bs-popover-right > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="right"] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] > .arrow,
.bs-popover-left > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="left"] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #007bff !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #007bff !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #007bff !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.min.css.map */

`;