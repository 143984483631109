import { ResponseStatus } from "@patentedai/common/api/Common";
import { getSearchLogs } from "@patentedai/web/helpers/functionHelper";
import { useEffect, useState } from "react";
import { Loading } from "@patentedai/web/components/core/Loading";
import { Backdrop } from "@mui/material";
import Button from "@patentedai/web/components/core/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { LogInfo } from "@patentedai/common/api/Log";
import { getDatePlusDays } from "@patentedai/common/helpers/timeHelper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import * as Sentry from "@sentry/react";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FBFAFF;",
    border: "1px solid #E7E7E7",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "12px",
    textAlign: "center",
    color: "#404246",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const AdminTables = styled("div")`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  h2 {
    margin: 30px 0px 0px;
  }
`;

interface UserObject {
  userName?: string;
  userId?: string;
}

const Log = () => {
  const [isLoadingLogs, setIsLoadingLogs] = useState(true);
  const [logs, setLogs] = useState<LogInfo[]>([]);
  const [logsCopy, setLogsCopy] = useState<LogInfo[]>([]);
  const [users, setUsers] = useState<UserObject[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");

  useEffect(() => {
    const searchWaitlist = async () => {
      try {
        setIsLoadingLogs(true);

        const response = await getSearchLogs({
          startDate: getDatePlusDays(new Date(), -7).getTime(),
        });
        if (response.status !== ResponseStatus.Success) {
          setLogs([]);
          setLogsCopy([]);
          return;
        }

        const usersObject: UserObject[] = [];
        response?.logs?.forEach((log: LogInfo) =>
          usersObject.push({ userName: log.userName, userId: log.userId })
        );
        const uniqueUsersObject: UserObject[] = usersObject.filter(
          (user, index, array) => {
            const firstIndex = array.findIndex((u) => u.userId === user.userId);
            return index === firstIndex;
          }
        );
        setUsers(
          uniqueUsersObject?.filter((usersObject) => usersObject?.userId)
        );
        setLogs(response.logs);
        setLogsCopy(response.logs);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      } finally {
        setIsLoadingLogs(false);
      }
    };

    searchWaitlist();
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const userId = event.target.value as string;
    if (userId) {
      setSelectedUserId(userId);
      const filteredData = logsCopy.filter(
        (logs: LogInfo) => logs.userId === userId
      );
      setLogs(filteredData);
    } else {
      setLogs(logsCopy);
      setSelectedUserId("");
    }
  };

  const handleReset = () => {
    setLogs(logsCopy);
    setSelectedUserId("");
  };

  if (isLoadingLogs)
    return (
      <Backdrop open={true}>
        <Loading />
      </Backdrop>
    );

  return (
    <Box sx={{ width: "100%" }}>
      <AdminTables>
        <h2 style={{ marginBottom: "20px" }}>Logs</h2>
        <FormControl
          fullWidth
          style={{ display: "flex", flexDirection: "row", gap: 5 }}
        >
          <InputLabel size="small" id="user-select-label">
            {selectedUserId ? "" : "Select User"}
          </InputLabel>
          <Select
            size="small"
            labelId="user-select-label"
            id="user-select"
            value={selectedUserId}
            onChange={handleChange}
            style={{ marginBottom: "20px", width: "200px" }}
          >
            {users?.map((user: UserObject) => (
              <MenuItem value={user?.userId}>{user?.userName}</MenuItem>
            ))}
          </Select>
          {selectedUserId && (
            <Button
              variant="text"
              size="small"
              onClick={handleReset}
              style={{ width: "150px", height: 38 }}
            >
              Reset
            </Button>
          )}
        </FormControl>
        <div style={{ maxHeight: "500px", overflow: "auto" }}>
          <TableContainer sx={{ width: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>User</StyledTableCell>
                  <StyledTableCell>Workspace</StyledTableCell>
                  <StyledTableCell>TimeStamp</StyledTableCell>
                  <StyledTableCell>Patent</StyledTableCell>
                  <StyledTableCell>Search Id</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{log.userName}</StyledTableCell>
                    <StyledTableCell>{log.workspaceName}</StyledTableCell>
                    <StyledTableCell>
                      {new Date(log.createdAt).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell>{log.patentNumber}</StyledTableCell>
                    <StyledTableCell>{log.searchId}</StyledTableCell>
                    <StyledTableCell>{log.description}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </AdminTables>
    </Box>
  );
};

export default Log;
