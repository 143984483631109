import { useState } from "react";
import { TextField, IconButton, InputLabel } from "@mui/material";

import { ReactComponent as CloseIcon } from "@patentedai/web/images/closeIcon.svg";

import ModalWrapper from "modal/ModalWrapper";
import { deletePriorArtsCache } from "@patentedai/web/helpers/functionHelper";
import { ResponseStatus } from "@patentedai/common/api/Common";
import Button from "@patentedai/web/components/core/Button";

interface PurgeAIEngineCacheModalProps {
  open: boolean;
  handleClose: () => void;
}

const PurgeAIEngineCacheModal = (props: PurgeAIEngineCacheModalProps) => {
  const { open, handleClose } = props;

  const [patentNumber, setPatentNumber] = useState("");
  const [inProgress, setInProgress] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleSubmit = async () => {
    setSuccessMessage("");
    setErrorMessage("");
    setInProgress(true);

    const response = await deletePriorArtsCache({
      patentPublicationNumber: patentNumber,
    });
    if (response.status !== ResponseStatus.Success) {
      setErrorMessage(response?.message || "");
      setInProgress(false);
    } else {
      setSuccessMessage(response?.message || "");
      setPatentNumber("");
      setInProgress(false);
    }
  };

  const onClose = () => {
    setPatentNumber("");
    setSuccessMessage("");
    setErrorMessage("");
    handleClose();
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <div className="modal-header">
          <h2 className="modal-title">Purge AI Engine Cache</h2>
          <IconButton className="close-modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ width: "85%", marginTop: "10px" }}>
          <div>
            {successMessage && (
              <p style={{ marginBottom: "20px" }}>{successMessage}</p>
            )}
            <InputLabel className="label">Patent Number</InputLabel>
            <TextField
              fullWidth
              size="small"
              value={patentNumber}
              placeholder="Enter Patent Number"
              onChange={(e) => setPatentNumber(e.target.value)}
              helperText={errorMessage}
              error={errorMessage?.length > 0}
            />
          </div>
          <div
            className="modal-buttons"
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "10px",
              marginTop: "40px",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="contained"
              className="create-matter-button"
              size="small"
              onClick={handleSubmit}
              disabled={inProgress}
            >
              Submit
            </Button>
            <Button
              className="cancel-button"
              variant="outlined"
              size="small"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </>
    </ModalWrapper>
  );
};

export default PurgeAIEngineCacheModal;
