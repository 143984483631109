import { ClaimChartReasonElementData } from "@patentedai/common/entities/ClaimChartReasonElement";
import { ClaimChartReasonSubElementData } from "@patentedai/common/entities/ClaimChartReasonElement";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as OverlapTrue } from "@patentedai/web/images/overlapTrue.svg";
import { ReactComponent as OverlapFalse } from "@patentedai/web/images/overlapFalse.svg";
import { filterText } from "utils/searchDetailsUtils";

interface ClaimOverlapTableProps {
  claimChartReasons?: ClaimChartReasonElementData[]
  patentNumber?: string
  patent: ReducedPatentData
  showPvPHeader?: boolean
}

const ClaimOverlapTable = ({ claimChartReasons, patent, patentNumber, showPvPHeader = false }: ClaimOverlapTableProps) => (
  <div className="section-container">
    <TableContainer className="claim-overlap-table">
      <Table stickyHeader aria-label="claim-overlap table">
        <TableHead>
          <TableRow>
            <StyledTableCell className={`${showPvPHeader ? "red-thead" : ""}`} style={{ borderRadius: "5px 0px 0px 5px" }}>#</StyledTableCell>
            <StyledTableCell className={`${showPvPHeader ? "red-thead" : ""}`}>Element Content</StyledTableCell>
            <StyledTableCell className={`${showPvPHeader ? "white-thead" : ""}`}>Overlap</StyledTableCell>
            <StyledTableCell className={`${showPvPHeader ? "grey-thead" : ""}`} style={{ borderRadius: "0px 5px 5px 0px" }}>Reason</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {claimChartReasons?.sort((a, b) => a.id - b.id).map((claimReason) => {
            const fullClaim = patent.claims.find(x => x.id === claimReason.id);
            return (
              <>
                <ClaimOverlapRow
                  id={claimReason.id}
                  content={fullClaim?.element}
                  reasons={claimReason}
                  publicationNumber={patentNumber ? patentNumber : patent.publicationNumber}
                  isFirstLevelElement
                />
                {claimReason.subElements.map(subElementReason => {
                  const matchedSubElementContent = fullClaim?.subElements.find(subElementContent => subElementContent.id === subElementReason.id)?.subElement;

                  return (
                    <ClaimOverlapRow
                      id={subElementReason.id}
                      content={matchedSubElementContent}
                      reasons={subElementReason}
                      publicationNumber={patentNumber ? patentNumber : patent.publicationNumber}
                    />
                  );
                })}
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FBFAFF;",
    border: "1px solid #E7E7E7",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "12px",
    textAlign: "center",
    color: "#404246",
    padding: "9px",
    "&.red-thead": {
      background: "#FDF3F5",
      color: "#DF1440",
    },
    "&.white-thead": {
      background: "#FFF",
    },
    "&.grey-thead": {
      background: "#F1F3F3",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  }
}));

const ClaimOverlapRow = ({ id, content, reasons, publicationNumber, isFirstLevelElement }: { id: string | number; publicationNumber?: string; isFirstLevelElement?:boolean; content: string | undefined; reasons: ClaimChartReasonElementData | ClaimChartReasonSubElementData | undefined }) => {
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={id}>
      <StyledTableCell id="element-number">{id}</StyledTableCell>
      <StyledTableCell id={`element-content${reasons?.hasOverlap ? "-overlap" : ""}`}><p>{content ?? "-"}</p></StyledTableCell>
      <StyledTableCell id="isOverlapping">{isFirstLevelElement ? <></> : reasons?.hasOverlap ? <OverlapTrue /> : <OverlapFalse />}</StyledTableCell>
      <StyledTableCell id="reason">{isFirstLevelElement ? "-" : filterText(reasons?.reason, publicationNumber) || "-"}</StyledTableCell>
    </TableRow>
  );
};


export default ClaimOverlapTable;