import { styled } from "@mui/material/styles";
import { Select, MenuItem, SelectChangeEvent, Link } from "@mui/material";
import { MenuWrapper } from "menu/MenuWrapper";
import PatentMenuCard from "./PatentMenuCard";
import Divider from "@patentedai/web/components/core/Divider";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { MatterBase } from "@patentedai/common/entities/Matter";
import mockUserImage from "@patentedai/web/images/mock-user-image.png";
import { DateTime } from "luxon";

const DetailMenuWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 20px 0px 0px;
    overflow: hidden;
    position: relative;
    .workspace-card{
        display: flex;
        flex-direction: column;
        gap: 15px;
        .matter-dropdown{
            height: 50px;
            color: var(--color-palette-grey-100, #1F2023);
            font-family: Inter;
            font-weight: 500;
        }
        .matter-creation-date{
            color: var(--color-palette-grey-50, #8F8F91);
            font-family: Inter;
            font-size: 15px;
            font-weight: 500;
        }
        .workspace-members{
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            .members{
                img{
                    width: 30px;
                }
                display: flex;
                align-items: center;
                gap: 9px;
                color: #A8A8B7;
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: -0.13px;
            }
            .invite-button{
                color: var(--color-palette-grey-33, #B5B5B6);
                text-decoration-color: var(--color-palette-grey-33, #B5B5B6);
                font-family: Manrope;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: -0.13px;
            }
        }
    }
    .section-title {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        color: var(--color-palette-grey-100, #1F2023);
        font-family: Manrope;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.15px;
    }
    .activity-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        .activities {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            & > *:not(:last-child) {
                border-bottom: 1px solid var(--color-palette-grey-5, #F1F3F3);
            }
            .activity {
                    display: flex;
                    padding: 10px 0px;
                    align-items: flex-start;
                    gap: 15px;
                    align-self: stretch;
                .activity-description {
                    flex: 1 0 0;
                    color: var(--color-palette-grey-75, #505154);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 17px; /* 141.667% */
                    .highlighted{

                    }
                }

                .activity-date {
                    color: var(--color-palette-grey-33, #B5B5B6);
                    font-family: Inter;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px; /* 145.455% */
                }
            }
        }
    }
    .notes-list {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: flex-start;
        align-self: stretch;
        .notes {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;
            .note {
                display: flex;
                padding: 14px 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;
                border-radius: 6px;
                border-right: 1px solid #B0C8E1;
                border-bottom: 1px solid #B0C8E1;
                background: #F0F7FF;
                .note-description {
                    align-self: stretch;
                    color: var(--color-palette-grey-75, #505154);
                    font-family: Inter;
                    font-size: 15px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 22px; /* 146.667% */
                    letter-spacing: -0.15px;
                }

                .note-metadata {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;
                    .note-topic {
                        display: flex;
                        align-items: center;
                        gap: 9px;
                        img{
                            width: 27px;
                        }
                        .topic{
                            display: inline-flex;
                            color: var(--color-palette-grey-50, #8F8F91);
                            font-family: Inter;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 15px;
                            letter-spacing: -0.12px;
                            .item-name{
                                color: var(--color-palette-grey-75, #505154);
                                font-family: Inter;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 15px;
                                letter-spacing: -0.12px;
                            }
                        }
                    }

                    .note-creation-date {
                        color: var(--color-palette-grey-50, #8F8F91);
                        font-family: Inter;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px; /* 160% */
                        letter-spacing: -0.1px;
                    }
                }
            }
        }
    }

`;

interface PriorArtDetailMenuProps {
    matters: MatterBase[];
    selectedMatter: MatterBase;
    patent?: ReducedPatentData;
    handleSelectMatter: (matter: any) => void;
}

const MatterMenu = ({ patent, matters, selectedMatter, handleSelectMatter }: PriorArtDetailMenuProps) => {

    const onChangeMatter = (event: SelectChangeEvent<string>) => {
        handleSelectMatter(matters.find(matter => matter.id === event.target.value));
    };

    return (
        <MenuWrapper>
            <DetailMenuWrapper>
                <div className="workspace-card">
                    <Select
                        labelId="matter-dropdown"
                        className="matter-dropdown"
                        value={selectedMatter.id}
                        onChange={onChangeMatter}
                        label=""
                    >
                        {matters.map(matter => (
                            <MenuItem value={matter.id}>{matter.name}</MenuItem>
                        ))}
                    </Select>
                    <div className="matter-creation-date">Created on: {DateTime.fromJSDate(selectedMatter.createdAt).toFormat("MMM d, yyyy")}</div>
                    <Divider />
                    <div className="workspace-members">
                        <div className="members">
                            <img src={mockUserImage} alt="" />
                            1 Member</div>
                        <Link
                            component="button"
                            className="invite-button"
                        >
                            + Invite Members
                        </Link>
                    </div>
                </div>
                <PatentMenuCard patent={patent} />
                <div className="activity-list">
                    <h2 className="section-title">
                        Activity Log
                    </h2>
                    <div className="activities">
                        <div className="activity">
                            <div className="activity-description">
                                Veronica B. has added Facebook Search product to the matter.
                            </div>
                            <div className="activity-date">
                                Apr 23
                            </div>
                        </div>
                        <div className="activity">
                            <div className="activity-description">
                                Veronica B. has added Facebook Search product to the matter.
                            </div>
                            <div className="activity-date">
                                Apr 23
                            </div>
                        </div>
                    </div>
                </div>
                <div className="notes-list">
                    <h2 className="section-title">Notes</h2>
                    <div className="notes">
                        <div className="note">
                            <div className="note-description">
                                “Adding this to get extra support on how the engine works.”
                            </div>
                            <div className="note-metadata">
                                <div className="note-topic">
                                    <img src={mockUserImage} alt="" />
                                    <div className="topic">
                                        David on&nbsp;
                                        <div className="item-name">
                                            Facebook Deep Linking
                                        </div>
                                    </div>
                                </div>
                                <div className="note-creation-date"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </DetailMenuWrapper>
        </MenuWrapper>
    );
};

export default MatterMenu;

