import { getNonEmptyData, getParentId, searchsCollection } from "@patentedai/web/database";
import { doc, DocumentSnapshot, getDoc, onSnapshot } from "firebase/firestore";
import { SearchBase, SearchData } from "@patentedai/common/entities/Search";

const mapEntity = function (snapshot: DocumentSnapshot<SearchData>) {
    return new Search(snapshot.id, getParentId(snapshot), getNonEmptyData(snapshot));
};

export class Search extends SearchBase {
    static async getById(workspaceId: string, id: string) {
        const reference = doc(searchsCollection(workspaceId), id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            return null;
        }
        return mapEntity(snapshot);
    }
    static listenChanges(workspaceId: string, id: string, callback: (data: Search) => void) {
        const reference = doc(searchsCollection(workspaceId), id);
        return onSnapshot(reference, snapshot => {
            if (!snapshot.exists())
                return;
            callback(mapEntity(snapshot));
        });
    }
}