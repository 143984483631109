import { FormHelperText, InputLabel, OutlinedInput, FormControl as MaterialFormControl, OutlinedInputProps, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface FormControlProps extends OutlinedInputProps {
    label?: string;
    helperText?: string;
}

const FormControl = ({
    id,
    label,
    fullWidth,
    size,
    helperText,
    error,
    ...rest
}: FormControlProps) => {
    const classes = useStyles();

    return (
        <Stack spacing={"8px"}>
            {label &&
                <InputLabel
                    htmlFor={id}
                    style={{ fontSize: "12px" }}>
                    {label}
                </InputLabel>}
            <MaterialFormControl
                fullWidth={fullWidth}
                size={size}
                error={error}>
                <OutlinedInput id={id} {...rest} className={classes.input}></OutlinedInput>
            </MaterialFormControl>
            {helperText &&
                <FormHelperText
                    error={error}>
                    {helperText}
                </FormHelperText>}
        </Stack>
    );
};

const useStyles = makeStyles(() => ({
    input: {
        borderRadius: "8px",
    }
}));


export default FormControl;