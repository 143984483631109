// import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
// import { ReactComponent as GreaterThan } from "@patentedai/web/images/greaterThan.svg";
import { ProductPriorArtData } from "@patentedai/common/entities/ProductPriorArt";
import { OfflineSearchPatentBase } from "@patentedai/common/entities/OfflineSearchPatent";
import { SearchProductRelatedPatentBaseExtended } from "@patentedai/common/entities/SearchProductRelatedPatent";
import { SearchResultsViewMode } from "offlineSearch/OfflineSearchResultDetail";

interface SearchResultHeaderProps {
  priorArt: ProductPriorArtData
  patent?: OfflineSearchPatentBase | SearchProductRelatedPatentBaseExtended
  activeView: SearchResultsViewMode
  returnToPriorArtView?: (view: SearchResultsViewMode) => void
}

//TODO: UNMOCK PATENTSCORE
const SearchResultHeader = ({ priorArt, patent, activeView }: SearchResultHeaderProps) => {
  if (activeView === "PATENT" && patent) return (
    <SearchResultHeaderWrapper>
      <div className="columns">
        {/* <div className="row">
          <div className="breadcrumb">
            <Link className="previous" onClick={() => returnToPriorArtView?.("PRODUCT")}>
              {priorArt.productName}
            </Link>
            <GreaterThan />
            <div className="current">
              Related patent
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="match-meter">
            {`${patent.score ?? 0}%`}
          </div>
          <div className="publication-number">
            {patent?.priorArtPatentObject.publicationNumber}
          </div>
        </div>
      </div>
    </SearchResultHeaderWrapper>
  );
  return (
    <SearchResultHeaderWrapper>
      <div className="row">
        <div className="match-meter">
          {`${priorArt?.score ?? 0}%`}
        </div>
        <h1 className="product-name">
          {priorArt?.productName}
        </h1>
      </div>
    </SearchResultHeaderWrapper>
  );
};

const SearchResultHeaderWrapper = styled("div")`
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .columns{
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .row{
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .match-meter{
    display: flex;
    height: 31px;
    padding: 0px 8px;
    border-radius: 6px;
    background: var(--color-palette-red-100, #FB0F42);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
  }
  .product-name{
    color: var(--color-palette-grey-100, #1F2023);
    font-family: Manrope;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.3px;
  }
  .publication-number{
    color: #DF1440;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Manrope;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px; /* 100% */
    letter-spacing: 0.46px;
    display: flex;
    height: 32px;
    padding: 6px 10px 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    background: var(--color-palette-red-15, #FEE9ED);
    box-sizing: border-box;
  }
  .breadcrumb{
    display: flex;
    width: 350px;
    height: 31px;
    align-items: center;
    gap: 11px;
    .previous{
      color: var(--color-palette-grey-75, #505154);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px; /* 150% */
      letter-spacing: 0.9px;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }
    .current{
      color: var(--color-palette-grey-33, #B5B5B6);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px; /* 150% */
      letter-spacing: 0.9px;
      text-transform: uppercase;
    }
  }
`;

export default SearchResultHeader;
