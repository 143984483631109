import { useEffect, useState } from "react";
// @ts-ignore
import { ReactComponent as GoogleIcon } from "@patentedai/web/images/google.svg";
// @ts-ignore
import { ReactComponent as MicrosoftIcon } from "@patentedai/web/images/microsoft.svg";
// @ts-ignore
import { ReactComponent as TicketIcon } from "@patentedai/web/images/ticketIcon.svg";
import { getAuthorizationUrl } from "@patentedai/web/helpers/functionHelper";
import { AuthorizationProvider } from "@patentedai/common/api/Authorization";
import { ResponseStatus } from "@patentedai/common/api/Common";
import Button from "@patentedai/web/components/core/Button";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import { useNavigate, useLocation, Link } from "react-router-dom";
import * as Sentry from "@sentry/react";

const Signup = () => {
    const [isLoading, setIsLoading] = useState<AuthorizationProvider | null>(null);
    const { isUserAuthenticated } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orgId = queryParams.get("orgId");
    const orgName = queryParams.get("orgName");

    useEffect(() => {
        if (isUserAuthenticated())
            navigate("/patent", { replace: true });
    }, []);

    const handleSignUpWithProvider = async (provider: AuthorizationProvider) => {
        const state = orgId && orgName ? `orgId:${orgId}|orgName:${orgName}` : undefined;
        try {
            setIsLoading(provider);
            const response = await getAuthorizationUrl({ provider, state });
            if (response.status === ResponseStatus.Error)
                return;

            window.location.href = response.url;
        } catch (error: any) {
            console.log(error);
            Sentry.captureException(error);
            // Reset IsLoading is not in finally block because the redirect takes a long time on a slow network
            // and the user doesn't understand whats is happening
            setIsLoading(null);
        }
    };

    return (
        <div className="content">
            <h1 className="title-header">Apply for Access</h1>
            <h3 className="sub-title-header">Sign up below to request access</h3>
            <Button variant="outlined"
                className="button"
                textTransform="none"
                startIcon={<GoogleIcon />}
                onClick={() => handleSignUpWithProvider(AuthorizationProvider.Google)}
                showSpinner={isLoading === AuthorizationProvider.Google}>
                Sign up with Google
            </Button>
            <Button variant="outlined"
                className="button"
                textTransform="none"
                startIcon={<MicrosoftIcon />}
                onClick={() => handleSignUpWithProvider(AuthorizationProvider.Microsoft)}
                showSpinner={isLoading === AuthorizationProvider.Microsoft}>
                Sign up with Microsoft
            </Button>
            <div className="divider" />
            <div className="comment">
                <TicketIcon />
                <span>Already approved?</span>
                <Link to={"/signin"}>sign in</Link>
            </div>
        </div>
    );
};

export default Signup;