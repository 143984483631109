import { styled } from "@mui/material/styles";
import { ReactNode } from "react";

interface IconWithTextWrapperProps {
  truncateText?: boolean
  textColor?: string
}

const IconWithTextWrapper = styled("div") <IconWithTextWrapperProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({textColor}) => textColor ? textColor : "#8F8F91"};
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  ${({ truncateText }) => truncateText && `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `}
  .text-wrapper{
    flex: 1; /* To allow the text to take the remaining available space */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface IconWithTextProps extends IconWithTextWrapperProps {
  icon: ReactNode;
  text?: string | string[];
}

const IconWithText = ({ icon, text, truncateText = true, textColor }: IconWithTextProps) => (
  text && text.length > 0 ? (
    <IconWithTextWrapper truncateText={truncateText} textColor={textColor}>
      {icon}
      <div className="text-wrapper">
        {text}
      </div>
    </IconWithTextWrapper>
  ) : null
);

export default IconWithText;
