import { PatentData } from "./Patent";

interface OfflineSearchData {
    searchNumber: number;
    targetPatent: PatentData,
    selectedClaims: number[];
    createdAt: Date;
    updatedAt?: Date;
}

interface OfflineSearchBase extends OfflineSearchData {
    id: string;
}

class OfflineSearchBase {
    constructor(id: string, data: OfflineSearchData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): OfflineSearchData {
        const { id, ...data } = this;
        return data;
    }
}

export { OfflineSearchBase };
export type { OfflineSearchData };