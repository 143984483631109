import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OfflineSearchResultDetail from "./OfflineSearchResultDetail";
import { OfflineSearch } from "@patentedai/web/entities/OfflineSearch";
import { OfflineSearchProduct } from "@patentedai/web/entities/OfflineSearchProduct";
import { Backdrop } from "@mui/material";
import { Loading } from "@patentedai/web/components/core/Loading";
import { PriorArtDataComparer } from "@patentedai/common/entities/MatterProduct";
import * as Sentry from "@sentry/react";

const OfflineSearchContainer = () => {
    const routeParams = useParams<{ offlineSearchId: string, itemId?: string }>();
    const [offlineSearch, setOfflineSearch] = useState<OfflineSearch | undefined>(undefined);
    const [offlineSearchProducts, setOfflineSearchProducts] = useState<OfflineSearchProduct[]>([]);
    const [isLoadingOfflineSearchAndPatent, setIsLoadingOfflineSearchAndPatent] = useState(true);

    useEffect(() => {
        let isMounted = true;

        const getSearch = async () => {
            try {
                if (!routeParams.offlineSearchId) {
                    setOfflineSearch(undefined);
                    setOfflineSearchProducts([]);
                    return;
                }

                setIsLoadingOfflineSearchAndPatent(true);

                const [offlineSearch, offlineSearchProducts] = await Promise.all([
                    OfflineSearch.getById(routeParams.offlineSearchId),
                    OfflineSearchProduct.searchAll(routeParams.offlineSearchId)
                ]);
                if (!offlineSearch) {
                    return;
                }

                if (isMounted) {
                    setOfflineSearch(offlineSearch);
                    setOfflineSearchProducts(offlineSearchProducts.sort(PriorArtDataComparer));
                }
            } catch (error) {
                console.error(error);
                Sentry.captureException(error);
            }
            finally {
                setIsLoadingOfflineSearchAndPatent(false);
            }
        };

        getSearch();

        return () => {
            isMounted = false;
        };

    }, [routeParams.offlineSearchId]);


    if (isLoadingOfflineSearchAndPatent) {
        return (
            <Backdrop open={true}>
                <Loading />
            </Backdrop>
        );
    }

    if (!offlineSearch) {
        return (
            <div>
                Search not found!
            </div>
        );
    }
    if (!offlineSearchProducts?.length) {
        return (
            <div>
                Products not found!
            </div>
        );
    }

    return (
        <>
            <OfflineSearchResultDetail
                patent={offlineSearch.targetPatent}
                offlineSearch={offlineSearch}
                products={offlineSearchProducts}
            />
        </>
    );
};

export default OfflineSearchContainer;