
import { BootstrapMin, CustomCss, CustomStyles } from "./styles";

const Privacy = () => {
  return (
    <BootstrapMin>
    <CustomStyles>
      <CustomCss>
        <div>
          <section id="hero-privacy">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="top-area">
                    <h1>Privacy Policy</h1>
                    <p className="featured">Last updated Jun. 26, 2023</p>
                    <div className="blur" />
                    <div id="particles-js-privacy-hero" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="privacy-body">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-9">
                  <p>
                    This privacy notice (“Privacy Notice”) for Licensing, Inc.
                    DBA Patented.Ai (“Company,” “we,” “us,” or “our“), describes
                    how and why we might collect, store, use, and/or share
                    (“process“) your information when you use our Services. This
                    Privacy Notice is incorporated into and forms part of
                    Company’s Terms of Use (“Terms”), and incorporates the
                    definitions therein. If you do not agree with our policies
                    and practices, please do not use our Services. If you still
                    have any questions or concerns, please contact us at{" "}
                    <a href="mailto:legal@patented.ai">legal@patented.ai</a>.
                  </p>
                  <p>
                    As we release services and features, this Privacy Notice may
                    be updated per the provisions below.
                  </p>
                </div>
                <div className="col-12 col-md-10">
                  <div className="table-content">
                    <h3>Table of Contents</h3>
                    <div className="content-links">
                      <div>
                        <a href="#1">1. What Information Do We Collect?</a>
                        <a href="#2">2. How Do We Process Your Information?</a>
                        <a href="#3">
                          3. What Legal Bases Do We Rely On To Process Your
                          Personal Information?
                        </a>
                        <a href="#4">
                          4. When And With Whom Do We Share Your Personal
                          Information?
                        </a>
                        <a href="#5">
                          5. What Is Our Stance On Third-Party Websites?
                        </a>
                        <a href="#6">
                          6. Do We Use Cookies And Other Tracking Technologies?
                        </a>
                        <a href="#7">7. How Do We Handle Your Social Logins?</a>
                        <a href="#8">
                          8. Is Your Information Transferred Internationally?
                        </a>
                        <a href="#9">
                          9. How Long Do We Keep Your Information?
                        </a>
                      </div>
                      <div>
                        <a href="#10">
                          10. How Do We Keep Your Information Safe?
                        </a>
                        <a href="#11">
                          11. Do We Collect Information From Minors?
                        </a>
                        <a href="#12">12. What Are Your Privacy Rights?</a>
                        <a href="#13">13. Controls For Do-Not-Track Features</a>
                        <a href="#14">
                          14. Do California Residents Have Specific Privacy
                          Rights?
                        </a>
                        <a href="#15">
                          15. Do Virginia Residents Have Specific Privacy
                          Rights?
                        </a>
                        <a href="#16">16. Do We Make Updates To This Notice?</a>
                        <a href="#17">
                          17. How Can You Contact Us About This Notice?
                        </a>
                        <a href="#18">
                          18. How Can You Review, Update, Or Delete The Data We
                          Collect From You?
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-9">
                  <h3 id={"1"}>1. What Information Do We Collect?</h3>
                  <p>
                    <strong>In Short:</strong> We collect limited personal
                    information that you provide to us.
                  </p>
                  <p>
                    We collect personal information that you voluntarily provide
                    to us when you express an interest in obtaining information
                    about us or our products and Services, when you use the
                    Services, or otherwise when you contact us.
                  </p>
                  <p>
                    Personal Information Provided by You. The personal
                    information that we collect depends on the context of your
                    interactions with us and the Services, the choices you make,
                    and the products and features you use. The personal
                    information we collect may include the following:
                  </p>
                  <p>
                    Email address: when you register or sign up for information
                    regarding our products, services, company or related offers,
                    when you register for an account to consider, use or beta
                    test our Services.
                  </p>
                  <p>
                    Physical address and phone number: when you register for an
                    account to consider, use, beta test our Services.
                  </p>
                  <p>
                    Billing and payment information: when you use or beta test
                    our Services.
                  </p>
                  <p>
                    Third party login information: when you register using a
                    third party credential such as Google or Microsoft sign-on
                    services services.{" "}
                  </p>
                  <p>
                    Automatically collected information: Some information — such
                    as your Internet Protocol (IP) address and/or browser and
                    device characteristics — is collected automatically when you
                    visit our Services. We automatically collect certain
                    information when you visit, use, or navigate the Services.
                    This information does not reveal your specific identity
                    (like your name or contact information) but may include
                    device and usage information, such as your IP address,
                    browser and device characteristics, operating system,
                    language preferences, referring URLs, device name, country,
                    location, information about how and when you use our
                    Services, and other technical information. This information
                    is primarily needed to maintain the security and operation
                    of our Services, and for our internal analytics and
                    reporting purposes.
                  </p>
                  <p>
                    Cookie information: We collect information through cookies
                    and similar technologies, for site maintenance, security,
                    product development, marketing and analytics purposes.
                    Presently, cookies and tags used on our site include:
                  </p>
                  <p>
                    Google Analytics.{" "}
                    <a
                      href="https://policies.google.com/technologies/partner-sites"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Privacy Policy.
                    </a>{" "}
                    You can opt out via your{" "}
                    <a
                      href="https://myaccount.google.com/privacycheckup"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Privacy Settings
                    </a>{" "}
                    or via this{" "}
                    <a
                      href="https://tools.google.com/dlpage/gaoptout"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      opt-out link
                    </a>
                    .
                  </p>
                  <p>First party cookies for the purposes noted above.</p>
                  <p>
                    We do not process sensitive information, and You may not
                    submit sensitive information to us using the Services.
                  </p>
                  <p>
                    All personal information and payment information that you
                    provide to us must be true, complete, and accurate, and you
                    must notify us of any changes to such personal information.
                  </p>
                  <h5>The information we collect includes:</h5>
                  <p>
                    <strong>Log and Usage Data.</strong> Log and usage data is
                    service-related, diagnostic, usage, and performance
                    information our servers automatically collect when you
                    access or use our Services and which we record in log files.
                    Depending on how you interact with us, this log data may
                    include your IP address, device information, browser type,
                    and settings and information about your activity in the
                    Services (such as the date/time stamps associated with your
                    usage, pages and files viewed, searches, and other actions
                    you take such as which features you use), device event
                    information (such as system activity, error reports
                    (sometimes called “crash dumps”), and hardware settings).
                  </p>
                  <p>
                    <strong>Device Data.</strong> We collect device data such as
                    information about your computer, phone, tablet, or other
                    device you use to access the Services. Depending on the
                    device used, this device data may include information such
                    as your IP address (or proxy server), device and application
                    identification numbers, location, browser type, hardware
                    model, Internet service provider and/or mobile carrier,
                    operating system, and system configuration information.
                  </p>
                  <p>
                    <strong>Location Data.</strong> We may collect location data
                    such as information about your device’s location, which can
                    be either precise or imprecise. How much information we
                    collect depends on the type and settings of the device you
                    use to access the Services. For example, we may obtain
                    coarse location information based on your IP address or
                    other network data. You can opt out of allowing us to
                    collect this information using various means, such as by
                    refusing access to the information, masking your IP address,
                    or by disabling your Location setting on your device.
                    However, if you choose to opt out, you may not be able to
                    use certain aspects of the Services.
                  </p>
                  <p>
                    <strong>In Short:</strong> We may collect limited data from
                    public databases, marketing partners, social media
                    platforms, and other outside sources.
                  </p>
                  <p>
                    In order to enhance our ability to provide relevant
                    marketing, offers, and services to you and update our
                    records, we may obtain information about you from other
                    sources, such as public databases, joint marketing partners,
                    affiliate programs, data providers, social media platforms,
                    and from other third parties. This information includes
                    mailing addresses, job titles, email addresses, phone
                    numbers, intent data (or user behavior data), Internet
                    Protocol (IP) addresses, social media profiles, social media
                    URLs, and custom profiles, for purposes of targeted
                    advertising and event promotion. If you interact with us on
                    a social media platform using your social media account
                    (e.g., Facebook or Twitter), we receive personal information
                    about you such as your name, email address, and gender. Any
                    personal information that we collect from your social media
                    account depends on your social media account’s privacy
                    settings.
                  </p>
                  <p>
                    In order to provide payment services, we collect information
                    about you including your name, email address, physical
                    address and phone number, and share this with our payment
                    vendors to fulfill our contractual agreements with you.
                  </p>
                  <p>
                    In order to provide login via third party log-in services,
                    including Google and Microsoft Single SIgn-on, we may
                    receive certain information from those third parties,
                    including your name, email address, and an authorization
                    token. Your third party log-in can be revoked by you via
                    your preferences with those service providers or by closing
                    your account with us.
                  </p>
                  <h3 id={"2"}>2. How Do We Process Your Information?</h3>
                  <p>
                    <strong>In Short:</strong> We process your information to
                    provide, improve, and administer our Services, communicate
                    with you, for security and fraud prevention, and to comply
                    with law. We may also process your information for other
                    purposes with your consent.
                  </p>
                  <p>
                    We process your personal information for a variety of
                    reasons, depending on how you interact with our Services,
                    including:
                  </p>
                  <p>
                    <strong>
                      To facilitate account creation and authentication and
                      otherwise manage user accounts.
                    </strong>{" "}
                    We may process your information so you can create and log in
                    to your account, as well as keep your account in working
                    order.
                  </p>
                  <p>
                    <strong>
                      To deliver and facilitate delivery of services to the
                      user.
                    </strong>{" "}
                    We may process your information to provide you with the
                    requested service.
                  </p>
                  <p>
                    <strong>
                      To respond to user inquiries/offer support to users.
                    </strong>{" "}
                    We may process your information to respond to your inquiries
                    and solve any potential issues you might have with the
                    requested service.
                  </p>
                  <p>
                    <strong>To send administrative information to you.</strong>{" "}
                    We may process your information to send you details about
                    our products and services, changes to our terms and
                    policies, and other similar information.
                  </p>
                  <p>
                    <strong>To fulfill and manage your orders.</strong> We may
                    process your information to fulfill and manage your orders,
                    payments, reports, and usage of and made through the
                    Services
                  </p>
                  <p>
                    <strong>To request feedback.</strong> We may process your
                    information when necessary to request feedback and to
                    contact you about your use of our Services.
                  </p>
                  <p>
                    <strong>
                      To send you marketing and promotional communications.
                    </strong>{" "}
                    We may process the personal information you send to us for
                    our marketing purposes, if this is in accordance with your
                    marketing preferences. You can opt out of our marketing
                    emails at any time. For more information, see “WHAT ARE YOUR
                    PRIVACY RIGHTS?” below.
                  </p>
                  <p>
                    <strong>To protect our Services.</strong> We may process
                    your information as part of our efforts to keep our Services
                    safe and secure, including fraud monitoring and prevention.
                  </p>
                  <p>
                    <strong>To identify usage trends.</strong> We may process
                    information about how you use our Services to better
                    understand how they are being used so we can improve them.
                  </p>
                  <p>
                    <strong>
                      To determine the effectiveness of our marketing and
                      promotional campaigns.
                    </strong>{" "}
                    We may process your information to better understand how to
                    provide marketing and promotional campaigns that are most
                    relevant to you.
                  </p>
                  <p>
                    <strong>
                      To save or protect an individual’s vital interest.
                    </strong>{" "}
                    We may process your information when necessary to save or
                    protect an individual’s vital interest, such as to prevent
                    harm.
                  </p>
                  <h3 id={"3"}>
                    3. What Legal Bases Do We Rely On To Process Your Personal
                    Information?
                  </h3>
                  <p>
                    <strong>In Short:</strong> We only process your personal
                    information when we believe it is necessary and we have a
                    valid legal reason (i.e., legal basis) to do so under
                    applicable law, like with your consent, based on a
                    legitimate interest, to comply with laws, to provide you
                    with services to enter into or fulfill our contractual
                    obligations, to protect your rights, or to fulfill our
                    legitimate business interests.
                  </p>
                  <h5>
                    If you are located in the EU or UK, this section applies to
                    you.
                  </h5>
                  <p>
                    The General Data Protection Regulation (GDPR) and UK GDPR
                    require us to explain the valid legal bases we rely on in
                    order to process your personal information. As such, we may
                    rely on the following legal bases to process your personal
                    information:
                  </p>
                  <p>
                    <strong>Consent.</strong> We may process your information if
                    you have given us permission (i.e., consent) to use your
                    personal information for a specific purpose. You can
                    withdraw your consent at any time.
                  </p>
                  <ul>
                    <li>Support our marketing activities to you and others;</li>
                    <li>
                      To the extent we do so in the future, develop and display
                      personalized and relevant advertising content for our
                      users.
                    </li>
                  </ul>
                  <p>
                    <strong>Performance of a Contract.</strong> We may process
                    your personal information when we believe it is necessary to
                    fulfill our contractual obligations to you, including
                    providing our Services or at your request prior to entering
                    into a contract with you, including:
                  </p>
                  <ul>
                    <li>
                      Provide and support the Services as reasonably related to
                      fulfilling our Agreement with you.
                    </li>
                  </ul>
                  <p>
                    <strong>Legitimate Interests.</strong> We may process your
                    information when we believe it is reasonably necessary to
                    achieve our legitimate business interests and those
                    interests do not outweigh your interests and fundamental
                    rights and freedoms. For example, we may process your
                    personal information for some of the purposes described in
                    order to:
                  </p>
                  <ul>
                    <li>
                      Send users information about special offers and discounts
                      on our products and services
                    </li>
                    <li>
                      Analyze how our services are used so we can improve them
                      to engage and retain users
                    </li>
                    <li>
                      Diagnose problems and/or prevent fraudulent activities
                    </li>
                    <li>
                      Understand how our users use our products and services so
                      we can improve user experience
                    </li>
                  </ul>
                  <p>
                    <strong>Legal Obligations.</strong> We may process your
                    information where we believe it is necessary for compliance
                    with our legal obligations, such as to cooperate with a law
                    enforcement body or regulatory agency, exercise or defend
                    our legal rights, or disclose your information as evidence
                    in litigation in which we are involved.
                  </p>
                  <p>
                    <strong>Vital Interests.</strong> We may process your
                    information where we believe it is necessary to protect your
                    vital interests or the vital interests of a third party,
                    such as situations involving potential threats to the safety
                    of any person.
                  </p>
                  <p>
                    In legal terms, we are generally the “data controller” under
                    European data protection laws of the personal information
                    described in this privacy notice, since we determine the
                    means and/or purposes of the data processing we perform.
                    This privacy notice does not apply to the personal
                    information we process as a “data processor” on behalf of
                    our customers. In those situations, the customer that we
                    provide services to and with whom we have entered into a
                    data processing agreement is the “data controller”
                    responsible for your personal information, and we merely
                    process your information on their behalf in accordance with
                    your instructions. If you want to know more about our
                    customers’ privacy practices, you should read their privacy
                    policies and direct any questions you have to them.
                  </p>
                  <h5>
                    If you are located in Canada, this section applies to you.
                  </h5>
                  <p>
                    We may process your information if you have given us
                    specific permission (i.e., express consent) to use your
                    personal information for a specific purpose, or in
                    situations where your permission can be inferred (i.e.,
                    implied consent). You can withdraw your consent at any time.
                  </p>
                  <p>
                    In some exceptional cases, we may be legally permitted under
                    applicable law to process your information without your
                    consent, including, for example:
                  </p>
                  <ul>
                    <li>
                      If collection is clearly in the interests of an individual
                      and consent cannot be obtained in a timely way.
                    </li>
                    <li>
                      For investigations and fraud detection and prevention
                    </li>
                    <li>
                      For business transactions provided certain conditions are
                      met
                    </li>
                    <li>
                      If it is contained in a witness statement and the
                      collection is necessary to assess, process, or settle an
                      insurance claim
                    </li>
                    <li>
                      For identifying injured, ill, or deceased persons and
                      communicating with next of kin
                    </li>
                    <li>
                      If we have reasonable grounds to believe an individual has
                      been, is, or may be victim of financial abuse
                    </li>
                    <li>
                      If it is reasonable to expect collection and use with
                      consent would compromise the availability or the accuracy
                      of the information and the collection is reasonable for
                      purposes related to investigating a breach of an agreement
                      or a contravention of the laws of Canada or a province
                    </li>
                    <li>
                      If disclosure is required to comply with a subpoena,
                      warrant, court order, or rules of the court relating to
                      the production of records
                    </li>
                    <li>
                      If it was produced by an individual in the course of their
                      employment, business, or profession and the collection is
                      consistent with the purposes for which the information was
                      produced
                    </li>
                    <li>
                      If the collection is solely for journalistic, artistic, or
                      literary purposes
                    </li>
                    <li>
                      If the information is publicly available and is specified
                      by the regulations
                    </li>
                  </ul>
                  <h3 id={"4"}>
                    4. When And With Whom Do We Share Your Personal Information?
                  </h3>
                  <p>
                    <strong>In Short:</strong> We may share information in
                    specific situations described in this section and/or with
                    the following categories of third parties.
                  </p>
                  <p>
                    Vendors, Consultants, and Other Third-Party Service
                    Providers. We may share your data with third-party vendors,
                    service providers, contractors, or agents (“third parties”)
                    who perform services for us or on our behalf and require
                    access to such information to do that work. We have
                    contracts in place with our third parties, which are
                    designed to help safeguard your personal information. This
                    means that they cannot do anything with your personal
                    information unless we have instructed them to do it. They
                    will also not share your personal information with any
                    organization apart from us. They also commit to protect the
                    data they hold on our behalf and to retain it for the period
                    we instruct. The categories of third parties we may share
                    personal information with are as follows:
                  </p>
                  <ul>
                    <li>Affiliate and Email Services or Marketing Programs</li>
                    <li>Cloud Computing Services</li>
                    <li>Communication &amp; Collaboration Tools</li>
                    <li>Data Analytics Services</li>
                    <li>Data Storage Service Providers</li>
                    <li>Finance &amp; Accounting Tools</li>
                    <li>Government Entities</li>
                    <li>Performance Monitoring Tools</li>
                    <li>Product Engineering &amp; Design Tools</li>
                    <li>Sales &amp; Marketing Tools</li>
                    <li>Testing Tools</li>
                    <li>
                      User Account Registration &amp; Authentication Services
                    </li>
                    <li>Website Hosting Service Providers</li>
                  </ul>
                  <p>
                    We also may need to share your personal information in the
                    following situations:
                  </p>
                  <ul>
                    <li>
                      <strong>Business Transfers.</strong> We may share or
                      transfer your information in connection with, or during
                      negotiations of, any merger, sale of company assets,
                      financing, or acquisition of all or a portion of our
                      business to another company.
                    </li>
                    <li>
                      <strong>Affiliates.</strong> We may share your information
                      with our affiliates, in which case we will require those
                      affiliates to honor this privacy notice. Affiliates
                      include our parent company and any subsidiaries, joint
                      venture partners, or other companies that we control or
                      that are under common control with us.
                    </li>
                    <li>
                      <strong>Business Partners.</strong> We may share your
                      information with our business partners to offer you
                      certain products, services, or promotions.
                    </li>
                  </ul>
                  <p>
                    In particular, our major third party vendors who may receive
                    your personal information include:
                  </p>
                  <p className="pl-4">
                    Amazon (Amazon Web Services) –{" "}
                    <a
                      href="https://aws.amazon.com/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Notice
                    </a>
                  </p>
                  <p className="pl-4 mb-1">Google</p>
                  <p className="pl-5 mb-1">
                    Workspace –{" "}
                    <a
                      href="https://policies.google.com/technologies/partner-sites"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Statement
                    </a>
                  </p>
                  <p className="pl-5 mb-1">
                    Google Analytics –{" "}
                    <a
                      href="https://support.google.com/analytics/answer/6004245"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GA Privacy
                    </a>{" "}
                    –{" "}
                    <a
                      href="https://tools.google.com/dlpage/gaoptout"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GA Opt-out
                    </a>
                  </p>
                  <p className="pl-5">
                    Google Single Sign-on –{" "}
                    <a
                      href="https://support.google.com/accounts/answer/12921417?hl=en#:~:text=Sign%20in%20with%20Google%20doesn,to%20visit%20that%20third%20party."
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SSO Privacy
                    </a>{" "}
                    –{" "}
                    <a
                      href="https://myaccount.google.com/permissions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Managing SSO for our services
                    </a>
                  </p>
                  <p className="pl-4">
                    Mailchimp (Email and marketing services) –{" "}
                    <a
                      href="https://www.intuit.com/privacy/statement/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </p>
                  <p className="pl-4">
                    Microsoft (Single Sign-on) –{" "}
                    <a
                      href="https://privacy.microsoft.com/en-us/privacystatement"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Statement
                    </a>{" "}
                    –{" "}
                    <a
                      href="https://learn.microsoft.com/en-us/azure/active-directory/hybrid/connect/how-to-connect-sso-user-privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SSO Privacy
                    </a>{" "}
                    –{" "}
                    <a
                      href="http://account.microsoft.com/account"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Manage SSO access
                    </a>
                  </p>
                  <h3 id={"5"}>
                    5. What Is Our Stance On Third-Party Websites?
                  </h3>
                  <p>
                    <strong>In Short:</strong> We are not responsible for the
                    safety of any information that you share with third parties
                    that we may link to or who advertise on our Services, but
                    are not affiliated with, our Services.
                  </p>
                  <p>
                    The Services may link to third-party websites, online
                    services, or mobile applications and/or contain
                    advertisements from third parties that are not affiliated
                    with us and which may link to other websites, services, or
                    applications. Accordingly, we do not make any guarantee
                    regarding any such third parties, and we will not be liable
                    for any loss or damage caused by the use of such third-party
                    websites, services, or applications. The inclusion of a link
                    towards a third-party website, service, or application does
                    not imply an endorsement by us. We cannot guarantee the
                    safety and privacy of data you provide to any third parties.
                    Any data collected by third parties is not covered by this
                    privacy notice. We are not responsible for the content or
                    privacy and security practices and policies of any third
                    parties, including other websites, services, or applications
                    that may be linked to or from the Services. You should
                    review the policies of such third parties and contact them
                    directly to respond to your questions.
                  </p>
                  <h3 id={"6"}>
                    6. Do We Use Cookies And Other Tracking Technologies?
                  </h3>
                  <p>
                    <strong>In Short:</strong> We may use cookies and other
                    tracking technologies to collect and store your information.
                  </p>
                  <p>
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to access or store information.
                    Specific information about how we use such technologies and
                    how you can refuse certain cookies is set out in Section 1
                    above.
                  </p>
                  <h3 id={"7"}>7. How Do We Handle Your Social Logins?</h3>
                  <p>
                    <strong>In Short:</strong> You can log in to use the
                    Services via Google or Microsoft Single Sign-on.
                  </p>
                  <p>
                    We may receive and use your name, email address, and an
                    authorization token to associate these with your account,
                    provide the Services for the purposes and uses described in
                    Section 2. You can revoke these credentials via the settings
                    offered by Google or Microsoft, or by closing your account
                    with us.
                  </p>
                  <h3 id={"8"}>
                    8. Is Your Information Transferred Internationally?
                  </h3>
                  <p>
                    <strong>In Short:</strong> We are located in the United
                    States, and if you operate or live outside the U.S., we may
                    transfer, store, and process your information in countries
                    other than your own.
                  </p>
                  <p>
                    Our servers are located in the United States. If you are
                    accessing our Services from outside the United States,
                    please be aware that your information may be transferred to,
                    stored, and processed by us in our facilities and by those
                    third parties with whom we may share your personal
                    information (see “WHEN AND WITH WHOM DO WE SHARE YOUR
                    PERSONAL INFORMATION?” above), in the United States, and
                    other countries.
                  </p>
                  <p>
                    If you are a resident in the European Economic Area (EEA) or
                    United Kingdom (UK), then these countries may not
                    necessarily have data protection laws or other similar laws
                    as comprehensive as those in your country. However, we work
                    to take measures to protect your personal information in
                    accordance with this privacy notice and applicable law.
                  </p>
                  <p>
                    To protect our personal information, we may use measures
                    such as the European Commission’s Standard Contractual
                    Clauses.{" "}
                  </p>
                  <p>
                    For additional information, see the following parts of this
                    Privacy Notice:
                  </p>
                  <h6>
                    <a href="#1">WHAT INFORMATION DO WE COLLECT?</a>
                  </h6>
                  <p>
                    To learn more about the types of personal data Company
                    collects.
                  </p>
                  <h6>
                    <a href="#2">HOW DO WE PROCESS YOUR INFORMATION?</a>
                  </h6>
                  <p>
                    To learn more about the purposes for which Company collects
                    and uses personal information about you.
                  </p>
                  <h6>
                    <a href="#4">
                      WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </a>
                  </h6>
                  <p>
                    To learn more about the type or identity of third parties to
                    which Company discloses personal information, and the
                    purposes for which we do so.
                  </p>
                  <h6>
                    <a href="#12">WHAT ARE YOUR PRIVACY RIGHTS?</a>
                  </h6>
                  <p>
                    To learn more about the right of individuals to access their
                    personal data.
                  </p>
                  <h3 id={"9"}>9. How Long Do We Keep Your Information?</h3>
                  <p>
                    <strong>In Short:</strong> We keep your information for as
                    long as necessary to fulfill the purposes outlined in this
                    privacy notice unless otherwise required by law.
                  </p>
                  <p>
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal
                    requirements). No purpose in this notice will require us
                    keeping your personal information for longer than five
                    years, or a shorter period if required by law.
                  </p>
                  <p>
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete or
                    anonymize such information, or, if this is not possible (for
                    example, because your personal information has been stored
                    in backup archives), then we will securely store your
                    personal information and isolate it from any further
                    processing until deletion is possible.
                  </p>
                  <h3 id={"10"}>10. How Do We Keep Your Information Safe?</h3>
                  <p>
                    <strong>In Short:</strong> We aim to protect your personal
                    information through a system of organizational and technical
                    security measures.
                  </p>
                  <p>
                    We have implemented appropriate and reasonable technical and
                    organizational security measures designed to protect the
                    security of any personal information we process. However,
                    despite our safeguards and efforts to secure your
                    information, no electronic transmission over the Internet or
                    information storage technology can be guaranteed to be 100%
                    secure, so we cannot promise or guarantee that hackers,
                    cybercriminals, or other unauthorized third parties will not
                    be able to defeat our security and improperly collect,
                    access, steal, or modify your information. Although we will
                    do our best to protect your personal information,
                    transmission of personal information to and from our
                    Services is at your own risk. You should only access the
                    Services within a secure environment.
                  </p>
                  <h3 id={"11"}>11. Do We Collect Information From Minors?</h3>
                  <p>
                    <strong>In Short:</strong> Our Services are not available to
                    users below 18 years of age, are not intended for users
                    below 18 years of age, and we do not knowingly collect data
                    from or market to children under 18 years of age.
                  </p>
                  <p>
                    We do not knowingly solicit data from or market to children
                    under 18 years of age. By using the Services, you represent
                    that you are at least 18 or that you are the parent or
                    guardian of such a minor and consent to such minor
                    dependent’s use of the Services. If we learn that personal
                    information from users less than 18 years of age has been
                    collected, we will deactivate the account and take
                    reasonable measures to promptly delete such data from our
                    records. If you become aware of any data we may have
                    collected from children under age 18, please contact us at{" "}
                    <a href="mailto:legal@patented.ai">legal@patented.ai</a>.
                  </p>
                  <h3 id={"12"}>12. What Are Your Privacy Rights?</h3>
                  <p>
                    <strong>In Short:</strong> In some regions, such as the
                    European Economic Area (EEA), United Kingdom (UK), and
                    Canada, you have rights that allow you greater access to and
                    control over your personal information. You may review,
                    change, or terminate your account at any time.
                  </p>
                  <p>
                    In some regions (like the EEA, UK, and Canada), you have
                    certain rights under applicable data protection laws. These
                    may include the right (i) to request access and obtain a
                    copy of your personal information, (ii) to request
                    rectification or erasure; (iii) to restrict the processing
                    of your personal information; and (iv) if applicable, to
                    data portability. In certain circumstances, you may also
                    have the right to object to the processing of your personal
                    information. You can make such a request by contacting us by
                    using the contact details provided in the section “HOW CAN
                    YOU CONTACT US ABOUT THIS NOTICE?” below.
                  </p>
                  <p>
                    We will consider and act upon any request in accordance with
                    applicable data protection laws.
                  </p>
                  <p>
                    If you are located in the EEA or UK and you believe we are
                    unlawfully processing your personal information, you also
                    have the right to complain to your local data protection
                    supervisory authority. You can find their contact details
                    here:{" "}
                    <a
                      href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      edpb.europa.eu/about-edpb/about-edpb/members_en
                    </a>
                    .
                  </p>
                  <p>
                    If you are located in Switzerland, the contact details for
                    the data protection authorities are available here:{" "}
                    <a
                      href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.edoeb.admin.ch/edoeb/en/home.html
                    </a>
                    .
                  </p>
                  <p>
                    Withdrawing your consent: If we are relying on your consent
                    to process your personal information, which may be express
                    and/or implied consent depending on the applicable law, you
                    have the right to withdraw your consent at any time. You can
                    withdraw your consent at any time by contacting us by using
                    the contact details provided in the section{" "}
                    <a href="#17">
                      “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”
                    </a>{" "}
                    below or updating your preferences.
                  </p>
                  <p>
                    However, please note that this will not affect the
                    lawfulness of the processing before its withdrawal, nor when
                    applicable law allows, will it affect the processing of your
                    personal information conducted in reliance on lawful
                    processing grounds other than consent.
                  </p>
                  <p>
                    Opting out of marketing and promotional communications: You
                    can unsubscribe from our marketing and promotional
                    communications at any time by clicking on the unsubscribe
                    link in the emails that we send, replying “STOP” or
                    “UNSUBSCRIBE” to the SMS messages that we send, or by
                    contacting us using the details provided in the section “HOW
                    CAN YOU CONTACT US ABOUT THIS NOTICE?” below. You will then
                    be removed from the marketing lists, although removal may
                    take up to two weeks, and we may still communicate with you,
                    for example to send you service-related messages that are
                    necessary for the administration and use of your account, to
                    respond to service requests, or for other non-marketing
                    purposes.
                  </p>
                  <h5>Account Information</h5>
                  <p>
                    If we offer accounts, and you would at any time like to
                    review or change the information in your account or
                    terminate your account, you can:
                  </p>
                  <p>
                    Contact us using the contact information provided or, if
                    accounts are offered, via account settings.
                  </p>
                  <p>
                    Upon your request to terminate your account, we will
                    deactivate or delete your account. Email address and/or
                    information from our active databases. However, we may
                    retain some information in our files to prevent fraud,
                    troubleshoot problems, assist with any investigations,
                    enforce our legal terms and/or comply with applicable legal
                    requirements.
                  </p>
                  <p>
                    Cookies and similar technologies: Most Web browsers are set
                    to accept cookies by default. If you prefer, you can usually
                    choose to set your browser to remove cookies and to reject
                    cookies. If you choose to remove cookies or reject cookies,
                    this could affect certain features or services of our
                    Services. To opt out of interest-based advertising by
                    advertisers on our Services visit{" "}
                    <a
                      href="http://www.aboutads.info/choices/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.aboutads.info/choices/
                    </a>
                    . For further information, please see Section 1 above.
                  </p>
                  <p>
                    If you have questions or comments about your privacy rights,
                    you may email us at{" "}
                    <a href="mailto:legal@patented.ai">legal@patented.ai</a>.
                  </p>
                  <h3 id={"13"}>13. Controls For Do-Not-Track Features</h3>
                  <p>
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track (“DNT”) or Global
                    Privacy Controls (“GPC”) feature or setting you can activate
                    to signal your privacy preference not to have data about
                    your online browsing activities monitored and collected. As
                    standards for response to these features mature and we
                    implement our services, we will inform you about that
                    practice in this privacy notice.
                  </p>
                  <h3 id={"14"}>
                    14. Do California Residents Have Specific Privacy Rights?
                  </h3>
                  <p>
                    <strong>In Short:</strong> Yes, if you are a resident of
                    California, you are granted specific rights regarding access
                    to your personal information.
                  </p>
                  <p>
                    California Civil Code Section 1798.83, also known as the
                    “Shine The Light” law, permits our users who are California
                    residents to request and obtain from us, once a year and
                    free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for
                    direct marketing purposes and the names and addresses of all
                    third parties with which we shared personal information in
                    the immediately preceding calendar year. If you are a
                    California resident and would like to make such a request,
                    please submit your request in writing to us using the
                    contact information provided below.
                  </p>
                  <p>
                    If you are under 18 years of age, reside in California, and
                    have a registered account with Services, you have the right
                    to request removal of unwanted data that you publicly post
                    on the Services. To request removal of such data, please
                    contact us using the contact information provided below and
                    include the email address associated with your account and a
                    statement that you reside in California. We will make sure
                    the data is not publicly displayed on the Services, but
                    please be aware that the data may not be completely or
                    comprehensively removed from all our systems (e.g., backups,
                    etc.).
                  </p>
                  <h5>CCPA Privacy Notice</h5>
                  <p>
                    The California Code of Regulations defines a “resident” as:
                  </p>
                  <p>
                    <strong>(1)</strong> every individual who is in the State of
                    California for other than a temporary or transitory purpose
                    and
                  </p>
                  <p>
                    <strong>(2)</strong> every individual who is domiciled in
                    the State of California who is outside the State of
                    California for a temporary or transitory purpose{" "}
                  </p>
                  <p>All other individuals are defined as “non-residents.”</p>
                  <p>
                    If this definition of “resident” applies to you, we must
                    adhere to certain rights and obligations regarding your
                    personal information.
                  </p>
                  <p>What categories of personal information do we collect?</p>
                  <p>
                    We have collected the following categories of personal
                    information in the past twelve (12) months:
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Description</th>
                        <th>Collected &amp; Time period kept</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>A. Identifiers</td>
                        <td>
                          Contact details, such as real name, alias, postal
                          address, telephone or mobile contact number, unique
                          personal identifier, online identifier, Internet
                          Protocol address, email address, and account name.{" "}
                        </td>
                        <td>
                          YES <br />
                          Up to 5 years
                        </td>
                      </tr>
                      <tr>
                        <td>
                          B. Personal information per California Customer
                          Records Statute
                        </td>
                        <td>
                          Name in combination with statutory categories of
                          contact information, education, employment, employment
                          history, and financial information
                        </td>
                        <td>YES</td>
                      </tr>
                      <tr>
                        <td>
                          C. Protected classification under California or
                          Federal law
                        </td>
                        <td>Gender, date of birth</td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>D. Commercial information</td>
                        <td>
                          Transaction information, purchase history, financial
                          details, and payment information
                        </td>
                        <td>
                          YES <br />
                          Up to 7 years or as required by applicable tax laws
                        </td>
                      </tr>
                      <tr>
                        <td>E. Biometric information</td>
                        <td>
                          Fingerprints, voiceprints, eye prints, or facial
                          recognition
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>F. Internet or other similar network activity</td>
                        <td>
                          Browsing history, search history, online behavior,
                          interest data, and interactions with our and other
                          websites, applications, systems, and advertisements
                        </td>
                        <td>
                          YES <br />
                          Up to 5 years
                        </td>
                      </tr>
                      <tr>
                        <td>G. Geolocation data</td>
                        <td>Device location</td>
                        <td>
                          YES <br />
                          Up to 5 years
                        </td>
                      </tr>
                      <tr>
                        <td>
                          H. Audio, electronic, visual, thermal, olfactory, or
                          similar information
                        </td>
                        <td>
                          Images and audio, video or call recordings created in
                          connection with our business activities{" "}
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>
                          I. Professional or employment-related information
                        </td>
                        <td>
                          Business contact details in order to provide you our
                          services at a business level or job title, work
                          history, and professional qualifications if you apply
                          for a job with us{" "}
                        </td>
                        <td>
                          YES <br />
                          Up to 7 years after employment
                        </td>
                      </tr>
                      <tr>
                        <td>J. Education information</td>
                        <td>Student records and directory information</td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>
                          K. Inferences drawn from other personal information
                        </td>
                        <td>
                          Inferences drawn from any of the collected personal
                          information listed above to create a profile or
                          summary about, for example, an individual’s
                          preferences and characteristics
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>L. Sensitive personal information</td>
                        <td>
                          Account login information, drivers’ licenses, health
                          data, precise geolocation, racial or ethnic origin,
                          religious or philosophical beliefs, and sex life or
                          sexual orientation
                        </td>
                        <td>NO</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    We may also collect other personal information outside of
                    these categories instances where you interact with us in
                    person, online, or by phone or mail in the context of:
                  </p>
                  <ul>
                    <li>
                      Receiving help through our customer support channels;
                    </li>
                    <li>Participation in customer surveys or contests; and</li>
                    <li>
                      Facilitation in the delivery of our Services, beta tests,
                      and to respond to your inquiries.
                    </li>
                  </ul>
                  <p>How do we use and share your personal information?</p>
                  <p>
                    The Company collects and shares your personal information
                    through:
                  </p>
                  <ul>
                    <li>First party cookies</li>
                    <li>Analytics, targeting and/or marketing cookies</li>
                    <li>Beacons, Pixels, or Tags</li>
                  </ul>
                  <p>
                    More information about our data collection and sharing
                    practices can be found in this privacy notice in Section 1
                    above.
                  </p>
                  <p>
                    You may contact us by email at{" "}
                    <a href="mailto:legal@patented.ai">legal@patented.ai</a>, or
                    by referring to the contact details at the bottom of this
                    document.
                  </p>
                  <p>
                    If you are using an authorized agent to exercise your right
                    to opt out we may deny a request if the authorized agent
                    does not submit proof that they have been validly authorized
                    to act on your behalf.
                  </p>
                  <p>Will your information be shared with anyone else?</p>
                  <p>
                    We may disclose your personal information with our service
                    providers pursuant to a written contract between us and each
                    service provider. Each service provider is a for-profit
                    entity that processes the information on our behalf,
                    following the same strict privacy protection obligations
                    mandated by the CCPA.
                  </p>
                  <p>
                    We may use your personal information for our own business
                    purposes, such as for undertaking internal research for
                    technological development and demonstration. This is not
                    considered to be “selling” of your personal information.
                  </p>
                  <p>
                    Patented.ai has not sold or shared any personal information
                    to third parties for a business or commercial purpose in the
                    preceding twelve (12) months. Your rights with respect to
                    your personal data
                  </p>
                  <p>
                    Right to request deletion of the data — Request to delete
                  </p>
                  <p>
                    You can ask for the deletion of your personal information.
                    If you ask us to delete your personal information, we will
                    respect your request and delete your personal information,
                    subject to certain exceptions provided by law, such as (but
                    not limited to) the exercise by another consumer of his or
                    her right to free speech, our compliance requirements
                    resulting from a legal obligation, or any processing that
                    may be required to protect against illegal activities.
                  </p>
                  <p>Right to be informed — Request to know</p>
                  <p>
                    Depending on the circumstances, you have a right to know:
                  </p>
                  <ul>
                    <li>
                      whether we collect and use your personal information;
                    </li>
                    <li>
                      the categories of personal information that we collect;
                    </li>
                    <li>
                      the purposes for which the collected personal information
                      is used;
                    </li>
                    <li>
                      whether we sell or share personal information to third
                      parties;
                    </li>
                    <li>
                      the categories of personal information that we sold,
                      shared, or disclosed for a business purpose;
                    </li>
                    <li>
                      the categories of third parties to whom the personal
                      information was sold, shared, or disclosed for a business
                      purpose;
                    </li>
                    <li>
                      the business or commercial purpose for collecting,
                      sharing, or selling personal information; and
                    </li>
                    <li>
                      the specific pieces of personal information we collected
                      about you.
                    </li>
                    <li>
                      In accordance with applicable law, we are not obligated to
                      provide or delete consume
                    </li>
                    <li>
                      information that is de-identified in response to a
                      consumer request or to re-identify individual data to
                      verify a consumer request.
                    </li>
                  </ul>
                  <p>
                    Right to Non-Discrimination for the Exercise of a Consumer’s
                    Privacy Rights:
                  </p>
                  <p>
                    We will not discriminate against you if you exercise your
                    privacy rights.
                  </p>
                  <p>
                    Right to Limit Use and Disclosure of Sensitive Personal
                    Information:
                  </p>
                  <p>
                    We do not process consumer’s sensitive personal information.
                  </p>
                  <h5>Verification process:</h5>
                  <p>
                    Upon receiving your request, we will need to verify your
                    identity to determine you are the same person about whom we
                    have the information in our system. These verification
                    efforts require us to ask you to provide information so that
                    we can match it with information you have previously
                    provided us. For instance, depending on the type of request
                    you submit, we may ask you to provide certain information so
                    that we can match the information you provide with the
                    information we already have on file, or we may contact you
                    through a communication method (e.g., phone or email) that
                    you have previously provided to us. We may also use other
                    verification methods as the circumstances dictate.
                  </p>
                  <p>
                    We will only use personal information provided in your
                    request to verify your identity or authority to make the
                    request. To the extent possible, we will avoid requesting
                    additional information from you for the purposes of
                    verification. However, if we cannot verify your identity
                    from the information already maintained by us, we may
                    request that you provide additional information for the
                    purposes of verifying your identity and for security or
                    fraud-prevention purposes. We will delete such additionally
                    provided information as soon as we finish verifying you.
                  </p>
                  <h5>Other privacy rights:</h5>
                  <ul>
                    <li>
                      You may object to the processing of your personal
                      information.{" "}
                    </li>
                    <li>
                      You may request correction of your personal data if it is
                      incorrect or no longer relevant, or ask to restrict the
                      processing of the information.
                    </li>
                    <li>
                      You can designate an authorized agent to make a request
                      under the CCPA on your behalf. We may deny a request from
                      an authorized agent that does not submit proof that they
                      have been validly authorized to act on your behalf in
                      accordance with the CCPA.
                    </li>
                  </ul>
                  <h3 id={"15"}>
                    15. Do Virginia Residents Have Specific Privacy Rights?
                  </h3>
                  <p>
                    <strong>In Short:</strong> Yes, if you are a resident of
                    Virginia, you may be granted specific rights regarding
                    access to and use of your personal information.
                  </p>
                  <h5>Virginia CDPA Privacy Notice</h5>
                  <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
                  <p>
                    “Consumer” means a natural person who is a resident of the
                    Commonwealth acting only in an individual or household
                    context. It does not include a natural person acting in a
                    commercial or employment context.
                  </p>
                  <p>
                    “Personal data” means any information that is linked or
                    reasonably linkable to an identified or identifiable natural
                    person. “Personal data” does not include de-identified data
                    or publicly available information.
                  </p>
                  <p>
                    “Sale of personal data” means the exchange of personal data
                    for monetary consideration.
                  </p>
                  <p>
                    If this definition of “consumer” applies to you, we must
                    adhere to certain rights and obligations regarding your
                    personal data.
                  </p>
                  <p>
                    The information we collect, use, and disclose about you will
                    vary depending on how you interact with Company and our
                    Services. To find out more, please visit the following
                    sections above:
                  </p>
                  <h6>
                    <a href="#1">WHAT INFORMATION DO WE COLLECT?</a>
                  </h6>
                  <h6>
                    <a href="#2">HOW DO WE PROCESS YOUR INFORMATION?</a>
                  </h6>
                  <h6>
                    <a href="#4">
                      WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </a>
                  </h6>
                  <br />
                  <p>Your rights with respect to your personal data</p>
                  <ul>
                    <li>
                      Right to be informed whether or not we are processing your
                      personal data
                    </li>
                    <li>Right to access your personal data</li>
                    <li>Right to correct inaccuracies in your personal data</li>
                    <li>Right to request deletion of your personal data</li>
                    <li>
                      Right to obtain a copy of the personal data you previously
                      shared with us
                    </li>
                    <li>
                      Right to opt out of the processing of your personal data
                      if it is used for targeted advertising, the sale of
                      personal data, or profiling in furtherance of decisions
                      that produce legal or similarly significant effects
                      ([“profiling”/’profiling’])
                    </li>
                  </ul>
                  <p>
                    Company has not sold any personal data to third parties for
                    business or commercial purposes as defined under Virginia
                    law.{" "}
                  </p>
                  <p>Exercise your rights provided under the Virginia CDPA</p>
                  <p>
                    More information about our data collection and sharing
                    practices can be found in this privacy notice in Section 1
                    above.
                  </p>
                  <p>
                    You may contact us by email at{" "}
                    <a href="mailto:legal@patented.ai">legal@patented.ai</a> or
                    by referring to the contact details at the bottom of this
                    document.
                  </p>
                  <p>
                    If you are using an authorized agent to exercise your
                    rights, we may deny a request if the authorized agent does
                    not submit proof that they have been validly authorized to
                    act on your behalf.
                  </p>
                  <h5>Verification process</h5>
                  <p>
                    We may request that you provide additional information
                    reasonably necessary to verify you and your consumer’s
                    request. If you submit the request through an authorized
                    agent, we may need to collect additional information to
                    verify your identity before processing your request.
                  </p>
                  <p>
                    Upon receiving your request, we will respond without undue
                    delay, but in all cases, within forty-five (45) days of
                    receipt. The response period may be extended once by
                    forty-five (45) additional days when reasonably necessary.
                    We will inform you of any such extension within the initial
                    45-day response period, together with the reason for the
                    extension.
                  </p>
                  <h5>Right to appeal</h5>
                  <p>
                    If we decline to take action regarding your request, we will
                    inform you of our decision and reasoning behind it. If you
                    wish to appeal our decision, please email us at [Email
                    Address]. Within sixty (60) days of receipt of an appeal, we
                    will inform you in writing of any action taken or not taken
                    in response to the appeal, including a written explanation
                    of the reasons for the decisions. If your appeal is denied,
                    you may contact the Attorney General to submit a complaint.
                  </p>
                  <h3 id={"16"}>16. Do We Make Updates To This Notice?</h3>
                  <p>
                    <strong>In Short:</strong> Yes, we will update this notice
                    as necessary to stay compliant with relevant laws.
                  </p>
                  <p>
                    We may update this privacy notice from time to time, such as
                    upon beta tests, product launches, feature updates, business
                    changes, or changes in applicable laws and regulations. The
                    updated version will be indicated by an updated “Revised”
                    date and the updated version will be effective as soon as it
                    is accessible. If we make material changes to this privacy
                    notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a
                    notification. We encourage you to review this privacy notice
                    frequently to be informed of how we are protecting your
                    information.
                  </p>
                  <p>
                    This Privacy Notice in part uses, and is substantially
                    modified in form from, a Privacy Notice template provided by
                    Termly.
                  </p>
                  <h3 id={"17"}>
                    17. How Can You Contact Us About This Notice?
                  </h3>
                  <p>
                    If you have questions or comments about this notice, you may
                    contact us via email at{" "}
                    <a href="mailto:legal@patented.ai">legal@patented.ai</a>, or
                    by mail at:
                  </p>
                  <p>
                    <strong>LICENSING, INC D/B/A Patented.ai</strong> <br />
                    177 Huntington Ave #1700 PMB 91559 <br />
                    Boston, MA 02115
                  </p>
                  <h3 id={"18"}>
                    18. How Can You Review, Update, Or Delete The Data We
                    Collect From You?
                  </h3>
                  <p>
                    Based on the applicable laws of your country, you may have
                    the right to request access to the personal information we
                    collect from you, change that information, or delete it in
                    some circumstances. To request to review, update, or delete
                    your personal information, please contact us at{" "}
                    <a href="mailto:legal@patented.ai">legal@patented.ai</a>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </CustomCss>
    </CustomStyles>
    </BootstrapMin>
  );
};

export default Privacy;
