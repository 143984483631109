import { getNonEmptyData, offlineSearchesCollection } from "@patentedai/web/database";
import { doc, DocumentSnapshot, getDoc } from "firebase/firestore";
import { OfflineSearchBase, OfflineSearchData } from "@patentedai/common/entities/OfflineSearch";

const mapEntity = function (snapshot: DocumentSnapshot<OfflineSearchData>) {
    return new OfflineSearch(snapshot.id, getNonEmptyData(snapshot));
};

export class OfflineSearch extends OfflineSearchBase {
    static async getById(id: string) {
        const reference = doc(offlineSearchesCollection, id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            return null;
        }
        return mapEntity(snapshot);
    }
}