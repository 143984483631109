import { ResponseStatus } from "@patentedai/common/api/Common";
import {
  approveWaitlistUser,
  getWaitlistUsers,
} from "@patentedai/web/helpers/functionHelper";
import { useEffect, useState } from "react";
import { Loading } from "@patentedai/web/components/core/Loading";
import { Backdrop } from "@mui/material";
import Button from "@patentedai/web/components/core/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { WaitlistStatus } from "@patentedai/common/entities/Waitlist";
import { WaitlistUser } from "@patentedai/common/api/WaitList";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Product from "./product";
import Log from "./log";
import * as Sentry from "@sentry/react";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FBFAFF;",
    border: "1px solid #E7E7E7",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "12px",
    textAlign: "center",
    color: "#404246",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Admin = () => {
  const [isLoadingWaitlist, setIsLoadingWaitlist] = useState(true);
  const [waitlistUsers, setWaitlistUsers] = useState<WaitlistUser[]>([]);
  const [allowingUserEmail, setAllowingUserEmail] = useState<string | null>(
    null
  );
  const [value, setValue] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(event);
    setValue(newValue);
  };

  useEffect(() => {
    const searchWaitlist = async () => {
      try {
        setIsLoadingWaitlist(true);

        const response = await getWaitlistUsers();
        if (response.status !== ResponseStatus.Success) {
          setWaitlistUsers([]);
          return;
        }

        setWaitlistUsers(response.waitlist);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      } finally {
        setIsLoadingWaitlist(false);
      }
    };

    searchWaitlist();
  }, []);

  const handleAllowUser = (waitlistUser: string) => async () => {
    try {
      setAllowingUserEmail(waitlistUser);

      const response = await approveWaitlistUser({ email: waitlistUser });
      if (response.status !== ResponseStatus.Success) {
        return;
      }
      setWaitlistUsers(
        waitlistUsers.map((user) =>
          user.email === waitlistUser
            ? { email: user.email, status: WaitlistStatus.Approved }
            : user
        )
      );
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setAllowingUserEmail(null);
    }
  };

  if (isLoadingWaitlist)
    return (
      <Backdrop open={true}>
        <Loading />
      </Backdrop>
    );

  return (
    <div className="prior-art-list-container">
      <div className="content center-flex">
        <div className="list">
          <h1 className="title-header">Admin Dashboard</h1>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Waitlist" />
                <Tab label="Logs" />
                <Tab label="Products" />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <h2>Waitlist</h2>
              <TableContainer
                sx={{ height: "200px", width: "100%", marginTop: "20px" }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {waitlistUsers.map((waitlistUser, index) => (
                      <TableRow key={index}>
                        <StyledTableCell>{waitlistUser.email}</StyledTableCell>
                        <StyledTableCell>
                          {waitlistUser.status === WaitlistStatus.Approved ? (
                            <Chip
                              label="Allowed"
                              color="success"
                              variant="outlined"
                            />
                          ) : (
                            <Button
                              variant="outlined"
                              onClick={handleAllowUser(waitlistUser.email)}
                              disabled={!!allowingUserEmail}
                              showSpinner={
                                allowingUserEmail === waitlistUser.email
                              }
                            >
                              Allow
                            </Button>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Log />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Product />
            </CustomTabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Admin;
