import { Link } from "@mui/material";
import Button from "@patentedai/web/components/core/Button";
// @ts-ignore
import unionLogoBG from "@patentedai/web/images/unionLogoBG.svg";
import { useEffect, useState } from "react";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import PatentCard from "./PatentCard";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import makeSytles from "@mui/styles/makeStyles";
import { getPatent } from "@patentedai/web/helpers/functionHelper";
import { ResponseStatus } from "@patentedai/common/api/Common";
import { Backdrop } from "@mui/material";
import { Loading } from "@patentedai/web/components/core/Loading";
import * as Sentry from "@sentry/react";

const useStyles = makeSytles({
  inputOutline: {
    display: "flex",
    padding: "10px",
    background: "rgba(223, 20, 64, 0.04)",
    borderRadius: "20px",
    justifyContent: "center",
    alignItems: "center",
  },

  contentBackground: {
    backgroundImage: `url(${unionLogoBG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    backgroundSize: "auto",
  }
});

const PatentSearchResult = () => {
  const routeParams = useParams<"patentNumber">();
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [patent, setPatent] = useState<ReducedPatentData | null>(location.state?.patent || null);
  const [inventorName, setInventorName] = useState("");
  const [inventorMismatch, setInventorMismatch] = useState<boolean | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("author");
    setInventorName(query || "");
  }, [location.search]);

  useEffect(() => {
    if (patent) return;

    const handleSearchPatents = async () => {
      try {
        if (!routeParams.patentNumber)
          throw new Error("Missing patent number");

        const response = await getPatent({ patentPublicationNumber: routeParams.patentNumber });
        if (response.status !== ResponseStatus.Success) {
          setPatent(null);
          throw new Error(response.message);
        }
        setPatent(response.patent);
      } catch (error) {
        setIsError(true);
        console.log(error);
        Sentry.captureException(error);
      }
    };
    handleSearchPatents();
  }, [patent, routeParams.patentNumber]);

  useEffect(() => {
    if (!patent) return;

    function hasInventorMismatch(searchedInventor: string, patentInventors: string[]): boolean {
      const searchedNames: string[] = normalizeName(searchedInventor);

      return !patentInventors.some(patentInventor => {
        const patentNames: string[] = normalizeName(patentInventor);
        return searchedNames.every(name => patentNames.includes(name));
      });
    }

    function normalizeName(name: string): string[] {
      return name
        .toLowerCase()
        .split(/[\s,]+/)
        .filter(part => part.length > 0);
    }

    setInventorMismatch(inventorName ? hasInventorMismatch(inventorName, patent.authors) : false);
  }, [patent, inventorName]);

  const handleSearchInventorPatents = async () => {
    navigate(`/patent/inventor/${inventorName}`,
      {
        state: {
          patentNumber: patent?.publicationNumber
        }
      }
    );
  };

  const handleResetSearch = () => {
    navigate("/patent");
  };

  function capitalizeNames(names: string) {
    return names
      .split(" ")
      .map(name => name.charAt(0).toUpperCase() + name.slice(1))
      .join(" ");
  }

  if (!isError && (!patent || inventorMismatch === null)) {
    return (
      <Backdrop open={true}>
        <Loading />
      </Backdrop>
    );
  }

  const handleConfirmPatent = (patent: ReducedPatentData) => {
    navigate(`/prior-art/${patent.publicationNumber}/selectClaim`,
      {
        state: {
          patent
        }
      }
    );
  };

  if (patent && !inventorMismatch) {
    return (
      <div className="patent-container">
        <div className="content">
          <div id="confirm-search" className="patent-content-confirm">
            <h1 className="title-header">Confirm Patent</h1>
            <h3 className="sub-title-header">Confirm the selected patent, and let’s go for the final step.</h3>
            <div className={`${classes.inputOutline}`}>
              <PatentCard patent={patent} />
            </div>
            <Button
              className="search-button-smart-filter button-common"
              variant="contained"
              onClick={() => handleConfirmPatent(patent)}>
              Confirm Patent
            </Button>
            <div className="comment">
              <span style={{ marginLeft: 10 }} >
                OR&nbsp;
              </span>
              <Link
                component="button"
                className=""
                onClick={handleResetSearch}>
                RESET SEARCH
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else if (patent && inventorMismatch) {
    return (
      <div className="patent-container">
        <div className="content">
          <div id="confirm-search" className="patent-content-confirm">
            <h1 className="title-header">Ooops! We found a mismatch</h1>
            <h3 className="sub-title-header">The patent doesn’t belong to <span className="inventor-name">&nbsp;{capitalizeNames(inventorName)}.&nbsp;</span> What should we do next?</h3>
            <div className={`${classes.inputOutline}`}>
              <PatentCard patent={patent} />
            </div>
            <div className="cta-row">
              <Button
                className="search-button-smart-filter button-common"
                variant="contained"
                onClick={() => handleConfirmPatent(patent)}>
                Confirm Patent
              </Button>
              <Button
                className="inventor-patents-button button-common"
                variant="outlined"
                onClick={handleSearchInventorPatents}>
                Inventor's Patents
              </Button>
              <div className="comment">
                <span>
                  OR&nbsp;
                </span>
                <Link
                  component="button"
                  className=""
                  onClick={handleResetSearch}>
                  RESET SEARCH
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else return (
    <div className="patent-container">
      <div className="content">
        <div id="confirm-search" className="patent-content-confirm">
          <h1 className="title-header">Something went wrong</h1>
          <h3 className="sub-title-header">Please reset the search and try again.</h3>
          <div className="comment">
            <Link
              component="button"
              className=""
              onClick={handleResetSearch}>
              RESET SEARCH
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatentSearchResult;