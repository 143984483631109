import { SearchProductBase } from "@patentedai/common/entities/SearchProduct";
import { OfflineSearchProduct } from "@patentedai/web/entities/OfflineSearchProduct";

export const checkSubstringInEither = (string1?: string, string2?: string) => {
  if (typeof string1 === "string" && typeof string2 === "string") {
      return string1.includes(string2) || string2.includes(string1);
  }
  return false;
};

export const sortProductsByDateAndName = (a: OfflineSearchProduct | SearchProductBase, b: OfflineSearchProduct | SearchProductBase) => {
  if (!a.stringedReleaseDate && !b.stringedReleaseDate) {
      return a.productName.localeCompare(b.productName);
  }

  if (!a.stringedReleaseDate) return 1; // Move undefined or empty strings to the end
  if (!b.stringedReleaseDate) return -1; // Move undefined or empty strings to the end

  const dateA = isDateValid(a.stringedReleaseDate) ? new Date(a.stringedReleaseDate) : new Date("1900-01-01");
  const dateB = isDateValid(b.stringedReleaseDate) ? new Date(b.stringedReleaseDate) : new Date("1900-01-01");

  const dateComparison = dateB.getTime() - dateA.getTime();

  return dateComparison !== 0 ? dateComparison : a.productName.localeCompare(b.productName);

};

function decodeHTMLEntities(text: string) {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = text;
  return textarea.value;
}

export const filterText = (text?: string, publicationNumber?:string) => {
  const targetPatentRegex = /target patent/ig;
  const plainEnglishRegex = /plain\s+english/ig;
  const scenarioRegex = /@scenario/g;
  const possiblePatentRegex = /possible patent/i;
  const sharpRegex = /[#]/g;

  const newText = decodeHTMLEntities(text?.replace(targetPatentRegex, "")
      .replace(plainEnglishRegex, "")
      .replace(scenarioRegex, "")
      .replace(sharpRegex, "")
      .replace(possiblePatentRegex, match => publicationNumber || match) || "");
  return newText.trim();
};

export const extractUMLCode = (inputString: string) => {
  const startTag = "@startuml";
  const endTag = "@enduml";

  const regex = new RegExp(`${startTag}(.*?${endTag})`, "gs");
  const match = regex.exec(inputString);

  if (match && match[1]) {
      return match[1].replace(/{|}/g, "");
  } else {
      return "";
  }
};

const isDateValid = (dateString: string) => {
  if (!dateString) return false;
  return new Date(dateString).toString() !== "Invalid Date";
};