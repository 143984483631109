import { ClaimElementData } from "@patentedai/common/entities/Patent";

interface ClaimsCardProps {
    claim: ClaimElementData;
}

const ClaimsCard = ({ claim }: ClaimsCardProps) => {
    return (
        <div className="card-content">
            <div className="content">
                <ClaimElement element={claim} key={claim.id} />
            </div>
        </div>
    );
};

const ClaimElement = ({ element }: { element: ClaimElementData }) => {
    return (
        <>
            <div className="element" >
                <div className="number">
                    {element.id}
                </div>
                <div className="claim">
                    {element.element}
                </div>
            </div>
            {
                element.subElements.map((subElement, index) => (
                    <div className="subelement" key={index}>
                        <div className="number">
                            {subElement.id}
                        </div>
                        <div className="claim">
                            {subElement.subElement}
                        </div>
                    </div>
                ))
            }
        </>
    );
};

export default ClaimsCard;