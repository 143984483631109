import { styled } from "@mui/material/styles";
import { CalendarIcon } from "@patentedai/web/images/CalendarIcon";
import { BriefcaseIcon } from "@patentedai/web/images/BriefcaseIcon";
import { AuthorIcon } from "@patentedai/web/images/AuthorIcon";
import { DateTime } from "luxon";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";

const MatterCardWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  .matter-number-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .publication-number{
      display: flex;
      padding: 6px 10px 6px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: rgba(251, 15, 66, 0.07);
      color: var(--color-palette-red-dark, #DF1440);
      font-family: Manrope;
      font-size: 23px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0.46px;
      margin: 0;
    }
    .publication-date{
      display: flex;
      flex-direction: row;
      gap: 8px;

      color: var(--color-palette-grey-50, #8F8F91);
      font-family: Inter;
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
    }
  }
  .matter-title{
    color: var(--color-palette-grey-100, #1F2023);
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.18px;
  }
  .matter-divider{
    background-color: var(--color-palette-grey-15, #DDDEDE);
    width: 100%;
    min-height: 1px;
  }
  .matter-info{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-self: stretch;
    color: var(--color-palette-grey-50, #8F8F91);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.8px;
    text-transform: uppercase;

    .info{
      display: flex;
      gap: 8px;
    }
  }

`;

interface PatentMenuCardProps {
  patent?: ReducedPatentData;
}

const PatentMenuCard = ({ patent }: PatentMenuCardProps) => (patent ?
  <MatterCardWrapper>
    <div className="matter-number-row">
      <p className="publication-number">{patent.publicationNumber}</p>
      <div className="publication-date">
        <CalendarIcon color="#505154" />{patent?.priorityDate && DateTime.fromFormat(patent.priorityDate, "yyyyMMdd").toFormat("yyyy, MMM dd")}
      </div>
    </div>
    <div className="matter-title">
      {patent.title}
    </div>
    <div className="matter-divider" />
    <div className="matter-info">
      <div className="info">
        {patent.assignee && <>
          <BriefcaseIcon color="#505154" />
          {patent.assignee}
        </>}
      </div>
      <div className="info">
        <AuthorIcon />
        {patent.authors[0]}
      </div>
    </div>
  </MatterCardWrapper>
  : <></>
);

export default PatentMenuCard;