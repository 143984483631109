import { ReactComponent as PatentIcon } from "@patentedai/web/images/patentIcon.svg";
import { ReactComponent as ProductIcon } from "@patentedai/web/images/productIcon.svg";
import { styled } from "@mui/material/styles";
import Button from "@patentedai/web/components/core/Button";
import { SearchResultsViewMode } from "offlineSearch/OfflineSearchResultDetail";


interface SidebarResultTabsProps {
  selectedTab: SearchResultsViewMode;
  handleSelectTab: (newTab: SearchResultsViewMode) => void;
}

const SidebarResultTabs = ({ handleSelectTab, selectedTab }: SidebarResultTabsProps) => {
  const onSelectTab = (newTab: SearchResultsViewMode) => {
    handleSelectTab(newTab);
  };

  return (
    <SidebarResultTabsWrapper>
      <SidebarTabButton
        className={selectedTab === "PRODUCT" ? "selected" : "not-selected"}
        onClick={() => onSelectTab("PRODUCT")}
        textTransform="none"
      >
        <div className="text">
          <ProductIcon /> Product
        </div>
      </SidebarTabButton>
      {false && <SidebarTabButton className={selectedTab === "PATENT" ? "selected" : "not-selected"} onClick={() => onSelectTab("PATENT")} textTransform="none">
        <div className="text">
          <PatentIcon /> Patent
        </div>
      </SidebarTabButton>}
    </SidebarResultTabsWrapper>
  );
};

const SidebarResultTabsWrapper = styled("div")`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--invalidate-palette-grey-5, #f1f3f3);
`;

const SidebarTabButton = styled(Button)`
  display: flex;
  padding: 15px 25px 15px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 4px;

  &:hover {
  background-color: #fafbfb; /* Change hover color here */
}
.MuiTouchRipple-child{
  background-color: #fdfdfd;
}

  &.selected {
    background: #fff;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 15px 15px 0px rgba(0, 0, 0, 0.03),
      0px 34px 20px 0px rgba(0, 0, 0, 0.02), 0px 60px 24px 0px rgba(0, 0, 0, 0.01), 0px 93px 26px 0px rgba(0, 0, 0, 0);
  }
  &.not-selected {
    opacity: 0.75;
  }
  .text {
    display: flex;
    align-items: center;
    gap: 15px;
    color: var(--invalidate-palette-grey-100, #1f2023);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Manrope;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.15px;
  }
`;

export default SidebarResultTabs;
