import Button from "@patentedai/web/components/core/Button";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import PurgeAIEngineCacheModal from "./components/PurgeAIEngineCacheModal";
import DeleteProductModal from "./components/DeletePriorArtModal";
import Box from "@mui/material/Box";
import { useState } from "react";

const AdminTables = styled("div")`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  h2 {
    margin: 30px 0px 0px;
  }
`;

const Product = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteProduct, setOpenDeleteProduct] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDeleteProduct = () => {
    setOpenDeleteProduct(true);
  };

  const handleCloseDeleteProduct = () => {
    setOpenDeleteProduct(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AdminTables>
        <FormControl
          fullWidth
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ marginBottom: "20px" }}>Products</h2>
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleOpen}
              style={{ width: "250px", height: 38 }}
            >
              Purge AI Engine Cache
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleOpenDeleteProduct}
              style={{ width: "250px", height: 38 }}
            >
              Delete Prior Art
            </Button>
          </div>
        </FormControl>
      </AdminTables>
      <PurgeAIEngineCacheModal handleClose={handleClose} open={open} />
      <DeleteProductModal
        handleClose={handleCloseDeleteProduct}
        open={openDeleteProduct}
      />
    </Box>
  );
};

export default Product;
