import { useEffect, DependencyList } from "react";

export function useDebouncedEffect(
    effect: () => void,
    deps: DependencyList,
    delay: number,
): void {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);
        return () => clearTimeout(handler);
    }, [...deps, delay]);
}