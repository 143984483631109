import CircularProgress from "@mui/material/CircularProgress";

interface LoadingProps {
    color?: string;
}

export const Loading = ({ color }: LoadingProps) => {
    return (
        <div style={{ width: "100%", textAlign: "center", color: color }}>
            <CircularProgress color="inherit" />
        </div>
    );
};