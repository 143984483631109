import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { Link } from "@mui/material";
// @ts-ignore
import { BriefcaseIcon } from "@patentedai/web/images/BriefcaseIcon";
import { CalendarIcon } from "@patentedai/web/images/CalendarIcon";
import Divider from "@patentedai/web/components/core/Divider";
import { DateTime } from "luxon";

interface PatentCardProps {
    patents: ReducedPatentData[];
    handlePatentSearch: any
}

const InventorPatentList = ({ patents, handlePatentSearch }: PatentCardProps) => {
    return (
        <div className="card-content patent-card inventor-patent-list  styled-scroll">
            {patents.map(patent => (
                <div className="inventor-patent">
                    <div className="row">
                        <Link
                            component="button"
                            className="patent-title"
                            underline="hover"
                            onClick={() => handlePatentSearch(patent)}
                        >
                            {patent.title}
                        </Link>
                        <div className="publication-number">{patent.publicationNumber}</div>
                    </div>
                    <Divider />
                    <div className="row">
                        <div className="assignee"><BriefcaseIcon color="#8F8F91" />{patent.assignee}</div>
                        <div className="publication-date"><CalendarIcon color="#000000" /> FILED ON: {DateTime.fromFormat(patent.publicationDate, "yyyyMMdd").toFormat("MMM d, yyyy")}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default InventorPatentList;

