import ClaimsTable from "./components/ClaimsTable";
import { useSearch } from "contexts/SearchContext";
import { SelectedClaims_T } from "./SearchCriteria";

interface ClaimSelectionCriteriaProps {
  selectedClaims: SelectedClaims_T
  isClaimSelected: (claimId: number) => boolean
}

const ClaimSelectionCriteria = ({selectedClaims, isClaimSelected}: ClaimSelectionCriteriaProps) => {
  const { patent } = useSearch();

  if(!patent) return null;
  return (
    <div className="list">
      <div className="top-area claim-top-area">
        <h1 className="title-header">Claim Selection</h1>
        <h2 className="subtitle">Almost there! Select the Claims you wish to add in the Prior-Art Search.</h2>
      </div>
      <ClaimsTable
        claims={Object.keys(selectedClaims).flatMap((claimId) => {
          const matchingClaim = patent.claims.find((claim) => claim.id === parseInt(claimId));
          return matchingClaim || [];
        })}
        isClaimSelected={isClaimSelected}
      />
    </div>
  );
};

export default ClaimSelectionCriteria;
