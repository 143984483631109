import { Timestamp, FirestoreDataConverter, DocumentData, QueryDocumentSnapshot } from "firebase/firestore";

export class TimestampConverter<T extends DocumentData>
    implements FirestoreDataConverter<DocumentData>
{
    toFirestore(modelObject: DocumentData): DocumentData {
        return modelObject;
    }

    fromFirestore(snapshot: QueryDocumentSnapshot): T {
        const data = snapshot.data();

        const isObject = (value: any) => Object.prototype.toString.call(value) === "[object Object]";
        const isArray = (value: any) => Array.isArray(value);

        const resolveValue = (value: any, key: string, object: { [x: string]: any; }) => {
            if (value instanceof Timestamp) {
                object[key] = value.toDate();
            } else if (isArray(value) || isObject(value)) {
                go(value);
            }
        };

        const go = (object: any) => {
            if (isArray(object)) {
                object.forEach((value: any, i: any, array: any) => resolveValue(value, i, array));
            } else if (isObject(object)) {
                Object.keys(object).forEach(key => resolveValue(object[key], key, object));
            }
        };

        go(data);

        return data as T;
    }
}