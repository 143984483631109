import { ProductPriorArtData } from "./ProductPriorArt";

interface MatterProductData extends ProductPriorArtData {
    relatedSearch: {
        id: string;
        searchNumber: number;
    }
    createdAt: Date;
    updatedAt?: Date;
}

interface MatterProductBase extends MatterProductData {
    id: string;
    workspaceId: string;
    matterId: string;
}

class MatterProductBase {
    constructor(id: string, workspaceId: string, matterId: string, data: MatterProductData) {
        this.id = id;
        this.workspaceId = workspaceId;
        this.matterId = matterId;
        Object.assign(this, data);
    }
    toData(): MatterProductData {
        const { id, workspaceId, matterId, ...data } = this;
        return data;
    }
}

const PriorArtDataComparer = (a: ProductPriorArtData, b: ProductPriorArtData) => {
    if (a.score < b.score) {
        return 1;
    }
    if (a.score > b.score) {
        return -1;
    }

    if ((a.stringedReleaseDate ?? "") < (b.stringedReleaseDate ?? "")) {
        return 1;
    }
    if ((a.stringedReleaseDate ?? "") > (b.stringedReleaseDate ?? "")) {
        return -1;
    }

    if (a.productName < b.productName) {
        return -1;
    }
    if (a.productName > b.productName) {
        return 1;
    }

    return 0;
};

export { MatterProductBase, PriorArtDataComparer };
export type { MatterProductData };