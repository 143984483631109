import { DateTime } from "luxon";

export function getDatePlusSeconds(date: Date, seconds: number) {
    return DateTime.fromJSDate(date).plus({ seconds }).toJSDate();
}

export function getDatePlusMinutes(date: Date, minutes: number) {
    return DateTime.fromJSDate(date).plus({ minutes }).toJSDate();
}

export function getDatePlusHours(date: Date, hours: number) {
    return DateTime.fromJSDate(date).plus({ hours }).toJSDate();
}

export function getDatePlusDays(date: Date, days: number) {
    return DateTime.fromJSDate(date).plus({ days }).toJSDate();
}

export function getMinutesSince(startDate: Date) {
    const now = new Date();
    if (startDate.getTime() > now.getTime()) {
        return 0;
    }

    return DateTime.fromJSDate(now).diff(DateTime.fromJSDate(startDate), "minutes").minutes;
}
