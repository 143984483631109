import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { Checkbox, Link } from "@mui/material";
import { ReactComponent as CheckboxIcon } from "@patentedai/web/images/checkbox.svg";
import { ReactComponent as CheckboxCheckedIcon } from "@patentedai/web/images/checkboxChecked.svg";
import { ReactComponent as YourSelection } from "@patentedai/web/images/yourSelection.svg";
import { styled } from "@mui/material/styles";

interface SelectedClaimsListProps {
  patent: ReducedPatentData;
  claimStatusList: { [claimID: number]: boolean };
  showSelectAllButton?: boolean
  onClaimSelected: (claimId: number) => void;
  onSelectAllClaims?: () => void;
}

const SelectedClaimsList = ({ patent, claimStatusList, onClaimSelected, onSelectAllClaims, showSelectAllButton = true }: SelectedClaimsListProps) => {

  const selectAllButtonText =
    Object.keys(claimStatusList).length === patent.claims.length ? "Deselect All" : "Select All";

  const handleClaimCheck = (claimId: number) => {
    onClaimSelected(claimId);
  };

  const selectAllClaims = () => {
    onSelectAllClaims?.();
  };

  return (
    <SelectedClaimsWrapper>
      {showSelectAllButton && (
        <div className="select-all-row">
          <div className="your-selection-text"><YourSelection />Your selection</div>
          <Link
            component="button"
            className="select-claims-button"
            onClick={selectAllClaims}>
            {selectAllButtonText}
          </Link>
        </div>
      )}
      <div className="selected-claims-list">
        {Object.entries(claimStatusList).map(([claimId, isSelected]) => {
          const claim = patent.claims.find((claim) => claim.id === parseInt(claimId));
          if (!claim) return null;
          return (
            <div
              key={claim.id}
              className="claim-item"
            >
              <div className="claim-number">
                <Checkbox
                  checked={isSelected}
                  onChange={() => handleClaimCheck(claim.id)}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
                <p>{`Claim ${claim.id}`}</p>
              </div>
              <div className="claim-summary">
                ELEMENTS
                <div className="sub-element">{claim.id}</div>
                {claim.subElements.map(subElement => (
                  <div className="sub-element" key={subElement.id} >{subElement.id}</div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </SelectedClaimsWrapper>
  );
};

const SelectedClaimsWrapper = styled("div")`
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  overflow: hidden;
  .select-all-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    .your-selection-text{
      svg{
        margin-right: 10px;
      }
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;

      /* Invalidate Brand/Dark Grey (brand) */

      color: #1F2023;
    }
    .select-claims-button{
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 10px;
      line-height: 10px;
      /* identical to box height, or 100% */

      display: flex;
      align-items: flex-end;
      text-align: center;
      letter-spacing: 0.15em;
      text-transform: uppercase;

      /* Invalidate UI/Contrast Red */

      color: #DF1440;
    }
  }
  .selected-claims-list{
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-self: stretch;
    gap: 20px;
    .claim-item{
      display: flex;
      padding: 2px 0px 20px 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border-bottom: 1px solid var(--color-palette-grey-15, #DDDEDE);
      .claim-number{
        display: flex;
        align-items: center;
        gap: 12px;
        .MuiCheckbox-root{
          padding: 0;
        }
        p{
          margin: 0;
          font-family: Inter;
          font-weight: 500;
          font-size: 14px;
          letter-spacing: -0.01em;
          color: #1F2023;
        }
      }
      .claim-summary{
        display: flex;
        align-items: center;
        gap: 12px;
        color: var(--color-palette-grey-50, #8F8F91);
        text-align: center;
        font-family: Manrope;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 10px; /* 100% */
        letter-spacing: 1.5px;
        text-transform: uppercase;
        .sub-element{
          display: flex;
        width: 22px;
        height: 22px;
        padding-bottom: 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 555px;
        background: var(--color-palette-grey-15, #DDDEDE);
        color: var(--color-palette-grey-100, #1F2023);
        text-align: center;
        font-family: Manrope;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        }
      }
    }
  }
`;

export default SelectedClaimsList;