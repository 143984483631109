import { getFunctions, httpsCallable } from "firebase/functions";
import firebaseApp from "@patentedai/web/firebaseApp";
import { GetAuthorizationUrlParameters, GetAuthorizationUrlResponse, GetProfileParameters, GetProfileResponse, UpdateProfileParameters } from "@patentedai/common/api/Authorization";
import { UpdateUserNameParameters } from "@patentedai/common/api/User";
import { GetPatentParameters, GetPatentResponse, SearchPatentsByInventorParameters, SearchPatentsByInventorResponse, SearchPatentsParameters, SearchPatentsResponse } from "@patentedai/common/api/Patent";
import { GetWaitlistUsersResponse, ApproveWaitlistUserParams, AddToWaitlistParameters } from "@patentedai/common/api/WaitList";
import { DeletePriorArtParameters, DeletePriorArtsCacheParameters, GeneratePriorArtsParameters, GeneratePriorArtsResponse, SearchPriorArtsParameters, SearchPriorArtsResponse } from "@patentedai/common/api/PriorArt";
import { GetProcessingStatusParameters, GetProcessingStatusResponse } from "@patentedai/common/api/ProcessingStatus";
import { CreateSearchParameters, CreateSearchResponse, GetSearchProductRelatedPatentsParameters, GetSearchProductRelatedPatentsResponse, GetSearchProductsParameters, GetSearchProductsResponse, GetSearchesParameters, GetSearchesResponse } from "@patentedai/common/api/Search";
import { CreateMatterParameters, CreateMatterResponse, GetMattersParameters, GetMattersResponse } from "@patentedai/common/api/Matter";
import { GetLogsParameters, GetLogsResponse } from "@patentedai/common/api/Log";
import { CreateDefaultWorkspaceParams, CreateDefaultWorkspaceResponse, GetWorkspaceMembersParams, GetWorkspaceMembersResponse, InviteMemberParams, InviteMemberResponse, JoinToWorkspaceParams, JoinToWorkspaceResponse, UpdateWorkspaceMemberRoleParams, UpdateWorkspaceNameParams } from "@patentedai/common/api/Workspace";
import { SuccessResponse, ErrorResponse } from "@patentedai/common/api/Common";
import { timestampsToDates } from "./serializationHelper";

const functions = getFunctions(firebaseApp);

const authFunctionsPrefix = "auth";
const userFunctionsPrefix = "user";
const patentFunctionsPrefix = "patent";
const priorArtFunctionsPrefix = "prior_art";
const searchFunctionsPrefix = "search";
const matterFunctionsPrefix = "matter";
const waitListFunctionsPrefix = "wait_list";
const workspaceFunctionsPrefix = "workspace";
const processingStatusPrefix = "processing_status";
const logPrefix = "log";

export const getAuthorizationUrl = async (params: GetAuthorizationUrlParameters): Promise<GetAuthorizationUrlResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetAuthorizationUrlParameters, GetAuthorizationUrlResponse | ErrorResponse>(functions, `${authFunctionsPrefix}-getAuthorizationUrl`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};
export const getProfile = async (params: GetProfileParameters): Promise<GetProfileResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetProfileParameters, GetProfileResponse | ErrorResponse>(functions, `${authFunctionsPrefix}-getProfile`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};
export const updateProfile = async (params: UpdateProfileParameters): Promise<SuccessResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<UpdateProfileParameters, SuccessResponse | ErrorResponse>(functions, `${authFunctionsPrefix}-updateProfile`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};

export const updateName = async (params: UpdateUserNameParameters): Promise<SuccessResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<UpdateUserNameParameters, SuccessResponse | ErrorResponse>(functions, `${userFunctionsPrefix}-updateName`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};

export const addToWaitlist = async (params: AddToWaitlistParameters): Promise<SuccessResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<AddToWaitlistParameters, SuccessResponse | ErrorResponse>(functions, `${waitListFunctionsPrefix}-addToWaitlist`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};

export const getWaitlistUsers = async (): Promise<GetWaitlistUsersResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<object, GetWaitlistUsersResponse | ErrorResponse>(functions, `${waitListFunctionsPrefix}-getWaitlistUsers`);
    const httpResult = await callableFunction();
    return httpResult.data;
};
export const approveWaitlistUser = async (params: ApproveWaitlistUserParams): Promise<SuccessResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<ApproveWaitlistUserParams, SuccessResponse | ErrorResponse>(functions, `${waitListFunctionsPrefix}-approveWaitlistUser`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};

export const createDefaultWorkspace = async (params: CreateDefaultWorkspaceParams): Promise<CreateDefaultWorkspaceResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<CreateDefaultWorkspaceParams, CreateDefaultWorkspaceResponse | ErrorResponse>(functions, `${workspaceFunctionsPrefix}-createDefaultWorkspace`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};
export const updateWorkspaceName = async (params: UpdateWorkspaceNameParams): Promise<SuccessResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<UpdateWorkspaceNameParams, SuccessResponse | ErrorResponse>(functions, `${workspaceFunctionsPrefix}-updateWorkspaceName`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};
export const inviteMember = async (params: InviteMemberParams): Promise<InviteMemberResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<InviteMemberParams, InviteMemberResponse | ErrorResponse>(functions, `${workspaceFunctionsPrefix}-inviteMember`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};
export const joinToWorkspace = async (params: JoinToWorkspaceParams): Promise<JoinToWorkspaceResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<JoinToWorkspaceParams, JoinToWorkspaceResponse | ErrorResponse>(functions, `${workspaceFunctionsPrefix}-joinToWorkspace`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};
export const updateMemberRole = async (params: UpdateWorkspaceMemberRoleParams): Promise<SuccessResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<UpdateWorkspaceMemberRoleParams, SuccessResponse | ErrorResponse>(functions, `${workspaceFunctionsPrefix}-updateMemberRole`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};
export const getMembers = async (params: GetWorkspaceMembersParams): Promise<GetWorkspaceMembersResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetWorkspaceMembersParams, GetWorkspaceMembersResponse | ErrorResponse>(functions, `${workspaceFunctionsPrefix}-getMembers`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as GetWorkspaceMembersResponse;
};


export const getPatent = async (params: GetPatentParameters): Promise<GetPatentResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetPatentParameters, GetPatentResponse | ErrorResponse>(functions, `${patentFunctionsPrefix}-getPatent`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as GetPatentResponse;
};
export const searchPatents = async (params: SearchPatentsParameters): Promise<SearchPatentsResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<SearchPatentsParameters, SearchPatentsResponse | ErrorResponse>(functions, `${patentFunctionsPrefix}-searchPatents`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as SearchPatentsResponse;
};
export const searchPatentsByInventor = async (params: SearchPatentsByInventorParameters): Promise<SearchPatentsByInventorResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<SearchPatentsByInventorParameters, SearchPatentsByInventorResponse | ErrorResponse>(functions, `${patentFunctionsPrefix}-searchPatentsByInventor`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as SearchPatentsByInventorResponse;
};

export const generatePriorArts = async (params: GeneratePriorArtsParameters): Promise<GeneratePriorArtsResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GeneratePriorArtsParameters, GeneratePriorArtsResponse | ErrorResponse>(functions, `${priorArtFunctionsPrefix}-generatePriorArts`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};


export const deletePriorArtsCache = async (params: DeletePriorArtsCacheParameters): Promise<SuccessResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<DeletePriorArtsCacheParameters, SuccessResponse | ErrorResponse>(functions, `${priorArtFunctionsPrefix}-deletePriorArtsCache`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};


export const deletePriorArt = async (params: DeletePriorArtParameters): Promise<SuccessResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<DeletePriorArtParameters, SuccessResponse | ErrorResponse>(functions, `${priorArtFunctionsPrefix}-deletePriorArt`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};



export const searchPriorArts = async (params: SearchPriorArtsParameters): Promise<SearchPriorArtsResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<SearchPriorArtsParameters, SearchPriorArtsResponse | ErrorResponse>(functions, `${priorArtFunctionsPrefix}-searchPriorArts`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as SearchPriorArtsResponse;
};

export const getProcessingStatus = async (params: GetProcessingStatusParameters): Promise<GetProcessingStatusResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetProcessingStatusParameters, GetProcessingStatusResponse | ErrorResponse>(functions, `${processingStatusPrefix}-getProcessingStatus`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};

export const createSearch = async (params: CreateSearchParameters): Promise<CreateSearchResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<CreateSearchParameters, CreateSearchResponse | ErrorResponse>(functions, `${searchFunctionsPrefix}-createSearch`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as CreateSearchResponse;
};
export const getSearches = async (params: GetSearchesParameters): Promise<GetSearchesResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetSearchesParameters, GetSearchesResponse | ErrorResponse>(functions, `${searchFunctionsPrefix}-getSearches`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as GetSearchesResponse;
};
export const getSearchProducts = async (params: GetSearchProductsParameters): Promise<GetSearchProductsResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetSearchProductsParameters, GetSearchProductsResponse | ErrorResponse>(functions, `${searchFunctionsPrefix}-getSearchProducts`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as GetSearchProductsResponse;
};
export const getSearchProductRelatedPatents = async (params: GetSearchProductRelatedPatentsParameters): Promise<GetSearchProductRelatedPatentsResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetSearchProductRelatedPatentsParameters, GetSearchProductRelatedPatentsResponse | ErrorResponse>(functions, `${searchFunctionsPrefix}-getSearchProductRelatedPatents`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as GetSearchProductRelatedPatentsResponse;
};

export const getLogs = async (params: GetLogsParameters): Promise<GetLogsResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetLogsParameters, GetLogsResponse | ErrorResponse>(functions, `${logPrefix}-getLogs`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as GetLogsResponse;
};

export const getSearchLogs = async (params: GetLogsParameters): Promise<GetLogsResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetLogsParameters, GetLogsResponse | ErrorResponse>(functions, `${logPrefix}-getSearchLogs`);
    const httpResult = await callableFunction(params);
    return httpResult.data;
};


export const createMatter = async (params: CreateMatterParameters): Promise<CreateMatterResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<CreateMatterParameters, CreateMatterResponse | ErrorResponse>(functions, `${matterFunctionsPrefix}-createMatter`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as CreateMatterResponse;
};
export const getMatters = async (params: GetMattersParameters): Promise<GetMattersResponse | ErrorResponse> => {
    const callableFunction = httpsCallable<GetMattersParameters, GetMattersResponse | ErrorResponse>(functions, `${matterFunctionsPrefix}-getMatters`);
    const httpResult = await callableFunction(params);
    const data = httpResult.data as any;
    timestampsToDates(data);
    return data as GetMattersResponse;
};