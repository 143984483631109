import { useState } from "react";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { MenuWrapper } from "./MenuWrapper";
import { Accordion, AccordionSummary, AccordionDetails, } from "@mui/material";
import Button from "@patentedai/web/components/core/Button";
import PatentMenuCard from "./PatentMenuCard";
import SelectedClaimsList from "./components/SelectedClaimsList";
import { CriteriaPages_T } from "searchs/criteria/SearchCriteria";

interface SelectedClaimsListProps {
  patent: ReducedPatentData;
  claimStatusList: { [claimID: number]: boolean };
  onClaimSelected: (claimId: number) => void;
  onChangeCriteriaPage: (nextPage: CriteriaPages_T) => void;
}

const SearchCriteriaMenu = ({ patent, claimStatusList, onClaimSelected, onChangeCriteriaPage }: SelectedClaimsListProps) => {
  type AccordionStates = CriteriaPages_T | false;
  const [expandedAccordion, setExpandedAccordion] = useState<AccordionStates>();

  const handleChangeAccordion = (panel: AccordionStates) => {
    setExpandedAccordion(prevState => prevState === panel ? false : panel);
    if(panel){
      onChangeCriteriaPage(panel);
    }
  };

  const handleClaimCheck = (claimId: number) => {
    onClaimSelected(claimId);
  };

  return (
    <MenuWrapper>
      <div className="claim-selection-menu">
        <PatentMenuCard patent={patent} />
        <Accordion
          expanded={expandedAccordion === "CLAIM_SELECTION"}
          onChange={() => handleChangeAccordion("CLAIM_SELECTION")}
        >
          <AccordionSummary>
            Claim Selection
          </AccordionSummary>
          <AccordionDetails>
            <SelectedClaimsList
              claimStatusList={claimStatusList}
              onClaimSelected={handleClaimCheck}
              patent={patent}
              showSelectAllButton={false}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedAccordion === "CLAIM_COMPONENTS"}
          onChange={() => handleChangeAccordion("CLAIM_COMPONENTS")}
        >
          <AccordionSummary>
            Claim Components
          </AccordionSummary>
          <AccordionDetails>
            Mock info 1
            Mock info 2
            Mock info 3
            Mock info 4
          </AccordionDetails>
        </Accordion>
        <div className="navigation-buttons">
          <Button
            variant="contained"
            className="apply-selection"
          >
            Check Search Status
          </Button>
        </div>
      </div>
    </MenuWrapper>
  );
};

export default SearchCriteriaMenu;

