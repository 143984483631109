import { getFirestore, CollectionReference, collection, DocumentData, DocumentSnapshot } from "firebase/firestore";
import { TimestampConverter } from "./TimestampConverter";
import { UserData } from "@patentedai/common/entities/User";
import { WorkspaceData } from "@patentedai/common/entities/Workspace";
import { SearchData } from "@patentedai/common/entities/Search";
import { OfflineSearchData } from "@patentedai/common/entities/OfflineSearch";
import { MatterData } from "@patentedai/common/entities/Matter";
import { MatterProductData } from "@patentedai/common/entities/MatterProduct";
import { OfflineSearchProductData } from "@patentedai/common/entities/OfflineSearchProduct";
import { OfflineSearchPatentData } from "@patentedai/common/entities/OfflineSearchPatent";


const firestore = getFirestore();

const createCollection = <T extends DocumentData = DocumentData>(path: string) => {
    const result = collection(firestore, path) as CollectionReference<T>;
    return result.withConverter(new TimestampConverter<T>());
};

export const usersCollection = createCollection<UserData>("users");
export const workspacesCollection = createCollection<WorkspaceData>("workspaces");
export const searchsCollection = (workspaceId: string) => createCollection<SearchData>(`workspaces/${workspaceId}/searchs`);
export const mattersCollection = (workspaceId: string) => createCollection<MatterData>(`workspaces/${workspaceId}/matters`);
export const matterProductsCollection = (workspaceId: string, matterId: string) => createCollection<MatterProductData>(`workspaces/${workspaceId}/matters/${matterId}/products`);

export const offlineSearchesCollection = createCollection<OfflineSearchData>("offline_searches");
export const offlineSearchProductsCollection = (offlineSearchId: string) => createCollection<OfflineSearchProductData>(`offline_searches/${offlineSearchId}/products`);
export const offlineSearchPatentsCollection = (offlineSearchId: string) => createCollection<OfflineSearchPatentData>(`offline_searches/${offlineSearchId}/patents`);

export function getNonEmptyData<T>(snapshot: DocumentSnapshot<T>): T {
    const documentData = snapshot.data();
    if (!documentData) throw new Error("Data should not be empty");
    return documentData;
}

export function getParentId(snapshot: DocumentSnapshot): string {
    if (!snapshot.ref.parent?.parent) throw new Error(`Parent should not be null for ${snapshot.ref.path}`);
    return snapshot.ref.parent.parent.id;
}

export function getGrandparentId(snapshot: DocumentSnapshot): string {
    if (!snapshot.ref.parent?.parent?.parent?.parent) throw new Error(`Grandparent should not be null for ${snapshot.ref.path}`);
    return snapshot.ref.parent.parent.parent.parent.id;
}