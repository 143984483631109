import { ProductClaimChartReasonElementData } from "@patentedai/common/entities/ProductPriorArt";
import { ClaimElementData, ReducedPatentData } from "@patentedai/common/entities/Patent";
import { styled } from "@mui/material/styles";
import { BriefcaseIcon } from "@patentedai/web/images/BriefcaseIcon";
import { CalendarIcon } from "@patentedai/web/images/CalendarIcon";
import { SearchProductBase } from "@patentedai/common/entities/SearchProduct";
import { OfflineSearchProduct } from "@patentedai/web/entities/OfflineSearchProduct";
import { OfflineSearchPatent } from "@patentedai/web/entities/OfflineSearchPatent";
import { DateTime } from "luxon";
import IconWithText from "@patentedai/web/components/core/IconWithText";
import { AuthorIcon } from "@patentedai/web/images/AuthorIcon";

interface PriorArtListItemProps {
  selectedPriorArtId?: string;
  priorArt?: SearchProductBase | OfflineSearchProduct;
  patent: ReducedPatentData;
  handleSelectPriorArt?: handleSelectPriorArt_T | offlineHandleSelectPriorArt_T;

  selectedRelatedPatentId?: string
  relatedPatent?: OfflineSearchPatent;
  handleSelectRelatedPatent?: (relatedPatent: OfflineSearchPatent) => void;
}

type handleSelectPriorArt_T = (priorArt: SearchProductBase) => void;
type offlineHandleSelectPriorArt_T = (priorArt: OfflineSearchProduct) => void;

const PriorArtCard = ({ selectedPriorArtId, priorArt, handleSelectPriorArt, patent, selectedRelatedPatentId, relatedPatent, handleSelectRelatedPatent }: PriorArtListItemProps) => {
  const item = relatedPatent ? relatedPatent : priorArt;
  const company = relatedPatent ? relatedPatent?.priorArtPatentObject.assignee : priorArt?.companyName;
  const releaseDate = relatedPatent ? DateTime.fromFormat(relatedPatent.priorArtPatentObject.priorityDate, "yyyyMMdd").toFormat("yyyy, MMM dd") : priorArt?.stringedReleaseDate;

  const isSelected = priorArt ? selectedPriorArtId === priorArt?.externalId : selectedRelatedPatentId === relatedPatent?.id;


  const onSelectItem = () => {
    if (priorArt) {
      if ("workspaceId" in priorArt) {
        (handleSelectPriorArt as handleSelectPriorArt_T)(priorArt);
      }
      else (handleSelectPriorArt as offlineHandleSelectPriorArt_T)(priorArt);
    }
    else if (relatedPatent) {
      handleSelectRelatedPatent?.(relatedPatent);
    }
  };
  if (!item) return <></>;
  return (
    <ListItemWrapper
      className={isSelected ? "selected" : "not-selected"}
      onClick={onSelectItem}>
      <div className="col name-col">
        {priorArt && (
          <div className="product-name">
            {priorArt?.productName}
          </div>
        )}
        {relatedPatent && (
          <PatentNumber isSelected={isSelected}>
            {relatedPatent.priorArtPatentObject.publicationNumber}
          </PatentNumber>
        )}
        <div className="match-meter">
          {`${item?.score}%`}
        </div>
      </div>
      {relatedPatent && (
        <div className="col name-col">
          <p className="product-name">
            {relatedPatent.priorArtPatentObject.title}
          </p>
        </div>
      )}
      <div className="list-item-divider" />
      <div className="col info-col">
        <div className="info">
          <BriefcaseIcon color={`${isSelected ? "#DF1440" : "#1F2023"}`} /> {company}
        </div>
        {releaseDate &&
          <div className="info">
            <CalendarIcon color={`${isSelected ? "#DF1440" : "#1F2023"}`} />{releaseDate}
          </div>
        }
      </div>
      {relatedPatent && (<>
        <div className="list-item-divider" />
        <div className="col info-col">
          <IconWithText
            icon={<AuthorIcon color={`${isSelected ? "#DF1440" : "#1F2023"}`} />}
            text={relatedPatent?.priorArtPatentObject.authors}
            textColor={isSelected ? "#1F2023" : "#8F8F91"}
            truncateText
          />
        </div>
      </>)
      }
      <div className="list-item-divider" />
      <label className="bingo-label">Claims Overlap</label>
      <div className="bingo-col">
        <Bingo allClaims={patent.claims.filter(claim => item.claimsQuery.includes(claim.id))} claimReasons={item.claimChartReasons} isSelected={isSelected} />
      </div>
    </ListItemWrapper>
  );
};

const ListItemWrapper = styled("div")`
  display: flex;
  padding: 15px 20px 25px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  &.not-selected{
    border: 1px solid var(--color-palette-grey-15, #DDDEDE);
    background: var(--color-palette-grey-5, #F1F3F3);
    .name-col {
      .product-name{
        color: var(--color-palette-grey-100, #1F2023);
        font-size: 15px;
      }
      .match-meter{
        height: 24px;
        padding: 0px 6.194px;
        border-radius: 4.645px;
        font-size: 13.935px;
        line-height: 11.613px;
      }
    }
    .info-col {
      color: var(--color-palette-grey-50, #8F8F91);
    }
    .bingo-label{
      color: var(--color-palette-grey-75, #505154);
    }
  }
  &.selected{
    border: 1px solid rgba(223, 20, 64, 0.25);
    background: #FDF3F5;
    .name-col {
      .product-name{
        color: var(--color-palette-grey-100, #1F2023);
        font-size: 21px;
      }
      .match-meter{
        height: 31px;
        padding: 0px 8px;
        border-radius: 6px;
        font-size: 18px;
        line-height: 15px;
      }
    }
    .info-col {
      color: var(--color-palette-grey-100, #1F2023);
    }
    .bingo-label{
      color: var(--color-palette-red-dark, #DF1440);
    }
  }
  .col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .list-item-divider {
    width: 100%;
    min-height: 1px;
    opacity: 0.1;
    background: #1F2023;
  }
  .name-col {
    .product-name{
      margin: 0;
      overflow: hidden;
      color: var(--color-palette-grey-100, #1F2023);
      font-feature-settings: 'clig' off, 'liga' off;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: Manrope;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      -webkit-line-clamp: 2;
    }
    .match-meter{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--color-palette-red-100, #FB0F42);
      color: #FFF;
      text-align: center;
      font-family: Manrope;
      font-style: normal;
      font-weight: 700;
    }
  }
  .info-col {
    height: 24px;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    .info {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .bingo-label{
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
  }
`;

interface BingoProps {
  claimReasons: ProductClaimChartReasonElementData[]
  allClaims: ClaimElementData[]
  isSelected?: boolean
}

const BingoStyle = styled("div")`
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    &.not-selected{
      .bingo-item.matched{
        background: var(--color-palette-grey-50, #8F8F91);
      }
    }
    .bingo-item{
      display: flex;
      width: 22px;
      height: 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 555px;
      box-sizing: border-box;
    }
    .not-matched{
      border: 1px solid var(--color-palette-grey-15, #DDDEDE);
      background: var(--color-palette-white, #FFF);
      color: var(--color-palette-grey-75, #505154);
      text-align: center;
      font-family: Manrope;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
    }
    .matched{
      background-color: #DF1440;
      color: var(--color-palette-white, #FFF);
      text-align: center;
      font-family: Manrope;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
    }
`;

const Bingo = ({ claimReasons, allClaims, isSelected }: BingoProps) => {
  return (
    <BingoStyle className={isSelected ? "" : "not-selected"}>
      {allClaims.map((claim) => {
        let claimReason = claimReasons.find(claimReason => claimReason.id === claim.id);
        return (
          <>
            <div key={claim.id} className={`${claimReason?.hasOverlap ? "matched" : "not-matched"} bingo-item`} title={claimReason?.reason}>
              {claim.id}
            </div>
            {claim.subElements.map(subElement => {
              const claimReasonSubElement = claimReason?.subElements.find(claimReasonSubElement => claimReasonSubElement.id === subElement.id);
              return (
                <div key={subElement.id} className={`${claimReasonSubElement?.hasOverlap ? "matched" : "not-matched"} bingo-item`} title={claimReasonSubElement?.reason}>
                  {subElement.id}
                </div>
              );
            })}
          </>
        );
      })}
    </BingoStyle>
  );
};

interface PatentNumberProps extends React.HTMLAttributes<HTMLDivElement> {
  isSelected: boolean
}

const PatentNumberWrapper = styled("div")`
display: flex;
padding: 5px 8px;
justify-content: center;
align-items: center;
border-radius: 5px;
&.selected{
  color: var(--color-palette-red-dark, #DF1440);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.7px;
  border: 1px solid var(--invalidate-palette-red-dark, #DF1440);
  background: var(--invalidate-palette-white, #FFF);
}
&.not-selected{
  border-radius: 5px;
  background: var(--invalidate-palette-white, #FFF);
  color: var(--invalidate-palette-grey-100, #1F2023);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.7px;
}
`;

const PatentNumber = ({ children, isSelected }: PatentNumberProps) => {
  return (
    <PatentNumberWrapper className={isSelected ? "selected" : "not-selected"}>
      {children}
    </PatentNumberWrapper>
  );
};

export default PriorArtCard;

