import React, { useState } from "react";
import { TextField, IconButton, InputLabel, InputAdornment } from "@mui/material";
import { ReactComponent as CloseIcon } from "@patentedai/web/images/closeIcon.svg";
import Button from "@patentedai/web/components/core/Button";
import Divider from "@patentedai/web/components/core/Divider";
import { ProductPriorArtData } from "@patentedai/common/entities/ProductPriorArt";
import ModalWrapper from "modal/ModalWrapper";


interface NewMatterModalProps {
  isLoading: boolean;
  open: boolean;
  priorArt?: ProductPriorArtData
  handleClose: () => void;
  handleAddToMatter: (matter?: any) => void;
  handleCreateNewMatter: (name: string, description: string) => void;
}

const AddToMatterModal: React.FC<NewMatterModalProps> = ({ isLoading, open, priorArt, handleClose, handleAddToMatter, handleCreateNewMatter }) => {
  const [existingMatter, setExistingMatter] = useState("");
  const [newMatterName, setNewMatterName] = useState("");
  const [newMatterDescription, setNewMatterDescription] = useState("");

  const onExistingMatterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExistingMatter(e.target.value);
  };
  const onNewMatterNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMatterName(e.target.value);
  };
  const onMatterDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMatterDescription(e.target.value);
  };

  const onAddToMatter = () => {
    //TODO Handle add to matter flow
    handleAddToMatter();
  };
  const onCreateNewMatter = () => {
    //TODO Handle create new matter flow
    handleCreateNewMatter(newMatterName, newMatterDescription);
  };



  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <div className="modal-header">
          <h2 className="modal-title">
            Add <span className="strong"> {priorArt?.productName} </span> to Matter
          </h2>
          <IconButton
            className="close-modal"
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider
          className="modal-divider"
        />
        <div className="modal-body">
          <div className="existing-matter">
            <h3 className="section-title">Add to Existing Matter</h3>
            <div className="search-matter-input">
              <TextField
                fullWidth
                size="small"
                placeholder="Find an existing matter"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        className="add-to-matter-button modal-outlined-button"
                        variant="contained"
                        onClick={onAddToMatter}>
                        Add to Matter
                      </Button>
                    </InputAdornment>
                  ),
                }}
                value={existingMatter}
                onChange={onExistingMatterChange}
              />

            </div>
          </div>
          <Divider
            className="modal-divider">
            OR
          </Divider>
          <div className="new-matter">
            <h3 className="section-title">Create New Matter</h3>
            <div className="new-matter-input">
              <div className="matter-name">
                <InputLabel
                  className="label">
                  Name*</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={newMatterName}
                  onChange={onNewMatterNameChange}
                />
              </div>
              <div className="matter-description">
                <InputLabel
                  className="label">
                  Description</InputLabel>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={newMatterDescription}
                  onChange={onMatterDescriptionChange}
                />
              </div>
            </div>
            <div className="modal-buttons">
              <Button
                showSpinner={isLoading}
                variant="contained"
                className="create-matter-button"
                onClick={onCreateNewMatter}
              >
                Create New Matter & Add
              </Button>
              <Button
                className="cancel-button"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </>
    </ModalWrapper>
  );
};

export default AddToMatterModal;
