import { useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { User } from "@patentedai/web/entities/User";
import { Workspace } from "@patentedai/web/entities/Workspace";
import { authentication } from "@patentedai/web/firebaseApp";
import { getAuth, signOut } from "firebase/auth";
import { Backdrop } from "@mui/material";
import { Loading } from "@patentedai/web/components/core/Loading";

export const AuthProvider = (props: { children: React.ReactNode }) => {
    const [user, setUser] = useState<User | undefined>(undefined);
    const [workspace, setWorkspace] = useState<Workspace | undefined>(undefined);
    const [loadingAuth, setLoadingAuth] = useState(true);

    useEffect(() => {
        authentication.onAuthStateChanged(async firebaseUser => {
            if (firebaseUser) {
                const user = await User.getById(firebaseUser.uid);
                //  In the registration flow, the user can be null because it is created after the user confirmation
                if (user) {
                    setUser(user);
                    if (user.defaultWorkspaceId) {
                        const workspace = await Workspace.getById(user.defaultWorkspaceId);
                        setWorkspace(workspace || undefined);
                    }
                }
            }
            else {
                setUser(undefined);
                setWorkspace(undefined);
            }
            setLoadingAuth(false);
        });
    }, []);

    const logOut = async () => {
        setUser(undefined);
        setWorkspace(undefined);
        await signOut(getAuth());
    };

    const value = {
        user,
        setUser,
        workspace,
        setWorkspace,
        isUserAuthenticated: () => !!user,
        logOut
    };

    return (
        <AuthContext.Provider value={{ ...value }}>
            {
                loadingAuth ?
                    <Backdrop open={true}>
                        <Loading />
                    </Backdrop>
                    : props.children
            }
        </AuthContext.Provider>
    );
};