import ClaimSelectionMenu from "menu/ClaimSelectionMenu";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { createSearch, getPatent } from "@patentedai/web/helpers/functionHelper";
import { ResponseStatus } from "@patentedai/common/api/Common";
import { Loading } from "@patentedai/web/components/core/Loading";
import { Backdrop } from "@mui/material";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import * as Sentry from "@sentry/react";
import ClaimsTable from "searchs/criteria/components/ClaimsTable";

export enum GeneratingSearchStatus {
  No = "no",
  SelectedClaims = "selectedClaims",
  AllClaims = "allClaims",
}

//TODO: Change URL and folder to better comply with current flow. maybe move to patents?
const ClaimSelection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { workspace } = useAuth();
  const routeParams = useParams<"patentPublicationNumber">();
  const [patent, setPatent] = useState<ReducedPatentData | null>(location.state?.patent || null);
  const [selectedClaims, setSelectedClaims] = useState<number[]>([]);
  const [isGeneratingSearch, setIsGeneratingSearch] = useState<GeneratingSearchStatus>(GeneratingSearchStatus.No);

  useEffect(() => {
    const callGetPatent = async () => {
      try {
        if (patent && patent.publicationNumber === routeParams.patentPublicationNumber) {
          // Marked as checked for now 25 Aug 2023
          if(patent?.claims?.length > 0){
            setSelectedClaims([patent?.claims[0].id]);
          }
          return;
        }

        const response = await getPatent({ patentPublicationNumber: `${routeParams.patentPublicationNumber}` });
        if (response.status !== ResponseStatus.Success) {
          return;
        }
        setPatent(response.patent);
        // Marked as checked for now 25 Aug 2023
        if(response.patent?.claims?.length > 0){
          setSelectedClaims([response.patent?.claims[0].id]);
        }

      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    };

    callGetPatent();
  }, [patent, routeParams.patentPublicationNumber]);

  const handleClaimSelect = (claimId: number) => {
    setSelectedClaims((prevSelectedClaims: number[]) => {
      if (prevSelectedClaims.includes(claimId)) {
        return prevSelectedClaims.filter((id) => id !== claimId);
      } else {
        return [...prevSelectedClaims, claimId].sort((a, b) => a - b);
      }
    });
  };

  const isClaimSelected = (claimId: number) => selectedClaims.includes(claimId);

  // Change  isAllClaimsSelected logic for now 25 Aug 2023
  const isAllClaimsSelected = selectedClaims.length === 1;

  const handleSelectAllClaims = () => {
    if (!patent)
      return;

    // Selecting only one claim for now: 25 Aug 2023
    if (selectedClaims.length === 1) {
      setSelectedClaims([]);
    } else {
      const allClaimIds = patent.claims.map((claim) => claim.id);
      if(allClaimIds.length > 0) {
        setSelectedClaims([allClaimIds[0]]);
      } else {
        setSelectedClaims([]);
      }
      
    }
  };

  const handleSearchPriorArt = async (applySelection: boolean) => {
    if (!patent)
      return;

    if (applySelection && !selectedClaims.length)
      return;

    if (!workspace)
      throw new Error("Workspace not found");

    try {
      setIsGeneratingSearch(applySelection ? GeneratingSearchStatus.SelectedClaims : GeneratingSearchStatus.AllClaims);

      const response = await createSearch({
        workspacesId: workspace.id,
        patentPublicationNumber: patent.publicationNumber,
        patentTitle: patent.title,
        selectedClaims: applySelection ? selectedClaims : patent.claims.map((claim) => claim.id)
      });

      if (response.status !== ResponseStatus.Success)
        throw new Error("Error generating Prior Arts");

      navigate(`/search/${response.search.id}/status`);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
    finally {
      setIsGeneratingSearch(GeneratingSearchStatus.No);
    }

  };

  if (!patent)
    return (
      <Backdrop open={true}>
        <Loading />
      </Backdrop>
    );

  return (
    <div className="prior-art-list-container">
      <div className="content">
        <div className="menu">
          <ClaimSelectionMenu
            patent={patent}
            selectedClaims={selectedClaims}
            onClaimSelected={handleClaimSelect}
            onSelectAllClaims={handleSelectAllClaims}
            onSearchPriorArt={handleSearchPriorArt}
            isGeneratingSearch={isGeneratingSearch}
          />
        </div>
        <div className="detail">
          <div className="list">
            <div className="top-area claim-top-area">
              <h1 className="title-header">
                Claim Selection
              </h1>
              <h2 className="subtitle">Almost there! Select the Claims you wish to add in the Prior-Art Search.</h2>
            </div>
            <ClaimsTable
              isClaimSelected={isClaimSelected}
              claims={patent.claims}
              handleClaimSelect={handleClaimSelect}
              handleSelectAllClaims={handleSelectAllClaims}
              isAllClaimsSelected={isAllClaimsSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimSelection;
