import { useState } from "react";
import { styled } from "@mui/material/styles";
import mockUserImage from "@patentedai/web/images/mock-user-image.png";
import Button from "@patentedai/web/components/core/Button";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import MatterMenu from "menu/MatterMenu";
import { useNavigate, useLocation } from "react-router-dom";

const CardList = styled("div")`
  display: flex;
  padding-top: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  .section-header{
    display: flex;
    padding: 4px 0px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    h3{
      color: #000;
      font-family: Inter;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px; /* 121.053% */
      letter-spacing: -0.19px;
    }
    .list-size{
      box-sizing: border-box;
      display: flex;
      width: 18px;
      height: 18px;
      padding: 0px 4px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 7.692px;
      border-radius: 42.308px;
      background: var(--invalidate-brand-patented-red, #FB0F42);
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 11px; /* 100% */
      letter-spacing: -0.11px;
    }
  }
  .section-list{
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
`;

const SectionHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  h2{
    color: var(--invalidate-brand-dark-grey-brand, #1F2023);
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 120.833% */
    letter-spacing: -0.24px;
    margin: 0;
  }
  .new-search-button{
    display: flex;
    padding: 12px 27px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid var(--color-palette-red-dark, #DF1440);
    background: var(--color-palette-red-dark, #DF1440);
    box-shadow: 0px 0px 0px 0px rgba(195, 19, 57, 0.22), 0px 4px 9px 0px rgba(195, 19, 57, 0.21), 0px 17px 17px 0px rgba(195, 19, 57, 0.18), 0px 38px 23px 0px rgba(195, 19, 57, 0.11), 0px 67px 27px 0px rgba(195, 19, 57, 0.03), 0px 105px 29px 0px rgba(195, 19, 57, 0.00);
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 118.75% */
    letter-spacing: 0.16px;
  }
`;



const MatterSingle = () => {
  const { user } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const matter = state?.matter;
  const matterProduct = state?.matterProduct;
  const patent = state?.patent;
  const matters = [matter];
  const matterProducts = [matterProduct];

  const [selectedMatter, setselectedMatter] = useState(
    matters[0]
  );

  const handleSelectMatter = (matter: any) => {
    if (!matter) return;
    setselectedMatter(matter);
  };

  const handleGoToSearch = (searchId?: string) => {
    if (searchId) {
      navigate(`/search/${searchId}/results`);
    }
    else navigate("/patent");
  };

  return (
    <>
      <div className="prior-art-list-container">
        <div className="content prior-art-details-content">
          <div className="menu">
            <MatterMenu
              patent={patent}
              matters={matters}
              selectedMatter={selectedMatter}
              handleSelectMatter={handleSelectMatter}
            />
          </div>
          <div className="detail">
            <div className="info-container">
              <div className="info-header">
                <div className="product-tag">
                  <h1 className="product-name">{selectedMatter?.name}</h1>
                </div>
                <div className="user-profile">
                  <div className="user-info">
                    <div className="user-name">{user?.fullname()}</div>
                    <div className="veritas-legal">Veritas Legal</div>
                  </div>
                  <div className="user-icon">
                    <img src={mockUserImage} alt="user-profile" />
                  </div>
                </div>
              </div>
            </div>
            <SectionHeader>
              <h2>Saved Elements</h2>
              <Button
                className="new-search-button"
                variant="contained"
                onClick={handleGoToSearch}>
                + New Prior Art Search
              </Button>
            </SectionHeader>
            <CardList>
              <div className="section-header">
                <h3>Products</h3> <div className="list-size">{matterProducts.length}</div>
              </div>
              <div className="section-list">{matterProducts.map(() => (
                // <ProductCard
                //   handleNewSearch={handleGoToSearch}
                //   relatedPatent={patent}
                //   product={product}
                // />
                <></>
              ))}</div>
            </CardList>
            <div className="tabs-container"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatterSingle;
