enum ResponseStatus {
    Success = "success",
    Error = "error"
}

interface ApiResponse {
    status: ResponseStatus;
    message?: string;
}

interface SuccessResponse extends ApiResponse {
    status: ResponseStatus.Success;
}

interface ErrorResponse extends ApiResponse {
    status: ResponseStatus.Error;
    message: string;
}

export {
    ResponseStatus
};

export type {
    ApiResponse,
    SuccessResponse,
    ErrorResponse,
};