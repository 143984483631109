function checkVersionAndReload() {
    fetch("/clientVersion.json?v=" + (new Date().getTime())) // adding the querystring forces us to jump the cache
        .then(res => res.json())
        .then(data => {
            if (process.env.REACT_APP_CLIENT_VERSION !== data.version) {
                console.log('Reloaded due to new version "' + data.version + '" found on the server');
                window.location.reload();
            }
        });
}

export default function setupVersionAutoReload() {
    setInterval(() => {
        checkVersionAndReload();
    }, 60 * 10 * 1000);
}