// @ts-ignore
import understandingPatentGif from "@patentedai/web/images/understanding-patent.gif";
import searchingPriorArtGif from "@patentedai/web/images/searching-prior-art.gif";
import searchReadyGif from "@patentedai/web/images/search-ready.gif";
import Logo from "@patentedai/web/components/core/Logo";
import Button from "@patentedai/web/components/core/Button";
import Divider from "@patentedai/web/components/core/Divider";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { ReactComponent as LoadingSearchStep } from "@patentedai/web/images/loadingSearchStep.svg";
import { styled } from "@mui/material/styles";

const StepsLabels = [
    { title: "Understanding Patent", subTitle: "Deconstructing the patent and cross referencing elements", image: <img src={understandingPatentGif} height="130" alt="Scanning Patent" /> },
    { title: "Finding Prior Art", subTitle: "Scanning against hundreds of millions of products", image: <img src={searchingPriorArtGif} height="130" alt="Matching Products" /> },
    { title: "Prior Art Search Ready", subTitle: "All set! No need to wait weeks or months.", image: <img src={searchReadyGif} height="130" alt="All set!" /> },
];

const PriorArtMenuStyling = styled("div")`
    padding: 20px;
    box-sizing: border-box;
    width: 290px;
    min-width: 290px;
    height: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    justify-content: flex-start;
    .menu-title-header{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 32px;
    }
    .logo-divider{
        width: calc(100% + 40px);
        margin-left: -20px;
    }
    .menu-sub-title-header{
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-family: 'Manrope';
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.02em;
        color: #DF1440;
        width: 174px;
        height: 29px;
        background: rgba(251, 15, 66, 0.07);
        border-radius: 6px;
    }
    .selected-claim-list{
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
        .claim{
            box-sizing: border-box;
            height: 42px;
            font-family: 'Manrope';
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;
            display: flex;
            color: #B5B5B6;
            padding: 10px 12px;
            height: 42px;
            border-radius: 6px;
            cursor: pointer;
            width: 100%;
        }
        .selected-claim{
            border: 1px solid #DDDEDE;
            color: #1F2023;
        }
    }
    .current-step-information{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto 0 10px;
        border-radius: 12px;
        gap: 5px;
        width: 100%;
        .title{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 0px;
            gap: 10px;
            height: 32px;

            font-family: 'Manrope';
            font-weight: 600;
            font-size: 16px;
            letter-spacing: -0.01em;
            color: #DF1440;
        }
        .sub-title{
            font-family: 'Inter';
            font-weight: 400;
            font-size: 13px;
            display: flex;
            align-items: flex-end;
            letter-spacing: -0.01em;
            color: #1F2023;
        }
    }
    .go-back-button{
        height: 50px;
        border: 1.5px solid #DF1440;
        border-radius: 5px;

        font-family: 'Inter';
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.01em;
    }
`;

interface GeneratePriorArtLoadingScreenProps {
    step: 1 | 2 | 3;
    onGoBack: () => void;
    onNewClaimSelected: (arg0: number) => void;
    patent: ReducedPatentData
    selectedClaims: number[]
    selectedClaimIndex: number
}

const SearchClaimsMenu = ({ step, patent, selectedClaims, selectedClaimIndex, onGoBack, onNewClaimSelected }: GeneratePriorArtLoadingScreenProps) => {
    return (
        <PriorArtMenuStyling>
            <Logo variant="full" />
            <Divider className="logo-divider" />
            <div className="menu-title-header">Claim Elements for</div>
            <div className="menu-sub-title-header">{patent.publicationNumber}</div>
            <Divider />
            <div className="selected-claim-list styled-scroll">
                {selectedClaims.map((claimNumber, index) => (
                    <div key={claimNumber}
                        className={`claim${selectedClaimIndex === index ? " selected-claim" : ""}`}
                        onClick={() => onNewClaimSelected(claimNumber)}
                    >
                        Claim #{claimNumber < 10 ? `0${claimNumber}` : claimNumber}
                    </div>
                ))}
            </div>
            <div className="current-step-information">
                <div className="title">
                    <LoadingSearchStep />
                    <div className="step-title">{StepsLabels[step - 1].title}</div>
                </div>
                <div className="sub-title" >
                    {StepsLabels[step - 1].subTitle}
                </div>
            </div>
            <Button
                className="go-back-button"
                variant="outlined"
                onClick={onGoBack}>
                Go Back
            </Button>

        </PriorArtMenuStyling>
    );
};

export default SearchClaimsMenu;
