import { getNonEmptyData, getParentId, offlineSearchProductsCollection } from "@patentedai/web/database";
import { DocumentSnapshot, getDocs, orderBy, query } from "firebase/firestore";
import { OfflineSearchProductBase, OfflineSearchProductData } from "@patentedai/common/entities/OfflineSearchProduct";

const mapEntity = function (snapshot: DocumentSnapshot<OfflineSearchProductData>) {
    return new OfflineSearchProduct(snapshot.id, getParentId(snapshot), getNonEmptyData(snapshot));
};

export class OfflineSearchProduct extends OfflineSearchProductBase {
    static async searchAll(offlineSearchId: string) {
        const snapshot = await getDocs(
            query(offlineSearchProductsCollection(offlineSearchId),
                orderBy("score", "desc")
            )
        );
        return snapshot.docs.map(x => mapEntity(x));
    }
}