export const AuthorIcon = ({ color = "#302F37" }: { color?: string }) => (
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1165_12409)">
      <path d="M2.97949 10.5581H6.73154M4.85538 1.27734C2.71904 1.27796 0.986328 3.00511 0.986328 5.14144C0.986328 6.24892 1.45309 7.2462 2.19935 7.9513C2.37176 8.11411 2.48566 8.32923 2.53735 8.56075L3.30095 11.9977C3.39535 12.4227 3.77204 12.7248 4.20754 12.7248H5.50352C5.93871 12.7248 6.31571 12.4224 6.41011 11.9977L7.17371 8.56075C7.22509 8.32923 7.33899 8.11411 7.51171 7.9513C8.25766 7.2462 8.72442 6.24892 8.72442 5.14144C8.72442 3.00511 6.99171 1.27796 4.85538 1.27734Z" stroke={color} strokeWidth="1.35417" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_1165_12409">
        <rect width="9.28571" height="13" fill="white" transform="translate(0.213867 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
