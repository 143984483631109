import { Timestamp } from "firebase/firestore";
import { SerializableTimestamp } from "@patentedai/common/api/SerializableTimestamp";

export const timestampsToDates = (object: any) => {

    const isObject = (value: any) => Object.prototype.toString.call(value) === "[object Object]";
    const isArray = (value: any) => Array.isArray(value);

    const resolveValue = (value: any, key: string, object: { [x: string]: any; }) => {
        if (isObject(value) && "_seconds" in value && "_nanoseconds" in value) {
            object[key] = toDate(value);
        } else if (isArray(value) || isObject(value)) {
            go(value);
        }
    };

    const go = (object: any) => {
        if (isArray(object)) {
            object.forEach((value: any, i: any, array: any) => resolveValue(value, i, array));
        } else if (isObject(object)) {
            Object.keys(object).forEach(key => resolveValue(object[key], key, object));
        }
    };

    go(object);
};

export const datesToTimestamps = (object: any) => {

    const isObject = (value: any) => Object.prototype.toString.call(value) === "[object Object]";
    const isArray = (value: any) => Array.isArray(value);

    const resolveValue = (value: any, key: string, object: { [x: string]: any; }) => {
        if (value instanceof Date) {
            object[key] = toTimestamp(value);
        } else if (isArray(value) || isObject(value)) {
            go(value);
        }
    };

    const go = (object: any) => {
        if (isArray(object)) {
            object.forEach((value: any, i: any, array: any) => resolveValue(value, i, array));
        } else if (isObject(object)) {
            Object.keys(object).forEach(key => resolveValue(object[key], key, object));
        }
    };

    go(object);
};

const toDate = (value: SerializableTimestamp) => {
    const timestamp = new Timestamp(value._seconds, value._nanoseconds);
    return timestamp.toDate();
};

const toTimestamp = (value: Date): SerializableTimestamp => {
    const timestamp = Timestamp.fromDate(value);
    return {
        _seconds: timestamp.seconds,
        _nanoseconds: timestamp.nanoseconds
    };
};