interface WaitlistData {
    email: string;
    status: WaitlistStatus;
    createdAt: Date;
    updatedAt?: Date;
    updatedByUserId?: string;
}

interface WaitlistBase extends WaitlistData {
    id: string
}

class WaitlistBase {
    constructor(id: string, data: WaitlistData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): WaitlistData {
        const { id, ...data } = this;
        return data;
    }
}

enum WaitlistStatus {
    Pending = "pending",
    Approved = "approved",
    Rejected = "rejected"
}

export { WaitlistBase, WaitlistStatus };
export type { WaitlistData };