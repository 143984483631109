import { createContext, useContext } from "react";
import { Search } from "@patentedai/web/entities/Search";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { SearchProductBase } from "@patentedai/common/entities/SearchProduct";

interface SearchContextType {
    searchId?: string;
    setSearchId: React.Dispatch<React.SetStateAction<string | undefined>>;
    search?: Search;
    patent?: ReducedPatentData;
    priorArts: SearchProductBase[];
    getNextPriorArtPage: () => Promise<void>;
    isLoadingPriorArts: boolean;
}

export const SearchContext = createContext<SearchContextType>(
    {
        searchId: undefined,
        setSearchId: (_: React.SetStateAction<string | undefined>) => null,
        search: undefined,
        patent: undefined,
        priorArts: [],
        getNextPriorArtPage: async () => Promise.resolve(),
        isLoadingPriorArts: false
    } as SearchContextType
);

export const useSearch = (): SearchContextType => useContext(SearchContext);