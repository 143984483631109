import { Button as DefaultButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    showSpinner?: boolean,
    disabled?: boolean,
    children: string | React.ReactNode,
    iconName?: string | null,
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    variant?: "contained" | "text" | "outlined"
    textTransform?: "none" | "capitalize" | "uppercase" | "lowercase" | "initial" | "inherit",
    [x: string]: unknown
}

const Button = ({
    showSpinner = false,
    disabled = false,
    iconName = null,
    color = "primary",
    variant = "text",
    textTransform = "uppercase",
    children,
    ...rest }: Props) => {
    return (
        <DefaultButton
            disabled={disabled || showSpinner}
            color={color}
            variant={variant}
            sx={{ textTransform }}
            {...rest}
        >
            {children}
            {showSpinner && <CircularProgress color="inherit" size={20} style={{ marginLeft: 10 }} />}
            {iconName && !showSpinner && <i className="material-icons" style={{ marginLeft: 10 }}>{iconName}</i>}
        </DefaultButton>
    );
};

export default Button;