// @ts-ignore
import { ReactComponent as LinkedIn } from "@patentedai/web/images/linkedIn.svg";
import Button from "@patentedai/web/components/core/Button";

const handleFollowInLinkedIn = () => {
    window.location.href = process.env.REACT_APP_LINKEDIN_URL || "";
};

const Waitlist = () => {
    return (
        <div className="content">
            <h1 className="title-header" >You’re on the list.</h1>
            <h3 className="sub-title-header">You’ll receive an email with account updates.</h3>
            <div className="divider" />
            <div className="comment">
                <LinkedIn />
                <span>
                    follow us in linkedin
                </span>
            </div>
            <Button
                className="button-action"
                variant="contained"
                onClick={handleFollowInLinkedIn}>
                Follow us on LinkedIn
            </Button>
        </div>
    );
};

export default Waitlist;