import { ReactElement } from "react";
import { Modal, ModalProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModalStyling = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  h2,h3{
    margin:0;
  }
  .modal-content{
    display: flex;
    width: 500px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid rgba(11, 19, 36, 0.07);
    box-shadow: 0px 1px 3px 0px rgba(11, 19, 36, 0.10);
    background: #FFF;
    overflow: hidden;
    &:focus-visible{
      outline: none;
    }
    .section-title{
      svg{
        margin-right: 10px;
      }
      color: #000;
      font-family: Manrope;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: -0.17px;
    }
    .modal-divider{
      width:100%;
      color: #6A6A6A;
      text-align: center;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 9.081px;
    }
    .modal-header{
      display: flex;
      padding: 16px 32px;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      .modal-title{
        color: #838383;
        font-family: Manrope;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: -0.19px;
        .strong{
          color: #000
        }
        &.strong{
          color: #000
        }
      }
      .close-modal{
        padding: 0;
      }
      .open-workspace-button{
        display: flex;
        padding: 6px 14px;
        align-items: flex-end;
        gap: 8px;
        border-radius: 5px;
        border: 1.5px solid var(--color-palette-red-dark, #DF1440);
        color: var(--color-palette-red-dark, #DF1440);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15px; /* 125% */
        letter-spacing: -0.12px;
      }
    }
    .modal-body{
      display: flex;
      padding: 32px 32px 40px 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      align-self: stretch;
      background: linear-gradient(180deg, #FFF2F5 0%, #FFF 100%);
      .MuiOutlinedInput-root{
        border-radius: 8px;
        background: #FFF;
        box-shadow: none;
      }
      .existing-matter{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 13px;
        align-self: stretch;
        .search-matter-input{
          display: flex;
          align-items: flex-start;
          align-self: stretch;
          .pfp{
            width: 36px;
            height: 36px;
            font-size: 13px;
          }
          .MuiInputBase-root{
            padding-right: 0;
          }
          .add-to-matter-button{
            height:38px;
            box-shadow: none;
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.14px;
            border-radius: 6px;
            background: var(--invalidate-ui-contrast-red, #DF1440);
            padding: 20px 16px;
          }
        }
      }
      .new-matter{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 22px;
        align-self: stretch;
        .new-matter-input{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 14px;
          align-self: stretch;
          .matter-description,.matter-name{
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-self: stretch;
            .label{
              color: var(--invalidate-ui-grey-600, #737373);
              font-family: Inter;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 9.081px;
            }
          }
        }
        .modal-buttons{
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          .create-matter-button{
            height: 42px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.14px;
            display: flex;
            width: 277px;
            border-radius: 8px;
            border: 1px solid var(--invalidate-ui-contrast-red, #DF1440);
            background: var(--invalidate-ui-contrast-red, #DF1440);
            box-shadow: 0px 0px 0px 0px rgba(195, 19, 57, 0.22), 0px 4px 9px 0px rgba(195, 19, 57, 0.21), 0px 17px 17px 0px rgba(195, 19, 57, 0.18), 0px 38px 23px 0px rgba(195, 19, 57, 0.11), 0px 67px 27px 0px rgba(195, 19, 57, 0.03), 0px 105px 29px 0px rgba(195, 19, 57, 0.00);
          }
          .cancel-button{
            border-radius: 5px;
            border: 1.5px solid var(--invalidate-ui-contrast-red, #DF1440);
            display: flex;
            width: 132px;
            height: 42px;
            gap: 8px;
          }
        }
      }
      .modal-columns {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        .your-matters {
          display: flex;
          min-width: 192px;
          padding-right: 0px;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          border-right: 1px solid var(--color-palette-grey-15, #DDDEDE);
          padding-right: 12px;
          .matter-list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            height: 200px;
            overflow-y: auto;
            .matter{
              display: flex;
              padding: 8px 15px 8px 10px;
              align-items: center;
              align-self: stretch;
              border-radius: 5px;
              background: var(--color-palette-red-15, #FEE9ED);
              color: var(--color-palette-red-dark, #DF1440);
              text-align: right;
              font-family: Manrope;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: 0.7px;
              margin-right: 10px;
            }
          }
        }

        .latest-searches {
          display: flex;
          min-width: 192px;
          padding-right: 0px;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          .search-list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 18.5px;
            height: 200px;
            overflow-y: auto;
            align-self: stretch;
            .search{
              display: flex;
              padding-left: 0px;
              align-items: center;
              gap: 10px;
              cursor: pointer;
              .icon{

              }
              .text{
                color: var(--color-palette-grey-75, #505154);
                text-align: right;
                font-family: Manrope;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 19px; /* 135.714% */
                letter-spacing: 0.14px;
              }
              .search-number{
                color: var(--color-palette-red-100, #FB0F42);
                font-family: Manrope;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 19px;
                letter-spacing: 0.7px;
              }
            }
          }
        }
      }
      .logoff-button{
        display: flex;
        width: fit-content;
        padding: 6px 14px;
        align-items: center;
        gap: 8px;
        color: var(--color-palette-red-dark, #DF1440);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: -0.12px;
      }
      .members{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        .member{
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          .member-info{
            display: flex;
            align-items: center;
            gap: 10px;
            .pfp{
              width: 36px;
              height: 36px;
              border-radius: 5555px;
            }
            .name{
              color: #000;
              font-family: Manrope;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 19px; /* 135.714% */
            }
          }
          .member-role{
            display: flex;
            box-sizing: border-box;
            width: 124px;
            height: 32px;
            padding: 8px 16px;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px;
            border: 1px solid var(--color-palette-grey-50, #8F8F91);
          }
        }
      }
      .user-changes{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        gap: 20px;
        .pfp{

        }
        .name-form{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 15px;
          .name-input{
            display: flex;
            height: 42px;
            padding-left: 0px;
            align-items: center;
            align-self: stretch;
            gap: 6px;
          }
          .name-cta{
            display: flex;
            align-items: center;
            .save-name-button{
              display: flex;
              padding: 6px 14px;
              align-items: center;
              gap: 8px;
              border-radius: 5px;
              border: 1.5px solid var(--color-palette-red-dark, #DF1440);
              color: var(--color-palette-red-dark, #DF1440);
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 15px; /* 125% */
              letter-spacing: -0.12px;
            }
          }
        }
      }
    }
  }
`;

interface ModalWrapperProps extends ModalProps {
  children: ReactElement;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({ children, ...restProps }) => {
  return (
    <ModalStyling {...restProps}>
      <div className="modal-content">
        {children}
      </div>
    </ModalStyling>
  );
};

export default ModalWrapper;

