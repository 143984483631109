import Logo from "@patentedai/web/components/core/Logo";


export const MenuWrapper = ({children, ...rest}:React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className="patent-menu" {...rest}>
      <div className="menu-header">
          <Logo variant="full" />
      </div>
      {children}
    </div>
  );
};