import { styled } from "@mui/material/styles";

interface UserCircleProps extends React.HTMLAttributes<HTMLDivElement>{
  width?: string;
  height?: string;
  fontSize?: string;
}

const UserPfp = styled("div")<UserCircleProps>`
  display: flex;
  width: ${props => props.width ?? "36px"};
  height: ${props => props.height ?? "36px"};
  text-align: center;
  border-radius: 555px;
  background-color: #404658;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-size: ${props => props.fontSize ?? "13px"};
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 1.17px;
  cursor: ${props => props.onClick ? "pointer" : "default"};
`;

interface InitialsAvatarProps extends UserCircleProps {
  name: string;
}

const InitialsAvatar = ({ name, ...rest }: InitialsAvatarProps) => {
  const words = name.trim().split(" ");
  const initials = (words.length >= 2) ? (words[0][0] + words[words.length - 1][0]).toUpperCase() : name[0].toUpperCase();

  return (
    <UserPfp {...rest}>
      {initials}
    </UserPfp>
  );
};

export default InitialsAvatar;
