import { styled } from "@mui/material/styles";
import UserHeader from "searchs/UserHeader";

const SearchCriteriaHeader = () => {

  return (
    <HeaderWrapper>
      <div className="breadcrumbs">
        Breadcrumbs
      </div>
      <UserHeader />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled("div")`
display: flex;
height: 76px;
padding: 12px 0px;
justify-content: space-between;
align-items: center;
align-self: stretch;
.breadcrumbs{
  display: flex;
  height: 36px;
  align-items: center;
  gap: 32px;
}
`;

export default SearchCriteriaHeader;