import { Stack as DefaultStack, StackProps as DefaultStackProps } from "@mui/material";

interface StackProps extends DefaultStackProps {
    children: React.ReactNode,
}

const Stack = ({ children, ...rest }: StackProps) => {
    return (
        <DefaultStack
            {...rest}
        >
            {children}
        </DefaultStack>
    );
};

export default Stack;
