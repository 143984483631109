import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSearch } from "contexts/SearchContext";
import SearchStatus from "./SearchStatus";
import SearchClaims from "./SearchClaims";
import SearchResultDetail from "./SearchResultDetail";
import SearchCriteria from "./criteria/SearchCriteria";

const SearchContainer = () => {
    const routeParams = useParams<{ searchId: string, action: string, itemId?: string }>();
    const { search, setSearchId } = useSearch();

    useEffect(() => {
        setSearchId(routeParams.searchId);
    }, [setSearchId, routeParams.searchId]);

    if (!search) {
        return (
            <div>
                Search not found!
            </div>
        );
    }

    return (
        <>
            {routeParams.action === "status" &&
                <SearchStatus />}
            {routeParams.action === "claims" &&
                <SearchClaims />}
            {routeParams.action === "results" && !routeParams.itemId &&
                <SearchResultDetail/>
            }
            {routeParams.action === "results" && routeParams.itemId &&
                <SearchResultDetail
                    initialSelectedPriorArtId={routeParams.itemId}
                />
            }
            {routeParams.action === "criteria" &&
                <SearchCriteria/>
            }
        </>
    );
};

export default SearchContainer;