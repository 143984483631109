// @ts-ignore
import { ReactComponent as InvalidateIconLogo } from "@patentedai/web/images/invalidateIconLogo.svg";
// @ts-ignore
import { ReactComponent as InvalidateFullLogo } from "@patentedai/web/images/invalidateFullLogo.svg";

export interface LogoProps {
    className?: string;
  variant: "icon" | "text" | "iconText"| "full";
}

const Logo = ({ variant }: LogoProps) => {
    return (
        <div className="logo">
            {
                variant === "icon" &&
                <div className="icon">
                    <InvalidateIconLogo />
                </div>
            }
            {
                variant === "full" &&
                <div className="text">
                    <InvalidateFullLogo />
                </div>
            }
        </div >
    );
};

export default Logo;