import { useState } from "react";
import SearchClaimsMenu from "../menu/SearchClaimsMenu";
import ClaimsCard from "patents/ClaimsCard";
import { useNavigate } from "react-router-dom";
import { Backdrop } from "@mui/material";
import { Loading } from "@patentedai/web/components/core/Loading";
import { CompactPatentCard } from "patents/CompactPatentCard";
// @ts-ignore
import unionLogoBG from "@patentedai/web/images/unionLogoBG.svg";
import makeSytles from "@mui/styles/makeStyles";
import { useSearch } from "contexts/SearchContext";

const useStyles = makeSytles({
    inputOutline: {
        display: "flex",
        padding: "10px",
        background: "rgba(223, 20, 64, 0.04)",
        borderRadius: "20px",
        justifyContent: "center",
        alignItems: "center",
    },

    contentBackground: {
        backgroundImage: `url(${unionLogoBG})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundSize: "auto",
    },

    line: {
        flexGrow: 1,
        height: "1px",
        margin: "0px 45px"
    },

    lineGrey: {
        backgroundColor: "#B5B5B6",
    },

    lineRed: {
        backgroundColor: "#FB0F42",
    },

    steps: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%"
    },

    step: {
        display: "flex",
    },

    stepUpcomingText: {
        color: "#8F8F91"
    },

    stepPassedText: {
        color: "#1F2023"
    }
});

const SearchClaims = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedClaimIndex, setSelectedClaimIndex] = useState(0);
    const { search, patent } = useSearch();


    const handleSeeSearchPage = () => {
        if (!search)
            return;
        navigate(`/search/${search.id}/status`);
    };

    const selectClaim = (claimNumber: number) => {
        setSelectedClaimIndex(search?.targetPatent.selectedClaims.indexOf(claimNumber) || 0);
    };


    if (!patent) {
        return (
            <Backdrop open={true}>
                <Loading />
            </Backdrop>
        );
    }

    const selectedClaim = patent.claims.find(claim => claim.id === search?.targetPatent.selectedClaims[selectedClaimIndex]);
    if (!selectedClaim)
        throw new Error("Selected claim is required at this point");

    return (
        <div className="patent-container">
            <div className={`content generate-prior-art-content ${classes.contentBackground}`}>
                <SearchClaimsMenu
                    step={1}
                    onGoBack={handleSeeSearchPage}
                    onNewClaimSelected={selectClaim}
                    patent={patent}
                    selectedClaims={search?.targetPatent.selectedClaims || []}
                    selectedClaimIndex={selectedClaimIndex}
                />
                <div className="patent-content-confirm">
                    <CompactPatentCard patent={patent} />
                    <div>
                        <h1 className="title-header">
                            Claim #{selectedClaim.id < 10 ? `0${selectedClaim.id}` : selectedClaim.id} Elements
                        </h1>
                    </div>
                    <div style={{ width: "100%" }}>
                        <ClaimsCard claim={selectedClaim} />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default SearchClaims;
