import { ClaimChartReasonElementData } from "@patentedai/common/entities/ClaimChartReasonElement";
import Button from "@patentedai/web/components/core/Button";
import { filterText } from "utils/searchDetailsUtils";


interface UseCaseCardProps {
  claimChartReason?: ClaimChartReasonElementData
  publicationNumber?: string
  handleImageClick: () => void
  diagramImage: Blob | null
  isImageLoaded: boolean
}

const UseCaseCard = ({ claimChartReason, publicationNumber, handleImageClick, diagramImage, isImageLoaded }: UseCaseCardProps) => {
  return (
    <div className="use-case-wrapper">
      <div className="use-case-card">
        <div className="use-case-label">Use Case Scenario</div>
        <div className="use-case-text">
          {claimChartReason?.scenario.split("\n").filter(Boolean).map((paragraph, index) => {
            return <p key={index}>{filterText(paragraph, publicationNumber)}</p>;
          }
          ) ?? ""}
          {isImageLoaded && (
            <div className="diagram">
              {diagramImage && <img src={URL.createObjectURL(diagramImage)} alt="Sequence Diagram" onClick={handleImageClick} className="diagram-image" />}
              <Button onClick={handleImageClick} variant="contained" className="diagram-button">View Full Diagram</Button>
              <div className="fade" />
            </div>
          )}
        </div>
      </div >
    </div >
  );
};

export default UseCaseCard;