import * as Sentry from "@sentry/react";
import setupVersionAutoReload from "./helpers/versionHelper";

setupVersionAutoReload();

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENVIRONMENT) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 0,
        release: "patentedai-invalidate@" + process.env.REACT_APP_CLIENT_VERSION
    });
}