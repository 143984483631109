export const CalendarIcon = ({ color = "black" }: { color?: string }) => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.541 12.7977H2.45988C1.4898 12.7977 0.703125 12.011 0.703125 11.041V2.95988C0.703125 1.9898 1.4898 1.20312 2.45988 1.20312H10.541C11.511 1.20312 12.2977 1.9898 12.2977 2.95988V11.041C12.2977 12.011 11.511 12.7977 10.541 12.7977Z" stroke={color} strokeWidth="0.972973" strokeMiterlimit="10" />
    <path d="M0.703125 4.01367H12.2977" stroke={color} strokeWidth="0.972973" strokeMiterlimit="10" />
    <path d="M3.51314 7.70207C3.99826 7.70207 4.39152 7.30881 4.39152 6.82369C4.39152 6.33858 3.99826 5.94531 3.51314 5.94531C3.02803 5.94531 2.63477 6.33858 2.63477 6.82369C2.63477 7.30881 3.02803 7.70207 3.51314 7.70207Z" fill={color} />
    <path d="M6.49947 7.70207C6.98459 7.70207 7.37785 7.30881 7.37785 6.82369C7.37785 6.33858 6.98459 5.94531 6.49947 5.94531C6.01436 5.94531 5.62109 6.33858 5.62109 6.82369C5.62109 7.30881 6.01436 7.70207 6.49947 7.70207Z" fill={color} />
    <path d="M3.51314 10.8661C3.99826 10.8661 4.39152 10.4729 4.39152 9.98775C4.39152 9.50264 3.99826 9.10938 3.51314 9.10938C3.02803 9.10938 2.63477 9.50264 2.63477 9.98775C2.63477 10.4729 3.02803 10.8661 3.51314 10.8661Z" fill={color} />
    <path d="M6.49947 10.8661C6.98459 10.8661 7.37785 10.4729 7.37785 9.98775C7.37785 9.50264 6.98459 9.10938 6.49947 9.10938C6.01436 9.10938 5.62109 9.50264 5.62109 9.98775C5.62109 10.4729 6.01436 10.8661 6.49947 10.8661Z" fill={color} />
    <path d="M9.48678 7.70207C9.97189 7.70207 10.3652 7.30881 10.3652 6.82369C10.3652 6.33858 9.97189 5.94531 9.48678 5.94531C9.00166 5.94531 8.6084 6.33858 8.6084 6.82369C8.6084 7.30881 9.00166 7.70207 9.48678 7.70207Z" fill={color} />
  </svg>
);