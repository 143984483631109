import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { MenuWrapper } from "./MenuWrapper";
import { GeneratingSearchStatus } from "priorArts/ClaimSelection";
import Button from "@patentedai/web/components/core/Button";
import PatentMenuCard from "./PatentMenuCard";
import SelectedClaimsList from "./components/SelectedClaimsList";

interface SelectedClaimsListProps {
  patent: ReducedPatentData;
  selectedClaims: number[];
  onClaimSelected: (claimId: number) => void;
  onSelectAllClaims: () => void;
  onSearchPriorArt: (applySelection: boolean) => void;
  isGeneratingSearch: GeneratingSearchStatus;
}

const ClaimSelectionMenu = ({ patent, selectedClaims, onClaimSelected, onSelectAllClaims, onSearchPriorArt, isGeneratingSearch }: SelectedClaimsListProps) => {

  const handleClaimCheck = (claimId: number) => {
    onClaimSelected(claimId);
  };

  const selectAllClaims = () => {
    onSelectAllClaims();
  };

  const onConfirmSearch = () => {
    const applySelection = selectedClaims.length > 0;
    onSearchPriorArt(applySelection);
  };

  return (
    <MenuWrapper>
      <div className="claim-selection-menu">
        <PatentMenuCard patent={patent} />
        <SelectedClaimsList
          claimStatusList={selectedClaims.reduce<{ [claimID: number]: boolean }>((claimStatusList, claimID) => {
            claimStatusList[claimID] = true;
            return claimStatusList;
          }, {})}
          onClaimSelected={handleClaimCheck}
          onSelectAllClaims={selectAllClaims}
          patent={patent}
        />
        <div className="navigation-buttons">
          <Button
            variant="contained"
            className="apply-selection"
            onClick={onConfirmSearch}
            showSpinner={isGeneratingSearch === GeneratingSearchStatus.SelectedClaims}
            disabled={isGeneratingSearch !== GeneratingSearchStatus.No}
          >
            Confirm & Search
          </Button>
        </div>
      </div>
    </MenuWrapper>
  );
};

export default ClaimSelectionMenu;

