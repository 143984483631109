interface WorkspaceData {
    name: string;
    status: WorkspaceStatus;
    ownerId: string;
    createdAt: Date;
    updatedAt?: Date;
}

interface WorkspaceBase extends WorkspaceData {
    id: string
}

class WorkspaceBase {
    constructor(id: string, data: WorkspaceData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): WorkspaceData {
        const { id, ...data } = this;
        return data;
    }
}

enum WorkspaceStatus {
    Active = "active",
    Inactive = "inactive",
}

export { WorkspaceBase, WorkspaceStatus };
export type { WorkspaceData };