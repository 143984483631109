import { getNonEmptyData, usersCollection } from "@patentedai/web/database";
import { doc, setDoc, updateDoc, DocumentSnapshot, getDoc } from "firebase/firestore";
import { UserBase, UserData } from "@patentedai/common/entities/User";

const mapEntity = function (snapshot: DocumentSnapshot<UserData>) {
    return new User(snapshot.id, getNonEmptyData(snapshot));
};

const setAuditableData = (data: UserData, isUpdate = false) => {
    if (isUpdate) {
        data.updatedAt = new Date();
    }
    else {
        data.createdAt = new Date();
    }
};

export class User extends UserBase {

    static async getById(id: string) {
        const reference = doc(usersCollection, id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            return null;
        }
        return mapEntity(snapshot);
    }
    static async create(userId: string, userData: UserData) {
        setAuditableData(userData);
        await setDoc(doc(usersCollection, userId), userData);
        return new User(userId, userData);
    }
    static async update(id: string, userData: UserData) {
        setAuditableData(userData, true);
        const reference = doc(usersCollection, id);
        await updateDoc(reference, { ...userData });
    }
}