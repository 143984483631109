import { useEffect, useState } from "react";

interface CountDown {
    startAt: number,
    onExpire: () => void,
    every?: number,
}
const CountDown = ({ every = 1000, startAt, onExpire }: CountDown) => {
    const [count, setCount] = useState<number>(startAt);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const discount = (count: number) => {
            if (count === 0) {
                onExpire();
                return;
            }
            timeoutId = setTimeout(() => {
                setCount(count);
            }, every);
        };
        discount(count - 1);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    return (
        <span>{count || "..."}</span>
    );
};

export default CountDown;