import { styled } from "@mui/material/styles";
import { SelectedClaims_T } from "./SearchCriteria";

interface ClaimComponentsCriteriaProps{
  components: {
    id: number
    title: string;
    description: string;
  }[]
  selectedComponents: SelectedClaims_T
  handleComponentSelect: (componentId: number) => void
}

const ClaimComponentsCriteria = ({components, selectedComponents, handleComponentSelect}:ClaimComponentsCriteriaProps) => {
  const onComponentSelect = (componentId: number) => {
    console.log("onselect");
    handleComponentSelect(componentId);
  };

  return (
    <div className="list">
      <div className="top-area claim-top-area">
        <h1 className="title-header">Claim Components</h1>
        <h2 className="subtitle">Almost there! Select the Claims you wish to add in the Prior-Art Search.</h2>
      </div>
      <ComponentsList>
      {components.map(component => (
        <ClaimComponent
          isSelected={selectedComponents[component.id]}
          title={component.title}
          description={component.description}
          onClick={() => onComponentSelect(component.id)}
        />
      ))}
      </ComponentsList>
    </div>
  );
};

const ComponentsList = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

interface ClaimComponentProps extends React.HTMLAttributes<HTMLDivElement>{
  isSelected?: boolean
  title: string
  description: string
}

const ClaimComponent = ({isSelected, title, description, onClick}: ClaimComponentProps) => (
  <ClaimComponentWrapper selected={isSelected} onClick={onClick}>
    <div className="claim-component-title">{title}</div>
    <div className="claim-component-description">
      {description}
    </div>
  </ClaimComponentWrapper>
);


interface ClaimComponentWrapperProps {
  selected?: boolean
}
const ClaimComponentWrapper = styled("div")<ClaimComponentWrapperProps>`
  display: flex;
  padding: 24px 28px 24px 26px;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--color-palette-grey-15, #DDDEDE);
  background: #FFF;
  ${({selected}) => selected ? "" : "opacity: 0.5;"}
  .claim-component-title{
    display: flex;
    align-items: flex-end;
    padding: 1px 8px 2px 8px;
    background: #EEF1F9;
    color: var(--color-palette-grey-100, #1F2023);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Courier;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 123.529% */
  }
  .claim-component-description{
    color: var(--color-palette-grey-100, #1F2023);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
`;

export default ClaimComponentsCriteria;
