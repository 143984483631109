import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Button from "@patentedai/web/components/core/Button";
import Divider from "@patentedai/web/components/core/Divider";
import ModalWrapper from "modal/ModalWrapper";
import { getMatters, getSearches, updateName } from "@patentedai/web/helpers/functionHelper";
import { ReactComponent as GreaterThan } from "@patentedai/web/images/greaterThan.svg";
import { ReactComponent as GearOutlined } from "@patentedai/web/images/gearOutlined.svg";
import { ReactComponent as PowerOffIcon } from "@patentedai/web/images/powerOff.svg";
import { ReactComponent as DocumentIcon } from "@patentedai/web/images/documentIcon.svg";
import { ReactComponent as SearchIcon } from "@patentedai/web/images/searchIcon.svg";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import { useEffect, useState } from "react";
import { ResponseStatus } from "@patentedai/common/api/Common";
import { MatterBase } from "@patentedai/common/entities/Matter";
import { SearchBase } from "@patentedai/common/entities/Search";
import InitialsAvatar from "@patentedai/web/components/core/InitialsAvatar";
import * as Sentry from "@sentry/react";

interface NewMatterModalProps {
  open: boolean;
  handleClose: () => void;
  handleOpenWorkspaceModal: () => void;
}

const ProfileSettingsModal: React.FC<NewMatterModalProps> = ({ open, handleClose, handleOpenWorkspaceModal }) => {
  const { user, workspace, logOut } = useAuth();
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [matters, setMatters] = useState<MatterBase[]>();
  const [searches, setSearches] = useState<SearchBase[]>();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const navigate = useNavigate();

  const [isChangeNameLoading, setIsChangeNameLoading] = useState(false);

  useEffect(() => {
    if (!workspace) return;
    const getMattersAndSearches = () => {
      getMatters({ workspaceId: workspace.id }).then(res => {
        if (res.status !== ResponseStatus.Success) {
          throw new Error("Error on getting matters");
        }
        setMatters(res.matters);
      });
      getSearches({ workspaceId: workspace.id }).then(res => {
        if (res.status !== ResponseStatus.Success) {
          throw new Error("Error on getting searches");
        }
        setSearches(res.searches);
      });
    };

    getMattersAndSearches();
  }, [workspace]);

  const isUserNameValid = () => {
    return firstName.length > 0 && lastName.length > 0;
  };

  const onChangeUserName = async () => {
    if (!user || !isUserNameValid()) return;
    try {
      setIsChangeNameLoading(true);
      const response = await updateName({ firstName, lastName, userId: user?.id });
      if (response.status !== ResponseStatus.Success) {
        throw new Error("Error changing user name");
      }
      setIsChangeNameLoading(false);
    }
    catch (error) {
      console.error(error);
      Sentry.captureException(error);
      setIsChangeNameLoading(false);
    }
  };

  const onLogout = async () => {
    setIsLoggingOut(true);
    await logOut();
    setIsLoggingOut(false);
    navigate("/");
  };

  const handleGoToSearch = (searchId?: string) => {
    if (searchId) {
      navigate(`/search/${searchId}/results`);
    }
  };

  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <div className="modal-header">
          <h2 className="modal-title strong">
            Profile Settings
          </h2>
          {false && <Button
            className="open-workspace-button"
            variant="outlined"
            onClick={handleOpenWorkspaceModal}>
            <GearOutlined />
            Workspace Settings
          </Button>}
        </div>
        <Divider
          className="modal-divider"
        />
        <div className="modal-body">
          <div className="existing-matter">
            <div className="user-changes">
              <div className="pfp">
                <InitialsAvatar name={user?.fullname() || ""} width="96px" height="96px" fontSize="40px" />
              </div>
              <div className="name-form">
                <div className="name-input">
                  <TextField
                    fullWidth
                    size="small"
                    label="First Name"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="Last Name"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                  />
                </div>
                <div className="name-cta">
                  <Button
                    variant="outlined"
                    className="save-name-button"
                    showSpinner={isChangeNameLoading}
                    disabled={!isUserNameValid()}
                    onClick={onChangeUserName}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Divider className="modal-divider" />
          <div className="modal-columns">
            <div className="your-matters">
              <h3 className="section-title"><DocumentIcon />Your Matters</h3>
              <div className="matter-list styled-scroll">
                {matters?.map(matter => (
                  <div className="matter">
                    {matter.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="latest-searches">
              <h3 className="section-title"><SearchIcon />Latest Searches</h3>
              <div className="search-list styled-scroll">
                {searches?.map(search => (
                  <div className="search"
                    onClick={() => handleGoToSearch(search.id)}>
                    <div className="icon">
                      <GreaterThan />
                    </div>
                    <div className="text">
                      Prior Art Search
                    </div>
                    <div className="search-number">
                      {search.searchNumber}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Divider className="modal-divider" />
          <Button
            className="logoff-button"
            variant="outlined"
            disabled={isLoggingOut}
            onClick={onLogout}>
            <PowerOffIcon />
            Logout
          </Button>
        </div>
      </>
    </ModalWrapper>
  );
};

export default ProfileSettingsModal;
