import { styled } from "@mui/material/styles";
import { ReactComponent as MorePatentsIcon } from "@patentedai/web/images/morePatentsIcon.svg";
import { BriefcaseIcon } from "@patentedai/web/images/BriefcaseIcon";
import { OfflineSearchPatent } from "@patentedai/web/entities/OfflineSearchPatent";
import Button from "@patentedai/web/components/core/Button";
import IconWithText from "@patentedai/web/components/core/IconWithText";

import Switch, { SwitchProps } from "@mui/material/Switch";
import { CalendarIcon } from "@patentedai/web/images/CalendarIcon";
import { useState } from "react";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { OfflineSearch } from "@patentedai/web/entities/OfflineSearch";
import { DateTime } from "luxon";

interface MorePatentsProps {
  notProcessedPatents: OfflineSearchPatent[]
  patent: ReducedPatentData
  offlineSearch: OfflineSearch
}

const MorePatents = ({ notProcessedPatents, offlineSearch, patent }: MorePatentsProps) => {
  const [selectedPatents, setSelectedPatents] = useState<OfflineSearchPatent[]>([]);

  const handleSwitchChange = (patent: OfflineSearchPatent) => {
    if (selectedPatents.some(p => p.priorArtPatentObject.publicationNumber === patent.priorArtPatentObject.publicationNumber)) {
      setSelectedPatents(prevState =>
        prevState.filter(p => p.priorArtPatentObject.publicationNumber !== patent.priorArtPatentObject.publicationNumber)
      );
    } else {
      setSelectedPatents(prevState => [...prevState, patent]);
    }
  };

  const sendMail = () => {
    const recipient = "Invalidate Team (request-analysis@invalidate.com)";
    const subject = "Request for In-depth Patent Analysis";
    console.log(selectedPatents);
    const htmlBody = `
      Prior Art Search #${offlineSearch?.searchNumber}
      ${patent.publicationNumber} / ${patent.title}
      ___________________

      I'd like to request patent analysis vs ${patent.publicationNumber} of the following:
      ________________________________________

      ${selectedPatents.map(patent => `•${patent.priorArtPatentObject.publicationNumber} - ${patent.priorArtPatentObject.title}`).join("\n")}
      ________________________________________

      Please bill me for each at the patent analysis rate.
      Thank you for your support!
    `;

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(htmlBody)}&content-type=text/html`;
    window.location.href = mailtoLink;
  };

  if (notProcessedPatents.length <= 0) return <></>;

  return (
    <MorePatentsWrapper>
      <div className="header">
        <div className="title">
          <MorePatentsIcon />
          More Patents
        </div>
        <div className="select-go">
          <p>
            <span className="highlight">
              Need patent analysis?
            </span>
            {" "}
            Indicate your picks below.
          </p>
          <Button
            variant="contained"
            textTransform="none"
            onClick={sendMail}
          >
            Select & Go!
          </Button>
        </div>
      </div>
      {notProcessedPatents.map((patent, index) => (
        <div className="unprocessed-patent-card" key={index}>
          <div className="patent-info">
            <div className="info-group">
              <div className="patent-number">{patent.priorArtPatentObject.publicationNumber}</div>
              <IconWithText
                icon={<BriefcaseIcon color="#505154" />}
                text={patent.priorArtPatentObject.assignee}
              />
            </div>
            <div className="info-group">
              <IconWithText
                icon={<CalendarIcon />}
                text={DateTime.fromFormat(patent.priorArtPatentObject.priorityDate, "yyyyMMdd").toFormat("yyyy, MMM d")}
              />
            </div>
            <div className="info-group">
              <p className="analysis">
                Get Analysis
              </p>
              <IOSSwitch
                onChange={() => handleSwitchChange(patent)}
              />
            </div>
          </div>
          <p className="patent-title">
            {patent.priorArtPatentObject.title}
          </p>
        </div>
      ))}
    </MorePatentsWrapper>
  );
};

const MorePatentsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  .header {
    display: flex;
    padding: 30px 16px 20px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--invalidate-palette-grey-15, #DDDEDE);
    .title {
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--invalidate-palette-grey-100, #1F2023);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Manrope;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 90.909% */
    }

    .select-go {
      display: flex;
      align-items: center;
      gap: 15px;
      color: var(--color-palette-grey-100, #1F2023);
      .highlight{
        color: var(--invalidate-palette-red-dark, #DF1440);
      }
      font-feature-settings: "clig" off, "liga" off;
      font-family: Manrope;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.15px;
    }
  }

  .unprocessed-patent-card {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid var(--color-palette-grey-15, #DDDEDE);
    gap: 8px;
    .patent-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      .info-group {
        display: flex;
        align-items: center;
        gap: 15px;
        align-self: stretch;
        .patent-number{
          display: flex;
          padding: 6px 10px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 5px;
          background: var(--invalidate-palette-grey-5, #F1F3F3);
          color: var(--invalidate-palette-grey-100, #1F2023);
          text-align: right;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Manrope;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.6px;
        }
        .analysis{
          color: #DF1440;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px; /* 140% */
          letter-spacing: 0.8px;
          text-transform: uppercase;
        }
      }
    }
    .patent-title {
      overflow: hidden;
      color: var(--color-palette-grey-100, #1F2023);
      font-feature-settings: "clig" off, "liga" off;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: Manrope;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  p{
    margin: 0;
  }
`;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 4,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#DF1440",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default MorePatents;