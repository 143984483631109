import { Modal } from "@mui/material";
// @ts-ignore
import CloseIcon from "@patentedai/web/images/close-icon.svg";
// @ts-ignore
import InvalidateIcon from "@patentedai/web/images/invalidate.svg";
import { useState } from "react";

export const AgreementModal = ({ open, onContinue }: any) => {
  const [agree, setAgree] = useState<boolean>(false);

  return (
    <Modal
      open={open}
      style={{ top: 65, outline: "none" }}
      disableAutoFocus={true}
    >
      <div className="agreement-modal">
        <div className="modal fade" aria-labelledby="modalLabel">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <img src={InvalidateIcon} alt="Invalidate" />
                {/* <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={CloseIcon} alt="Close Modal" />
                </button> */}
              </div>
              <div className="modal-body">
                <div className="copy-area">
                  <h2>Beta Agreement</h2>
                  <p>
                    Thank you for participating in Patented’s Beta Program. This
                    Beta Test Agreement (the “Agreement”) applies to and governs
                    your participation in any or all of public or private beta
                    programs. In order to participate in Patented’s Beta
                    Program, you must agree to these terms.
                  </p>
                  <br />
                  <p>
                    BY CLICKING ON THE "YES" OR “I ACCEPT” BUTTON (OR OTHER
                    BUTTON OR MECHANISM DESIGNED TO ACKNOWLEDGE AGREEMENT TO THE
                    TERMS OF THIS AGREEMENT), OR BY DOWNLOADING, ACCESSING OR
                    INSTALLING THE BETA PRODUCT, YOU ARE CONSENTING TO BE BOUND
                    BY THIS AGREEMENT.
                  </p>
                  <br />
                  <p>
                    This Agreement is by and between Licensing, Inc., a Delaware
                    corporation, with offices at 177 Huntington Avenue, #1700,
                    PMB 91559, Boston, Massachusetts 02115, dba Patented.ai, its
                    affiliates and assigns (collectively, “Patented.ai”) and you
                    (“Licensee” or “You”).
                  </p>

                  <h3>
                    <span className="number">1</span>Beta Product
                  </h3>
                  <p>
                    “Beta Product” shall mean those products, software,
                    services, and/or features that are designated as alpha,
                    beta, experimental, pilot, limited release, or pre-release
                    versions of Patented.ai, Licensing.com, Invalidate.com, or
                    Infringements.com, and are provided to You during the term
                    hereof. The Beta Product may contain errors or inaccuracies
                    and may not function as well as commercially released
                    software. You acknowledge and accept all risks associated
                    with using the Beta Product, understanding it may not be
                    functional or error-free.
                  </p>

                  <h3>
                    <span className="number">2</span>License
                  </h3>
                  <p>
                    Patented.ai grants to You a non-exclusive, non-transferable,
                    non-sublicensable license to use the Beta Product solely for
                    the purposes of testing, research, and evaluation. You shall
                    not modify, distribute, permit any third parties access,
                    disassemble, decompile, reverse engineer, rent, reverse
                    engineer, lease, loan, transfer, make copies of, or
                    otherwise attempt to decipher any code in connection with
                    the Beta Product.
                  </p>

                  <h3>
                    <span className="number">3</span>Ownership
                  </h3>
                  <p>
                    All title, interest, and ownership rights in and to the Beta
                    Product and associated documentation, including any
                    improvements, modifications, and enhancements made thereto,
                    are and shall remain in Patented.ai or Patented.ai’s
                    partners. Except for those rights expressly granted herein,
                    no other rights are granted, either express or implied, to
                    You.
                  </p>

                  <h3>
                    <span className="number">4</span>Confidentiality
                  </h3>
                  <p>
                    The Beta Product, the fact of its existence, all
                    accompanying documentation, and all information disclosed by
                    Patented.ai to You hereunder or otherwise in connection with
                    the Beta Product, including without limitation performance
                    data, features and other information relating to or obtained
                    from the Beta Product, is “Confidential Information” of
                    Patented.ai. You will not use the Confidential Information
                    except as necessary under this Agreement, and will not
                    disclose any portion of the Confidential Information to any
                    other person or entity. You will use all reasonable steps to
                    protect the Confidential Information from unauthorized use
                    or disclosure. Confidential Information does not include
                    information that: (1) was rightfully known by You at the
                    time of disclosure without an obligation of confidentiality,
                    (2) is lawfully obtained by You from a third party without
                    restriction on use or disclosure, or (3) is or becomes
                    generally known to the public through no fault or breach of
                    this Agreement by You. You shall notify Patented.ai
                    immediately upon any disclosure of Confidential Information
                    to any third party, regardless of whether such disclosure
                    was inadvertent or intentional.
                  </p>

                  <h3>
                    <span className="number">5</span>Termination
                  </h3>
                  <p>
                    Patented.ai reserves the right to modify or terminate the
                    Beta Product, or your use of the Beta Product, to limit or
                    deny access to the Beta Product, at any time, in its sole
                    discretion, for any reason, with or without notice and
                    without liability to You. You understand and agree that if
                    You use the Beta Product after the date on which the Terms
                    have changed, Patented.ai will treat Your use as acceptance
                    of the updated Terms. This Agreement shall terminate upon
                    the release by Patented.ai of a publicly available version
                    of the Beta Product. Patented.ai does not guarantee that any
                    such publicly available version will be released. Upon
                    termination of this Agreement or at Patented.ai’s request at
                    any time, You agree to promptly destroy all copies of the
                    Beta Product and related materials in Your possession or
                    control, and agree to certify in writing to Patented.ai that
                    you have complied.
                  </p>

                  <h3>
                    <span className="number">6</span>Data & Feedback
                  </h3>
                  <p>
                    You agree that Patented.ai may obtain information and data
                    from You in connection with Your registration, installation,
                    and use of the Beta Product including, without limitation,
                    personal information. Patented.ai may also collect and
                    process technical and performance information about your use
                    of the Beta Product and use this information to support and
                    troubleshoot issues, provide updates, analyze trends and
                    improve Patented.ai’s products or services. Patented.ai
                    shall be allowed to use such data and share such data with
                    Patented.ai’s partners for the program purposes. You hereby
                    consent to Patented.ai’s processing and transferring this
                    information, including, if any, personal information, in
                    conformity with the Patented.ai’s <a href="https://invalidate.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>  , as updated
                    from time to time. Further, You shall provide feedback to
                    Patented.ai concerning the functionality and performance of
                    the Beta Product from time to time as reasonably requested
                    by Patented.ai, including, without limitation, identifying
                    potential errors and improvements. Feedback provided to
                    Patented.ai through any of the foregoing methods in
                    connection with the Beta Product may be used by Patented.ai
                    and its partners to improve or enhance products, services
                    and/or features and, accordingly, Patented.ai shall have a
                    non-exclusive, perpetual, irrevocable, royalty-free,
                    worldwide right and license to use, reproduce, disclose,
                    sublicense, distribute, modify and otherwise exploit such
                    feedback without restriction.
                  </p>
                  <br/>
                  <p className="highlight">
                    <span className="number">7</span>THE BETA PRODUCT MAY
                    CONTAIN ERRORS AND IS PROVIDED FOR LIMITED EVALUATION ONLY.
                    THE BETA PRODUCT IS PROVIDED “AS IS” OR “AS AVAILABLE”
                    WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED,
                    STATUTORY, OR OTHERWISE. PATENTED.AI SPECIFICALLY DISCLAIMS
                    ALL IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY
                    QUALITY, TITLE, NONINFRINGEMENT, AND FITNESS FOR A
                    PARTICULAR PURPOSE. YOU UNDERSTAND AND AGREE THAT ANY
                    MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED
                    THROUGH THE USE OF ANY OF THE SERVICES IS DONE AT YOUR SOLE
                    RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
                    TO YOUR COMPUTER SYSTEM AND/OR LOSS OF DATA THAT RESULTS
                    FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA. NO
                    INFORMATION OR ADVICE, WHETHER ORAL OR WRITTEN, OBTAINED BY
                    YOU FROM PATENTED.AI OR THROUGH THE BETA PRODUCT SHALL
                    CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
                  </p>
                  <br/>
                  <p className="highlight">
                    <span className="number">8</span>TO THE EXTENT PERMITTED BY
                    APPLICABLE LAW, THE TOTAL LIABILITY OF PATENTED.AI ARISING
                    OUT OF OR RELATED TO THIS AGREEMENT SHALL NOT EXCEED $50. IN
                    NO EVENT SHALL PATENTED.AI OR ITS AFFILIATES HAVE LIABILITY
                    FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR
                    CONSEQUENTIAL DAMAGES INCLUDING, BUT NOT LIMITED TO, (I)
                    LOSS OF DATA, (II) LOSS OF INCOME, (III) LOSS OF
                    OPPORTUNITY, (IV) LOST PROFITS, AND (V) COSTS OF RECOVERY OR
                    ANY OTHER DAMAGES, HOWEVER CAUSED AND BASED ON ANY THEORY OF
                    LIABILITY, AND WHETHER OR NOT FOR BREACH OF CONTRACT, TORT
                    (INCLUDING NEGLIGENCE), VIOLATION OF STATUTE, OR OTHERWISE,
                    EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE
                    LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF
                    ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
                  </p>
                  <p>
                    You agree to hold harmless and indemnify Patented.ai, its
                    affiliates and subsidiaries, officers, directors, agents,
                    and employees from and against any third party claim arising
                    from or in any way related to (i) your breach of the Terms,
                    (ii) your use and/or your participation in the Beta Product,
                    or (iii) your violation of applicable laws, rules or
                    regulations in connection with the Beta Product.
                  </p>

                  <h3>
                    <span className="number">9</span>Miscellaneous
                  </h3>
                  <p>
                    This Agreement shall be governed by the laws of the
                    Commonwealth of Massachusetts without reference to conflict
                    of laws principles. You shall not assign this Agreement,
                    directly or indirectly, by operation of law or otherwise,
                    without the prior written consent of Patented.ai. You shall
                    comply in all respects with all U.S. and foreign export and
                    re-export laws and regulations applicable to the technology
                    and documentation provided hereunder. This is the entire
                    agreement between the parties relating to the subject matter
                    hereof. No waiver or modification of this Agreement shall be
                    valid unless in writing signed by each party. If any
                    provision of this Agreement is held by a court of competent
                    jurisdiction to be contrary to law, the remaining provisions
                    of this Agreement shall remain in full force and effect. 
                    You hereby acknowledge that deliverables provided by <a href="http://patented.ai/" target="_blank" rel="noreferrer">Patented.ai</a> {" "} 
                    is not and will not be interpreted or used as legal advice.
                  </p>
                  <br/>
                  <br/>
                </div>
              </div>
              <div className="modal-footer">
                <div className="validation-area">
                  <form action="">
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox"
                        id="checkbox"
                        onChange={(e) => setAgree(e.target.checked)}
                      />
                      I have read and agreed to the Beta Agreement.
                    </label>
                    <input
                      type="submit"
                      disabled={!agree}
                      className="button"
                      value="Continue with Registration"
                      onClick={onContinue}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
