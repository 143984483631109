import ProfileSettingsModal from "modal/ProfileSettingsModal";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import WorkspaceSettingsModal from "modal/WorkspaceSettingsModal";
import InitialsAvatar from "@patentedai/web/components/core/InitialsAvatar";

const UserHeader = () => {
  const { user, workspace } = useAuth();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState(false);

  const onOpenWorkspaceModal = () => {
    setIsWorkspaceModalOpen(true);
    setIsProfileModalOpen(false);
  };

  return (
    <UserHeaderWrapper>
      <div className="user-info">
        <div className="user-name">
          {user?.fullname()}
        </div>
        <div className="veritas-legal">
          {workspace?.name}
        </div>
      </div>
      <div className="user-icon">
        <InitialsAvatar name={user?.fullname() ?? ""} onClick={() => setIsProfileModalOpen(true)}
          width="46px" height="46px" fontSize="18px"
        />
        <ProfileSettingsModal
          handleClose={() => setIsProfileModalOpen(false)}
          handleOpenWorkspaceModal={onOpenWorkspaceModal}
          open={isProfileModalOpen}
        />
        <WorkspaceSettingsModal
          handleClose={() => setIsWorkspaceModalOpen(false)}
          handleOpenWorkspaceModal={onOpenWorkspaceModal}
          open={isWorkspaceModalOpen}
        />
      </div>
    </UserHeaderWrapper>
  );
};

const UserHeaderWrapper = styled("div")`
  display: flex;
  width: 216px;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  .user-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
    .user-name{
      color: var(--color-palette-grey-100, #1F2023);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.15px;
    }
    .veritas-legal{
      color: var(--color-palette-red-dark, #DF1440);
      text-align: right;
      font-family: Manrope;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 10px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }
  .user-icon{
    width: 46px;
    height: 46px;
    font-size: 18px;
  }
`;

export default UserHeader;