import { forwardRef, useState, useEffect, useCallback } from "react";
import { default as Phone, getCountryCallingCode } from "react-phone-number-input/input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { OutlinedInput } from "@mui/material";

interface PhoneInputProps {
    countryCode?: any,
    value: string,
    useLocalFormat: boolean,
    error: boolean,
    onChange: any,
    onValidate: any,
    autoFocus?: boolean,
    [x: string]: any
}

const PhoneInput = (
    {
        countryCode = "US",
        value,
        useLocalFormat,
        error,
        onChange,
        onValidate,
        autoFocus = false,
        ...rest
    }: PhoneInputProps) => {
    const [phoneNumber, setPhoneNumber] = useState();
    const getCountryCode = useCallback(() => {
        return "+" + getCountryCallingCode(countryCode) ?? "";
    }, [countryCode]);

    const getInternationalPhoneNumber = useCallback((value) => {
        let internationalNumber = value;
        if (useLocalFormat && value && !value.startsWith(getCountryCode())) {
            internationalNumber = getCountryCode() + value;
        }
        return internationalNumber;
    }, [useLocalFormat, getCountryCode]);

    const validatePhoneNumber = useCallback((value) => {
        onValidate(isPossiblePhoneNumber(value ?? ""));
    }, [onValidate]);

    useEffect(() => {
        const internationalPhoneNumber = getInternationalPhoneNumber(value);
        setPhoneNumber(internationalPhoneNumber);
        validatePhoneNumber(internationalPhoneNumber);

    }, [value, getInternationalPhoneNumber, validatePhoneNumber]);

    const handleChange = (value: any) => {
        setPhoneNumber(value);
        let localNumber = value;
        if (useLocalFormat && value) {
            localNumber = value.replace(getCountryCode(), "");
        }
        onChange(localNumber);
        validatePhoneNumber(value);
    };

    return (
        <Phone
            {...rest}
            country={countryCode}
            value={getInternationalPhoneNumber(phoneNumber)}
            onChange={handleChange}
            // @ts-ignore
            inputComponent={input}
            error={error}
            autoFocus={autoFocus}
        />
    );
};

export default PhoneInput;

const input = forwardRef((props, ref) => (
    <OutlinedInput
        {...props}
        inputRef={ref}
        style={{ borderRadius: "8px" }}
    />
));
