import { styled } from "@mui/material/styles";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import understandingPatentGif from "@patentedai/web/images/understanding-patent.gif";

interface CompactPatentCardProps {
  patent: ReducedPatentData
}

const CardWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    background: rgba(255, 255, 255, 0.25);
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    width: 100%;
    .patent-information-header{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 15px 20px;
      gap: 4px;
      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      border-radius: 12px;
      width: 100%;
      box-sizing: border-box;
      .image-container{
        img{
          height: 112px;
          width: auto;
          object-fit: contain;
        }
      }
      .card-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .patent-publication-number{
          font-family: Manrope;
          font-weight: 500;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: 0.1em;
          color: #FB0F42;
        }
        .patent-title{
          font-family: Manrope;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          /* identical to box height, or 133% */

          display: flex;
          align-items: center;
          letter-spacing: -0.01em;

          /* Invalidate Brand/Dark Grey (brand) */

          color: #1F2023;
          display: flex;
          gap: 50px;
          flex-direction: row;
          align-items: center;
        }
      }
    }
`;

export const CompactPatentCard = ({ patent }: CompactPatentCardProps) => (
  <CardWrapper>
    <div className="patent-information-header">
      <div className="image-container">
        <img src={understandingPatentGif} alt="Understanding Patent" />
      </div>
      <div className="card-text">
        <div className="patent-publication-number">{patent.publicationNumber}</div>
        <div className="patent-title">{patent.title}</div>
      </div>
    </div>
  </CardWrapper>
);