import { ReactComponent as CloseIcon } from "@patentedai/web/images/closeIcon.svg";
import Divider from "@patentedai/web/components/core/Divider";
import InitialsAvatar from "@patentedai/web/components/core/InitialsAvatar";
import ModalWrapper from "modal/ModalWrapper";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import Button from "@patentedai/web/components/core/Button";
import { useEffect, useState } from "react";
import { getMembers, inviteMember, updateMemberRole, updateWorkspaceName } from "@patentedai/web/helpers/functionHelper";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import { MemberBase, MemberRole } from "@patentedai/common/entities/Member";
import { ResponseStatus } from "@patentedai/common/api/Common";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Sentry from "@sentry/react";

interface NewMatterModalProps {
  open: boolean;
  handleClose: () => void;
  handleOpenWorkspaceModal: () => void;
}

const WorkspaceSettingsModal: React.FC<NewMatterModalProps> = ({ open, handleClose }) => {
  const { workspace } = useAuth();
  const [workspaceName, setWorkspaceName] = useState(workspace?.name ?? "");
  const [members, setMembers] = useState<MemberBase[]>();
  const [inviteeMail, setInviteeMail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    const getWorkspaceMembers = async () => {
      if (!workspace) return;
      const response = await getMembers({ workspaceId: workspace?.id });
      console.log(response);
      if (response.status === "success") {
        setMembers(response.members);
      }
    };

    getWorkspaceMembers();
  }, [workspace]);

  const onInviteMemberToWorkspace = async () => {
    try {
      if (!workspace)
        throw new Error("Workspace not found");

      const response = await inviteMember({
        workspaceId: workspace.id,
        email: inviteeMail
      });

      if (response.status !== ResponseStatus.Success)
        throw new Error("Error sending Invite");


    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInviteeMail(inputValue);
    setIsValidEmail(validateEmail(inputValue));
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const onEditWorkspaceName = async () => {
    if (!workspace || workspaceName.length < 1) return;
    try {
      const response = await updateWorkspaceName({ name: workspaceName, workspaceId: workspace?.id });
      if (response.status !== "success") {
        throw new Error("Error on editing workspace name");
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  const onChangeMemberRole = async (memberToChange: MemberBase, e: SelectChangeEvent<MemberRole>) => {
    const newRole = e.target.value;
    const isValidMemberRole = (value: string): value is MemberRole => {
      return Object.values(MemberRole).includes(value as MemberRole);
    };
    const updateMemberRoleOnState = (memberId: string, newRole: MemberRole) => {
      setMembers(prevMembers => {
        if (prevMembers) {
          return prevMembers.map(member => {
            if (member.id === memberId) {
              // Update the role of the matching member
              return { ...member, role: newRole };
            }
            return member;
          }) as MemberBase[];
        }
        return prevMembers;
      });
    };

    if (!isValidMemberRole(newRole) || !workspace || memberToChange.id === workspace.ownerId) return;

    try {
      const response = await updateMemberRole({ role: newRole, userId: memberToChange.id, workspaceId: workspace?.id });
      if (response.status !== "success") {
        throw new Error("Error on updating role");
      }
      updateMemberRoleOnState(memberToChange.id, newRole);
    }
    catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <div className="modal-header">
          <h2 className="modal-title strong">
            Workspace Settings
          </h2>
          <IconButton
            className="close-modal"
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider
          className="modal-divider"
        />
        <div className="modal-body">
          <div className="existing-matter">
            <div className="search-matter-input">
              <TextField
                fullWidth
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        className="add-to-matter-button modal-outlined-button"
                        variant="contained"
                        disabled={workspaceName.length < 1}
                        onClick={onEditWorkspaceName}>
                        Edit Name
                      </Button>
                    </InputAdornment>
                  ),
                }}
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
              />

            </div>
          </div>
          <Divider className="modal-divider" />
          <div className="existing-matter">
            <h3 className="section-title">Add People to Workspace</h3>
            <div className="search-matter-input">
              <TextField
                fullWidth
                size="small"
                placeholder="Add members by email"
                onChange={handleEmailChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        className="add-to-matter-button modal-outlined-button"
                        variant="contained"
                        disabled={!isValidEmail}
                        onClick={onInviteMemberToWorkspace}>
                        Invite Member
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />

            </div>
          </div>
          <HorizontalDivider numberOfMembers={members?.length} />
          <div className="members">
            {members && members.map((member) => (
              <div key={member.id} className="member">
                <div className="member-info">
                  <div className="pfp" style={{ width: "36px", height: "36px", fontSize: "13px" }}>
                    <InitialsAvatar name={member.name} width="36px" height="36px" fontSize="13px" />
                  </div>
                  <div className="name">{member.name}</div>
                </div>
                <Select
                  labelId="matter-dropdown"
                  className="member-role"
                  value={member.role}
                  disabled={member.id === workspace?.ownerId}
                  onChange={(e) => onChangeMemberRole(member, e)}
                  label=""
                >
                  {Object.values(MemberRole).map((key) => (
                    <MenuItem value={key} disabled={member.id === workspace?.ownerId}>{key}</MenuItem>
                  ))}
                </Select>
              </div>
            ))}
          </div>
        </div>
      </>
    </ModalWrapper>
  );
};

const DividerWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;
`;

const CircleNumber = styled("div")`
  display: flex;
  width: 18px;
  height: 18px;
  padding: 0px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.692px;
  border-radius: 42.308px;
  background: var(--color-palette-red-dark, #DF1440);
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px; /* 0% */
  letter-spacing: -0.11px;
  box-sizing: border-box;
  margin: 0px 20px 0px 8px;
`;

const Line = styled("div")`
  flex: 1;
  border-bottom: 1px solid #6A6A6A;
  opacity: 0.20000000298023224;
`;

const HorizontalDivider = ({ numberOfMembers }: { numberOfMembers?: number }) => {
  return (
    <DividerWrapper>
      <span>Members</span>
      <CircleNumber>{numberOfMembers}</CircleNumber>
      <Line />
    </DividerWrapper>
  );
};

export default WorkspaceSettingsModal;
