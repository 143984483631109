import SearchCriteriaMenu from "menu/SearchCriteriaMenu";
import { useEffect, useState } from "react";
import { Loading } from "@patentedai/web/components/core/Loading";
import { Backdrop } from "@mui/material";
import { useSearch } from "contexts/SearchContext";
import SearchCriteriaHeader from "./SearchCriteriaHeader";
import ClaimSelectionCriteria from "./ClaimSelectionCriteria";
import ClaimComponentsCriteria from "./ClaimComponentsCriteria";


export enum GeneratingSearchStatus {
  No = "no",
  SelectedClaims = "selectedClaims",
  AllClaims = "allClaims",
}

export type SelectedClaims_T = { [claimID: number]: boolean };
export type CriteriaPages_T = "CLAIM_COMPONENTS" | "CLAIM_SELECTION" | "PRODUCT_FOCUS";


const SearchCriteria = () => {
  const { patent, search } = useSearch();
  const [selectedClaims, setSelectedClaims] = useState<SelectedClaims_T>({});
  const [selectedComponents, setSelectedComponents] = useState<SelectedClaims_T>({1:true,2:false,3:true,4:false,5:true});
  const [currentCriteriaPage, setCurrentCriteriaPage] = useState<CriteriaPages_T>("CLAIM_SELECTION");

  const mockComponents = [
    {
      id: 1,
      title: "teste1",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, nesciunt in esse modi voluptatem veritatis odio iure. Nulla ex molestiae illo accusantium nesciunt tempora, perspiciatis, laborum totam dignissimos neque enim."
    },
    {
      id: 2,
      title: "teste2",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, nesciunt in esse modi voluptatem veritatis odio iure. Nulla ex molestiae illo accusantium nesciunt tempora, perspiciatis, laborum totam dignissimos neque enim."
    },
    {
      id: 3,
      title: "teste3",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, nesciunt in esse modi voluptatem veritatis odio iure. Nulla ex molestiae illo accusantium nesciunt tempora, perspiciatis, laborum totam dignissimos neque enim."
    },
    {
      id: 4,
      title: "teste4",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, nesciunt in esse modi voluptatem veritatis odio iure. Nulla ex molestiae illo accusantium nesciunt tempora, perspiciatis, laborum totam dignissimos neque enim."
    },
    {
      id: 5,
      title: "teste5",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, nesciunt in esse modi voluptatem veritatis odio iure. Nulla ex molestiae illo accusantium nesciunt tempora, perspiciatis, laborum totam dignissimos neque enim."
    },
  ];

  useEffect(() => {
    if (!search?.filters?.claims) return;

    setSelectedClaims(
      search?.filters.claims.reduce<{ [claimID: number]: boolean }>(
        (claimStatusList, claims) => {
          claimStatusList[claims.claimNumber] = claims.isSelected;
          return claimStatusList;
        },
        {}
      )
    );
  }, [search]);

  const handleClaimSelect = (claimId: number) => {
    if (!selectedClaims) return;
    setSelectedClaims((prevSelectedClaims) => ({
      ...prevSelectedClaims,
      [claimId]: !prevSelectedClaims[claimId],
    }));
  };

  const handleComponentSelect = (componentId: number) => {
    if(!selectedComponents) return;
    setSelectedComponents(prevSelectedComponents => ({
      ...prevSelectedComponents,
      [componentId]: !prevSelectedComponents[componentId]
    }));
  };

  const isClaimSelected = (claimId: number) => selectedClaims[claimId];

  const onChangeCriteriaPage = (nextPage:CriteriaPages_T) => {
    setCurrentCriteriaPage(nextPage);
  };

  if (!patent || !selectedClaims)
    return (
      <Backdrop open={true}>
        <Loading />
      </Backdrop>
    );

  return (
    <div className="prior-art-list-container">
      <div className="content">
        <div className="menu">
          <SearchCriteriaMenu
            patent={patent}
            claimStatusList={selectedClaims}
            onClaimSelected={handleClaimSelect}
            onChangeCriteriaPage={onChangeCriteriaPage}
          />
        </div>
        <div className="detail">
          <SearchCriteriaHeader />
            {currentCriteriaPage === "CLAIM_SELECTION" &&
              <ClaimSelectionCriteria
                isClaimSelected={isClaimSelected}
                selectedClaims={selectedClaims}
              />
            }
            {currentCriteriaPage === "CLAIM_COMPONENTS" &&
              <ClaimComponentsCriteria
                selectedComponents={selectedComponents}
                handleComponentSelect={handleComponentSelect}
                components={mockComponents}
              />
            }
        </div>
      </div>
    </div>
  );
};

export default SearchCriteria;
