import { useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box, CircularProgress, InputAdornment, Typography } from "@mui/material";
import { useDebouncedEffect } from "@patentedai/web/hooks/useDebouncedEffect";
import { searchPatents } from "@patentedai/web/helpers/functionHelper";
import { ResponseStatus } from "@patentedai/common/api/Common";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import makeSytles from "@mui/styles/makeStyles";
import * as Sentry from "@sentry/react";

const useStyles = makeSytles({
    noBorder: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                border: "none",
            },
        },
    },
    textField: {
        width: 500,
        height: 75,
        background: "#FFFFFF",
        border: "1px solid #FFFFFF",
        boxShadow: "0px 118px 47px rgba(0, 0, 0, 0.01), 0px 66px 40px rgba(0, 0, 0, 0.03), 0px 29px 29px rgba(0, 0, 0, 0.05), 0px 7px 16px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06)",
        borderRadius: 15,
        justifyContent: "center",
        fontSize: "42px",

        "& .MuiOutlinedInput-root": {
            paddingLeft: "0px",
            fontFamily: "Manrope",
            fontWeight: 300,
            fontSize: "42px",
            lineHeight: "28px",
            letterSpacing: "0.2em",

            "& .MuiOutlinedInput-input": {
                paddingLeft: "12px",
            }
        }
    },
    patentAdornment: {
        "& p": {
            color: "rgb(251, 15, 66)",
            width: "80px",
            height: "76px",
            lineHeight: "76px",
            textAlign: "center",
            backgroundColor: "rgb(250,250,250)",
            borderRadius: "15px 0 0 15px",
            fontFamily: "Manrope",
            fontWeight: 600,
            fontSize: "25px",
        }
    },
});

interface PatentAutocompleteProps {
    patentPrefix?: string;
    selectedPatent: ReducedPatentData | null;
    onChange: (patent: ReducedPatentData | null) => void;
}

const PatentAutocomplete = ({ patentPrefix = "US", selectedPatent, onChange }: PatentAutocompleteProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>("");
    const [options, setOptions] = useState<ReducedPatentData[]>([]);
    const [previouslyFetchedText, setPreviouslyFetchedText] = useState<string | null>(null);
    const fetchedOptions = useRef<ReducedPatentData[]>([]);
    const PATENT_PREFIX = patentPrefix.toUpperCase();
    const classes = useStyles();

    useDebouncedEffect(
        async () => {
            if (inputValue.length >= 7) {
                if (previouslyFetchedText && inputValue.startsWith(previouslyFetchedText)) {
                    setOptions(
                        fetchedOptions.current.filter(option =>
                            option.publicationNumber.startsWith(`${PATENT_PREFIX}-${inputValue}`),
                        ),
                    );
                } else {
                    try {
                        setIsLoading(true);
                        const response = await searchPatents({ patentPublicationNumber: `${PATENT_PREFIX}-${inputValue}` });
                        if (response.status !== ResponseStatus.Success) {
                            fetchedOptions.current = [];
                            setOptions([]);
                            return;
                        }
                        fetchedOptions.current = response.patents;
                        setPreviouslyFetchedText(inputValue);
                        setOptions(response.patents);
                    } catch (error) {
                        console.log(error);
                        Sentry.captureException(error);
                    }
                    finally {
                        setIsLoading(false);
                    }
                }
            }
        },
        [inputValue],
        300,
    );

    return (
        <Autocomplete
            // Allowing only one patent for now : 25 Aug 2023
            options={options?.filter((option) => option.publicationNumber === "US-10949543-B1")}
            getOptionLabel={({ publicationNumber }) => publicationNumber.replace(`${PATENT_PREFIX}-`, "")}
            onInputChange={(_, value) => setInputValue(value.toUpperCase())}
            onChange={(_, value) => onChange(value)}
            value={selectedPatent}
            loading={isLoading}
            noOptionsText={inputValue.length >= 7 ? "No patents found" : "Please enter at least 7 characters"}
            renderInput={params => (
                <TextField
                    className={`${classes.noBorder} ${classes.textField}`}
                    {...params}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <InputAdornment className={`${classes.patentAdornment}`} position="start">{PATENT_PREFIX}</InputAdornment>,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <Typography variant="subtitle1">{option.publicationNumber}</Typography>
                        <Typography variant="body2">{option.title}</Typography>
                    </div>
                </Box>
            )}
            loadingText="Searching patents..."
        />
    );
};

export default PatentAutocomplete;