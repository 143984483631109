interface MemberData {
    name: string;
    role: MemberRole;
    createdAt: Date;
    updatedAt?: Date;
}

interface MemberBase extends MemberData {
    id: string;
    workspaceId: string;
}

class MemberBase {
    constructor(id: string, workspaceId: string, data: MemberData) {
        this.id = id;
        this.workspaceId = workspaceId;
        Object.assign(this, data);
    }
    toData(): MemberData {
        const { id, workspaceId, ...data } = this;
        return data;
    }
}

enum MemberRole {
    User = "user",
    Admin = "admin",
}

export { MemberBase, MemberRole };
export type { MemberData };