import { ReducedPatentData } from "@patentedai/common/entities/Patent";
// @ts-ignore
import { BriefcaseIcon } from "@patentedai/web/images/BriefcaseIcon";
import { CalendarIcon } from "@patentedai/web/images/CalendarIcon";
import { AuthorIcon } from "@patentedai/web/images/AuthorIcon";
import Divider from "@patentedai/web/components/core/Divider";
import { DateTime } from "luxon";

interface PatentCardProps {
    patent: ReducedPatentData;
}

const PatentCard = ({ patent }: PatentCardProps) => {
    return (
        <div className="card-content patent-card styled-scroll">
            <div className="patent-card-info">
                <div className="patent-card-publication-number">
                    {patent.publicationNumber}
                </div>
                <div className="patent-card-info-assignee">
                    <BriefcaseIcon color="#DF1440" /> {patent.assignee}
                </div>
            </div>
            <div className="patent-card-title">
                {patent.title}
            </div>
            <Divider sx={{ width: "100%" }} />
            <div className="patent-card-info">
                <div className="patent-card-date">
                    <CalendarIcon color="#8F8F91" />
                    {DateTime.fromFormat(patent.priorityDate, "yyyyMMdd").toFormat("MMM d, yyyy")}
                </div>
                {
                    !!patent.authors.length &&
                    <div className="patent-card-authors">
                        <AuthorIcon color="#505154" />
                        {patent.authors.join(", ")}
                    </div>
                }
            </div>
            <div className="patent-card-abstract">
                {patent.abstract}
            </div>
        </div>
    );
};

export default PatentCard;

