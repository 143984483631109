interface SearchData {
    searchNumber: number;
    targetPatent: {
        publicationNumber: string;
        title: string;
        selectedClaims: number[];
    }
    processingStatusId: string;
    productsProcessingStatus: SearchStatus;
    patentsProcessingStatus: SearchStatus;
    status: SearchStatus;
    processingFinishedAt?: Date;
    createdAt: Date;
    createdByUserId: string;
    updatedAt?: Date;
    updateSearchStatusTaskId?: string;
    filters: SearchFilterCriteria;
}

interface SearchFilterCriteria {
    claims: {
        claimNumber: number;
        isSelected: boolean;
    }[];
    components?: {
        componentId: string;
        isSelected: boolean;
    }[];
    industries?: {
        industryId: string;
        isSelected: boolean;
    }[];
    companies?: {
        companyId: string;
        isSelected: boolean;
    }[];
}

interface SearchBase extends SearchData {
    id: string;
    workspaceId: string;
}

class SearchBase {
    constructor(id: string, workspaceId: string, data: SearchData) {
        this.id = id;
        this.workspaceId = workspaceId;
        Object.assign(this, data);
    }
    toData(): SearchData {
        const { id, workspaceId, ...data } = this;
        return data;
    }
}

enum SearchStatus {
    Queued = "queued",
    Processing = "processing",
    ProcessingChildren = "processing_children",
    Completed = "completed",
    Failed = "failed"
}

export { SearchBase, SearchStatus };
export type { SearchData };