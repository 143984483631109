import { PatentData } from "./Patent";
import { PatentPriorArtData } from "./PatentPriorArt";

interface OfflineSearchPatentData extends PatentPriorArtData {
    priorArtPatentObject: PatentData;

    createdAt: Date;
    updatedAt?: Date;
}

interface OfflineSearchPatentBase extends OfflineSearchPatentData {
    id: string;
    offlineSearchId: string;
}

class OfflineSearchPatentBase {
    constructor(id: string, offlineSearchId: string, data: OfflineSearchPatentData) {
        this.id = id;
        this.offlineSearchId = offlineSearchId;
        Object.assign(this, data);
    }
    toData(): OfflineSearchPatentData {
        const { id, offlineSearchId, ...data } = this;
        return data;
    }
}
const OfflineSearchPatentDataComparer = (a: OfflineSearchPatentData, b: OfflineSearchPatentData) => {
    if (a.score < b.score) {
        return 1;
    }
    if (a.score > b.score) {
        return -1;
    }

    if ((a.priorArtPatentObject?.priorityDate ?? "") < (b.priorArtPatentObject?.priorityDate ?? "")) {
        return 1;
    }
    if ((a.priorArtPatentObject?.priorityDate ?? "") > (b.priorArtPatentObject?.priorityDate ?? "")) {
        return -1;
    }

    if (a.priorArtPatentObject?.title < b.priorArtPatentObject?.title) {
        return -1;
    }
    if (a.priorArtPatentObject?.title > b.priorArtPatentObject?.title) {
        return 1;
    }

    return 0;
};

export { OfflineSearchPatentBase, OfflineSearchPatentDataComparer };
export type { OfflineSearchPatentData };