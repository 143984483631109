import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { signInWithCustomToken } from "firebase/auth";
import { authentication } from "@patentedai/web/firebaseApp";
import { addToWaitlist, getProfile } from "@patentedai/web/helpers/functionHelper";
import { User } from "@patentedai/web/entities/User";
import { Workspace } from "@patentedai/web/entities/Workspace";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import { ResponseStatus } from "@patentedai/common/api/Common";
import { Loading } from "@patentedai/web/components/core/Loading";
import { Backdrop } from "@mui/material";
import { WaitlistStatus } from "@patentedai/common/entities/Waitlist";

const WORKOS_PROFILE_CODE = "code";

const Auth = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [mustRetry, setMustRetry] = useState(false);
    const { search } = useLocation();
    const navigate = useNavigate();
    const { setUser, setWorkspace, logOut } = useAuth();

    const decodeStateQueryParam = (state: string | null) => {
        if (!state) return { orgId: undefined, orgName: undefined };

        const decodedValues = state.split("|");
        const values = { orgId: "", orgName: "" };

        decodedValues.forEach((pair) => {
            const [key, value] = pair.split(":");

            if (key === "orgId" || key === "orgName") {
                values[key] = value;
            }
        });

        if (values.orgId && values.orgName) {
            return values;
        }
        return { orgId: undefined, orgName: undefined };
    };

    useEffect(() => {
        const authenticate = async () => {
            try {
                const query = new URLSearchParams(search);
                const code = query.get(WORKOS_PROFILE_CODE);
                const state = query.get("state");
                const { orgId, orgName } = decodeStateQueryParam(state);
                if (!code)
                    throw new Error("Missing WorkOS code");

                const response = await getProfile({ code });

                if (response.status !== ResponseStatus.Success)
                    throw new Error("Profile not found");
                if (!response.token)
                    throw new Error("Token not found");

                const userCredential = await signInWithCustomToken(authentication, response.token);
                if (response.profile.userExists) {
                    const user = await User.getById(userCredential.user.uid);
                    if (!user)
                        throw new Error("User not found");
                    setUser(user);
                    if (!user.defaultWorkspaceId)
                        throw new Error("Workspace not found");
                    const workspace = await Workspace.getById(user.defaultWorkspaceId);
                    if (!workspace)
                        throw new Error("Workspace not found");
                    setWorkspace(workspace);

                    navigate("/patent", { replace: true });
                }
                else {
                    if (response.profile.waitlistStatus === WaitlistStatus.Approved) {
                        navigate("/signin/personalinfo",
                            {
                                replace: true,
                                state: {
                                    uid: userCredential.user.uid,
                                    firstName: response.profile.firstName,
                                    lastName: response.profile.lastName,
                                    email: response.profile.email,
                                    orgId,
                                    orgName,
                                    isAgreeOnPrivacyPolicy: response.profile.isAgreeOnPrivacyPolicy,
                                }
                            });
                    }
                    else {
                        if (!response.profile.waitlistStatus) {
                            await addToWaitlist({ email: response.profile.email });
                            await logOut();
                        }
                        navigate("/waitlist", { replace: true });
                    }
                }
            } catch (error) {
                console.error(error);
                Sentry.captureException(error);
                setMustRetry(true);
            }
            finally {
                setIsLoading(false);
            }
        };

        authenticate();
    }, []);

    if (isLoading)
        return (
            <Backdrop open={true}>
                <Loading />
            </Backdrop>
        );

    return (
        <>
            {mustRetry &&
                <>
                    An error occurred while authenticating. Please, try again.
                </>}
        </>
    );
};

export default Auth;