import { useEffect } from "react";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import Button from "@patentedai/web/components/core/Button";
import CountDown from "@patentedai/web/components/core/CountDown";
// @ts-ignore
import { ReactComponent as DocumentIcon } from "@patentedai/web/images/documentIcon.svg";

const FinalStep = () => {
    const { user, isUserAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isUserAuthenticated())
            navigate("/", { replace: true });
    }, []);

    const onExpireCountDown = async () => {
        navigate("/patent", { replace: true });
    };

    return (
        <div className="content">
            <h1 className="title-header">Access Approved</h1>
            <h3 className="sub-title-header final-step">{`We are happy to have you onboard, ${user?.firstName}.`}</h3>
            <div className="divider final-step" />
            <Button
                className="button-action"
                variant="contained"
                onClick={onExpireCountDown}>
                Take me to my Dashboard
            </Button>

            <div className="redirect-countdown">
                REDIRECTING YOU IN <CountDown startAt={7} onExpire={onExpireCountDown} />{" SECONDS..."}
            </div>
        </div>
    );
};

export default FinalStep;