import { TextField } from "@mui/material";
import Button from "@patentedai/web/components/core/Button";
import PatentAutocomplete from "@patentedai/web/components/core/PatentAutocomplete/PatentAutocomplete";
// @ts-ignore
import { ReactComponent as SearchIcon } from "@patentedai/web/images/searchIcon.svg";
// @ts-ignore
import unionLogoBG from "@patentedai/web/images/unionLogoBG.svg";
import { AuthorIcon } from "@patentedai/web/images/AuthorIcon";
import { useState } from "react";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { useNavigate } from "react-router-dom";
import makeSytles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";


const useStyles = makeSytles({
    inputOutline: {
        display: "flex",
        padding: "10px",
        background: "rgba(223, 20, 64, 0.04)",
        borderRadius: "20px",
        justifyContent: "center",
        alignItems: "center",
    },

    contentBackground: {
        backgroundImage: `url(${unionLogoBG})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundSize: "auto",
    },
});

const StyledTextField = styled(TextField)`
    width: 502px;
    height: 41px;
    border-radius: 10px;
    border: 1px solid #FFF;
    background: #FFF;
    padding: 10px 15px;
    box-sizing: border-box;

    & .MuiOutlinedInput-root {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }

    & fieldset {
        border:none
    }

    & .MuiInputLabel-root {
        display: none;
    }
`;

const Patent = () => {
    const [patent, setPatent] = useState<ReducedPatentData | null>(null);
    const [inventorName, setInventorName] = useState("");
    const classes = useStyles();
    const navigate = useNavigate();

    const handleContinue = async () => {
        if (!patent) return;

        navigate(`/patent/${patent.publicationNumber}${inventorName.length > 0 ? `?author=${inventorName}` : ""}`,
            {
                state: {
                    patent
                }
            }
        );
    };

    return (
        <div className="patent-container">
            <div className="patent-content">
                <div className={`content ${classes.contentBackground}`}>
                    <h1 className="title-header">Let’s start with a search</h1>
                    <h3 className="sub-title-header">Add a Patent Number to run a search.</h3>
                    <div className={`${classes.inputOutline}`}>
                        <PatentAutocomplete
                            selectedPatent={patent}
                            onChange={setPatent}
                        />
                    </div>
                    <div className="comment">
                        <SearchIcon />
                        <span style={{ marginLeft: 10 }} >
                            REMEMBER TO ADD THE FULL PATENT NUMBER
                        </span>
                    </div>
                    <div className="inventor-input">
                        <div className="comment">
                            <AuthorIcon color="#FB0F42" />
                            <span style={{ marginLeft: 10 }} >
                                inventor’s name <span className="highlight">(optional)</span>
                            </span>
                        </div>
                        <StyledTextField
                            placeholder="Inventor’s Name"
                            value={inventorName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setInventorName(event.target.value);
                            }}
                        />
                    </div>
                    <Button
                        className={`search-button ${patent ? "" : "disabled"}`}
                        variant="contained"
                        onClick={handleContinue}
                        disabled={!patent}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Patent;