import { Link } from "@mui/material";
import Button from "@patentedai/web/components/core/Button";
// @ts-ignore
import unionLogoBG from "@patentedai/web/images/unionLogoBG.svg";
import { useEffect, useState } from "react";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { Backdrop } from "@mui/material";
import { Loading } from "@patentedai/web/components/core/Loading";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import makeSytles from "@mui/styles/makeStyles";
import InventorPatentList from "./InventorPatentList";
import * as Sentry from "@sentry/react";
import { searchPatentsByInventor } from "@patentedai/web/helpers/functionHelper";
import { ResponseStatus } from "@patentedai/common/api/Common";

const useStyles = makeSytles({
  inputOutline: {
    display: "flex",
    padding: "10px",
    background: "rgba(223, 20, 64, 0.04)",
    borderRadius: "20px",
    justifyContent: "center",
    alignItems: "center",
  },

  contentBackground: {
    backgroundImage: `url(${unionLogoBG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    backgroundSize: "auto",
  }
});

const InventorPatents = () => {
  const routeParams = useParams<"inventorName">();
  const location = useLocation();
  const [inventorPatents, setInventorPatents] = useState<ReducedPatentData[] | null>(null);
  const previouslySearchedPatentNumber = location.state?.patentNumber;
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const handleSearchInventorPatents = async () => {
      try {
        if (!routeParams.inventorName)
          throw new Error("Missing patent inventor name");

        const response = await searchPatentsByInventor({ inventor: routeParams.inventorName });

        if (response.status !== ResponseStatus.Success)
          throw new Error("Error generating Prior Arts");

        setInventorPatents(response.patents);
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    };

    handleSearchInventorPatents();
  }, [routeParams.inventorName]);


  const handleResetSearch = () => {
    navigate("/patent");
  };

  const handleReturnToMismatch = () => {
    if (previouslySearchedPatentNumber)
      navigate(`/patent/${previouslySearchedPatentNumber}?author=${routeParams.inventorName}`);
    else {
      navigate("/patent");
    }
  };

  const handleInventorPatentSearch = (patent: ReducedPatentData) => {
    navigate(`/patent/${patent.publicationNumber}`,
      {
        state: {
          patent
        }
      }
    );
  };

  function capitalizeNames(names: string | undefined) {
    if (!names) {
      return "";
    }
    return names
      .split(" ")
      .map(name => name.charAt(0).toUpperCase() + name.slice(1))
      .join(" ");
  }

  if (!inventorPatents) {
    return (
      <Backdrop open={true}>
        <Loading />
      </Backdrop>
    );
  }

  return (
    <div className="patent-container">
      <div className="content">
        <div id="confirm-search" className="patent-content-confirm">
          <h1 className="title-header">{capitalizeNames(routeParams.inventorName)}'s Patents</h1>
          <h3 className="sub-title-header">{`${inventorPatents.length > 0 ? "Select any patent from this inventor to start a new search." : "No patents match the searched inventor"}`}</h3>
          {inventorPatents.length > 0 &&
            <div className={`${classes.inputOutline}`}>
              <InventorPatentList
                patents={inventorPatents}
                handlePatentSearch={handleInventorPatentSearch}
              />
            </div>
          }
          <div className="cta-row">
            <Button
              className="button-common"
              id="go-back-button"
              variant="outlined"
              onClick={handleReturnToMismatch}>
              Go Back
            </Button>
            <div className="comment">
              <span>
                OR&nbsp;
              </span>
              <Link
                component="button"
                className=""
                onClick={handleResetSearch}>
                RESET SEARCH
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventorPatents;