import { useEffect, useState } from "react";
import { SearchContext } from "./SearchContext";
import { Backdrop } from "@mui/material";
import { Loading } from "@patentedai/web/components/core/Loading";
import { Search } from "@patentedai/web/entities/Search";
import { ReducedPatentData } from "@patentedai/common/entities/Patent";
import { useAuth } from "@patentedai/web/contexts/AuthContext/AuthContext";
import { ResponseStatus } from "@patentedai/common/api/Common";
import { getPatent, getSearchProducts } from "@patentedai/web/helpers/functionHelper";
// import { PriorArtDataComparer } from "@patentedai/common/entities/MatterProduct";

import * as Sentry from "@sentry/react";
import { SearchProductBase } from "@patentedai/common/entities/SearchProduct";

const PRIOR_ART_PAGE_SIZE = 400;

export const SearchProvider = (props: { children: React.ReactNode }) => {
    const { workspace } = useAuth();

    const [searchId, setSearchId] = useState<string | undefined>(undefined);
    const [search, setSearch] = useState<Search | undefined>(undefined);
    const [patent, setPatent] = useState<ReducedPatentData | undefined>(undefined);
    const [isLoadingSearchAndPatent, setIsLoadingSearchAndPatent] = useState(true);
    const [priorArts, setPriorArts] = useState<SearchProductBase[]>([]);
    const [priorArtsPageNumber, setPriorArtsPageNumber] = useState<number | undefined>(undefined);
    const [isLoadingPriorArts, setIsLoadingPriorArts] = useState(false);
    const [keepSearchingItems, setKeepSearchingItems] = useState(true);

    useEffect(() => {
        let isMounted = true;

        const getSearch = async () => {
            try {
                if (!workspace || !searchId) {
                    setSearch(undefined);
                    setPatent(undefined);
                    setPriorArts([]);
                    setPriorArtsPageNumber(undefined);
                    setKeepSearchingItems(true);
                    return;
                }

                setIsLoadingSearchAndPatent(true);

                const search = await Search.getById(workspace.id, searchId);
                if (!search) {
                    return;
                }
                const getPatentResponse = await getPatent({ patentPublicationNumber: search.targetPatent.publicationNumber });

                if (isMounted) {
                    setSearch(search);
                    if (getPatentResponse.status !== ResponseStatus.Success) {
                        return;
                    }
                    setPatent(getPatentResponse.patent);
                }
            } catch (error) {
                console.error(error);
                Sentry.captureException(error);
            }
            finally {
                setIsLoadingSearchAndPatent(false);
            }
        };

        getSearch();

        return () => {
            isMounted = false;
        };

    }, [workspace, searchId]);

    const getNextPriorArtPage = async () => {
        try {
            if (!search || !keepSearchingItems || !workspace) {
                return;
            }

            setIsLoadingPriorArts(true);

            const pageNumber = priorArtsPageNumber ? priorArtsPageNumber + 1 : 1;

            const response = await getSearchProducts({
                workspaceId: workspace.id,
                searchId: search.id,
                pageSize: PRIOR_ART_PAGE_SIZE,
            });
            if (response.status !== ResponseStatus.Success)
                throw new Error("Error getting Prior Arts");

            // setPriorArts(prev => prev.concat(response.products).sort(PriorArtDataComparer));
            const products = response.products;
            products.sort((a, b) => b.score - a.score);

            const dateComparator = (a: any, b: any) => {
                const dateA = new Date(a?.stringedReleaseDate);
                const dateB = new Date(b?.stringedReleaseDate);
            
                if (dateA < dateB) {
                    return 1;
                }
                if (dateA > dateB) {
                    return -1;
                }
                return 0;
            };

            products.sort(dateComparator);

            setPriorArts(products?.filter((products) => !["Aqua Trivy", "CyberGRX", "Cyentia", "Cyentia Institute", "Rapid7", "RiskSense"].includes(products.productName)));

            setPriorArtsPageNumber(pageNumber);
            if (response.products.length === 0) {
                setKeepSearchingItems(false);
            }
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
        finally {
            setIsLoadingPriorArts(false);
        }
    };

    const value = {
        searchId,
        setSearchId,
        search,
        patent,
        priorArts,
        getNextPriorArtPage,
        isLoadingPriorArts
    };

    return (
        <SearchContext.Provider value={{ ...value }}>
            {
                isLoadingSearchAndPatent ?
                    <Backdrop open={true}>
                        <Loading />
                    </Backdrop>
                    : props.children
            }
        </SearchContext.Provider>
    );
};