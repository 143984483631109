import { ProductPriorArtData } from "./ProductPriorArt";

interface OfflineSearchProductData extends ProductPriorArtData {
    createdAt: Date;
    updatedAt?: Date;
}

interface OfflineSearchProductBase extends OfflineSearchProductData {
    id: string;
    offlineSearchId: string;
}

class OfflineSearchProductBase {
    constructor(id: string, offlineSearchId: string, data: OfflineSearchProductData) {
        this.id = id;
        this.offlineSearchId = offlineSearchId;
        Object.assign(this, data);
    }
    toData(): OfflineSearchProductData {
        const { id, offlineSearchId, ...data } = this;
        return data;
    }
}

export { OfflineSearchProductBase };
export type { OfflineSearchProductData };