import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "@patentedai/web/entities/Search";
import { SearchStatus as SearchStatusEnum } from "@patentedai/common/entities/Search";
import { CompactPatentCard } from "patents/CompactPatentCard";
import { styled } from "@mui/material/styles";
// @ts-ignore
import { ReactComponent as LoadingSearchStep } from "@patentedai/web/images/loadingSearchStep.svg";
// @ts-ignore
import { ReactComponent as NextSearchStep } from "@patentedai/web/images/nextSearchStep.svg";
// @ts-ignore
import { ReactComponent as CompletedSearchStep } from "@patentedai/web/images/completedSearchStep.svg";
// @ts-ignore
import unionLogoBG from "@patentedai/web/images/unionLogoBG.svg";
import makeSytles from "@mui/styles/makeStyles";
import Button from "@patentedai/web/components/core/Button";
import { useSearch } from "contexts/SearchContext";

const useStyles = makeSytles({
    inputOutline: {
        display: "flex",
        padding: "10px",
        background: "rgba(223, 20, 64, 0.04)",
        borderRadius: "20px",
        justifyContent: "center",
        alignItems: "center",
    },

    contentBackground: {
        backgroundImage: `url(${unionLogoBG})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundSize: "auto",
    },

    line: {
        flexGrow: 1,
        height: "1px",
        margin: "0px 45px"
    },

    lineGrey: {
        backgroundColor: "#B5B5B6",
    },

    lineRed: {
        backgroundColor: "#FB0F42",
    },

    steps: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%"
    },

    step: {
        display: "flex",
    },

    stepUpcomingText: {
        color: "#8F8F91"
    },

    stepPassedText: {
        color: "#1F2023"
    }
});

const StepWrapper = styled("div")`
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      width: 70px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      text-transform: uppercase;
  `;
interface StepsProps extends React.HTMLAttributes<HTMLDivElement> {
    step: number
}
const Steps = ({ step, ...props }: StepsProps) => {
    const classes = useStyles();

    let icons: React.ReactNode[] = [];
    switch (step) {
        case 1:
            icons = [<CompletedSearchStep />, <LoadingSearchStep />, <NextSearchStep />, <NextSearchStep />];
            break;
        case 2:
            icons = [<CompletedSearchStep />, <CompletedSearchStep />, <LoadingSearchStep />, <NextSearchStep />];
            break;
        case 3:
            icons = [<CompletedSearchStep />, <CompletedSearchStep />, <CompletedSearchStep />, <CompletedSearchStep />];
            break;
    }

    return (
        <div className={classes.steps} {...props}>
            <StepWrapper className={classes.stepPassedText}>
                {icons[0]}
                Claim Selection
            </StepWrapper>
            <div className={`${classes.line} ${step > 0 ? classes.lineRed : classes.lineGrey}`} />
            <StepWrapper className={`${step > 0 ? classes.stepPassedText : classes.stepUpcomingText}`}>
                {icons[1]}
                analyzing patent
            </StepWrapper>
            <div className={`${classes.line} ${step > 1 ? classes.lineRed : classes.lineGrey}`} />
            <StepWrapper className={`${step > 1 ? classes.stepPassedText : classes.stepUpcomingText}`}>
                {icons[2]}
                finding prior art
            </StepWrapper>
            <div className={`${classes.line} ${step > 2 ? classes.lineRed : classes.lineGrey}`} />
            <StepWrapper className={`${step > 2 ? classes.stepPassedText : classes.stepUpcomingText}`}>
                {icons[3]}
                prior art ready!
            </StepWrapper>
        </div>
    );

};

const SearchStatus = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [loadingStep, setLoadingStep] = useState<1 | 2 | 3>(1);

    const [searchStatus, setSearchStatus] = useState<string | null>(null);
    const { search, patent } = useSearch();

    useEffect(() => {
        if (!search) return;
        const unsubscribe = Search.listenChanges(search.workspaceId, search.id, (search) => setSearchStatus(search.status));
        return () => unsubscribe();
    }, [search]);

    useEffect(() => {
        const updateStep = async () => {
            if (!searchStatus)
                return;
            switch (searchStatus) {
                case SearchStatusEnum.Queued:
                case SearchStatusEnum.Processing:
                    setLoadingStep(1);
                    break;
                case SearchStatusEnum.ProcessingChildren:
                    setLoadingStep(2);
                    break;
                case SearchStatusEnum.Completed:
                    setLoadingStep(3);
                    break;
                case SearchStatusEnum.Failed:
                    setLoadingStep(3);
                    break;
            }
        };
        updateStep();
    }, [searchStatus]);

    const handleSeeResults = () => {
        if (!search)
            return;
        navigate(`/search/${search.id}/results`);
    };


    if (!patent) {
        return (
            <>Error... No patent found</>
        );
    }

    const handleSeeClaimElements = () => {
        if (!search)
            return;
        navigate(`/search/${search.id}/claims`);
    };

    return (
        <div className="patent-container">
            <div className={`content ${classes.contentBackground}`}>
                <div className="patent-content-confirm confirm-search-content">
                    <h1 className="title-header">Search in progress...</h1>
                    <h3 className="sub-title-header">This will take a moment, so we’ll let you know when the results are ready. Meanwhile we invite you to browse the Claim Elements, you’ll be amazed.</h3>

                    <Steps step={loadingStep} />

                    <CompactPatentCard patent={patent} />

                    <div className="cta-row">
                        <Button
                            className="search-button-smart-filter"
                            variant="contained"
                            onClick={handleSeeClaimElements}>
                            Claim Elements
                        </Button>
                        <Button
                            className="button-common"
                            variant="outlined"
                            disabled={loadingStep !== 3}
                            onClick={handleSeeResults}>
                            See Results
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default SearchStatus;